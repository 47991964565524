import axios from 'axios';
import config from '../constants/config';
import { langs } from '../constants/languages';
import storage from './storage.service';

const version = config.APP_VERSION;
const platform = config.APP_PLATFORM;

const get = (url, headers = {}, params = {}, accessToken = null) => {
  const baseURL = config.API_SERVER;
  const http = axios.create({ baseURL: `${baseURL}/` });
  const authHeader = { Authorization: `Bearer ${accessToken}` };
  let userLanguage = storage.getItem("lokalokator.language");
  if (userLanguage != null) {
    if (userLanguage.language != null) {
      userLanguage = userLanguage.language.toLowerCase();
    } else {
      userLanguage = "en";
    }
  } else {
    userLanguage = "en";
  }  
  if(langs.indexOf(userLanguage.toUpperCase()) === -1) {
    userLanguage = "en";
  }
  let combinedHeaders = {
    ...authHeader,
    ...headers,
    "Content-Language": userLanguage,
    "Version": version,
    "Platform": platform
  };
  return http.get(url, {
    ...params,
    headers: combinedHeaders,
  });
};

const options = (url, headers = {}, params = {}, accessToken = null) => {
  const baseURL = config.API_SERVER;
  const http = axios.create({ baseURL: `${baseURL}/` });
  const authHeader = { Authorization: `Bearer ${accessToken}` };
  let userLanguage = storage.getItem("lokalokator.language");
  if (userLanguage != null) {
    if (userLanguage.language != null) {
      userLanguage = userLanguage.language.toLowerCase();
    } else {
      userLanguage = "en";
    }
  } else {
    userLanguage = "en";
  }  
  if(langs.indexOf(userLanguage.toUpperCase()) === -1) {
    userLanguage = "en";
  }
  return http.options(url, {
    ...params,
    headers: {
      ...authHeader,
      ...headers,
      "Content-Language": userLanguage,
      "Version": version,
      "Platform": platform
    },
  });
};

const post = (url, data, headers = {}, params = {}, accessToken = null) => {
  const baseURL = config.API_SERVER;
  const http = axios.create({ baseURL: `${baseURL}/` });
  const authHeader = { Authorization: `Bearer ${accessToken}` };
  let userLanguage = storage.getItem("lokalokator.language");
  if (userLanguage != null) {
    if (userLanguage.language != null) {
      userLanguage = userLanguage.language.toLowerCase();
    } else {
      userLanguage = "en";
    }
  } else {
    userLanguage = "en";
  }  
  if(langs.indexOf(userLanguage.toUpperCase()) === -1) {
    userLanguage = "en";
  }
  let combinedHeaders = {
    'Content-Type': 'application/json',
    ...headers,
    "Content-Language": userLanguage,
    "Version": version,
    "Platform": platform
  };
  if (url !== 'user/login' || url !== 'user/register') {
    combinedHeaders = { ...authHeader, ...combinedHeaders };
  }
  return http.post(url, data, {
    ...params,
    headers: combinedHeaders,
  });
};

const put = (url, data, headers = {}, params = {}, accessToken = null) => {
  const baseURL = config.API_SERVER;
  const http = axios.create({ baseURL: `${baseURL}/` });
  const authHeader = { Authorization: `Bearer ${accessToken}` };
  let userLanguage = storage.getItem("lokalokator.language");
  if (userLanguage != null) {
    if (userLanguage.language != null) {
      userLanguage = userLanguage.language.toLowerCase();
    } else {
      userLanguage = "en";
    }
  } else {
    userLanguage = "en";
  }  
  if(langs.indexOf(userLanguage.toUpperCase()) === -1) {
    userLanguage = "en";
  }
  return http.put(url, data, {
    ...params,
    headers: {
      ...authHeader,
      ...headers,
      'Content-Type': 'application/json',
      "Version": version,
      "Platform": platform
    },
  });
};

const remove = (url, data, headers = {}, accessToken = null) => {
  const baseURL = config.API_SERVER;
  const http = axios.create({ baseURL: `${baseURL}/` });
  const authHeader = { Authorization: `Bearer ${accessToken}` };
  let userLanguage = storage.getItem("lokalokator.language");
  if (userLanguage != null) {
    if (userLanguage.language != null) {
      userLanguage = userLanguage.language.toLowerCase();
    } else {
      userLanguage = "en";
    }
  } else {
    userLanguage = "en";
  }  
  if(langs.indexOf(userLanguage.toUpperCase()) === -1) {
    userLanguage = "en";
  }  
  return http.delete(url, {
    headers: { ...authHeader, ...headers },
    data,
  });
};

const exportedServices = {
  get,
  options,
  post,
  put,
  remove,
};

export default exportedServices;