import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import config from '../../../constants/config';
import DocumentTitle from "react-document-title";
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100vw - 48px)',
    overflow: 'auto',
    padding: '24px',
    flex: '0 0 auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      textAlign: "left",
      height: "100%",
      width: "calc(90% - 15rem)",
      fontSize: "0.9rem",
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.7rem',
        width: "calc(90% - 5rem)",
      },
      '& > h1': {
        fontSize: "3rem",
        marginTop: "1rem",
        marginLeft: "auto",
        marginRight: "auto",
        fontWeight: "300",
        [theme.breakpoints.down('sm')]: {
          fontSize: '2rem',
        },
      },
      '& > h2': {
        fontSize: "1.5rem",
        fontWeight: "400",
        marginTop: "1rem",
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.85rem',
        },
        '& ~ p': {
          paddingLeft: '4rem',
          [theme.breakpoints.down('sm')]: {
            paddingLeft: '2.5rem',
          },
        },
        '& ~ ul': {
          paddingLeft: '4rem',
          '& > li': {
            '& > a': {
              color: '#8CC63F',
            },
          },
          [theme.breakpoints.down('sm')]: {
            paddingLeft: '2.5rem',
          },
        },
      },
      '& > p': {
        '& + p': {
          marginTop: '0.5rem',
        },
        '&:last-of-type': {
          paddingBottom: '0.5rem',
        },
        '& > a': {
          color: '#8CC63F',  
        },
      },
      '& > strong': {
        width: "100%",
        textAlign: "center",
        marginTop: "1rem",
        fontWeight: "600",
        paddingBottom: "2rem",
        '& > span': {
          color: '#8CC63F',
        },      
      },
    },
  },                                                        
}));

const PoliciesTerms = ({}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Podmínky použití')}`}>                            
      <section className={classes.root}>                                                                                   
      	<div>
      		<h1>Podmínky použití</h1>
      		<strong>Poslední aktualizace: <span>31/05/2022</span></strong>
      		<p>Prosíme, přečtěte si podmínky použití pečlivě, než začnete plně využívat webové stránky <Link to={{pathname: "https://www.lokalokator.cz"}} target="_Blank">lokalokator.cz</Link> - Službu.</p>
      		<p>Váš přístup ke Službě a její používání je podmíněno vaším přijetím a dodržováním těchto podmínek. Tyto podmínky se vztahují na všechny návštěvníky, uživatele a další, kteří ke Službě přistupují nebo ji používají.</p>
      		<p>Přístupem nebo používáním Služby souhlasíte s tím, že budete vázáni těmito podmínkami použití. Pokud nesouhlasíte s jakoukoli částí podmínek, nemůžete mít přístup ke Službě.</p>
      		<h2>Změny</h2>
      		<p>Vyhrazujeme si právo dle vlastního uvážení tyto Podmínky použití kdykoli upravit nebo nahradit. Pokud je revize významná, pokusíme se vás upozornit emailem, který je spojen s Vaším účtem alespoň 30 dní před nabytím účinnosti nových Podmínek použití. Co představuje podstatnou změnu, určíme podle našeho výhradního uvážení.</p>
      		<p>Pokračováním v přístupu nebo používáním naší Služby po nabytí účinnosti těchto revizí souhlasíte s tím, že budete vázáni revidovanými Podmínkami použití. Pokud s novými Podmínkami použití nesouhlasíte, přestaňte Službu používat.</p>
      		<p>Tyto stránky mohou být čas od času aktualizovány a jejich obsah se může kdykoli změnit. Vezměte však na vědomí, že jakýkoli obsah na našem webu může být kdykoli zastaralý a my nejsme povinni jej aktualizovat.</p>
      		<p>Nezaručujeme, že náš web ani žádný jeho obsah nebude obsahovat chyby ani pochybení.</p>
      		<h2>Účet</h2>
      		<p>Pokud se rozhodnote si zvolit nebo Vám bude poskytnut identifikační kód uživatele, heslo nebo jiné informace, musíte s těmito informacemi zacházet jako s důvěrnými. Nesmíte je prozradit žádné třetí straně.</p>
      		<p>Vyhrazujeme si právo pozastavit Váš účet, pokud nahrajete nebo jiným způsobem poskytnete obsah (mimo jiné včetně textu a obrázků), který lze považovat za urážlivý nebo který zobrazuje materiály, značky nebo obrázky značek jakékoli třetí strany, na které se vztahují ochranné známky, reklamní, marketingové nebo propagační materiály, politické nebo náboženské osobnosti, prohlášení nebo symboly, zbraně, alkohol, tabák nebo drogy, sexuální obsah, nahé nebo polonahé lidi jakéhokoli věku.</p>
      		<p>Jste oprávněn kdykoliv zrušit svou registraci a jste oprávněn kdykoliv vymazat svůj obsah.</p>
      		<h2>Ukončení přístupu</h2>
      		<p>Můžeme Vám ukončit nebo pozastavit přístup k naší Službě okamžitě, bez předchozího upozornění nebo odpovědnosti, z jakéhokoli důvodu, a to i bez omezení, pokud porušíte Podmínky.</p>
      		<p>Všechna ustanovení Podmínek použití, která by ze své podstaty měla přetrvávat i po ukončení přístupu, zůstanou v platnosti i po ukončení přístupu.</p>
      		<h2>Služby třetích stran</h2>
      		<p>Naše Služba může obsahovat odkazy na webové stránky nebo služby třetích stran, které nevlastní ani nekontroluje Lokalokátor.</p>
      		<p>Lokalokátor nemá žádnou kontrolu nad obsahem a zásadami ochrany osobních údajů nebo praktikami webových stránek nebo služeb třetích stran a nepřebírá za ně žádnou odpovědnost. Dále berete na vědomí a souhlasíte s tím, že Lokalokátor nebude přímo ani nepřímo odpovědný za jakoukoli škodu, ztrátu způsobenou nebo údajně způsobenou a nebo v souvislosti s používáním či spoléháním se na takový obsah, zboží nebo služby dostupné prostřednictvím jakékoli takové webové stránky nebo služby.</p>
      		<p>Lokalokátor Vám také doporučuje, abyste si přečetli podmínky a zásady ochrany osobních údajů všech služeb třetích stran.</p>
      		<h2>Rozhodné právo</h2>
      		<p>Tyto Podmínky použití se řídí právním řádem České republiky, zejména zák. č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů.</p>
      		<h2>Zasílání novinek</h2>
      		<p>Dle zákona č. 480/2004 Sb. souhlasíte s tím, že je možné na Vaši emailovou adresu spojenou s Vaším účtem zasílat zprávy, obsahující informace především o novinkách na webové stránce.</p>
      		<h2>Čestné použití</h2>
      		<p>Zásady spravedlivého používání odkazují na systémové limity platné pro provoz webu.</p>
      		<p>Upozorňujeme, že váš účet může být dočasně pozastaven, pokud budou tyto limity splněny; nebo může být účet zrušen.</p>
      		<h2>Uživateli vytvořený obsah</h2>
      		<p>Vyhrazujeme si právo odstranit jakoukoliv mapu, profilový obrázek nebo jiný obsah, který lze považovat za urážlivý, zahrnující (ale nejen) urážlivé symboly, prohlášení nebo poznámky nebo jakkoliv jinak nevhodný či nežádoucí obsah.</p>
      		<p>Lokalokátor v žádném případě nezodpovídá za uživateli vytvořený obsah</p>
      		<h2>Kontakt</h2>
      		<p>Máte-li jakékoli dotazy týkající se těchto podmínek, kontaktujte nás</p>
      	</div>
      </section>
    </DocumentTitle>
  );
};

export default PoliciesTerms;
