import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {useTranslation} from "react-i18next";
import streaksTypeFormat from "../../../utils/streaksTypeFormat";
import NormalButton from '../../../components/Buttons/NormalButton';
import PaginationButton from '../../../components/Buttons/PaginationButton';
import pointsFormat from '../../../utils/pointsFormat';
import totalTimesFormat from '../../../utils/totalTimesFormat';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    overflow: 'auto',
  },
  table: {
    width: '100%',
  },
  row: {
    '&:not(:last-of-type)': {
      borderBottomWidth: '0.125rem',
      borderBottomStyle: 'solid',
      borderBottomColor: '#DDDDDD',
    },
  },
  emptyRow: {
    '&:not(:last-of-type)': {
      '& > td': {
        height: '46px',
      },
    },
    '&:last-of-type': {
      '& > td': {
        height: '45px',
      },
    },
  },
  item: {
    padding: '0.5rem 0.5rem',
    whiteSpace: 'nowrap',
    '& > a > div': {
      marginRight: '1rem',
    },
    '&:nth-child(1)': {
      textAlign: 'left',
    },
    '&:nth-child(2)': {
      textAlign: 'right',
      width: '0px',
    },
    '&:nth-child(3)': {
      textAlign: 'right',
      width: '0px',
    },
    '&:nth-child(4)': {
      textAlign: 'right',
      width: '0px',
    },
  },
  linkTo: {
    color: '#8cc63f',
    textDecoration: 'none',
    verticalAlign: 'middle',
  },
  pagination: {
    marginTop: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    gap: '1rem',
  },
  sort: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '1rem 0',
    gap: '0.5rem',
    color: '#000000',
    '& > span': {
      color: '#000000',
      '&.active': {
        color: '#8cc63f',
      },
      '&:not(.active)': {
        cursor: 'pointer',
      },
    },
  },
  filter: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem 0',
    gap: '0.5rem',
    color: '#000000',
    '& > span': {
      color: '#000000',
      '&.active': {
        color: '#8cc63f',
      },
      '&:not(.active)': {
        cursor: 'pointer',
      },
    },
  },
}));

const HistoryTable = ({ data, limit = "10", fillEmpty }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);
  const [sort, setSort] = useState("Newest");
  const [mode, setMode] = useState("All");
  const [isLoaded, setIsLoaded] = useState(false);
  const historyData = data.map( x => {return x} );
  const [showData, setShowData] = useState(data);
  limit = parseInt(limit);
  
  useEffect(() => {
    setIsLoaded(false);
    data.sort((a, b) => {
      let keyA = moment(a.Date).format("x");
      let keyB = moment(b.Date).format("x");
      if(keyA > keyB) return -1;
      if(keyA < keyB) return 1;
      return 0;
    });
    setIsLoaded(true);    
  }, [data]);
  
  const handleData = (type1, type2) => {
    let tmpData = [];
    if(type2 === "All") {
      tmpData = historyData.map( x => {return x} );
    } else if(type2 === "Explorer") {
      let oldData = historyData.map( x => {return x} );
      let newData = [];
      oldData.map((item) => {
        if(item.Mode === "explorer") {
          newData.push(item);
        }
      });
      tmpData = newData;    
    } else if(type2 === "Streaks") {
      let oldData = historyData.map( x => {return x} );
      let newData = [];
      oldData.map((item) => {
        if(item.Mode === "streaks") {
          newData.push(item);
        }
      });
      tmpData = newData;   
    }
    if(type1 === "Newest") {
      tmpData.sort((a, b) => {
        let keyA = moment(a.Date).format("x");
        let keyB = moment(b.Date).format("x");
        if(keyA > keyB) return -1;
        if(keyA < keyB) return 1;
        return 0;
      }); 
    } else if(type1 === "Name") {
      tmpData.sort((a, b) => {
        let keyA;
        let keyB;
        if(a.Mode === "explorer") {
          keyA = t(a.MapName);
        } else if(a.Mode === "streaks") {
          keyA = streaksTypeFormat(a.Type, t);
        }
        if(b.Mode === "explorer") {
          keyB = t(b.MapName);
        } else if(b.Mode === "streaks") {
          keyB = streaksTypeFormat(b.Type, t);
        }
        return keyA.localeCompare(keyB);
      });
    } else if(type1 === "Score") {
      tmpData.sort((a, b) => {
        let keyA1;
        let keyB1;
        if(a.Mode === "explorer") {
          keyA1 = parseInt(a.Points);
        } else if(a.Mode === "streaks") {
          keyA1 = parseInt(a.Guessed);
        }
        if(b.Mode === "explorer") {
          keyB1 = parseInt(b.Points);
        } else if(b.Mode === "streaks") {
          keyB1 = parseInt(b.Guessed);
        }
        if(keyA1 > keyB1) return -1;
        if(keyA1 < keyB1) return 1;
        if(keyA1 === keyB1) {
          let keyA2;
          let keyB2;
          if(a.Mode === "explorer") {
            keyA2 = parseInt(a.Times);
          } else if(a.Mode === "streaks") {
            keyA2 = 0;
          }
          if(b.Mode === "explorer") {
            keyB2 = parseInt(b.Times);
          } else if(b.Mode === "streaks") {
            keyB2 = 0;
          }
          if(keyA2 < keyB2) return -1;
          if(keyA2 > keyB2) return 1;
          return 0;        
        }
        return 0;
      }); 
    }
    setShowData(tmpData);
    setCurrent(0);  
  };
  
  const handleSort = (type,type2) => {
    setSort(type);
    handleData(type, type2)
  };
  
  const handleMode = (type,type2) => {
    setMode(type2);
    handleData(type, type2);
  };
  
  return (
    <div className={classes.root}>
      <div className={classes.sort}>
        <p>{t('Seřadit podle')}:</p>
        <span className={`${sort === "Newest" && "active"}`} onClick={() => sort !== "Newest" && handleSort("Newest", mode)}>{t('Nejnovější')}</span>
        |
        <span className={`${sort === "Name" && "active"}`} onClick={() => sort !== "Name" && handleSort("Name", mode)}>{t('Název')}</span>
        |
        <span className={`${sort === "Score" && "active"}`} onClick={() => sort !== "Score" && handleSort("Score", mode)}>{t('Skóre')}</span>
      </div>
      <div className={classes.filter}>
        <p>{t('Filtrovat podle režimu')}:</p>
        <span className={`${mode === "All" && "active"}`} onClick={() => mode !== "All" && handleMode(sort, "All")}>{t('Všechny režimy')}</span>
        |
        <span className={`${mode === "Explorer" && "active"}`} onClick={() => mode !== "Explorer" && handleMode(sort, "Explorer")}>{t('Průzkumník')}</span>
        |
        <span className={`${mode === "Streaks" && "active"}`} onClick={() => mode !== "Streaks" && handleMode(sort, "Streaks")}>{t('Série')}</span>
      </div>
      <table className={classes.table}>
        <tbody>
        {
          isLoaded && showData.slice(current, current + limit).map((history,key) => (
            <tr key={`${"k_"}${key}`} className={classes.row}>
              <td className={classes.item}>
                {
                  history.Mode === "explorer" && (<Link className={classes.linkTo} to={{pathname: '/mode/explorer/' + history.MID + '/map'}}>{t(history.MapName)}</Link>)
                }
                {
                  history.Mode === "streaks" && (<Link className={classes.linkTo} to={{pathname: '/mode/streaks/' + history.Type.toLowerCase()}}>{streaksTypeFormat(history.Type,t)}</Link>)
                }
              </td>
              {
                history.Mode === "explorer" && (
                  <td className={classes.item}>
                    {pointsFormat(history.Points, t)}
                  </td>
                )
              }
              {
                history.Mode === "streaks" && (
                  <td className={classes.item} colSpan={"2"}>
                    {t('Uhádnuto')}: {history.Guessed}x
                  </td>
                )
              }
              {
                history.Mode === "explorer" && (
                  <td className={classes.item}>
                    {totalTimesFormat(history.Times, t)}
                  </td>
                )
              }
              <td className={classes.item}>
                <Link className={classes.linkTo} to={{pathname: '/results/' + history.GID}}>
                  <SearchOutlinedIcon/>
                </Link>
              </td>
            </tr>
          ))    
        }
        {
          !isLoaded && Array.from('x'.repeat(showData.length > limit ? limit : showData.length)).map((i,key) => (
            <tr key={`${"k_"}${key}`} className={classes.emptyRow}>
              <td/>
            </tr>
          )) 
        }
        {
          fillEmpty && data.length > limit && showData.slice(current, current + limit).length < limit && Array.from('x'.repeat(limit - showData.slice(current, current + limit).length)).map((i,key) => (
            <tr key={`${"k_"}${key}`} className={classes.emptyRow}>
              <td/>
            </tr>
          ))    
        }
        </tbody>
      </table>
      {
        showData.length > limit && (
          <div className={classes.pagination}>
            <NormalButton buttonType="play" disabled={current === 0} onClick={() => setCurrent(0)}>
              {t('První stránka')}
            </NormalButton>
            {
              showData.length - limit !== 0 && Array.from('x'.repeat(Math.ceil(((showData.length - limit) / limit) - 1))).map((i, key) => (
                <PaginationButton disabled={current === limit * (key + 1)} onClick={() => setCurrent(limit * (key + 1))} key={`${"k_"}${key}`}>
                  { key + 2 }
                </PaginationButton>
              ))   
            } 
            <NormalButton buttonType="play" disabled={(current + limit) >= showData.length} onClick={() => setCurrent(Math.floor(showData.length/limit) * 10)}>
              {t('Poslední stránka')}
            </NormalButton>     
          </div>
        )
      }
    </div>
  );
};

export default HistoryTable;
