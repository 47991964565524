import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../../constants/config';
import DocumentTitle from "react-document-title";
import GoogleAds from "react-google-ads";
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import NormalButton from "../../../../components/Buttons/NormalButton";
import * as StreaksService from "../../../../services/content/modeStreaks.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Context } from "../../../../contexts";
import { ReactFitty } from "react-fitty";

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  banner: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '30rem',
    flex: '0 0 30rem',
    backgroundColor: 'rgb(71,157,55)',
    backgroundImage: 'linear-gradient(0deg, rgba(71,157,55,1) 0%, rgba(199,250,140,1) 100%)',
    [theme.breakpoints.down('sm')]: {
      height: '10rem',
      flex: '0 0 10rem',
    },
    '& > svg': {
      color: '#FFFFFF',
      fontSize: '6rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '3rem',
      },
    }, 
  },
  title: {
    color: '#FFFFFF',
    fontSize: '4rem',
    marginTop: '1rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  subTitle: {
    color: '#FFFFFF',
    fontSize: '2rem',
    marginTop: '0.25rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    '& svg': {
      color: "#8CC63F",
    }
  },
  selector: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '2rem',
    minHeight: '50rem',
    padding: '2rem 0',
    [theme.breakpoints.down('sm')]: {
      minHeight: '30rem',
    },  
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem',
    borderRadius: '1rem',
    backgroundColor: '#CBCACB',
    width: '280px',
    height: '400px',
    cursor: 'pointer',
    position: 'relative',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      marginTop: 'auto',
    },
    '& > h3': {
      fontSize: '2rem',
      fontWeight: '600',
      color: '#777777',
      transition: 'color 0.5s',
      textTransform: 'uppercase',
      marginTop: 'auto',
    },
    '&:hover': {
      '& > h3': {
        color: '#FFFFFF',
      },
    },  
  },
  board: {
    borderRadius: '0.5rem',
    borderWidth: '0.175rem',
    borderStyle: 'solid',
    borderColor: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontWeight: '600',
    fontSize: '2rem',
    width: '200px',
    height: '75px',
    position: 'relative',
    '& > span': {
      padding: '0 1rem',
      maxWidth: 'calc(100% - 2rem)',
      '& > div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',      
      },
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      borderRadius: '0.5rem',
      borderWidth: '0.25rem',
      borderStyle: 'solid',
      borderColor: '#000000',
    },
  },
}));

const ModeMinigames = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { contextData, setContextData } = useContext(Context);
  const authToken = contextData.authToken;
  const userStatus = contextData.userStatus;
  
  const newGame = (type) => {
    if(userStatus) {
      StreaksService.newGame(authToken).then((result) => {
        if(result) {
          if(result.data) {
            setContextData({...contextData, game: result.data.GID});
          }
        }
      }).catch((error) => {
      
      });
    } else {
    
    }
  }; 
  
  return (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Režim miniher')}`}> 
      <section className={classes.section}>
        <div className={classes.banner}>
      		<SportsEsportsOutlinedIcon/>
          <h1 className={classes.title}>{t('Minihry')}</h1>
          <h2 className={classes.subTitle}>{t('Nebaví vás jen procházet mapy v klasickém modu? Zkuste tyto minihry!')}</h2>
      	</div>
        <div className={classes.selector}>
          <div className={classes.item} onClick={() => newGame("Regions")}>
            <div>
              <div className={classes.board}>
                {t('Plzeň')}
              </div>
              <div className={classes.board}>
                {t('Ostrava')}
              </div>
              <div className={classes.board}>
                <span>
                  <ReactFitty>{t('Strakonice')}</ReactFitty>
                </span>
              </div>
            </div>
            <h3>{t('Cedule')}</h3>
          </div>
        </div>
      </section>
    </DocumentTitle>
  );
};



export default ModeMinigames;