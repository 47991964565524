import React, { useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import htmlParse from 'html-react-parser';
import NormalButton from "../../Buttons/NormalButton";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: "10px",
    background: "white",
    width: "50rem",
    maxWidth: 'calc(90vw - 2.5rem)',
    overflow: "auto",
    padding: "1.25rem",
    margin: "1.25rem",
    maxHeight: 'calc(100vh - 3.75rem)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
      fontSize: '2rem',
    },
  },
  body: {
    marginTop: "1.25rem",
  },
  closeButton: {
    padding: '0.625rem',
    '& svg': {
      color: "#8CC63F",
    }
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1.25rem",
  },
}));


const SimpleDetailModal = ({ onCloseModal, title = 'Modal', content = '', closeButton = true, closeButtonText = 'Zavřít' }) => {

  const { t } = useTranslation();
  const classes = useStyles({});

  const handleClose = (e) => {
    e.stopPropagation();
    onCloseModal();
  };
  
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.wrapper}>
          <p>{title}</p>
        </div>
        <IconButton className={classes.closeButton} onClick={handleClose} size="small">
          <CloseOutlinedIcon/>
        </IconButton>
      </div>
      <div className={classes.body}>
          {content}
      </div>
      {
        closeButton && (
          <div className={classes.footer}>
            <NormalButton buttonType="primary" onClick={handleClose}>
              {closeButtonText}
            </NormalButton>
          </div>
        )
      }
    </div>
  )
};  

export default SimpleDetailModal;
