import React, {useEffect, useState} from 'react'
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: '1rem',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    width: '10rem',
    overflow: 'hidden',
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: '0.5rem',
    borderRadius: '0.25rem',
    borderWidth: '0.125rem',
    borderStyle: 'solid',
    borderColor: 'rgba(255,255,255,0.75)',
    '&::before': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '5px',
      height: '8px',
      backgroundColor: 'red',
      clipPath: 'polygon(0 0, 50% 100%, 100% 0)',     
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      bottom: '0',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '5px',
      height: '8px',
      backgroundColor: 'red',
      clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',         
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      transform: 'translateX(0%)',
      gap: '0.25rem',
      '& > span': {
        display: 'block',
        minWidth: '0.125rem',
        height: '1rem',
        backgroundColor: 'rgba(255,255,255,0.75)',
      },
      '& > strong': {
        textAlign: 'center',
        color: '#FFFFFF',
        fontWeight: '600',
        minWidth: '1.156rem',
        fontSize: '0.8rem',
        lineHeight: '1rem',
      },    
    },
  },
}));

const NewCompass = ({rotate = 0}) => {
  const classes = useStyles();
  const compassSides = ["SZ","S","SV","V","JV","J","JZ","Z"];  
  return (
    <div className={classes.root}>
      <div style={{transform: 'translate(-' + ((rotate / 0.63808065339) + 47.267) + 'px)'}}>
      {
        compassSides.map((side, key) => Array.from('x'.repeat(9)).map((line, key) => key === 8 ? (
          <strong key={`${"k_"}${key}`} >{side}</strong>
        ) : (
          <span key={`${"k_"}${key}`} ></span>
        )))                
      }
      {
        Array.from('x'.repeat(8)).map((line, key) => (
          <span key={`${"k_"}${key}`} ></span>
        ))            
      }
      <strong>{compassSides[0]}</strong>
      {
        Array.from('x'.repeat(8)).map((line, key) => (
          <span key={`${"k_"}${key}`} ></span>
        ))            
      }
      <strong>{compassSides[1]}</strong>
      {
        Array.from('x'.repeat(8)).map((line, key) => (
          <span key={`${"k_"}${key}`} ></span>
        ))            
      }
      <strong>{compassSides[2]}</strong>
      {
        Array.from('x').map((line, key) => (
          <span key={`${"k_"}${key}`} ></span>
        ))            
      }
      </div>
	  </div>
  );
};      
export default NewCompass;