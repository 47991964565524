import HttpService from '../http.service';

export const getGameData = (gid, accessToken) => {
  return HttpService.get('get/game?gid=' + gid, {}, {}, accessToken);
}

export function editGameData(mode, data, accessToken) {
  return HttpService.post('mode/' + mode + '/editgame', data, {}, {}, accessToken);
}

export function submitGameData(mode, data, accessToken) {
  return HttpService.post('mode/' + mode + '/submitgame', data, {}, {}, accessToken);
}

export function quitGame(mode, data, accessToken) {
  return HttpService.post('mode/' + mode + '/quitgame', data, {}, {}, accessToken);
}