import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react'
import './index.css';
import App from './App';
import store, { history, persistor } from './store';
import reportWebVitals from './reportWebVitals';
import './i18n';
import HttpsRedirect from 'react-https-redirect';
import Splashscreen from "./components/Widgets/Splashscreen";
import Rotatescreen from "./components/Widgets/Rotatescreen";
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import { ContextProvider, ImageContextProvider, GameContextProvider, CreatorContextProvider } from './contexts';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Suspense fallback={<Splashscreen/>}>
          <HttpsRedirect>
            <ContextProvider>
              <ImageContextProvider>
                <GameContextProvider>
                  <CreatorContextProvider>
                    <DeviceOrientation lockOrientation={'landscape'}>
                      <Orientation orientation='landscape' alwaysRender={true}>
                        <App />
                      </Orientation>
                      <Orientation orientation='portrait' alwaysRender={false}>
                        <Rotatescreen/>
                      </Orientation>
                    </DeviceOrientation>
                  </CreatorContextProvider>
                </GameContextProvider>
              </ImageContextProvider>
            </ContextProvider>
          </HttpsRedirect>
        </Suspense>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();