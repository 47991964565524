import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  loginInput: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
    width: '100%', 
    '& > label': {
    	marginTop: '1rem',
    	fontSize: '0.8rem',
    	width: '90%',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    borderRadius: '0',
    borderWidth: '0.125rem',
    borderStyle: 'solid',
    borderColor: '#E1E1E1',
    boxShadow: 'none',
    '-webkit-appearance': 'none',
    outline: 'none',
    lineHeight: 'normal',
    marginTop: '0.5rem',
    height: '44px',
    '& > input': {
      flex: '1 1 auto',
      border: '0',
      borderRadius: 'inherit',
      backgroundColor: 'inherit',
      color: 'inherit',
      fontSize: 'inherit',
      height: '100%',
      maxHeight: '100%',
      padding: '0 1.125rem',
      outline: 'none',
      boxSizing: 'inherit',
      order: '0',
      '&:disabled': {
        color: '#949494',
        backgroundColor: '#F8F8F8',
      },
    },
    '& > button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: '0 0 auto',
      backgroundColor: 'transparent',
      color: '#5A5C7B',
      border: '0',
      height: '100%',
      padding: '0px 10px',
      minWidth: 'unset',
      borderRadius: 'none',
      order: '1',
      transition: 'all 0.5s',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#F9F9FF',
        color: '#8cc63f',
      },
      '& > svg': {
        color: 'inherit',
        width: '24px',
        height: '24px',
      },
      '&:disabled': {
        color: '#949494',
        backgroundColor: '#f8f8f8',
      },
    }, 
  },
}));

const LoginInput = ({placeholder, label, name, type, onChange, disabled, tabIndex, autofocus}) => {
  const [isVisible,setIsVisible] = useState(false);
  const handleChangeValue = (e) => {
    onChange(name, e.target.value);
  };
  const classes = useStyles();
  
  const handleVisible = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsVisible(!isVisible);
  };

  return (
    <div className={classes.loginInput}>
      <label>
        {label}
      </label>
      <div className={classes.wrapper}>
        {
          type == "password" && (
            <Button type="button" tabIndex={parseInt(tabIndex) + 1} disabled={disabled} onClick={handleVisible}>{isVisible ? (<VisibilityIcon/>) : (<VisibilityOffIcon/>) }</Button>  
          )
        }
        <input
          type={type == "password" ? (isVisible ? "text" : "password") : type}
          placeholder={placeholder}
          name={name}
          onChange={(e) => handleChangeValue(e)}
          disabled={disabled}
          tabIndex={tabIndex}
          autoFocus={autofocus}
        />
      </div>
    </div>
  );
};

LoginInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
  autofocus: PropTypes.bool,
};

LoginInput.defaultProps = {

};

export default LoginInput;
