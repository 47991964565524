const distanceFormat = (distance, points, t) => {
  distance = parseFloat(distance);
  points = parseInt(points);
  let formatedDistance = "";
	if(distance === 0 && points !== 0) {
		formatedDistance = t('Přesná pozice');
	} else if(distance === 0 && points === 0) {
		formatedDistance = t('Žádná pozice');
	} else if(distance === -1 && points === 0) {
		formatedDistance = t('Žádná pozice');
	} else if(distance < 1) {
		let newDistance = (distance * 1000);
		if(newDistance === 1) formatedDistance = newDistance + ' ' + t('metr');
		else if(newDistance === 2 || newDistance === 3 || newDistance === 4) formatedDistance = newDistance + ' ' + t('metry');
		else formatedDistance = newDistance + ' ' + t('metrů');
	} else {
		let newDistance = distance;
		if(newDistance === 1) formatedDistance = newDistance + ' ' + t('kilometr');
		else if(newDistance === 2 || newDistance === 3 || newDistance === 4) formatedDistance = newDistance  + ' ' + t('kilometry');
		else formatedDistance = newDistance + ' ' +  t('kilometrů');
	} 
  return formatedDistance;
}

export default distanceFormat;