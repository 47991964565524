import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../constants/config';
import DocumentTitle from "react-document-title";
import * as ResultsService from "../../../services/content/results.service";
import { useParams }  from "react-router-dom";
import Avatar from '../../../components/Images/Avatar';
import FinishImage from '../../../components/Images/FinishImage';
import distanceFormat from '../../../utils/distanceFormat';
import pointsFormat from '../../../utils/pointsFormat';
import timeFormat from '../../../utils/timeFormat';
import resultTimeFormat from '../../../utils/resultTimeFormat';
import totalTimesFormat from '../../../utils/totalTimesFormat';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    height: 'calc(100vh - 8.375rem)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 7.125rem)',
    },
    '& svg': {
      color: "#8CC63F",
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    minHeight: 'calc(100vh - 4.375rem)',
    flex: '1 0 auto',
    backgroundColor: 'rgb(193,193,193)',
    backgroundImage: 'linear-gradient(180deg, rgba(69,93,92,1) 0%, rgba(2,13,66,1) 100%)',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 3.125rem)',                               
    },
  },
  mapWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  	minHeight: '50vh', 
    '& > #mapa': {
      width: '100%',
      minHeight: '40%',
      flex: '1 1 100%',
      '& .card': {
        zIndex: '3',
        '& > .card-header': {
          '& > strong': {
            fontWeight: 'bold',
          },
        },
        '& > .card-body': {
          '& > p': {
            fontSize: '0.8rem',
            marginTop: '0-25rem',
          },
        },
        '& > .card-footer': {
          '& > p': {
            fontSize: '0.8rem',
            marginTop: '0-25rem',
          },
          '& > a': {
            fontSize: '0.8rem',
            marginTop: '0-25rem',
            color: '#8CC63F',          
          },
        },
      },
      '& .cluster': {
        '& > div': {
          color: '#FFFFFF !important',
          backgroundColor: 'transparent',
          backgroundImage: 'url("https://api.lokalokator.cz/images/marker/cluster-red.png")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          border: '0',
          fontSize: '0',
          transform: 'scale(0.5)',
          '& > img': {
            display: 'none',
          },        
        },
      },
      '& a.marker.type-paid': {
        zIndex: '1',
        cursor: 'help',
      },
      '& a.marker.type-poi': {
        zIndex: '1',
        cursor: 'help',
      },
    },    
  },
  data: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    minHeight: 'fit-content',
    flex: '0 1 fit-content',
    padding: '2rem 0',
    '& > h2': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '2rem',
      color: '#FFFFFF',
      '&::after': {
        content: `''`,
      	width: '3rem',
      	height: '0.25rem',
      	backgroundColor: '#DDDDDD',        
        
      },
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1rem',
    gap: '2rem', 
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > svg': {
      	width: '1.5rem',
        height: '1.5rem',
      	marginRight: '1rem',
      	backgroundColor: 'rgb(193,193,193)',
      	backgroundImage: 'linear-gradient(180deg, rgba(193,193,193,1) 0%, rgba(95,96,95,1) 100%)',
      	color: '#FFFFFF',
      	padding: '0.5rem',
      	borderRadius: '100%',      
      },
      '& > div': {
      	display: 'flex',
      	flexDirection: 'column',
      	fontSize: '0.75rem',
        color: '#DDDDDD',
        '& > h5': {
        	fontSize: '1rem',
        	fontWeight: '600',
        	color: '#FFFFFF',
        	marginBottom: '0.25rem',
          '& > a': {
            color: 'inherit',
            borderBottomWidth: '0.1rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'currentColor',
            textDecoration: 'none',
          },        
        },
        '& > span': {
          '& a': {
            color: '#8CC63F',
            textDecoration: 'none',
          },
        },
        '& > div': {
          display: 'flex',
          flexDirection: 'column',
        },      
      },
    },
  },
  table: {
  	display: 'flex',
  	flexDirection: 'row',
  	flexWrap: 'wrap',
  	width: '100%',
  	maxWidth: '100%',
  	height: 'auto',
  	padding: '3rem 0',
  },
  row: {
  	display: 'flex',
  	flexDirection: 'column',
  	flexWrap: 'wrap',
  	width: 'calc(50% - 0.25rem)',
  	maxWidth: '100%',
  	height: 'auto',
    '&:first-of-type': {
      fontWeight: 'bold',
    },
    '& + div': {
      borderLeftWidth: '0.125rem',
      borderLeftStyle: 'solid',
      borderLeftColor: 'rgba(255,255,255,0.25)',    
    },    
  },
  userRow: {
  	display: 'flex',
  	flexDirection: 'row',
  	justifyContent: 'center',
  	alignItems: 'center',
  	padding: '2rem 0rem',
  	width: '100%',
  	borderBottomWidth: '0.125rem',
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(255,255,255,0.25)',
    '& > a': {
    	display: 'flex',
    	flexDirection: 'row',
    	justifyContent: 'center',
    	alignItems: 'center',
    	color: '#FFFFFF',
      textDecoration: 'none',
      '& > strong': {
        marginLeft: '0.5rem',
      },    
    },   
  },
  cell: {
  	display: 'flex',
  	flexDirection: 'row',
  	justifyContent: 'center',
  	alignItems: 'center',
  	padding: '1rem 2rem',
    width: 'auto',
  	height: '5rem',
    color: '#FFFFFF',
    '& > a': {
    	display: 'flex',
    	flexDirection: 'row',
    	justifyContent: 'center',
    	alignItems: 'center',
    	color: '#FFFFFF',
      textDecoration: 'none',
      '& > strong': {
        marginLeft: '0.5rem',
      },    
    },   
  },
  columnCell: {
  	display: 'flex',
  	flexDirection: 'column',
  	justifyContent: 'center',
  	alignItems: 'center',
  	padding: '1rem 2rem',
    width: 'auto',
  	height: '5rem', 
    '& > a': {
    	display: 'flex',
    	flexDirection: 'row',
    	justifyContent: 'center',
    	alignItems: 'center',
    	marginTop: '0.25rem',
    	fontSize: '0.75rem',
    	color: '#8cc63f',
    	whiteSpace: 'nowrap',
    	cursor: 'pointer',  
    },
    '& > span': {
      '&:nth-child(1)': {
      	whiteSpace: 'nowrap',
      	fontWeight: 'bold',
        color: '#FFFFFF',      
      },
      '&:nth-child(2)': {
      	marginTop: '0.25rem',
      	fontSize: '0.75rem',
      	color: '#DDDDDD',
      	whiteSpace: 'nowrap',     
      },
      '&:nth-child(3)': {
      	marginTop: '0.25rem',
      	fontSize: '0.75rem',
      	color: '#BBBBBB',
      	whiteSpace: 'nowrap',
      	fontWeight: '600',      
      },
    },        
  },
}));

const PublicResultsExplorer = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [resultData, setResultData] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [totalTimes, setTotalTimes] = useState(0);
  const gid = useParams().id;  
  
  useEffect(() => {
    window.Loader.load(null, {poi:true}, loadData);
  }, []);
  
  let SMap;
  let JAK;
  let mapa;
  let layer_guess;
  let layer_locations;
  let layer_lines;
  
  const initMap = (data) => {
    SMap = window.SMap;
    JAK = window.JAK; 
    let stred = SMap.Coords.fromWGS84(15.3386383,49.7437572);
		mapa = new SMap(JAK.gel("mapa"), stred, 7);
		mapa.addDefaultLayer(SMap.DEF_BASE).enable();
    mapa.setZoomRange(7, 19);
    mapa.addDefaultLayer(SMap.DEF_BASE).enable();
    let zajmy = new SMap.Layer.Marker(undefined, {
    	poiTooltip: true
    });
    mapa.addLayer(zajmy).enable();
    let dataProvider = mapa.createDefaultDataProvider();
    dataProvider.setOwner(mapa);
    dataProvider.addLayer(zajmy);
    dataProvider.setMapSet(SMap.MAPSET_BASE);
    dataProvider.enable();
    let move = new SMap.Control.Mouse(1);
    let scroll = new SMap.Control.Mouse(2);
    let zoom = new SMap.Control.Zoom('', {showZoomMenu: false});
    let sync = new SMap.Control.Sync({bottomSpace:0});
    mapa.addControl(move);
    mapa.addControl(scroll);
    mapa.addControl(zoom);
    mapa.addControl(sync);
    let click = false;
    let removeLink = function() {
    	if(document.querySelector("a.print")) document.querySelector("a.print").removeAttribute("href");
    }
    let signals = mapa.getSignals();
    signals.addListener(window, "map-redraw", removeLink);
    layer_guess = new SMap.Layer.Marker();
    mapa.addLayer(layer_guess);
    layer_guess.enable();
    
    layer_locations = new SMap.Layer.Marker();
    mapa.addLayer(layer_locations);
    layer_locations.enable();
    
    layer_lines = new SMap.Layer.Geometry();
    mapa.addLayer(layer_lines);
    layer_lines.enable();
    removeLink();
    loadMapData(data);
  };
  
  const loadData = () => {
    ResultsService.getResultsData(gid).then((result) => {
      if(result) {
        if(result.data) {
          setResultData(result.data);
          setIsLoaded(true);
          initMap(result.data);
          let totalPts = 0;
          result.data.Points.forEach((i) => {
            totalPts = parseFloat(totalPts) + parseFloat(i);  
          });
          setTotalPoints(totalPts);
          let totalTms = 0;
          result.data.Times.forEach((i) => {
            totalTms = parseFloat(totalTms) + parseFloat(i);  
          });
          setTotalTimes(totalTms);
        }
      }
    });  
  };
  
  const loadMapData = (data) => {
    let compute_locations = [];
    let llocations = [];
    let lcoords = [];
    let lmarks = [];
    let limages = [];
    let ldescriptions = [];
    let lmarkers = [];
    let lanswers = [];
    let lcards = [];
    data.Locations.map((location, key) => {
      llocations[key] = location.split(",");
  		lcoords[key] = SMap.Coords.fromWGS84(parseFloat(llocations[key][0]),parseFloat(llocations[key][1]));
    	lmarks[key] = JAK.mel("div");
    	limages[key] = JAK.mel("img", {src: 'https://api.lokalokator.cz/images/marker/drop-blank.png'});
    	lmarks[key].appendChild(limages[key]);
  		ldescriptions[key] = JAK.mel("div", {}, {position: 'absolute', left: '-6px', top: '10px', textAlign: 'center', width: '22px', color: 'white', fontWeight: 'bold', zIndex: '2'});
  		ldescriptions[key].innerHTML = '<div id="location' + key + '">...</div>';
  		lmarks[key].appendChild(ldescriptions[key]);
    	lmarkers[key] = new SMap.Marker(lcoords[key], 'location' + key, {url: lmarks[key]});
    	lcards[key] = new SMap.Card();
    	lcards[key].getHeader().innerHTML = "";
    	lmarkers[key].decorate(SMap.Marker.Feature.Card, lcards[key]);
    	layer_locations.addMarker(lmarkers[key]);
    	lanswers[key] = function(geocoder) {
       	let results = geocoder.getResults();
       	lcards[key].getHeader().innerHTML = '<strong>Správná lokace ' + (key + 1) + '</strong>';
       	lcards[key].getBody().innerHTML = '<p>' + results.items[0].name + ', ' + results.items[2].name + '</p>';
    	  lcards[key].getFooter().innerHTML = '<a href="https://mapy.cz/?x=' + llocations[key][0] + '&y=' + llocations[key][1] + '&z=16&source=coor&id=' + llocations[key][0] + '%2C' + llocations[key][1] + '" target="_Blank">Zobrazit na mapě</a>';
    	}
    	new SMap.Geocoder.Reverse(lcoords[key], lanswers[key]);
      ReactDOM.render(<FinishImage number={key + 1} size="1.5rem"/>, document.getElementById("location" + key));
      compute_locations.push(lmarkers[key]._coords);
    });
    let glocations = [];
    let gcoords = [];
    let gmarks = [];
    let gimages = [];
    let gdescriptions = [];
    let gmarkers = [];
    let ganswers = [];
    let gcards = [];
    data.GuessLocations.map((location, key) => {
      if(location != "0" && location != "-1") {
        glocations[key] = location.split(",");
  			gcoords[key] = SMap.Coords.fromWGS84(parseFloat(glocations[key][0]),parseFloat(glocations[key][1]));
  			gmarks[key] = JAK.mel("div");
  			gimages[key] = JAK.mel("img", {src: 'https://api.lokalokator.cz/images/marker/drop-blank.png'});
  			gmarks[key].appendChild(gimages[key]);
  			gdescriptions[key] = JAK.mel("div", {}, {position: 'absolute', left: '0px', top: '2px', textAlign: 'center', width: '22px', color: 'white', fontWeight: 'bold', zIndex: '2'});
  			gdescriptions[key].innerHTML = '<div id="guess' + key + '">...</div>';
  			gmarks[key].appendChild(gdescriptions[key]);
  			gmarkers[key] = new SMap.Marker(gcoords[key], 'guess' + key, {url: gmarks[key]});
  			gcards[key] = new SMap.Card();
  			gmarkers[key].decorate(SMap.Marker.Feature.Card, gcards[key]);
  			layer_guess.addMarker(gmarkers[key]);
  			ganswers[key] = function(geocoder) {
      		let results = geocoder.getResults();
      		gcards[key].getHeader().innerHTML = '<strong>Hráčův odhad ' + (key + 1) + '</strong>';
  				gcards[key].getBody().innerHTML = '<p>' + results.items[0].name + ', ' + results.items[2].name + '</p>';
  				gcards[key].getFooter().innerHTML = '<p>' + distanceFormat(data.Distance[key], data.Points[key], t) + ' | ' + pointsFormat(data.Points[key], t) + '</p>';
  			}
  			new SMap.Geocoder.Reverse(gcoords[key], ganswers[key]);
        ReactDOM.render(<Avatar userData={data.User} size="1.75rem"/>, document.getElementById("guess" + key));
        compute_locations.push(gmarkers[key]._coords);
      } else {
        compute_locations.push(lmarkers[key]._coords);
      }
    });    
    let linesPoints = [];
    let linesGuessLocations = []; 
    let linesLocations = [];
    let linesLines = [];
		let linesOptions = {
			color: "#000000",
			width: 3
		}; 
        
    data.GuessLocations.map((location, key) => {
      if(location != "0" && location != "-1") {
        linesGuessLocations[key] = location.split(",");
        linesLocations[key] = data.Locations[key].split(",");
        linesPoints[key] = [
      			SMap.Coords.fromWGS84(parseFloat(linesLocations[key][0]),parseFloat(linesLocations[key][1])),
      			SMap.Coords.fromWGS84(parseFloat(linesGuessLocations[key][0]),parseFloat(linesGuessLocations[key][1]))
  			];
        linesLines[key] = new SMap.Geometry(SMap.GEOMETRY_POLYLINE, 'line' + key, linesPoints[key], linesOptions);
			  layer_lines.addGeometry(linesLines[key]);
      }
    });
    let compute_all = mapa.computeCenterZoom(compute_locations);
    let compute_coords = compute_all[0];
    let compute_zoom = compute_all[1];
    mapa.setCenterZoom(compute_coords,compute_zoom);
  };
  
  return isLoaded ? (
    <div className={classes.main}>
      <div className={classes.mapWrapper}>
        <div id="mapa"></div>
      </div>
      <div className={classes.data}>
        <h2>{t('Přehled')}</h2>
        <div className={classes.info}>
          <div>
          	<InfoOutlinedIcon/>
          	<div>
          		<h5>
                <Link to={{pathname: '/mode/explorer/' + resultData.MID + '/map'}}>{resultData.Name}</Link>
              </h5>
          		<span>
              {
                resultData.MID === "Random" ? t('z celé České republiky') : (
                  <p>{t('Vytvořil')} <Link to={{pathname: '/profile/' + resultData.Author.UID}}>{resultData.Author.Nickname}</Link></p>
                )
              }
              </span>
          	</div>
          </div>
          <div>
          	<SettingsOutlinedIcon/>
          	<div>
          		<h5>
              {t('Nastavení hry')}
              </h5>
              {
                (parseInt(resultData.TimeLimit) === parseInt(resultData.MapTimeLimit) && parseInt(resultData.CanMove) === parseInt(resultData.MapCanMove) && parseInt(resultData.CanLook) === parseInt(resultData.MapCanLook) && parseInt(resultData.CanZoom) === parseInt(resultData.MapCanZoom)) ? t('Bez úprav a změn') : (
                  <div>
                    <span>{timeFormat(parseInt(resultData.TimeLimit), true, t)}</span>
                    <span>{parseInt(resultData.CanMove) === 1 ? t('Lze se pohybovat') : t('Nelze se pohybovat')}</span>
                    <span>{parseInt(resultData.CanLook) === 1 ? t('Lze se rozhlížet') : t('Nelze se rozhlížet')}</span>
                    <span>{parseInt(resultData.CanZoom) === 1 ? t('Lze přibližovat') : t('Nelze přibližovat')}</span>
                  </div>                               
                )
              }
          	</div>
          </div>
        </div>
        <div className={classes.table}>
          <span className={classes.userRow}>
            <Link to={{pathname: '/profile/' + resultData.User.UID}}>
              <Avatar userData={resultData.User}/>
              <strong>{resultData.User.Nickname}</strong>
            </Link>
          </span>
          <div className={classes.row}>
          	{
              resultData.Points.map((i,key) => (
          		  <span className={classes.cell} key={`${"k_"}${key}`}>{t('Kolo')} {key + 1}</span>
              ))
            }
            <span className={classes.cell}>{t('Celkem')}</span>
          </div>
          <div className={classes.row}>
          	{
              resultData.Points.map((i,key) => (
                <span className={classes.columnCell} key={`${"k_"}${key}`}>
              		<span>{pointsFormat(resultData.Points[key], t)}</span>
              		<span>{distanceFormat(resultData.Distance[key],resultData.Points[key],t)}</span>
              		<span>{resultTimeFormat(resultData.Times[key], t)}</span>
              		<Link to={{pathname: 'https://mapy.cz/?x=' + resultData.Locations[key].split(',')[0] + '&y=' + resultData.Locations[key].split(',')[1] + '&z=16&source=coor&id=' + resultData.Locations[key].split(',')[0] + '%2C' + resultData.Locations[key].split(',')[1]}} target="_Blank">Zobrazit na mapě</Link>
              	</span>                
              ))
            }
          	<span className={classes.columnCell}>
          		<span>{pointsFormat(totalPoints,t)}</span>
          		<span>{totalTimesFormat(totalTimes,t)}</span>
          	</span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  )
};



export default PublicResultsExplorer;
