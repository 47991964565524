import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../../../constants/config';
import DocumentTitle from "react-document-title";
import GoogleAds from "react-google-ads";
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import NormalButton from "../../../../../components/Buttons/NormalButton";
import Snackbar from "../../../../../components/Notifications/Snackbar";
import * as ModeExplorerService from "../../../../../services/content/modeExplorer.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Context, GameContext } from "../../../../../contexts";
import { useParams }  from "react-router-dom";
import Maps from '../../../../../components/Items/Maps';
import ErrorCompass from '../../../../../components/Widgets/ErrorCompass';

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  banner: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '30rem',
    flex: '0 0 30rem',
    backgroundColor: 'rgb(193,193,193)',
    backgroundImage: 'linear-gradient(180deg, rgba(69,93,92,1) 0%, rgba(2,13,66,1) 100%)',
    [theme.breakpoints.down('sm')]: {
      height: '10rem',
      flex: '0 0 10rem',
    },
    '& > svg': {
      color: '#E5B513',
      fontSize: '6rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '3rem',
      },
    }, 
  },
  title: {
    color: '#FFFFFF',
    fontSize: '4rem',
    marginTop: '1rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  subTitle: {
    color: '#FFFFFF',
    fontSize: '2rem',
    marginTop: '0.25rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    flex: '1',
    '& svg': {
      color: "#8CC63F",
    }
  },
  titles: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100% - 4rem)',
    padding: '3rem 2rem',
    gap: '2rem',
    '& > h3': {
      color: '#8CC63F',
      fontSize: '2rem',
      fontWeight: '500',
      '&.active': {
        borderBottomWidth: '0.25rem',
        borderBottomStyle: 'solid',
        borderBottomColor: '#8CC63F',
      },
      '&:not(.active)': {
        cursor: 'pointer',
      },    
    }, 
  },
  wrapper: {
    width: '100%',
  },
  category: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '& > h4': {
      marginLeft: '2rem',
      color: '#8CC63F',
      fontSize: '1.5rem',
      width: 'calc(100% - 2rem)',
    },
  },
  maps: {
  	width: '100%',
  	display: 'flex',
  	flexDirection: 'row',
    '& > p': {
      display: 'block',
      width: '100%',
      textAling: 'center',
      paddingTop: '1rem',
    },
    '& > div': {
    	width: '100%',
    	display: 'flex',
    	flexDirection: 'column',    
    },  
  },
  noMaps: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center !important',
    flex: '1',
    width: '100%',
    height: '100%',
    paddingBottom: '3rem',
    '& > h4': {
      fontSize: '1.5rem',
    },
    '& > div': {
      [theme.breakpoints.down('sm')]: {
        zoom: '0.5',
      },
    },
  },
}));

const ModeExplorerCategory = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const navigate = (to) => history.push(to);
  const { contextData, setContextData } = useContext(Context);
  const authToken = contextData.authToken;
  const userStatus = contextData.userStatus;
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMapsLoaded, setIsMapsLoaded] = useState(false);
  const [category, setCategory] = useState(null);
  const [maps, setMaps] = useState([]);
  const [tab, setTab] = useState(1);
  const categoryShortCode = useParams().id;
  
  useEffect(() => {
    ModeExplorerService.getCategory(categoryShortCode).then((result) => {
      setCategory(result.data);
      ModeExplorerService.getMapSameCategory(categoryShortCode, 1, 0, authToken).then((result) => {
        setMaps(result.data);
        setIsLoaded(true);
        setIsMapsLoaded(true);
      });        
    });  
  }, []);

  
  const handleSetTab = (tab) => {
    setTab(tab);
    setIsMapsLoaded(false);
    loadMaps(tab);
  };
  
  const loadMaps = (tab) => {
    ModeExplorerService.getMapSameCategory(categoryShortCode, tab, 0, authToken).then((result) => {
      setMaps(result.data);
      setIsMapsLoaded(true);
    });
  };
  
  return isLoaded ? (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t(category.Name) + ' - ' + t('Režim průzkumník')}`}> 
      <section className={classes.section}>
        <div className={classes.banner}>
      		<TravelExploreOutlinedIcon/>
          <h1 className={classes.title}>{t('Prozkoumejte Česko')}</h1>
          <h2 className={classes.subTitle}>{t(category.Name)}</h2>
      	</div>
        <div className={classes.titles}>
          <h3 className={`${tab === 1 && "active"}`} onClick={() => tab !== 1 && handleSetTab(1)}>{t('Oficiální mapy')}</h3>
          <h3 className={`${tab === 0 && "active"}`} onClick={() => tab !== 0 && handleSetTab(0)}>{t('Uživatelské mapy')}</h3>
        </div>
        {
          isMapsLoaded ? (
            <div className={classes.wrapper}>
              {
                Object.keys(maps).length === 0 && (
                  <div className={classes.noMaps}>
                    <ErrorCompass/>
                    <h4>{t('Nepodařilo se najít žádné mapy')}</h4>
                  </div>
                )
              }
              <div className={classes.category}>
                <div className={classes.maps}>
                  {
                    Object.keys(maps).length === 0 ? (
                      <p>{t('Není tu žádná mapa')}</p>
                    ) : (
                      <div>
                        <Maps items={Object.values(maps).slice(0,3)} size="large"/>
                        {
                          Object.keys(maps).length > 3 && (
                            <Maps items={Object.values(maps).slice(3,7)} size="small"/>   
                          ) 
                        }
                      </div>           
                    )
                  }
                </div>                
              </div>
            </div>
          ) : (
            <div className={classes.spinner}>
              <CircularProgress />
            </div>
          )
        }
      </section>
    </DocumentTitle>
  ) : (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Načítání kategorie')}`}> 
      <section className={classes.section}>
        <div className={classes.banner}>
      		<TravelExploreOutlinedIcon/>
          <h1 className={classes.title}>{t('Prozkoumejte Česko')}</h1>
        </div>
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      </section>
    </DocumentTitle>  
  )
};



export default ModeExplorerCategory;