import HttpService from './http.service';

export function signUp(name, nickname, email, password, passwordConfirm) {
  return HttpService.post('user/register', {
    name: name,
    nickname: nickname,
    email: email,
    password: password,
    passwordConfirm: passwordConfirm,
  }, {}, {});
}

export function signIn(email, password) {
  return HttpService.post('user/login', {
    email: email,
    password: password,
  }, {}, {});          
}

export function resetPasswordRequest(email,redirectUri) {
  return HttpService.post('user/reset-password', {
    email,
    redirectUri,
  }, {}, {}).then((result) => {
    return result;
  });
}

export function resetPassword(token,password) {
  return HttpService.post('user/set-password', {
    token,
    password,
  }, {}, {}).then((result) => {
    return result;
  });
}

export function logout(accessToken) {
  return HttpService.get('user/logout', {}, {}, accessToken);
}