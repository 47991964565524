import React, {useEffect, useState, useContext, useRef} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Modal from "@material-ui/core/Modal";
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import useThemes from '../../constants/themes';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import MainMenu from "../../components/Menus/Main";
import { mainMenu, userMenu, secondMainMenu } from '../../constants/sidebar';
import NotLoggedModal from "../../components/Modals/NotLoggedModal";
import { Context } from "../../contexts";


const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: 'normal',
    width: '100%',
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  main: {
		width: 'calc(100% - 16.25rem)',
    minHeight: '100%',
    maxHeight: '100vh',
  	position: 'fixed',
    top: '0',
    left: '16.25rem', 
    right: '0',
    bottom: '0',
		zIndex: '1001',
		overflowY: 'auto',
    transition: 'width 0.4s, left 0.4s',
    '&.isToggled': {
      left: '0rem',
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
    	width: '100%',
    	maxWidth: '100vw',
    	flex: '1 1 auto',
      zIndex: '1',
      left: '0rem',
    },
  },
  logo: {
	  position: 'fixed',
	  top: '0',
	  left: '0',
	  display: 'flex',
	  flexDirection: 'row',
	  justifyContent: 'center',
	  alignItems: 'center',
		width: '16.25rem',
		height: '4.375rem',
	  transition: 'background-color 0.4s, width 0.4s, height 0.4s',
	  zIndex: '1004',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '10.5rem',
      height: '3.125rem',                               
    },
    '& > svg': {
	    display: 'block',
      width: 'auto',
      height: '60%',  
      '-webkit-user-select': 'none',
      '-khtml-user-select': 'none',
      '-moz-user-select': 'none',
      '-o-user-select': 'none',
      userSelect: 'none',
      '-webkit-user-drag': 'none',
      '-khtml-user-drag': 'none',
      '-moz-user-drag': 'none',
      '-o-user-drag': 'none',
      userDrag: 'none',
      pointerEvents: 'none',
    },
  },
  app: {
    display: 'block',
    overflow: 'hidden',
    position: 'relative',
    top: '4.375rem',
    width: '100%',
    '& > section': {
      minHeight: 'calc(100vh - 4.375rem)',
      [theme.breakpoints.down('sm')]: {
        minHeight: 'calc(100vh - 3.125rem)',                               
      },
    },
    [theme.breakpoints.down('sm')]: {
     top: '3.125rem',                               
    },
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hidden: {
    display: 'none',
    visibility: 'hidden',
    pointerEvents: 'none',
    width: '0',
    height: '0',
  },
}));

function MainLayout(props) {  

  const history = useHistory();
  const location = useLocation();
  const navigate = (to) => history.push(to);
  const theme = useTheme();
  const { contextData, setContextData } = useContext(Context);
  const userTheme = contextData.userData?.Settings?.Theme || 'default'; 
  const themes = useThemes.themes[userTheme] ? useThemes.themes[userTheme]() : useThemes.themes["default"]();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));      
  const [height, setHeight] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const isToggled = contextData.isToggledMenu;
  const mainContainer = useRef(null);
  
  const getHeight = () => window.innerHeight 
    || document.documentElement.clientHeight 
    || document.body.clientHeight;
    
  const isPlay = () => {
    const lastPath = location.pathname.split("/")[location.pathname.split("/").length - 1] || '';
    return lastPath.includes("play");
  }; 
  
  useEffect(() => {
    let menus = [];
    menus["mainMenu"] = mainMenu;
    menus["userMenu"] = userMenu;
    menus["secondMainMenu"] = secondMainMenu;
    setMenuItems(menus);    
  }, []);
  
  useEffect(() => {
    history.listen((location, action) => {
      handleMainScrollTop();
    });   
  }, []);
  
  const handleMainScrollTop = () => {
    if(mainContainer.current) {
      mainContainer.current.scrollTop = 0;
    }
  };
  
  const handleNavigate = (to) => {
    if(isToggled && isMobile) {
      setContextData({...contextData, isToggledMenu: !isToggled});   
    }
    navigate(to);
  };
    
  function ChangeWindowSize() {
    useEffect(() => {
      let timeoutId = null;
      const resizeListener = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => setHeight(getHeight()), 150);
      };
      window.addEventListener('resize', resizeListener);
      return () => {
        window.removeEventListener('resize', resizeListener);
      }
    }, [])
    return height;
  } 
  
  return (
    <div className={`${classes.root} ${themes.root} ${isToggled && 'isToggled'}`}>
      <main className={`${classes.main} ${isToggled && 'isToggled'}`} ref={mainContainer}>
        <Header/>
        <div className={`${classes.app} ${isToggled && 'isToggled'}`}>
          {props.children}
          {!isPlay() && (<Footer/>)}
        </div>
      </main>
      <a onClick={() => handleNavigate("/")} className={`${classes.logo} ${themes.logo}`}>
        <SVG src="/resources/images/logo.svg" alt="Lokalokator.cz" width="0" height="0"/>
      </a>
      <MainMenu menuItems={menuItems}/>
      <ToastContainer
        position="bottom-center"
      />
      <Modal
        open={contextData.showNotLoggedModal}
        onClose={() => setContextData({...contextData, showNotLoggedModal: false})}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
      >
        <div>
          <NotLoggedModal/>
        </div>
      </Modal>
      <div className={classes.hidden}>
        {
          ChangeWindowSize()
        }
        <SVG src="/resources/filters.svg" alt="Filters" width="0" height="0"/>
      </div>
    </div>
  );
}

MainLayout.propTypes = {
  _window: PropTypes.func,
};
export default MainLayout;
