const gameTimeFormat = (seconds, t) => {
  let formatedGameTime;
  seconds = parseFloat(seconds);
  if(seconds <= 0) {
    formatedGameTime = '00:00';
  }
  else {
    formatedGameTime = "";
  	let minutes = Math.floor(seconds / 60).toString();
    if(minutes < 10) minutes = "0" + minutes;
  	seconds = seconds - (minutes * 60).toString();
    if(seconds < 10) seconds = "0" + seconds;
    formatedGameTime = minutes + ":" + seconds;
  }
  return formatedGameTime;
}

export default gameTimeFormat;