import HttpService from '../http.service';

export const create = (type, accessToken) => {
  return HttpService.post('mode/creator/create', {type: type}, {}, {}, accessToken);
}

export const edit = (mid, accessToken) => {
  return HttpService.post('mode/creator/edit', {mid: mid}, {}, {}, accessToken);
}

export const getMaps = (accessToken) => {
  return HttpService.get('get/modeCreatorMaps', {}, {}, accessToken);
}

export const getMap = (mid,accessToken) => {
  return HttpService.get('get/modeCreatorMap?mid=' + mid, {}, {}, accessToken);
}

export const getCategories = () => {
  return HttpService.get('get/modeExplorerCategories', {}, {});
}

export const getWikipedia = (value) => {
  return HttpService.get('get/modeCreatorWikipedia?value=' + value, {}, {});
}

export const updateImage = (mid, accessToken) => {
  return HttpService.post('mode/creator/updateimage', {mid: mid}, {}, {}, accessToken);
}

