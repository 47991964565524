import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import Avatar from '../../../components/Images/Avatar';
import NormalButton from '../../../components/Buttons/NormalButton';
import PaginationButton from '../../../components/Buttons/PaginationButton';
import pointsFormat from '../../../utils/pointsFormat';
import totalTimesFormat from '../../../utils/totalTimesFormat';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    overflow: 'auto',
  },
  table: {
    width: '100%',
  },
  row: {
    '&:not(:last-of-type)': {
      borderBottomWidth: '0.125rem',
      borderBottomStyle: 'solid',
      borderBottomColor: '#DDDDDD',
    },
  },
  emptyRow: {
    '&:not(:last-of-type)': {
      '& > td': {
        height: '56.38px',
      },
    },
    '&:last-of-type': {
      '& > td': {
        height: '55.38px',
      },
    },
  },
  item: {
    padding: '0.5rem 0.5rem',
    whiteSpace: 'nowrap',
    '& > a > div': {
      marginRight: '1rem',
    },
    '&:nth-child(1)': {
      textAlign: 'center',
    },
    '&:nth-child(3)': {
      textAlign: 'right',
      width: '0px',
    },
    '&:nth-child(4)': {
      textAlign: 'right',
      width: '0px',
    },
    '&:nth-child(5)': {
      textAlign: 'right',
      width: '0px',
    },
  },
  linkTo: {
    color: '#8cc63f',
    textDecoration: 'none',
    verticalAlign: 'middle',
  },
  pagination: {
    marginTop: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    gap: '1rem',
  },
}));

const ScoreTable = ({ data, limit = "10", fillEmpty }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);
  limit = parseInt(limit);
  
  return (
    <div className={classes.root}>
      <table className={classes.table}>
        <tbody>
        {
          data.slice(current, current + limit).map((score,key) => (
            <tr key={`${"k_"}${key}`} className={classes.row}>
              <td className={classes.item}>
                {current + key + 1}.
              </td>
              <td className={classes.item}>
                <Link className={classes.linkTo} to={{pathname: '/profile/' + score.User.UID}}>
                  <Avatar userData={score.User}/>
                  {score.User.Nickname}
                </Link>
              </td>
              <td className={classes.item}>
                {pointsFormat(score.Points, t)}
              </td>
              <td className={classes.item}>
                {totalTimesFormat(score.Times, t)}
              </td>
              <td className={classes.item}>
                <Link className={classes.linkTo} to={{pathname: '/results/' + score.GID}}>
                  <SearchOutlinedIcon/>
                </Link>
              </td>
            </tr>
          ))    
        }
        {
          fillEmpty && data.length > limit && data.slice(current, current + limit).length < limit && Array.from('x'.repeat(limit - data.slice(current, current + limit).length)).map((i,key) => (
            <tr key={`${"k_"}${key}`} className={classes.emptyRow}>
              <td/>
            </tr>
          ))    
        }
        </tbody>
      </table>
      {
        data.length > limit && (
          <div className={classes.pagination}>
            <NormalButton buttonType="play" disabled={current === 0} onClick={() => setCurrent(0)}>
              {t('První stránka')}
            </NormalButton>
            {
              data.length - limit !== 0 && Array.from('x'.repeat(Math.ceil(((data.length - limit) / limit) - 1))).map((i, key) => (
                <PaginationButton disabled={current === limit * (key + 1)} onClick={() => setCurrent(limit * (key + 1))} key={`${"k_"}${key}`}>
                  { key + 2 }
                </PaginationButton>
              ))   
            } 
            <NormalButton buttonType="play" disabled={(current + limit) >= data.length} onClick={() => setCurrent(Math.floor(data.length/limit) * limit)}>
              {t('Poslední stránka')}
            </NormalButton>     
          </div>
        )
      }
    </div>
  )
};

export default ScoreTable;
