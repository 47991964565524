import React, { useState, useEffect } from "react";
import ImageRenderer from "../../Lazy/imageRenderer";

const CachedImage = ({ thumbLink, fullsizeLink, isResponsive, isPreloaded, className, isClickable, onClick }) => {

  const [isLoading,setIsLoading] = useState(false);
  
  useEffect(() => {
    setIsLoading(true);
    setTimeout(function() {
      setIsLoading(false);
    }, 1);
  }, [thumbLink,fullsizeLink]);
  
  return isLoading ? (<div></div>) : (
    <ImageRenderer
      thumbLink={thumbLink}
      fullsizeLink={fullsizeLink}
      isResponsive={isResponsive}
      isPreloaded={isPreloaded}
      className={className}
      onClick={onClick}
      isClickable={isClickable}
    />
  );
}

export default CachedImage;
