const totalTimesFormat = (seconds, t) => {
  seconds = parseFloat(seconds);
  let formatedTotalTimes;
  if(seconds === 0) {
    formatedTotalTimes = t('Žádná aktivita');
  } else {
    formatedTotalTimes = [];
  	const minutes = Math.floor(seconds / 60);
  	let totalMinutes = "";
  	if(minutes === 1) totalMinutes = minutes + ' ' + t('minuta');
  	else if(minutes === 2 || minutes === 3 || minutes === 4) totalMinutes = minutes + ' ' + t('minuty');
  	else totalMinutes = minutes + ' ' + t('minut');
  	seconds = seconds - (minutes * 60);
  	let totalSeconds = "";
  	if(seconds === 1) totalSeconds = seconds + ' ' + t('sekunda');
  	else if(seconds === 2 || seconds === 3 || seconds === 4) totalSeconds = seconds + ' ' + t('sekundy');
  	else totalSeconds = seconds + ' ' + t('sekund');
  	if(minutes !== 0) formatedTotalTimes.push(totalMinutes);
  	if(seconds !== 0) formatedTotalTimes.push(totalSeconds);
    formatedTotalTimes = formatedTotalTimes.join(" ");
  }
  return formatedTotalTimes;
}

export default totalTimesFormat;