import React, { useEffect, useState, useContext } from 'react';
import {useTranslation} from "react-i18next";
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import htmlParse from 'html-react-parser';
import NormalButton from "../../Buttons/NormalButton";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Context } from "../../../contexts";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "10px",
    background: "white",
    width: "30rem",
    maxWidth: '100%',
    overflow: "auto",
    padding: "1.25rem",
    margin: "1.25rem",
    maxHeight: 'calc(100vh - 2.5rem)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
      fontSize: '1.25rem',
    },
  },
  body: {
    padding: "1.25rem 0",
    maxHeight: 'calc(100vh - 18.75rem)',
  },
  closeButton: {
    padding: '0.625rem',
    '& svg': {
      color: "#8CC63F",
    }
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1.25rem",
    gap: '1rem',
  },
}));


const NotLoggedModal = ({ onCloseModal }) => {

  const { t } = useTranslation();
  const classes = useStyles({});
  const history = useHistory();
  const navigate = (to) => history.push(to);
  const { contextData, setContextData } = useContext(Context);

  const handleClose = (e) => {
    e.stopPropagation();
    setContextData({...contextData, showNotLoggedModal: false});
  };
  
  const handleLogin = () => {
    setContextData({...contextData, showNotLoggedModal: false});
    navigate('/login');
  };
  
  const handleRegister = () => {
    setContextData({...contextData, showNotLoggedModal: false});
    navigate('/register');
  };
  
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.wrapper}>
          <p>{t('Musíte být přihlášen')}</p>
        </div>
        <IconButton className={classes.closeButton} onClick={handleClose} size="small">
          <CloseOutlinedIcon/>
        </IconButton>
      </div>
      <div className={classes.body}>
          Pro tento obsah musíte být přihlášen
      </div>
      <div className={classes.footer}>
        <NormalButton buttonType="play" onClick={handleLogin}>
          {t('Přihlásit se')}
        </NormalButton>
        <NormalButton buttonType="default" onClick={handleRegister}>
          {t('Zaregistrovat se')}
        </NormalButton>
      </div>
    </div>
  )
};  

export default NotLoggedModal;
