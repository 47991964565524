import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core";
import moment from 'moment';
import Button from "@material-ui/core/Button";
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import {useTranslation} from "react-i18next";
import { Context, GameContext } from "../../../contexts";  
import * as ModeStreaksService from "../../../services/content/modeStreaks.service";   
import * as PlayService from "../../../services/content/play.service";              
import NormalButton from "../../../components/Buttons/NormalButton";
import Snackbar from "../../../components/Notifications/Snackbar";
import roundTimeFormat from "../../../utils/roundTimeFormat";
import streaksTypeFormat from "../../../utils/streaksTypeFormat";
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'; 
import OpenWithOutlinedIcon from '@mui/icons-material/OpenWithOutlined';
import ThreeSixtyOutlinedIcon from '@mui/icons-material/ThreeSixtyOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1',
    width: '100%',
    height: 'calc(100% - 2rem)',
    padding: '1rem 0',
    backgroundColor: 'rgb(0,0,119)',
    backgroundImage: 'linear-gradient(0deg, rgba(0,0,119,1) 0%, rgba(194,191,255,1) 100%)',
    '& > svg': {
      color: '#E5B513',
      fontSize: '6rem',
      marginTop: '3rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '3rem',
      },
    },
    '& > h1': {
      fontSize: '4rem',
      fontWeight: '600',
      color: '#FFFFFF',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
    },
    '& > h2': {
      fontSize: '2rem',
      fontWeight: '600',
      color: '#FFFFFF',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
    },
    '& > button': {
      marginTop: '2rem',
    },
    '& > span': {
      display: 'flex',
      marginTop: '1rem',
      fontWeight: '600',
      color: '#FFFFFF',
      '& > svg': {
        color: '#E5B513',
      },
    }, 
    '& > h3': {
      fontSize: '1.5rem',
      fontWeight: '600',
      color: '#FFFFFF',
      textTransform: 'uppercase',
      marginTop: '1rem',
      borderBottomWidth: '0.138rem',
      borderBottomStyle: 'solid',
      borderBottomColor: 'rgba(255,255,255,0.75)',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    '& > p': {
      fontSize: '0.7rem',
      color: '#FFFFFF',
      marginTop: '0.5rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.5rem',
      },
      '& > strong': {
        fontWeight: 'bold',
      },
      '& > span': {
        color: '#FFFFFF',
        fontSize: '1rem',
        fontWeight: '500',
        '& > span': {
          '&.Mui-checked': {
            color: '#8CC63F',
            '& + .MuiSwitch-track': {
              backgroundColor: '#A3D165',       
            },
          },
        },
      },
    },  
  },
  mode: {
    display: 'flex',
    gap: '2rem',
    marginTop: '3rem',
  },
  modeItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    '& > button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '5rem',
      height: '5rem',
      flex: '0 0 5rem',
      backgroundColor: '#EEEEEE',
      backgroundSize: '100% auto',
      backgroundPosition: 'center center',
      border: '0.5rem double #DDDDDD',
      position: 'relative',
      borderRadius: '100%',
      padding: '0',
      overflow: 'hidden',
      cursor: 'inherit',
      '& > svg': {
        width: '40%',
        height: '40%',
        padding: '1.25rem',
        transition: 'all 0.5s',
        color: '#000000',        
      },
    },
    '& > span': {
      color: '#FFFFFF',
      fontWeight: '600',
    },
    '&.active': {
      '& > button': {
        '& > svg': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgb(193,193,193)',
          backgroundImage: 'linear-gradient(180deg, rgba(193,193,193,1) 0%, rgba(95,96,95,1) 100%)',
          color: '#ffd700',
          width: '50%',
          height: '50%',
          padding: '1rem',
          borderRadius: '100%',    
        },
      },
    }, 
    '&:not(.active)': {
      cursor: 'pointer',
    },   
  },
  customSettings: {
    display: 'flex',
    gap: '2rem',
    marginTop: '3rem',
  },
  customSettingsSlider: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    width: '12rem',
    '& > span:first-of-type': {
      color: '#FFFFFF',
      fontWeight: '600',
      fontSize: '0.8rem',    
    },
    '& > span:last-of-type': {
        color: '#8CC63F',
        '& > span.MuiSlider-markLabel': {
          color: '#FFFFFF',
        },
        '& > span.MuiSlider-thumb': {
          '&:hover': {
            boxShadow: '0px 0px 0px 8px rgb(140 198 6 / 16%)',
          },
          '&.Mui-focusVisible': {
            boxShadow: '0px 0px 0px 8px rgb(140 198 6 / 16%)',
          },
          '&.Mui-active': {
            boxShadow: '0px 0px 0px 14px rgb(140 198 6 / 16%)',
          },
        },
      },
    '& > p': {
      color: '#FFFFFF',
      fontSize: '0.8rem',    
    },  
  },
  customSettingsSwitch: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.5rem',
    '& > svg': {
      color: '#8CC63F',
      width: '2.5rem',
      height: '2.5rem',
    },
    '& > span:first-of-type': {
      color: '#FFFFFF',
      fontWeight: '600',
      fontSize: '0.8rem',
    },
    '& > span:last-of-type': {
      '& > span.Mui-checked': {
        color: '#8CC63F',
        '& + .MuiSwitch-track': {
          backgroundColor: '#A3D165',       
        },
      },
    },
  },
}));

const LobbyStreaks = ({}) => {
  const { t } = useTranslation();
  const classes = useStyles({});
  const { contextData, setContextData } = useContext(Context);
  const { gameContextData, setGameContextData } = useContext(GameContext);
  const authToken = contextData.authToken;
  const gameData = gameContextData;
  const gid = gameData.gid; 
  const [highStreak, setHighStreak] = useState(0);    
  const [isFailed, setIsFailed] = useState(false);
  const [gameTimeLimit, setGameTimeLimit] = useState(gameData.TimeLimit);
  const [isCustomSettings, setIsCustomSettings] = useState((parseInt(gameData.TimeLimit) === 0 && parseInt(gameData.CanMove) === 1 && parseInt(gameData.CanLook) === 1 && parseInt(gameData.CanZoom) === 1) ? false : true);

  const getHighStreak = () => {
    ModeStreaksService.getHighStreaks(gameData.Type, authToken).then((result) => {
      setHighStreak(result.data);   
    });   
  }; 
  
  const handleSetMode = (mode) => {
    setGameContextData({...gameContextData, Mode: mode});
    const payload = {
      "gid": gid,
      "mode": mode
    };
    updateGameData(payload);
  };
  
  const handleChangeRoundTime = (e, value) => {
    setGameTimeLimit(value.toString());
  };
  
  const handleUpdateRoundTime = (e, value) => {
    setGameContextData({...gameContextData, TimeLimit: value.toString()});
    const payload = {
      "gid": gid,
      "timelimit": value
    };
    updateGameData(payload);
  };
  
  const handleUpdateCanMove = () => {
    let canMove = parseInt(gameContextData.CanMove);
    let newCanMove = 0;
    if(canMove === 0) newCanMove = 1;
    setGameContextData({...gameContextData, CanMove: newCanMove.toString()});
    const payload = {
      "gid": gid,
      "canmove": newCanMove
    };
    updateGameData(payload);
  };
  
  const handleUpdateCanLook = () => {
    let canLook = parseInt(gameContextData.CanLook);
    let newCanLook = 0;
    if(canLook === 0) newCanLook = 1;
    setGameContextData({...gameContextData, CanLook: newCanLook.toString()});
    const payload = {
      "gid": gid,
      "canlook": newCanLook
    };
    updateGameData(payload);
  };
  
  const handleUpdateCanZoom = () => {
    let canZoom = parseInt(gameContextData.CanZoom);
    let newCanZoom = 0;
    if(canZoom === 0) newCanZoom = 1;
    setGameContextData({...gameContextData, CanZoom: newCanZoom.toString()});
    const payload = {
      "gid": gid,
      "canzoom": newCanZoom
    };
    updateGameData(payload);
  };
  
  const updateGameData = (payload) => {
    PlayService.editGameData("streaks",payload,contextData.authToken).then((result) => {}).catch((error) => {
      setIsFailed(true);
    });
  };
  
  const startGame = () => {
    setGameContextData({...gameContextData, Phase: ["Round|1"]});
    const payload = {
      "gid": gid,
      "phase": "Round|1",
      "dateround": moment().format("YYYY-MM-DD HH:mm:ss")
    };
    updateGameData(payload);  
  };
  
  useEffect(() => {
    if(!isCustomSettings) {
      setGameTimeLimit("0");
      setGameContextData({...gameContextData, TimeLimit: '0', CanMove: '1', CanLook: '1', CanZoom: '1'});
      const payload = {
        "gid": gid,
        "timelimit": 0,
        "canmove": 1,
        "canlook": 1,
        "canzoom": 1
      };
      updateGameData(payload);    
    }
  }, [isCustomSettings]);
  
  useEffect(() => {
    getHighStreak();
  }, []);
  
  return (
    <div className={classes.root}>
      <BoltOutlinedIcon/>
      <h1>{t('Lobby')}</h1>
      <h2>{t('Režim Série')} - {streaksTypeFormat(gameData.Type, t)}</h2>
      <div className={classes.mode}>
        <div className={`${classes.modeItem} ${gameData.Mode === "Single" && "active"}`} onClick={() => handleSetMode("Single")}>
          <button>
            <PersonOutlineOutlinedIcon/>  
          </button>
          <span>{t('Jeden hráč')}</span>
        </div>
        <div className={`${classes.modeItem} ${gameData.Mode === "Challenge" && "active"}`} onClick={() => handleSetMode("Challenge")}>
          <button>
            <GroupOutlinedIcon/>  
          </button>
          <span>{t('Výzva')}</span>
        </div>
      </div>
      {
        gameData.Mode === "Single" && (
          <NormalButton buttonType="play" startIcon={<SportsEsportsOutlinedIcon/>} onClick={startGame}>
            {t('Hrát')}
          </NormalButton>
        )
      }
      {
        gameData.Mode === "Challenge" && (
          <NormalButton buttonType="play" startIcon={<GroupAddOutlinedIcon/>} disabled>
            {t('Pozvat hráče')}
          </NormalButton>
        )
      }
      {
        <span>{t('Vaše nejlepší série')} - <BoltOutlinedIcon/> {highStreak}</span>
      }
      <h3>{t('Nastavení hry')}</h3>
      <p><strong>{t('Základní nastavení')}</strong> ({t('Žádný časový limit, lze se pohybovat, rozhlížet a přibližovat')}) <Switch checked={isCustomSettings ? false : true} onChange={() => setIsCustomSettings(!isCustomSettings)} /></p>
      {
        isCustomSettings && (
          <div className={classes.customSettings}>
            <div className={classes.customSettingsSlider}>
              <span>{t('Čas kola')}</span>
              <Slider
                value={parseInt(gameTimeLimit)}
                min={0}
                max={600}
                step={10}
                onChange={handleChangeRoundTime}
                onChangeCommitted={handleUpdateRoundTime}
              /> 
              <p>{roundTimeFormat(gameTimeLimit,t)}</p>          
            </div>
            <div className={classes.customSettingsSwitch}>
              <OpenWithOutlinedIcon/>
              <span>{t('Pohyb')}</span>
              <Switch checked={parseInt(gameData.CanMove) === 1? true : false} onChange={handleUpdateCanMove} />
            </div>
            <div className={classes.customSettingsSwitch}>
              <ThreeSixtyOutlinedIcon/>
              <span>{t('Rozhlížení')}</span>
              <Switch checked={parseInt(gameData.CanLook) === 1? true : false} onChange={handleUpdateCanLook} />
            </div>
            <div className={classes.customSettingsSwitch}>
              <ZoomInOutlinedIcon/>
              <span>{t('Přibližovat')}</span>
              <Switch checked={parseInt(gameData.CanZoom) === 1? true : false} onChange={handleUpdateCanZoom} />
            </div>
          </div>
        )
      }
      <Snackbar
        open={isFailed}
        autoHideDuration={3000}
        onClose={() => setIsFailed(false)}
        type="error"
        content={t("Něco se nepovedlo")}
      />
    </div>
  )
};

export default LobbyStreaks;
