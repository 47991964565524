import React, { useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core";
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import * as UserService from "../../../services/user.service";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../constants/config';
import DocumentTitle from "react-document-title";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useThemes from '../../../constants/themes';
import MenuIcon from '@mui/icons-material/Menu';
import NormalButton from "../../../components/Buttons/NormalButton";
import Snackbar from "../../../components/Notifications/Snackbar";
import { Context } from "../../../contexts";
import { useCookies } from 'react-cookie';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import AccessibilityNewOutlinedIcon from '@mui/icons-material/AccessibilityNewOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';

const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  banner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "clamp(2.5rem,5vw,5rem) 0",
    backgroundColor: "#EEEEEE",
    width: "100%",
    "& > h1": {
      color: "#8CC63F",
      fontSize: "clamp(2rem,2.5vw,3rem)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      "&:only-child": {
        fontSize: "clamp(3rem,3.5vw,4rem)",
      },
    },
    "& > h2": {
      color: "#000000",
      fontSize: "clamp(1rem,2vw,1.5rem)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
    },
  },
  tabs: {
    width: 'calc(100% - 2rem)',
    padding: '1rem',
    '& .MuiTabs-indicator	': {
      display: 'none',    
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      padding: '0',
    },
  },
  tab: {
    minHeight: 'unset !important',
    height: 'fit-content',
    borderBottomWidth: '2px !important',
    borderBottomStyle: 'solid !important',
    borderBottomColor: 'transparent !important',
    '&.Mui-selected': {
      color: '#8CC63F !important',
      borderBottomColor: '#8CC63F !important',
    },
    '& > svg': {
      width: '20px',
      height: '20px',
    },
  },
  premiumTab: {
    minHeight: 'unset !important',
    height: 'fit-content',
    color: '#FFD700 !important',
    borderBottomWidth: '2px !important',
    borderBottomStyle: 'solid !important',
    borderBottomColor: 'transparent !important',
    '&.Mui-selected': {
      borderBottomColor: '#FFD700 !important',
    },
    '& > svg': {
      width: '20px',
      height: '20px',
    },
  },
  seznamTab: {
    minHeight: 'unset !important',
    height: 'fit-content',
    color: '#CC0000 !important',
    borderBottomWidth: '2px !important',
    borderBottomStyle: 'solid !important',
    borderBottomColor: 'transparent !important',
    '&.Mui-selected': {
      borderBottomColor: '#CC0000 !important',
    },
    '& > svg': {
      width: '20px',
      height: '20px',
    },
  },
  subTitle: {
    color: '#000000',
    fontSize: '1.5rem',
    padding: '0.5rem 0', 
    width: 'auto', 
  },
  infoText: {
    marginBottom: '1rem',
    '& > strong': {
      fontWeight: 'bold',
      marginLeft: '0.5rem',
    }, 
  },
  tabPanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 'calc(100% - 8rem)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
    },
    '&:not([hidden])': {
      padding: '2rem 4rem',
      [theme.breakpoints.down('sm')]: {
        padding: '0',
      },    
    },
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100%',
    width: '100%'
  },
  previews: {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fill, minmax(25rem, 1fr) )',
    gridGap: '1rem 3rem',
    width: 'calc(100% - 4rem)',
    maxWidth: '100%',
    padding: '2rem 0 4rem 0',
    margin: '0 auto', 
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  previewWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewBox: {
  	position: 'relative',
  	display: 'flex',
  	flexDirection: 'row-reverse',
  	width: '100%',
  	maxWidth: 'calc(100vw - 4rem)',
  	height: '20rem',
  	borderWidth: '0.4rem',
    borderStyle: 'double',
    borderColor: '#000000',
  	borderRadius: '0.25rem',
    transition: 'background-color 0.25s',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-o-user-select': 'none',
    userSelect: 'none',
    '&:not(.active)': {
      cursor: 'pointer',
    },
    '&.active': {
      backgroundColor: '#8CC63F',    
    },
    '&::before': {
    	content: `''`,
    	display: 'flex',
    	flexDirection: 'column',
    	justifyContent: 'center',
    	alignItems: 'center',
    	position: 'absolute',
    	top: '0',
    	left: '0',
    	right: '0',
    	bottom: '0',
    	backgroundColor: 'transparent',
    	fontSize: '200%',
    	fontWeight: 'bold',
    	color: '#FFFFFF',    
    },
    '& *': {
      '-webkit-user-select': 'none',
      '-khtml-user-select': 'none',
      '-moz-user-select': 'none',
      '-o-user-select': 'none',
      userSelect: 'none',    
    },  
  },
  previewMenu: {
  	position: 'relative',
  	display: 'flex',
  	flexDirection: 'column',
  	alignItems: 'center',
  	width: '66px',
  	flex: '1 0 auto',
  	height: '100%',
  	overflow: 'hidden',
    zIndex: '1',
    '& > ul': {
	    width:'100%',
      marginTop: '20px',
      '& > li': {
        fontSize: '50%',
        padding: '2px 6px',
        whiteSpace: 'nowrap',     
      },      
    },
  },
  previewLogo: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  	width: '66px',
    height: '20px',
    '& > svg': {
      width: 'calc(90% - 0.2rem)',
      padding: '0 0.1rem',
      height: 'auto',    
    },
  },
  previewMain: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 auto',
    width: 'calc(100% - 66px)',
    height: '100%',
    backgroundColor: '#FCFAF9',  
  },
  previewHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    height: '20px',
  },
  previewHeaderToggle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    '& > svg': {
      width: '12px',
      height: '12px',
    },  
  },
  previewBody: {
  	display: 'flex',
  	flexDirection: 'column',
  	width: '100%',
  	height: 'calc(100% - 20px)',
    '& > p': {
      fontSize: '12px',
      width: 'calc(100% - 32px)',
      padding: '8px 16px',
      maxHeight: '100%',
      overflow: 'auto',
    }, 
  },
  previewBanner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '65px',
    backgroundColor: '#EEEEEE',
    '& > span': {
      fontSize: '20px',
      color: '#8CC63F',
    },  
  },
  previewName: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.75rem',
    fontSize: '20px',
    '&:not(.active)': {
      cursor: 'pointer',
    },
    '&.active': {
      color: '#8CC63F',
      fontWeight: 'bold',    
    },
  },
}));

const Settings = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const { contextData, setContextData } = useContext(Context);
  const userData = contextData.userData;
  const userTheme = contextData.userData?.Settings?.Theme || 'default';
  const userSeznamStatus = contextData.userData?.Seznam?.Status || false;
  const userSeznamUID = contextData.userData?.Seznam?.UID || "";
  const userSeznamToken = contextData.userData?.Seznam?.Token || "";    
  const themesNames = Object.entries(useThemes.themesNames);
  const themes = useThemes.themes;
  
  const [userLastTheme,setUserLastTheme] = useState(userTheme);
  const [changeThemeSuccess,setChangeThemeSuccess] = useState(false);
  const [changeThemeFailed,setChangeThemeFailed] = useState(false);
  
  const [cookies, setCookie] = useCookies([]);
  
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`settings-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  };

  
  const handleSetTheme = (theme) => {
    let tempUserData = userData;
    tempUserData.Settings.Theme = theme;
    setContextData({...contextData, userData: tempUserData});
  };
  
  const handleSaveChanges = (type) => {
    setChangeThemeSuccess(false);
    setChangeThemeFailed(false);
    if(type === "theme") {
      if(userLastTheme !== userTheme) {
        setUserLastTheme(userTheme);
        const payload = {
          "theme": userTheme
        };
        UserService.updateSettings(payload,contextData.authToken).then((result) => {
          if(result) {
            if(result.data) {
              setChangeThemeSuccess(true);
            }
          }
        }).catch((error) => {
          setChangeThemeFailed(true);
        });
      }
    }  
  };
  
  const handleSeznamConnect = () => {
    const linkUrl = contextData.configuration.SeznamClientLink;
    const clientID = contextData.configuration.SeznamClientID;
    const finalLink = linkUrl.replace("%clientID%",clientID) + "seznam/connect";
    setCookie('authToken', contextData.authToken, {
      path: '/',
      domain: '.lokalokator.cz'
    });
    const connect_window = window.open(finalLink, '_blank');
    var winClosed = setInterval(function () {
      if(connect_window.closed) {
        clearInterval(winClosed);
        setContextData({...contextData, updateUser: Date.now()});
      }
    }, 250);
  };
  
  const handleSeznamRemove = () => {
    UserService.removeSeznamToken(userSeznamToken, contextData.authToken).then((result) => {
      setContextData({...contextData, updateUser: Date.now()});
    }).catch((error) => {
      setContextData({...contextData, updateUser: Date.now()});
    });
  };
  
  return (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Nastavení')}`}> 
      <section className={classes.section}>
      	<div className={classes.banner}>
      		<h1>{t('Nastavení')}</h1>
      		<h2>{t('Zde si můžete vše nastavit dle vaší libosti')}</h2>
      	</div>
        <div className={classes.tabs}>
          <Tabs value={value} indicatorColor="primary" onChange={handleChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
            <Tab className={classes.tab} icon={<PaletteOutlinedIcon/>} iconPosition="start" label={t("Přizpůsobení")} />
            <Tab className={classes.tab} icon={<AccessibilityNewOutlinedIcon/>} iconPosition="start" label={t("Usnadnění")} />
            <Tab className={classes.tab} icon={<BuildOutlinedIcon/>} iconPosition="start" label={t("Obsah")} />
            <Tab className={classes.premiumTab} icon={<WorkspacePremiumOutlinedIcon/>} iconPosition="start" label={t("Předplatné")} />
            <Tab className={classes.seznamTab} icon={<SVG src="/resources/images/third/seznam-logo-esko.svg" />} iconPosition="start" label={t("Přihlášení")} />
          </Tabs>
          <TabPanel className={classes.tabPanel} value={value} index={0}>
            <h2 className={classes.subTitle}>{t('Barevná schémata')}</h2>
            <NormalButton buttonType={userLastTheme === userTheme ? "nochange" : "save"} onClick={() => handleSaveChanges("theme")} type="button">
      				{t('Uložit změny')}
      			</NormalButton>
            <div className={classes.previews}>
              {
                themesNames.map((previewTheme,key) => (
                  <div className={classes.previewWrapper} key={`key_${key}`}>
                    <div className={`${classes.previewBox} ${themes[previewTheme[0]]().previewRoot} ${userTheme === previewTheme[0] && 'active'}`} onClick={() => handleSetTheme(previewTheme[0])}>
            					<div className={`${classes.previewLogo} ${themes[previewTheme[0]]().previewLogo}`}>
            						<SVG src="/resources/images/logo.svg"/>
            					</div>
            					<div className={classes.previewMain}>
                        <div className={`${classes.previewHeader} ${themes[previewTheme[0]]().previewHeader}`}>
            							<div className={`${classes.previewHeaderToggle} ${themes[previewTheme[0]]().previewHeaderToggle}`}>
            								<MenuIcon/>
            							</div>
            						</div>
            						<div className={classes.previewBody}>
            							<div className={classes.previewBanner}>
            								<span>Náhled</span>
            							</div>
                          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Curabitur ligula sapien, pulvinar a vestibulum quis, facilisis vel sapien. Ut tempus purus at lorem. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Vivamus porttitor turpis ac leo. Donec vitae arcu. Duis sapien nunc, commodo et, interdum suscipit, sollicitudin et, dolor. Cras pede libero, dapibus nec, pretium sit amet, tempor quis. Etiam neque. Quisque porta. Ut tempus purus at lorem. Integer in sapien. Fusce nibh.</p>
            					  </div>
      					      </div>
                      <div className={`${classes.previewMenu} ${themes[previewTheme[0]]().previewMenu}`}>
            						<ul>
            							<li>Položka 1</li>
            							<li className={themes[previewTheme[0]]().previewMenuHover}>Položka 2</li>
            							<li>Položka 3</li>
            						</ul>
            					</div>
                    </div>
                    <span className={`${classes.previewName} ${userTheme === previewTheme[0] && 'active'}`} onClick={() => handleSetTheme(previewTheme[0])}>
                      {previewTheme[1]}
                    </span>
                  </div>
                ))
              }
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            Filtry
          </TabPanel>
          <TabPanel value={value} index={2}>
            Obsah
          </TabPanel>
          <TabPanel value={value} index={3}>
            Předplatné
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={value} index={4}>
            {
              userSeznamStatus ? (
                <div className={classes.tabWrapper}>
                  <h2 className={classes.subTitle}>{t('Přihlásit se přes Seznam.cz')}</h2>
                  <p className={classes.infoText}>{t('Váš účet je propojený se službou Seznam.cz.')}</p>
                  <p className={classes.infoText}>
                    {t('Známe pouze Vaše SeznamID')}:
                    <strong>{userSeznamUID.replaceAll('"',"")}</strong>
                  </p>
                  <NormalButton buttonType={"seznam"} onClick={() => handleSeznamRemove()} startIcon={<SVG src="/resources/images/third/seznam-logo-esko.svg" alt="Seznam.cz" width="0" height="0"/>} type="button">
            				{t('Zrušit propojení účtů')}
            			</NormalButton>
                </div>            
              ) : (
                <div className={classes.tabWrapper}>
                  <h2 className={classes.subTitle}>{t('Přihlásit se přes Seznam.cz')}</h2>
                  <p className={classes.infoText}>{t('Vypadá to, že ještě nejste přihlášen přes Seznam.cz. Tato možnost Vám přináší výhodu, že se můžete přihlásit jedním kliknutím přes Seznam účet.')}</p>
                  <NormalButton buttonType={"seznam"} onClick={() => handleSeznamConnect()} startIcon={<SVG src="/resources/images/third/seznam-logo-esko.svg" alt="Seznam.cz" width="0" height="0"/>} type="button">
            				{t('Propojit účet se Seznam účtem')}
            			</NormalButton>
                </div>
              )
            }
          </TabPanel>
        </div>
        <Snackbar
          open={changeThemeFailed}
          autoHideDuration={3000}
          onClose={() => setChangeThemeFailed(false)}
          type="error"
          content={t("Barevné schéma se nepodařilo uložit")}
        />
        <Snackbar
          open={changeThemeSuccess}
          autoHideDuration={3000}
          onClose={() => setChangeThemeSuccess(false)}
          type="success"
          content={t("Barevné schéma bylo uloženo")}
        />
      </section>
    </DocumentTitle>
  );
};

export default Settings;
