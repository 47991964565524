import React, { useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core";
import crypto from 'crypto';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../constants/config';
import DocumentTitle from "react-document-title";
import * as AuthService from "../../../services/auth.service";
import * as UserService from "./../../../services/user.service";
import LoginInput from "../../../components/Forms/LoginInput";
import NormalButton from "../../../components/Buttons/NormalButton";
import Snackbar from "../../../components/Notifications/Snackbar";
import * as Toast from '../../../components/Notifications/Toast';
import useLocalStorageObject from '../../../utils/useLocalStorageObject';
import StorageService from "../../../services/storage.service";
import { Context, ContextDefaultData } from "../../../contexts";
import { useCookies, withCookies } from 'react-cookie';

const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  banner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "clamp(2.5rem,5vw,5rem) 0",
    backgroundColor: "#EEEEEE",
    width: "100%",
    "& > h1": {
      color: "#8CC63F",
      fontSize: "clamp(2rem,2.5vw,3rem)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      "&:only-child": {
        fontSize: "clamp(3rem,3.5vw,4rem)",
      },
    },
    "& > h2": {
      color: "#000000",
      fontSize: "clamp(1rem,2vw,1.5rem)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "0 0 1rem 0",
    width: "50%",
    [theme.breakpoints.down('sm')]: {
      width: "90%",
    },  
  },
  buttons: {
  	width: '100%',
  	display: 'flex',
  	flexDirection: 'row',
  	flexWrap: 'wrap',
  	justifyContent: 'flex-end',
    gap: '1rem',
    marginTop: '1rem',
  },
}));

const Login = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  
  const [isNotFilledAll,setIsNotFilledAll] = useState(false);
  const [isNotFilledAllSnack,setIsNotFilledAllSnack] = useState(false);
  const [isUnknownError,setIsUnknownError] = useState(false);  
  const [isEmailNotFound,setIsEmailNotFound] = useState(false);
  const [isEmailInvalid,setIsEmailInvalid] = useState(false);
  const [isPasswordInvalid,setIsPasswordInvalid] = useState(false);
  const [isEmailAndPasswordNotMatch,setIsEmailAndPasswordNotMatch] = useState(false);
  const [isAuthTokenInvalid,setIsAuthTokenInvalid] = useState(false);  
  const [isSubmit,setIsSubmit] = useState(false);
  
  const [userToken, setUserToken] = useLocalStorageObject(config.TOKEN_KEY, null);
  const [userData, setUserData] = useLocalStorageObject(config.USER_KEY, null);
  const { contextData, setContextData } = useContext(Context);
  
  const [cookies, setCookie] = useCookies([]);
  
  const getCookies = (name) => {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];  
  };
  
  const [formState, setFormState] = useState({
    password: null,
    email: null
  });
  
  const onInputChange = (name, value) => {
    setIsSubmit(false);
    setIsNotFilledAll(false);
    setIsNotFilledAllSnack(false);
    setFormState({
      ...formState,
      [name]: value,
    });
  };
  
  const handleSubmit = (e) => {
    setIsSubmit(false);
    setIsSubmit(true);
    e.preventDefault();
    if(formState.email && formState.password) {
      const encryptPassword = crypto.createHash('sha1').update(formState.password).digest('hex');
      AuthService.signIn(formState.email, encryptPassword).then((result) => {
        if(result) {
          if(result.data) {
            Toast.createNotification("success",t("Úspěšně přihlášen"));
            setUserToken(result.data.authToken); 
            setUserData(result.data.user);
            setContextData({...contextData, authToken: result.data.authToken, userData: result.data.user, userStatus: true});
          }
        }
      }).catch((error) => {
        if (error.response) {
          switch(error.response.status) {
            case 406:
              if(error.response.data) {
                switch(error.response.data) {
                  case "EmailInvalid": 
                    setIsEmailInvalid(false);
                    break;
                  case "PasswordInvalid": 
                    setIsPasswordInvalid(true);
                    break;
                  default:
                    setIsUnknownError(true);
                    break; 
                }
              }
              break;
            case 409:
              if(error.response.data) {
                switch(error.response.data) {
                  case "EmailNotFound": 
                    setIsEmailNotFound(true);
                    break;
                  case "EmailAndPasswordNotMatch": 
                    setIsEmailAndPasswordNotMatch(true);
                    break;
                  default:
                    setIsUnknownError(true);
                    break; 
                }
              }
              break;
            default:
              setIsUnknownError(true);
              break;
          }
        }
        setIsSubmit(false);
      });
    } else {
      setIsNotFilledAll(true);
      setIsNotFilledAllSnack(true);
      setIsSubmit(false);
    }
  };
  
  const handleForceLogout = (token) => {
    AuthService.logout(token).then((result) => {
      StorageService.removeItem(config.TOKEN_KEY);
      StorageService.removeItem(config.USER_KEY);
      setContextData({...ContextDefaultData, configuration: contextData.configuration});
      setIsAuthTokenInvalid(true);    
    }).catch((error) => {
      StorageService.removeItem(config.TOKEN_KEY);
      StorageService.removeItem(config.USER_KEY);
      setContextData({...ContextDefaultData, configuration: contextData.configuration});
      setIsAuthTokenInvalid(true);  
    });   
  };
  
  const handleUpdateUser = (token) => {
    console.log(token);
    UserService.updateUser(token).then((result) => {
      if(result) {
        if(result.data) {
          if(result.data.user) {
            setUserToken(token); 
            setUserData(result.data.user);
            setContextData({...contextData, authToken: token, userData: result.data.user, userStatus: true});
          }
        }
      }
    }).catch((error) => {
      handleForceLogout(token);   
    });  
  }; 
  
  const handleSeznamLogin = () => {
    const linkUrl = contextData.configuration.SeznamClientLink;
    const clientID = contextData.configuration.SeznamClientID;
    const finalLink = linkUrl.replace("%clientID%",clientID) + "seznam/login";
    const connect_window = window.open(finalLink, '_blank');
    var winClosed = setInterval(function () {
      if(connect_window.closed) {
        clearInterval(winClosed);
        const authToken = getCookies("authToken");
        if(authToken !== undefined) {
          handleUpdateUser(authToken);
        }
      }
    }, 250);  
  };  

  return (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Přihlášení')}`}>
      <section className={classes.section}>
      	<div className={classes.banner}>
      		<h1>{t('Přihlásit se')}</h1>
      		<h2>{t('ke svému účtu!')}</h2>
      	</div>
      	<form className={classes.form} onSubmit={handleSubmit}>
          <LoginInput
            placeholder={t('Zadejte Vaši emailovou adresu')}
            type="text"
            label={t('Email') + '*'}
            name="email"
            onChange={onInputChange}
            tabIndex="1"
            disabled={isSubmit}
          />
          <LoginInput
            placeholder={t('Zadejte Vaše heslo')}
            type="password"
            label={t('Heslo') + '*'}
            name="password"
            onChange={onInputChange}
            disabled={isSubmit}
            tabIndex="2"
          />
      		<div className={classes.buttons}>
            <NormalButton buttonType="lokalokator" type="submit" tabIndex="3" disabled={isSubmit} startIcon={<SVG src="/resources/images/logo_icon.svg" alt="Lokalokátor.cz" width="0" height="0"/>}>
      				{t('Přihlásit přes Lokalokátor')}
      			</NormalButton>
            {
              !isSubmit && (
                <NormalButton buttonType="seznam" type="button" tabIndex="4" startIcon={<SVG src="/resources/images/third/seznam-logo-esko.svg" alt="Seznam.cz" width="0" height="0"/>} onClick={handleSeznamLogin}>
          				{t('Přihlásit přes Seznam')}
          			</NormalButton>
              )
            }
      		</div>
      	</form>
        <Snackbar
          open={isNotFilledAllSnack}
          autoHideDuration={3000}
          onClose={() => setIsNotFilledAllSnack(false)}
          type="warning"
          content={t("Musíte vyplnit všechna pole")}
        />
        <Snackbar
          open={isUnknownError}
          autoHideDuration={3000}
          onClose={() => setIsUnknownError(false)}
          type="error"
          content={t("Vyskytla se neznámá chyba")}
        />
        <Snackbar
          open={isEmailInvalid}
          autoHideDuration={3000}
          onClose={() => setIsEmailInvalid(false)}
          type="error"
          content={t("Zadaný email je neplatný")}
        />
        <Snackbar
          open={isEmailNotFound}
          autoHideDuration={3000}
          onClose={() => setIsEmailNotFound(false)}
          type="error"
          content={t("Tento email není zaregistrován")}
        />
        <Snackbar
          open={isPasswordInvalid}
          autoHideDuration={3000}
          onClose={() => setIsPasswordInvalid(false)}
          type="error"
          content={t("Zadané heslo je neplatné")}
        />
        <Snackbar
          open={isEmailAndPasswordNotMatch}
          autoHideDuration={3000}
          onClose={() => setIsEmailAndPasswordNotMatch(false)}
          type="error"
          content={t("Zadaný email nebo heslo není správné")}
        />
        <Snackbar
          open={isAuthTokenInvalid}
          autoHideDuration={3000}
          onClose={() => setIsAuthTokenInvalid(false)}
          type="error"
          content={t("Tento Seznam účet není připojen k žádnému účtu")}
        />
      </section>
    </DocumentTitle>
  );
};


export default Login;
