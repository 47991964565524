import React, { useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core";
import {useTranslation} from "react-i18next";
import { Link, useHistory } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import NormalButton from "../../Buttons/NormalButton";
import CachedImage from '../../Images/CachedImage';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import pointsFormat from "../../../utils/pointsFormat"; 
import timeFormat from "../../../utils/timeFormat";
import { Context, GameContext } from "../../../contexts";
import * as ModeExplorerService from "../../../services/content/modeExplorer.service";
import Snackbar from "../../../components/Notifications/Snackbar";

const useStyles = makeStyles((theme) => ({
  largeItems: {
  	position: 'relative',
  	margin: '0 auto',
  	padding: '2rem 0',
  	justifyContent: 'center',
  	display: 'grid',
  	gridTemplateColumns: 'repeat( auto-fill, minmax(29rem, 0.5fr) )',
  	gridGap: '3rem',
  	flexWrap: 'wrap',
  	width: 'calc(100% - 4rem)',
  	maxWidth: '100%',
    '& > li': {
    	display: 'flex',
    	flexDirection: 'column',
    	justifyContent: 'flex-start',
    	justifySelf: 'center',
    	alignItems: 'center',
    	minWidth: '18rem',
    	width: '100%',
    	maxWidth: 'calc(100vw - 4rem)',
    	minHeight: '50vh',
    	borderRadius: '1rem',
    	overflow: 'hidden',
    	'-webkit-box-shadow': '0px 0px 32px -8px rgba(0,0,0,0.75)',
    	'-moz-box-shadow': '0px 0px 32px -8px rgba(0,0,0,0.75)',
    	boxShadow: '0px 0px 32px -8px rgba(0,0,0,0.75)',
    	position: 'relative',
    	zIndex: '3',
      backgroundImage: 'linear-gradient(to bottom, #ececec, #e3e3e4, #dcd9db, #d4d0d2, #cdc7c8)',
      '& > div.preview': {
      	display: 'flex',
      	justifyContent: 'center',
      	alignItems: 'center',
      	width: '100%',
      	height: '10rem',
      	minHeight: '10rem',
      	flex: '0 0 10rem',
      	backgroundColor: '#8CC63F',
      	backgroundSize: '100% auto',
      	backgroundPosition: 'center center',
      	position: 'relative',
      	overflow: 'hidden',
        '&::after': {
          content: `''`,
        	position: 'absolute',
        	top: '0',
        	left: '0',
        	right: '0',
        	bottom: '0',
        	zIndex: '2',
        	backgroundColor: 'rgba(0,0,0,0.2)',          
        },      
      }, 
      '& > div.medal': {
      	position: 'absolute',
      	width: '60px',
      	height: '60px',
      	top: '0.25rem',
      	right: '0.25rem',
      	zIndex: '2',
      },
      '& > div:not(.preview):not(.medal)': {
      	display: 'flex',
      	flexDirection: 'column',
      	alignItems: 'center',
      	width: 'calc(100% - 2rem)',
      	padding: '1rem 1rem 0 1rem',
      	height: 'auto',
      	flex: '1 1 auto',
        '& > h4': {
        	position: 'relative',
        	zIndex: '2',
        	fontSize: '2rem',
          fontWeight: '600',
        	whiteSpace: 'nowrap',
        	overflow: 'hidden',
          textOverflow: 'ellipsis',
        	maxWidth: '100%',
          paddingBottom: '0.175rem',
          '& > a': {
            textDecoration: 'none',
          	color: '#8CC63F',
          	borderBottomWidth: '0.175rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'transparent',
          	transition: 'all 0.5s',
            '&:hover': {
              borderBottomColor: '#8CC63F',
            },          
          },        
        },
        '& > p': {
        	marginBottom: '3rem',
        	maxHeight: '8rem',
        	overflow: 'auto',        
        },
        '& > button': {
          marginTop: 'auto',
          marginBottom: '1rem',
        },      
      },
      '& > ul': {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
        gridColumnGap: '1rem',
        gridRowGap: '1rem',
        justifyItems: 'center',
        width: '100%',
        backgroundImage: 'linear-gradient(180deg, rgba(140,198,63,1) 0%, rgba(98,138,44,1) 100%)',
        padding: '1rem 0',
        [theme.breakpoints.down('sm')]: {
          gridTemplateColumns: 'repeat(auto-fill, minmax(130px, 1fr))',
          gridColumnGap: '0.75rem',
          gridRowGap: '0.75rem',                         
        },
        '& > li': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          fontSize: '0.75rem',
          width: '130px',
          height: '50px',
          whiteSpace: 'nowrap',
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          color: '#EDEDED',
          borderRadius: '10px',
          fontWeight: 'bold',
          '& > svg': {
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
            width: '1.25rem',
            height: '1.25rem',
            color: '#FFFFFF',
          },
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
            fontWeight: '300',
            '& > span': {
              fontWeight: '600',
              fontSize: '0.75rem',
              color: '#FFFFFF',
              textTransform: 'uppercase',
            },       
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: '0.6rem',
            width: '120px',
            height: '40px',
            '& > div': {
              '& > span': {
                fontSize: '0.65rem !important',
              },       
            },                     
          },           
        },         
      },   
    },
  },
  smallItems: {
  	position: 'relative',
  	margin: '0 auto',
  	padding: '2rem 0',
  	justifyContent: 'center',
  	display: 'grid',
  	gridTemplateColumns: 'repeat( auto-fit, minmax(16rem, 1fr) )',
  	gridGap: '3rem',
  	flexWrap: 'wrap',
  	width: 'calc(100% - 4rem)',
  	maxWidth: '100%',
    '& > li': {
    	display: 'flex',
    	flexDirection: 'column',
    	justifyContent: 'flex-start',
    	justifySelf: 'center',
    	alignItems: 'center',
    	minWidth: '18rem',
    	width: '100%',
    	maxWidth: 'calc(100vw - 4rem)',
    	minHeight: '35vh',
    	borderRadius: '1rem',
    	overflow: 'hidden',
    	'-webkit-box-shadow': '0px 0px 32px -8px rgba(0,0,0,0.75)',
    	'-moz-box-shadow': '0px 0px 32px -8px rgba(0,0,0,0.75)',
    	boxShadow: '0px 0px 32px -8px rgba(0,0,0,0.75)',
    	position: 'relative',
    	zIndex: '2',
      backgroundImage: 'linear-gradient(to bottom, #ececec, #e3e3e4, #dcd9db, #d4d0d2, #cdc7c8)',
      '& > div.preview': {
      	display: 'flex',
      	justifyContent: 'center',
      	alignItems: 'center',
      	width: '100%',
      	height: '10rem',
      	minHeight: '7.5rem',
      	flex: '0 0 7.5rem',
      	backgroundColor: '#8CC63F',
      	backgroundSize: '100% auto',
      	backgroundPosition: 'center center',
      	position: 'relative',
      	overflow: 'hidden',
        '&::after': {
          content: `''`,
        	position: 'absolute',
        	top: '0',
        	left: '0',
        	right: '0',
        	bottom: '0',
        	zIndex: '1',
        	backgroundColor: 'rgba(0,0,0,0.2)',          
        },      
      }, 
      '& > div.medal': {
      	position: 'absolute',
      	width: 'auto',
      	height: '30%',
      	top: '0.25rem',
      	right: '0.25',
      	'-webkit-user-select': 'none',
      	'-khtml-user-select': 'none',
      	'-moz-user-select': 'none',
      	'-o-user-select': 'none',
      	userSelect: 'none',
      	'-webkit-user-drag': 'none',
      	'-khtml-user-drag': 'none',
      	'-moz-user-drag': 'none',
      	'-o-user-drag': 'none',
      	userDrag: 'none',
      	zIndex: '2',
        pointerEvents: 'none',
      },
      '& > div:not(.preview):not(.medal)': {
      	display: 'flex',
      	flexDirection: 'column',
      	alignItems: 'center',
      	width: 'calc(100% - 2rem)',
      	padding: '1rem 1rem 0 1rem',
      	height: 'auto',
      	flex: '1 1 auto',
        '& > h4': {
        	position: 'relative',
        	zIndex: '2',
        	fontSize: '1.25rem',
          fontWeight: '600',
        	whiteSpace: 'nowrap',
        	overflow: 'hidden',
          textOverflow: 'ellipsis',
        	maxWidth: '100%',
          paddingBottom: '0.125rem',
          marginBottom: '0.5rem',
          [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',                     
          }, 
          '& > a': {
            textDecoration: 'none',
          	color: '#8CC63F',
          	borderBottomWidth: '0.125rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'transparent',
          	transition: 'all 0.5s',
            '&:hover': {
              borderBottomColor: '#8CC63F',
            },          
          },        
        },
        '& > button': {
          marginBottom: '1rem',
          zoom: '0.75',
        },      
      },
      '& > ul': {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(130px, 1fr))',
        gridColumnGap: '0.75rem',
        gridRowGap: '0.75rem',
        justifyItems: 'center',
        width: '100%',
        backgroundImage: 'linear-gradient(180deg, rgba(140,198,63,1) 0%, rgba(98,138,44,1) 100%)',
        padding: '1rem 0',
        '& > li': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          fontSize: '0.6rem',
          width: '120px',
          height: '40px',
          whiteSpace: 'nowrap',
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          color: '#EDEDED',
          borderRadius: '10px',
          fontWeight: 'bold',
          '& > svg': {
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
            width: '1.25rem',
            height: '1.25rem',
            color: '#FFFFFF',
          },
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
            fontWeight: '300',
            '& > span': {
              fontWeight: '600',
              fontSize: '0.65rem',
              color: '#FFFFFF',
              textTransform: 'uppercase',
            },       
          },           
        },         
      },   
    },
  },
  tooltipAutoWidth: {
    width: "auto"
  },
  scoreIndicator: {
    display: 'flex',
    gap: '0.063rem',
    alignItems: 'flex-end',
    marginLeft: '0.5rem',
    marginRight: '0.65rem',
    color: '#FFFFFF',
    '& > span': {
      display: 'block',
      backgroundColor: 'currentColor',
    },
    '& > span:nth-child(1)': {
      width: '0.156rem',
      height: '0.2rem',
    },
    '& > span:nth-child(2)': {
      width: '0.156rem',
      height: '0.35rem',
    }, 
    '& > span:nth-child(3)': {
      width: '0.156rem',
      height: '0.5rem',
    }, 
    '& > span:nth-child(4)': {
      width: '0.156rem',
      height: '0.65rem',
    }, 
    '& > span:nth-child(5)': {
      width: '0.156rem',
      height: '0.8rem',
    },    
    '&.score_1': {
      '& > span.color': {
        color: '#57D600',
      },
    },
    '&.score_2': {
      '& > span.color': {
        color: '#8CC63F',
      },
    },
    '&.score_3': {
      '& > span.color': {
        color: '#E99235',
      },
    },
    '&.score_4': {
      '& > span.color': {
        color: '#D11B26',
      },
    },
    '&.score_5': {
      '& > span.color': {
        color: '#FF0000',
      },
    },
  },
}));

const Maps = ({ className, items, size, moreButton }) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const navigate = (to) => history.push(to);
  const { contextData, setContextData } = useContext(Context);
  const { gameContextData, setGameContextData } = useContext(GameContext);
  const authToken = contextData.authToken;
  const userStatus = contextData.userStatus;
  const [isUnknownError,setIsUnknownError] = useState(false);  
  const [isWrongType,setIsWrongType] = useState(false);
  const [isTooMuchOngoingGames,setIsTooMuchOngoingGames] = useState(false);
  
  const handlePlay = (mid) => {
    if(userStatus) {
      ModeExplorerService.newGame(mid, authToken).then((result) => {
        if(result) {
          if(result.data) {
            setGameContextData({...gameContextData, gid: result.data.GID});
            navigate('/play');
          }
        }
      }).catch((error) => {
        if (error.response) {
          if(error.response.data) {
            switch(error.response.data) {
              case "WrongType": 
                setIsWrongType(true);
                break;
              case "TooMuchOngoingGames": 
                setIsTooMuchOngoingGames(true);
                break;
              default:
                setIsUnknownError(true); 
            }
          }
        }
      });    
    } else {
      setContextData({...contextData, showNotLoggedModal: true});  
    }
  };
  
  return (
    <ul className={`${size === "large" ? classes.largeItems : classes.smallItems} ${className}`}>
      {
        items.map((map,key) => (
          <li key={`${"k_"}${key}`}>
            <CachedImage className={"preview"} thumbLink={map.thumbLink} fullsizeLink={map.fullsizeLink} alt={map.Name}/>   
              {
                map.Medal != null && (
                  <CachedImage className={"medal"} fullsizeLink={`${"https://api.lokalokator.cz/images/medals/medal_"}${map.Medal.toLowerCase()}${"_b.png"}`} alt={t('Medaile')}/>  
                )
              }
              <div>
                <h4>
                  <Link to={`${"/mode/explorer/"}${map.MID}${"/map"}`}>{map.Name}</Link>
                </h4>
                {
                  size === "large" && (
                    <p>{map.Description}</p>
                  )
                }
                <NormalButton buttonType="play" type="button" oneClick={true} tabIndex="-1" startIcon={<SportsEsportsOutlinedIcon/>} onClick={() => handlePlay(map.MID)}>
        				  {t('Hrát')}
        			  </NormalButton>
              </div>
              <ul>
                <li>
                  <TimerOutlinedIcon/>
                  <div>
                    <span>{t('Čas')}</span>
                    {timeFormat(map.TimeLimit, false, t)}
                  </div>
                </li>
                <li>
                  <ReplayOutlinedIcon/>
                  <div>
                    <span>{t('Počet kol')}</span>
                    {map.Rounds}
                  </div>
                </li>
                <li>
                  <LocationOnOutlinedIcon/>
                  <div>
                    <span>{t('Počet lokací')}</span>
                    {map.Locations}
                  </div>
                </li>
                <li>
                  <span className={`${classes.scoreIndicator} ${"score_"}${map.Score.Indicator.length}`}>
                    {
                      [...Array(map.Score.Indicator.length)].map((i, key) => (
                        <span key={`${"k_"}${key}`} className={"color"}></span>
                      ))
                    }
                    {
                      [...Array(5 - map.Score.Indicator.length)].map((i, key) => (
                        <span key={`${"k_"}${key}`}></span>
                      ))
                    }
                  </span>
                  <div>
                    <span>
                      {t('Obtížnost')}
                     </span>
                     {pointsFormat(map.Score.Data, t)}
                  </div>
                </li>
                {
                  size === "large" && (
                    <li>
                      <GroupsOutlinedIcon/>
                      <div>
                        <span>{t('Počet hraní')}</span>
                        {map.TotalPlay}
                      </div>
                    </li>
                  )
                }
                {
                  size === "large" && (
                    <li>
                      <ThumbUpOutlinedIcon/>
                      <div>
                        <span>{t('Počet palců')}</span>
                        {map.Likes}
                      </div>
                    </li>
                  )
                }
              </ul>
            </li>  
          ))
        }
        <Snackbar
          open={isUnknownError}
          autoHideDuration={3000}
          onClose={() => setIsUnknownError(false)}
          type="error"
          content={t("Vyskytla se neznámá chyba")}
        />
        <Snackbar
          open={isWrongType}
          autoHideDuration={3000}
          onClose={() => setIsWrongType(false)}
          type="warning"
          content={t("Neplatný typ hry")}
        />
        <Snackbar
          open={isTooMuchOngoingGames}
          autoHideDuration={3000}
          onClose={() => setIsTooMuchOngoingGames(false)}
          type="warning"
          content={t("Máte moc rozehraných her. Dohrajte nějakou nebo ji smažte.")}
        />
    </ul>
  )
};

export default Maps;
