import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../../constants/config';
import DocumentTitle from "react-document-title";
import GoogleAds from "react-google-ads";
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import InfoIcon from '@mui/icons-material/Info';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import NormalButton from "../../../../components/Buttons/NormalButton";
import Snackbar from "../../../../components/Notifications/Snackbar";
import Modal from '@mui/material/Modal';
import SimpleDetailModal from "../../../../components/Modals/SimpleDetailModal";
import * as ModeStreaksService from "../../../../services/content/modeStreaks.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Context, GameContext } from "../../../../contexts";
import { useParams }  from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  banner: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '30rem',
    flex: '0 0 30rem',
    backgroundColor: 'rgb(0,0,119)',
    backgroundImage: 'linear-gradient(0deg, rgba(0,0,119,1) 0%, rgba(194,191,255,1) 100%)',
    [theme.breakpoints.down('sm')]: {
      height: '10rem',
      flex: '0 0 10rem',
    },
    '& > svg': {
      color: '#E5B513',
      fontSize: '6rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '3rem',
      },
    }, 
  },
  title: {
    color: '#FFFFFF',
    fontSize: '4rem',
    marginTop: '1rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  subTitle: {
    color: '#FFFFFF',
    fontSize: '2rem',
    marginTop: '0.25rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    '& svg': {
      color: "#8CC63F",
    }
  },
  selector: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '2rem',
    minHeight: '50rem',
    padding: '2rem 0',
    [theme.breakpoints.down('sm')]: {
      minHeight: '30rem',
    },  
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem',
    borderRadius: '1rem',
    backgroundColor: '#CBCACB',
    width: '280px',
    height: '400px',
    cursor: 'pointer',
    position: 'relative',
    '& > span': {
      position: 'absolute',
      top: '1rem',
      left: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,0.1)',
      borderRadius: '0 0.25rem 0.25rem 0',
      padding: '0.063rem 0.5rem 0.063rem 0.125rem',
      color: '#777777',
      fontWeight: 'bold',
      lineHeight: '1.5rem',
      transition: 'color 0.5s',
      '& > svg': {
        marginRight: '0.125rem',
      },
    },
    '& > a': {
      position: 'absolute',
      top: '1rem',
      right: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#777777',
      fontWeight: 'bold',
      lineHeight: '1.5rem',
      transition: 'color 0.5s',
      '&:hover': {
        color: '#FFFFFF',
      },
    },
    '& > svg': {
      transform: 'scale(0.5)',
      color: '#9E9C9E',
      transition: 'color 0.5s',
      width: '250%',
    },
    '& > h3': {
      fontSize: '2rem',
      fontWeight: '600',
      color: '#777777',
      transition: 'color 0.5s',
      textTransform: 'uppercase',
    },
    '&:hover': {
      '& > span': {
        color: '#FFFFFF'
      },
      '& > svg': {
        color: '#FFFFFF', 
      },
      '& > h3': {
        color: '#FFFFFF',
      },
    },  
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: { 
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '1.5rem',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 'calc(33% - 1.5rem)',
      minWidth: '150px',
      '&:first-of-type > svg': {
        color: '#8CC63F',
      },
      '&:not(:first-of-type):not(:last-of-type) > svg': {
        color: 'rgba(255,0,0,0.2)',
        '& path': {
          stroke: '#FF0000 !important',
        },
        '& polygon': {
          stroke: '#FF0000 !important',
        },
      },
      '&:last-of-type > svg': {
        fontSize: '4rem',
        height: '6.25rem',
      },
      '& > span': {
        marginTop: '1rem',
        color: '#8CC63F',
        fontWeight: '600',
        fontSize: '1.25rem',
      },
      '& > p': {
        marginTop: '1rem',
        fontSize: '0.9rem',
        lineHeight: '1.1rem',
      },
    },
  },
}));

const ModeStreaks = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const navigate = (to) => history.push(to);
  const { contextData, setContextData } = useContext(Context);
  const { gameContextData, setGameContextData } = useContext(GameContext);
  const authToken = contextData.authToken;
  const userStatus = contextData.userStatus;
  const detail = useParams().id;
  const [highStreakRegions, setHighStreakRegions] = useState(0);
  const [highStreakDistricts, setHighStreakDistricts] = useState(0);
  const [isUnknownError,setIsUnknownError] = useState(false);  
  const [isWrongType,setIsWrongType] = useState(false);
  const [isTooMuchOngoingGames,setIsTooMuchOngoingGames] = useState(false);
  const [isOpenRegions, setIsOpenRegions] = useState(false);
  const [isOpenDistricts, setIsOpenDistricts] = useState(false);
  
  
  const newGame = (type) => {
    if(userStatus) {
      ModeStreaksService.newGame(type, authToken).then((result) => {
        if(result) {
          if(result.data) {
            setGameContextData({...gameContextData, gid: result.data.GID});
            navigate('/play');
          }
        }
      }).catch((error) => {
        if (error.response) {
          if(error.response.data) {
            switch(error.response.data) {
              case "WrongType": 
                setIsWrongType(true);
                break;
              case "TooMuchOngoingGames": 
                setIsTooMuchOngoingGames(true);
                break;
              default:
                setIsUnknownError(true); 
            }
          }
        }
      });
    } else {
      setContextData({...contextData, showNotLoggedModal: true});
    }
  };
  
  const getHighStreak = (type) => {
    ModeStreaksService.getHighStreaks(type, authToken).then((result) => {
      if(type === "Regions") {
        setHighStreakRegions(result.data);
      } else if(type == "Districts") {
        setHighStreakDistricts(result.data);
      }          
    });   
  }; 
  
  useEffect(() => {
    if(detail === "regions") {
       setIsOpenRegions(true);
    } else if(detail === "districts") {
       setIsOpenDistricts(true);
    }
  }, [detail]);
  
  const handleDetailClose = () => {
    setIsOpenRegions(false);
    setIsOpenDistricts(false);
    navigate('/mode/streaks');  
  };
  
  useEffect(() => {
    getHighStreak("Regions");
    getHighStreak("Districts");
  }, []);
  
  
  const regionsContent = (
    <div className={classes.row}>
      <div>
        <SVG src="https://api.lokalokator.cz/images/streaks/southbohemia.svg" viewBox="0 0 1496 1186" width="100" height="100"/>
        <span>{t('Kraje v České republice')}</span>
        <p>
           {t('Kraje v Česku jsou vyšší územní samosprávné celky, které jsou územními společenstvími občanů s právem na samosprávu. ')} 
           {t('Krajů je celkem 14, hlavní město Praha je podle zákona o hlavním městě Praze krajem i obcí zároveň.')}   
        </p>
      </div>
      <div>
        <SVG src="https://api.lokalokator.cz/images/streaks/southbohemia.svg" viewBox="0 0 1496 1186" width="100" height="100"/>
        <span>{t('Volba')}</span>
        <p>
           {t('Na výběr toto správného kraje máte k dispozici neomezený čas. ')} 
           {t('Jakmile si budete krajem jisti, stačí kliknout na místo na mapě a vybraný kraj se vyznačí.')}   
        </p>
      </div>
      <div>
        <DoubleArrowIcon/>
        <span>{t('V jedné řadě')}</span>
        <p>
           {t('Cílem tohoto režimu je poznat co nejvíce krajů v jedné řadě, bez chyby. ')} 
           {t('To se může jevit jako jednoduché, ale i v případě 14 krajů je těžké se pokaždé trefit.')}   
        </p>
      </div>
    </div>
  );
  
  const districtsContent = (
    <div className={classes.row}>
      <div>
        <SVG src="https://api.lokalokator.cz/images/streaks/pilsen.svg" viewBox="0 0 581.024 581.041" width="100" height="100"/>
        <span>{t('Okresy v České republice')}</span>
        <p>
           {t('Okresy v České republice jsou územní jednotky středního stupně, na něž se dělí území státu podle zákona o územně správním členění státu. ')}
           {t('Česká republika má celkem 76 okresů, hlavní město Praha nepatří do žádného z nich, není a nebyla okresem a její území se nedělí na okresy, ale na deset číslovaných obvodů.')}   
        </p>
      </div>
      <div>
        <SVG src="https://api.lokalokator.cz/images/streaks/pilsen.svg" viewBox="0 0 581.024 581.041" width="100" height="100"/>
        <span>{t('Volba')}</span>
        <p>
           {t('Na výběr toto správného okresu máte k dispozici neomezený čas. ')} 
           {t('Jakmile si budete okresem jisti, stačí kliknout na místo na mapě a vybraný okres se vyznačí.')}   
        </p>
      </div>
      <div>
        <DoubleArrowIcon/>
        <span>{t('V jedné řadě')}</span>
        <p>
           {t('Cílem tohoto režimu je poznat co nejvíce okresů v jedné řadě, bez chyby. ')} 
           {t('To se může jevit jako jednoduché, ale i v případě 76 okresů je těžké se pokaždé trefit.')}   
        </p>
      </div>
    </div>
  );
  
  return (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Režim série')}`}> 
      <section className={classes.section}>
        <div className={classes.banner}>
      		<BoltOutlinedIcon/>
          <h1 className={classes.title}>{t('Pokořte svou nejlepší sérii')}</h1>
          <h2 className={classes.subTitle}>{t('Kolik krajů nebo okresů dokážete poznat v jedné řadě?')}</h2>
      	</div>
        <div className={classes.selector}>
          <div className={classes.item} onClick={() => newGame("Regions")}>
            {
              userStatus && (
                <span>
                  <BoltOutlinedIcon/>
                  {highStreakRegions}
                </span>
              )
            }
            <Link onClick={(e) => e.stopPropagation()} to={{pathname: '/mode/streaks/regions'}}>
              <InfoIcon/>
            </Link>
            <SVG src="https://api.lokalokator.cz/images/czechrepublic_regions.svg"/>
            <h3>{t('Kraje')}</h3>
          </div>
          <div className={classes.item} onClick={() => newGame("Districts")}>
            {
              userStatus && (
                <span>
                  <BoltOutlinedIcon/>
                  {highStreakDistricts}
                </span>
              )
            }
            <Link onClick={(e) => e.stopPropagation()} to={{pathname: '/mode/streaks/districts'}}>
              <InfoIcon/>
            </Link>
            <SVG src="https://api.lokalokator.cz/images/czechrepublic_districts.svg"/>
            <h3>{t('Okresy')}</h3>
          </div>
        </div>
        <Snackbar
          open={isUnknownError}
          autoHideDuration={3000}
          onClose={() => setIsUnknownError(false)}
          type="error"
          content={t("Vyskytla se neznámá chyba")}
        />
        <Snackbar
          open={isWrongType}
          autoHideDuration={3000}
          onClose={() => setIsWrongType(false)}
          type="warning"
          content={t("Neplatný typ hry")}
        />
        <Snackbar
          open={isTooMuchOngoingGames}
          autoHideDuration={3000}
          onClose={() => setIsTooMuchOngoingGames(false)}
          type="warning"
          content={t("Máte moc rozehraných her. Dohrajte nějakou nebo ji smažte.")}
        />
        <Modal
          open={isOpenRegions}
          onClose={handleDetailClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modal}
        >
          <div>
            <SimpleDetailModal
              onCloseModal={handleDetailClose}
              title={t('Jak hrát Kraje')}
              content={regionsContent}
              closeButtonText={t('Rozumím')}
            />
          </div>
        </Modal>
        <Modal
          open={isOpenDistricts}
          onClose={handleDetailClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modal}
        >
          <div>
            <SimpleDetailModal
              onCloseModal={handleDetailClose}
              title={t('Jak hrát Okresy')}
              content={districtsContent}
              closeButtonText={t('Rozumím')}
            />
          </div>
        </Modal>
      </section>
    </DocumentTitle>
  );
};



export default ModeStreaks;