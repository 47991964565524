import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import {useTranslation} from "react-i18next";
import { useHistory } from 'react-router-dom';
import * as PlayService from "../../../services/content/play.service";
import * as ModeStreaksService from "../../../services/content/modeStreaks.service";
import * as UserService from "../../../services/user.service";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import Modal from '@mui/material/Modal';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Button from "@material-ui/core/Button";
import NormalButton from "../../../components/Buttons/NormalButton";
import NewCompass from "../../../components/Widgets/NewCompass";
import OldCompass from "../../../components/Widgets/OldCompass";
import gameTimeFormat from "../../../utils/gameTimeFormat";
import { Context, GameContext } from "../../../contexts";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import GraphicEqOutlinedIcon from '@mui/icons-material/GraphicEqOutlined';
import MusicNoteOutlinedIcon from '@mui/icons-material/MusicNoteOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import ExploreOffOutlinedIcon from '@mui/icons-material/ExploreOffOutlined';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import TextIncreaseOutlinedIcon from '@mui/icons-material/TextIncreaseOutlined';
import TextDecreaseOutlinedIcon from '@mui/icons-material/TextDecreaseOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import hotkeys from 'hotkeys-js';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import CachedImage from '../../../components/Images/CachedImage';

const useStyles = makeStyles((theme) => ({
  guessbox: {
  	position: 'absolute',
  	bottom: '2.5rem',
  	right: '2rem',
  	zIndex: '2',
  	transition: 'opacity 1s',
    opacity: '0.35',
    '&.hidden': {
      zIndex: '-1',
    },
    '& > div:not(#mapa)': {
      transition: 'all 1s',
      opacity: '0',
      borderRadius: '0.25rem 0.25rem 0 0',
      backgroundColor: '#FFFFFF',
      color: '#000000',
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      padding: '0.5rem 0.5rem',
      width: 'calc(100% - 1rem)',
      '& > button': {
        width: '1.75rem',
        height: '1.75rem',
        minWidth: 'unset',
        borderRadius: '100%',
        padding: '0.25rem',
        '& svg': {
          width: '1.25rem',
          height: '1.25rem',       
        },
        '&:last-of-type': {
          marginLeft: 'auto',
        },
      },
    },
    '& > div#mapa': {
    	position: 'relative',
    	cursor: 'crosshair',
    	transition: 'all 1s',
      width: '16vw',
      height: '9vw',
      borderRadius: '0.25rem 0.25rem 0 0',
      '& div.zoom': {
        opacity: '0',
        transition: 'opacity 1s',
      },
      '& .cluster': {
        '& > div': {
          color: '#FFFFFF !important',
          backgroundColor: 'transparent',
          backgroundImage: 'url("https://api.lokalokator.cz/images/marker/cluster-red.png")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          border: '0',
          fontSize: '0',
          transform: 'scale(0.5)',
          '& > img': {
            display: 'none',
          },        
        },
      },
      '& a.marker.type-paid': {
        zIndex: '1',
        cursor: 'help',
      },
      '& a.marker.type-poi': {
        zIndex: '1',
        cursor: 'help',
      },       
    },
    '& > button': {
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      color: '#FFFFFF !important',
      borderRadius: '0 0 0.25rem 0.25rem',
      transition: 'all 0.5s',
      '&.send': {
        backgroundColor: 'rgba(140,198,63,1)',
        '&:hover': {
          backgroundColor: 'rgba(126,178,56,1)',
        },
      },
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.8)',
      },
    },
    '&.active': {
      opacity: '1',
      '& > div#mapa': {
         borderRadius: '0',
        '& div.zoom': {
          opacity: '1',
        },      
      },
      '& > div:not(#mapa)': { 
        opacity: '1',
      },
      '&.zoom0 > div#mapa': {
        width: '20vw',
        height: '12vw',      
      },
      '&.zoom1 > div#mapa': {
        width: '30vw',
        height: '18vw',      
      },
      '&.zoom2 > div#mapa': {
        width: '40vw',
        height: '24vw',      
      },
      '&.zoom3 > div#mapa': {
        width: '50vw',
        height: '30vw',      
      },
    },
    '& > span': {
      position: 'absolute',
      left: '1rem',
      bottom: '3rem',
      backgroundColor: 'rgba(0,0,0,0.8)',
      fontSize: '0.8rem',
      color: '#FFFFFF',
      padding: '0.25rem 0.5rem',
      borderRadius: '0.25rem',
      zIndex: '1',
      display: 'flex',
      alignItems: 'center',
    }, 
  },
  openGuessBox: {
  	position: 'absolute',
  	bottom: '2.5rem',
  	right: '2rem',
    zIndex: '2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 'unset',
    width: '3rem',
    height: '3rem',
    color: 'rgba(255,255,255,0.75)',
    backgroundColor: 'rgba(140,198,63,0.5)',
    borderWidth: '0.188rem',
    borderStyle: 'solid',
    borderColor: 'rgba(255,255,255,0.75)',
    borderRadius: '100%',
    '&:hover': {
      backgroundColor: 'rgba(140,198,63,0.8)',
    },  
  },
  panorama: {
    width: '100%',
    height: '100%',
    flex: '1',
  },
  infobox: {
    position: 'absolute',
    top: '1rem',
    right: '0',
    padding: '1rem',
    backgroundColor: 'rgb(0,0,119)',
    backgroundImage: 'linear-gradient(0deg, rgba(0,0,119,1) 0%, rgba(194,191,255,1) 100%)',
    display: 'flex',
    '&::before': {
      content: `''`,
      position: 'absolute',
      top: '0',
      bottom: '0',
      right: '100%',
      width: '1rem',
      backgroundColor: 'rgb(0,0,119)',
      backgroundImage: 'linear-gradient(0deg, rgba(0,0,119,1) 0%, rgba(194,191,255,1) 100%)',
      clipPath: 'polygon(100% 0, 100% 0%, 100% 100%, 100% 100%, 0 50%)',
      zIndex: '1',    
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '0',
      bottom: '0',
      right: '100%',
      width: '0.063rem',
      backgroundColor: 'rgb(0,0,119)',
      backgroundImage: 'linear-gradient(0deg, rgba(0,0,119,1) 0%, rgba(194,191,255,1) 100%)',
      zIndex: '2',        
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      padding: '0 1rem',
      '& > span': {
        color: 'rgba(255,255,255,0.75)',
        fontSize: '0.8rem',
        fontWeight: '500',
        textTransform: 'uppercase',
      },
      '& > p': {
        color: '#FFFFFF',
        fontWeight: '700',
        fontSize: '1.25rem',
        width: '100%',
      },
    },
  },
  controls: {
    position: 'absolute',
  	left: '1.3rem',
    bottom: '2rem',
  	zIndex: '1',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  controlsRow: {
    display: 'flex',
    gap: '1rem',
  },
  controlsColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyControls: 'center',
    gap: '1rem',
    '& > button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 'unset',
      width: '3rem',
      height: '3rem',
      color: 'rgba(255,255,255,0.75)',
      backgroundColor: 'rgba(0,0,0,0.5)',
      borderWidth: '0.188rem',
      borderStyle: 'solid',
      borderColor: 'rgba(255,255,255,0.75)',
      borderRadius: '100%',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.7)',      
      },
      '&:disabled': {
        color: 'rgba(255,255,255,0.45)',
        backgroundColor: 'rgba(0,0,0,0.3)',
        borderColor: 'rgba(255,255,255,0.45)',  
      },
    },  
  },
  controlsZoom: {
    display: 'flex',
    flexDirection: 'column',
    '& > button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 'unset',
      width: '3rem',
      height: '3.469rem',
      color: 'rgba(255,255,255,0.75)',
      backgroundColor: 'rgba(0,0,0,0.5)',
      borderWidth: '0.188rem',
      borderStyle: 'solid',
      borderColor: 'rgba(255,255,255,0.75)',
      '&:first-of-type': {
        borderRadius: '100% 100% 0 0',
        borderBottomWidth: '0.031rem',  
      },
      '&:last-of-type': {
        borderRadius: '0 0 100% 100%',
        borderTopWidth: '0.031rem',       
      },
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.7)',      
      },
      '&:disabled': {
        color: 'rgba(255,255,255,0.45)',
        backgroundColor: 'rgba(0,0,0,0.3)',
        borderColor: 'rgba(255,255,255,0.45)',  
      },
    },   
  },
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  settings: {
    width: '600px',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > h1': {
      fontSize: '2rem',
      color: '#FFFFFF',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
  },
  settingsVolumes: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '1rem',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '0.25rem',
      padding: '1rem 0',
      '& > span': {
        color: '#FFFFFF',
        fontSize: '1rem',
        fontWeight: '500',
      },
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: '2rem',
        width: '100%',
        '& > svg': {
          color: '#FFFFFF',
        },
        '& > span': {
          color: '#8CC63F',
          '& > span.MuiSlider-markLabel': {
            color: '#FFFFFF',
          },
          '& > span.MuiSlider-thumb': {
            '&:hover': {
              boxShadow: '0px 0px 0px 8px rgb(140 198 6 / 16%)',
            },
            '&.Mui-focusVisible': {
              boxShadow: '0px 0px 0px 8px rgb(140 198 6 / 16%)',
            },
            '&.Mui-active': {
              boxShadow: '0px 0px 0px 14px rgb(140 198 6 / 16%)',
            },
          },
        },
      },    
    },
  },
  settingsToggles: {
    display: 'flex',
    gap: '3rem',
    width: '100%',
    marginTop: '1rem',
    marginBottom: '1rem',
    justifyContent: 'center',
    '& > div': {
      width: '10rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      '& > svg': {
        color: '#FFFFFF',
        width: '1.5rem',
        height: '1.5rem',
      },
      '& > span': {
        color: '#FFFFFF',
        fontSize: '1rem',
        fontWeight: '500',
        '& > span': {
          '&.Mui-checked': {
            color: '#8CC63F',
            '& + .MuiSwitch-track': {
              backgroundColor: '#A3D165',       
            },
          },
        },
      },
    },
  },
  settingsFooter: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    width: '100%',
    paddingTop: '1rem',
    borderTopWidth: '0.188rem',
    borderTopStyle: 'solid',
    borderTopColor: 'rgba(255,255,255,0.75)',
  },
  tooltip: {
    backgroundColor: 'rgb(0,0,0,0.8) !important',  
  },
  loading: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgb(0,0,119)',
    backgroundImage: 'linear-gradient(0deg, rgba(0,0,119,1) 0%, rgba(194,191,255,1) 100%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    zIndex: '3',
    gap: '4rem',
  },
  spinner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      color: '#FFFFFF',
      position: 'absolute',
    },
    '& > div': {
      '& > svg': {
        color: "#FFFFFF",
        width: '8rem',
        height: '8rem',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      },
    }
  },
  selectedImage: {
    display: 'inline-block',
    width: '1.5rem',
    height: '1rem',
    marginRight: '0.5rem',
  },
}));

const PlayStreaks = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const navigate = (to) => history.push(to);
  const { contextData, setContextData } = useContext(Context);
  const { gameContextData, setGameContextData } = useContext(GameContext);
  const gameData = gameContextData;
  const gid = gameContextData.gid;
  const userData = contextData.userData;
  const userSettings = userData.Settings;
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  
  const [mapControl, setMapControl] = useState(null);
  const [mapPinned, setMapPinned] = useState(false);
  const [mapZoom, setMapZoom] = useState(1);
  const [mapClosed, setMapClosed] = useState(false);
  
  const [bboxes, _setBBoxes] = useState([]);
  const bboxesRef = useRef(bboxes);
  const setBBoxes = data => {
    bboxesRef.current = data;
    _setBBoxes(data);
  };
  const [polygonData, _setPolygonData] = useState([]);
  const polygonDataRef = useRef(polygonData);
  const setPolygonData = data => {
    polygonDataRef.current = data;
    _setPolygonData(data);
  };
  
  const [panoramaScene, setPanoramaScene] = useState(null);
  const [panoramaSignals, setPanoramaSignals] = useState(null);
  
  const [compassRotate, setCompassRotate] = useState(null);
  const [moves, _setMoves] = useState([]);
  const movesRef = useRef(moves);
  const setMoves = data => {
    movesRef.current = data;
    _setMoves(data);
  };
  const [checkpoint, setCheckpoint] = useState(null);
  const [zoom, _setZoom] = useState(2);
  const zoomRef = useRef(zoom);
  const setZoom = data => {
    zoomRef.current = data;
    _setZoom(data);
  };
  const zoomLimit = [20,  2];
  const [effectVolume, setEffectVolume] = useState(userSettings.EffectVolume);
  const [musicVolume, setMusicVolume] = useState(userSettings.MusicVolume);
  const timeLimitTotal = (Math.round(gameData.TimeLimit - moment.duration(moment().diff(moment(gameData.DateRound))).asSeconds())) + 1;
      
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isGuessboxActive,setIsGuessboxActive] = useState(false);
  const handleFullscreen = useFullScreenHandle();
  const handleLastMoveButton = useRef(null);
  const handleCheckpointButton = useRef(null);
  const handleReturnStartButton = useRef(null);

  let SMap;
  let JAK;
  let turf;
  let mapa;
  let layer;
  let polygon;
  
  const volumeMarks = [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];
  
  useEffect(() => {
    prepareGame();
  }, []);

  const prepareGame = () => {
    if(parseInt(gameData.TimeLimit) !== 0 && timeLimitTotal <= 0) {
      handleSubmit();
    } else {
      window.Loader.load(null, {pano: true, poi:true}, initMap);
    } 
  };
  
  const getCompass = (e,tmpPanoramaScene) => {
    const yaw = tmpPanoramaScene.getCamera().yaw;
    const rotation = yaw * 57.3015671409;
    setCompassRotate(rotation);
  };
  
  const updatePanoChange = (e,tmpPanoramaScene) => {
    let place = tmpPanoramaScene.getPlace();
    let fov = tmpPanoramaScene.getCamera().fov;
    let currentZoom = Math.round(Math.PI/fov);
    if(zoomRef.current != currentZoom) {
      setZoom(currentZoom);
    }
    let placeCoords = place.getCoords();
    let coords = placeCoords.x + "," + placeCoords.y;
    if(movesRef.current[movesRef.current.length - 1] !== coords) {
      let tmpMoves = movesRef.current;
      tmpMoves.push(coords);
      setMoves(tmpMoves);
    }
  };
  
  const handleGetCompass = (tmpPanoramaSignals,tmpPanoramaScene) => {
    tmpPanoramaSignals.addListener(window, "pano-change", function(e) {
      getCompass(e,tmpPanoramaScene);
    });
    return () => tmpPanoramaSignals.removeListener(window, "pano-change");   
  };
  
  const handlePanoChange = (tmpPanoramaSignals,tmpPanoramaScene) => {
    let i = 0;
    tmpPanoramaSignals.addListener(window, "pano-change", function(e) {
      updatePanoChange(e,tmpPanoramaScene);
    });    
    return () => tmpPanoramaSignals.removeListener(window, "pano-change");   
  };
              
  const initMap = () => {
    SMap = window.SMap;
    JAK = window.JAK; 
    let center = SMap.Coords.fromWGS84(15.3386383, 49.7437989);
		mapa = new SMap(JAK.gel("mapa"), center, 7);
		mapa.addDefaultLayer(SMap.DEF_BASE).enable();
    mapa.setZoomRange(7, 19);
    mapa.addDefaultLayer(SMap.DEF_BASE).enable();
    let zajmy = new SMap.Layer.Marker(undefined, {
    	poiTooltip: true
    });
    mapa.addLayer(zajmy).enable();
    let dataProvider = mapa.createDefaultDataProvider();
    dataProvider.setOwner(mapa);
    dataProvider.addLayer(zajmy);
    dataProvider.setMapSet(SMap.MAPSET_BASE);
    dataProvider.enable();
    let move = new SMap.Control.Mouse(1);
    let scroll = new SMap.Control.Mouse(2);
    let zoom = new SMap.Control.Zoom('', {showZoomMenu: false});
    let sync = new SMap.Control.Sync({bottomSpace:0});
    mapa.addControl(move);
    mapa.addControl(scroll);
    mapa.addControl(zoom);
    layer = new SMap.Layer.Geometry();
		mapa.addLayer(layer);
		layer.enable();
    ModeStreaksService.getBBoxes(gameData.Type, contextData.authToken).then((result) => {
      setBBoxes(result.data);
    });
    let mapSignals = mapa.getSignals();
    mapSignals.addListener(window, "map-click", handleMapClick);
    let removeLink = function() {
    	if(document.querySelector("a.print")) document.querySelector("a.print").removeAttribute("href");
    }
    mapSignals.addListener(window, "map-redraw", removeLink);
    setMapControl(mapa);
    let data = gameData;
    let coords = data.Locations[data.Locations.length - 1].split(",");
    let position = SMap.Coords.fromWGS84(parseFloat(coords[0]),parseFloat(coords[1]));
    let options = {
      nav: parseInt(data.CanMove) === 1 ? true : false,
      static: parseInt(data.CanLook) === 0 ? true : false,
      fov: Math.PI/2,
      fovRange: parseInt(data.CanZoom) === 0 ? [Math.PI/2,Math.PI/2] : [Math.PI/20,Math.PI/2]
    };
    let tmpPanoramaScene = new SMap.Pano.Scene(document.querySelector(".panorama"), options);
    setPanoramaScene(tmpPanoramaScene);
    SMap.Pano.getBest(position, 50).then(function(place) {
      tmpPanoramaScene.show(place);
      tmpPanoramaScene.syncPort();
    }, function() {
      alert("Panorama se nepodařilo zobrazit !");
    });
    window.addEventListener('resize', function() {
      const interval = setInterval(function() {
        tmpPanoramaScene.syncPort();
      }, 1);
      setTimeout(function() {
        clearInterval(interval);
        tmpPanoramaScene.syncPort();
      }, 400);   
    });
    let tmpPanoramaSignals = tmpPanoramaScene.getSignals();
    setPanoramaSignals(tmpPanoramaSignals);
    handleGetCompass(tmpPanoramaSignals,tmpPanoramaScene);
    handlePanoChange(tmpPanoramaSignals,tmpPanoramaScene);
    setTimeout(function() {
      setIsLoaded(true);
    }, 1000);
  };
  
  useEffect(() => {
    if(panoramaScene != null) {
      let interval = setInterval(function() {
        panoramaScene.syncPort();
      }, 1);
      setTimeout(function() {
        clearInterval(interval);
        panoramaScene.syncPort();
      }, 400); 
    }
  }, [contextData.isToggledMenu]);
  
  const checkInBBox = (x,y) => {
    turf = window.turf;
  	let is = [];
  	let pt = turf.point([x, y]);
    Object.values(bboxesRef.current).map((data) => {
      let id = data["ID"];
  		let name = data["Name"];
      let image = data["Image"];
  		let bounds = data["Bounds"].slice(1, -1).split(",");
  		let x_min = bounds[0];
  		let y_min = bounds[1];
  		let x_max = bounds[2];
  		let y_max = bounds[3];
    	if(x >= x_min && x <= x_max && y >= y_min && y <= y_max) {
  			is.push([id, name, image]);
  		}    
    });
  	if(is.length > 0) {
  		let area;
      let isthereset = false;
      is.map((data) => {
        let id = data[0];
        let name = data[1];
        let image = data[2];
        ModeStreaksService.getPolygon(gameData.Type, id, contextData.authToken).then((result) => {
  				let temp_polygon_data = JSON.parse(result.data);
  				let poly = turf.polygon([temp_polygon_data]);
  				let isthere = turf.booleanPointInPolygon(pt, poly);
  				if(isthere) {
            if(!isthereset) {
              isthereset = true;
              setPolygonData([id, name, image]);
    					area = temp_polygon_data;
              selectArea(area);            
            }
  				}
  			});      
      });
  	} else {
  		if(polygon !== undefined) {
  			layer.removeGeometry(polygon);
        setPolygonData([]);
  			polygon = undefined;
  		}
  	}  
  };
  
  const selectArea = (areaData) => {
  	let points = [];
    areaData.map((value) => {
      points.push(SMap.Coords.fromWGS84(value[0], value[1]));
    });
  	let options = {
      color: "#FF0000"
  	};
  	if(polygon != undefined) layer.removeGeometry(polygon);
  	polygon = new SMap.Geometry(SMap.GEOMETRY_POLYGON, null, points, options);
  	layer.addGeometry(polygon);
  };
  
  const handleMapClick = (signal) => {
    const coords = SMap.Coords.fromEvent(signal.data.event, mapa);
    const x = coords.x;
    const y = coords.y;
    checkInBBox(x,y);
  };
  
  const handleLastMove = () => { 
    if(moves.length > 1) {
      SMap = window.SMap;
      const lastMove = moves[moves.length - 2];
      const coords = lastMove.split(",");
      const newPosition = SMap.Coords.fromWGS84(parseFloat(coords[0]),parseFloat(coords[1]));
      const camera = panoramaScene.getCamera();
      let tmpMoves = moves;
      tmpMoves.pop();
      setMoves(tmpMoves);
      SMap.Pano.getBest(newPosition, 50).then(function(place) {
        panoramaScene.show(place, camera);
        panoramaScene.syncPort();
      }, function() {
          alert("Panorama se nepodařilo zobrazit !");
      });
    }
  };
  
  const handleReturnStart = () => { 
    if(moves.length > 0) {
      SMap = window.SMap;
      const startPlace = moves[0];
      const coords = startPlace.split(",");
      const newPosition = SMap.Coords.fromWGS84(parseFloat(coords[0]),parseFloat(coords[1]));
      const camera = panoramaScene.getCamera();
      setMoves([]);
      SMap.Pano.getBest(newPosition, 50).then(function(place) {
        panoramaScene.show(place, camera);
        panoramaScene.syncPort();
      }, function() {
          alert("Panorama se nepodařilo zobrazit !");
      });
    }
  };
  
  const handleCheckpoint = () => {
    if(checkpoint === null) {
      const place = panoramaScene.getPlace();
      const coords_x = place._data.mark.lon;
      const coords_y = place._data.mark.lat;
      const coords = coords_x + "," + coords_y;
      setCheckpoint(coords); 
    } else {
      SMap = window.SMap;
      const coords = checkpoint.split(",");
      const newPosition = SMap.Coords.fromWGS84(parseFloat(coords[0]),parseFloat(coords[1]));
      const camera = panoramaScene.getCamera();
      setCheckpoint(null); 
      SMap.Pano.getBest(newPosition, 50).then(function(place) {
        panoramaScene.show(place, camera);
        panoramaScene.syncPort();
      }, function() {
          alert("Panorama se nepodařilo zobrazit !");
      });
    }
  };
  
  const handleZoomIn = () => {
    const camera = panoramaScene.getCamera();
    let newCamera = camera;
    if(zoom <= zoomLimit[0]) {
      newCamera.fov = Math.PI/(zoom + 3);
      setZoom(zoom + 3);
      panoramaScene.setCamera(newCamera);
    } else {
      newCamera.fov = Math.PI/zoomLimit[0];
      setZoom(zoomLimit[0]);
      panoramaScene.setCamera(newCamera);    
    }
  };
  
  const handleZoomOut = () => {
    const camera = panoramaScene.getCamera();
    let newCamera = camera;
    if(zoom >= zoomLimit[1]) {
      newCamera.fov = Math.PI/(zoom - 3);
      setZoom(zoom - 3);
      panoramaScene.setCamera(newCamera);
    } else {
      newCamera.fov = Math.PI/zoomLimit[1];
      setZoom(zoomLimit[1]);
      panoramaScene.setCamera(newCamera);    
    }
  };
  
  const handleUpdateSound = () => {
    if(parseInt(userSettings.Sound) === 1) {
      let tempUserData = userData;
      tempUserData.Settings.Sound = 0;
      setContextData({...contextData, userData: tempUserData});
      const payload = {
        "sound": 0
      };
      handleUpdateUserSettings(payload);
    } else {
      let tempUserData = userData;
      tempUserData.Settings.Sound = 1;
      setContextData({...contextData, userData: tempUserData});
      const payload = {
        "sound": 1
      };
      handleUpdateUserSettings(payload);    
    }                        
  };
  
  const handleUpdateCompassType = () => {
    if(parseInt(userSettings.CompassType) === 1) {
      let tempUserData = userData;
      tempUserData.Settings.CompassType = 2;
      setContextData({...contextData, userData: tempUserData});
      const payload = {
        "compasstype": 2
      };
      handleUpdateUserSettings(payload);
    } else {
      let tempUserData = userData;
      tempUserData.Settings.CompassType = 1;
      setContextData({...contextData, userData: tempUserData});
      const payload = {
        "compasstype": 1
      };
      handleUpdateUserSettings(payload);    
    }                               
  };
  
  const handleUpdateFullscreen = () => {
    if(isFullscreen) {
      setIsFullscreen(false);
      handleFullscreen.exit();
    } else {
      setIsFullscreen(true);
      handleFullscreen.enter();   
    }                              
  };
  
  const handleChangeEffectVolume = (e,value) => {
    setEffectVolume(value / 100); 
  };
  
  const handleUpdateEffectVolume = (e,value) => {
    let tempUserData = userData;
    tempUserData.Settings.EffectVolume = value / 100;
    setContextData({...contextData, userData: tempUserData});
    setEffectVolume(value / 100);
    const payload = {
      "effectvolume": value / 100
    };
    handleUpdateUserSettings(payload); 
  };
  
  const handleChangeMusicVolume = (e,value) => {
    setMusicVolume(value / 100); 
  };
  
  const handleUpdateMusicVolume = (e, value) => {
    let tempUserData = userData;
    tempUserData.Settings.MusicVolume = value / 100;
    setContextData({...contextData, userData: tempUserData});
    setMusicVolume(value / 100);
    const payload = {
      "musicvolume": value / 100
    };
    handleUpdateUserSettings(payload);   
  };
  
  const handleUpdateUserSettings = (payload) => {
    UserService.updateSettings(payload,contextData.authToken);  
  };
  
  const handleGuessClick = () => {
    handleSubmit();
  };
  
  const handleSubmit = () => {
    if(!isSubmited) {
      setIsSubmited(true);
      let submitData = null;
      if(polygonData.length >= 2) {
        submitData = polygonData[0];
        const payload = {
          "gid": gid,
          "submit": submitData
        };
        PlayService.submitGameData("streaks",payload,contextData.authToken).then((result) => {
          setGameContextData({...gameContextData, ...result.data});
          if(result.data.Phase[0] === "Result") {
            let usrData = contextData.userData;
            usrData.XP = parseInt(usrData.XP) + 20;
            setContextData({...contextData, userData: usrData});
          }
        }); 
      } else {
        submitData = "-1";
        const payload = {
          "gid": gid,
          "submit": submitData
        };
        PlayService.submitGameData("streaks",payload,contextData.authToken).then((result) => {
          setGameContextData({...gameContextData, ...result.data});
        });    
      }
    }
  };
  
  const handleQuitGame = () => {
    const payload = {
      "gid": gid
    };
    PlayService.quitGame("streaks",payload,contextData.authToken).then((result) => {
      navigate('/results/' + gid);
    });
    
  };
  
  useEffect(() => {
    hotkeys('Z,C,R,F11', function (event, handler) {
      switch (handler.key) {
        case 'Z': {
            handleLastMoveButton.current.click();
          }
          break;
        case 'C': {
            handleCheckpointButton.current.click();
          }
          break;
        case 'R': {
            handleReturnStartButton.current.click();
          }
          break;
        case 'F11': {
            event.stopPropagation();
            event.preventDefault();
            handleUpdateFullscreen();
          }
          break;
        default: {};
      }
    });
    return () => hotkeys.unbind('Z,C,R,F11');
  }, []);
  
  useEffect(() => {
    setTimeout(function() {
      if(mapControl != null) {
        mapControl.syncPort();
      }
    }, 1000);
  }, [isGuessboxActive, mapZoom]);
  
  useEffect(() => {
    if(parseInt(gameData.TimeLimit) !== 0 && timeLimitTotal <= 0) {
      handleSubmit();   
    }
  }, [timeLimitTotal]);

  return (
    <FullScreen handle={handleFullscreen}>
      {
        !isLoaded && (
          <div className={classes.loading}>
            <div className={classes.spinner}>
              <SVG src="https://api.lokalokator.cz/images/czechrepublic.svg" viewBox="0 0 800 600" width="100" height="80"/>
              <CircularProgress thickness={1.5} />
            </div>
            <p>{t('Načítání lokace')}...</p>
          </div>
        )
      }
      <div className={`${classes.guessbox} ${(isGuessboxActive || mapPinned) && 'active'} ${mapClosed && 'hidden'} ${'zoom' + mapZoom}`} onMouseEnter={() => setIsGuessboxActive(true)} onMouseMove={() => !isGuessboxActive && setIsGuessboxActive(true)} onMouseLeave={() => setIsGuessboxActive(false)}>
        <div>
          <Button onClick={() => setMapZoom(mapZoom + 1)} disabled={mapZoom === 3 ? true : false}>
            <TextIncreaseOutlinedIcon/>
          </Button>
          <Button onClick={() => setMapZoom(mapZoom - 1)} disabled={mapZoom === 0 ? true : false}>
            <TextDecreaseOutlinedIcon/>
          </Button>
          <Button onClick={() => setMapPinned(!mapPinned)}>
            {
              mapPinned ? (<PushPinIcon/>) : (<PushPinOutlinedIcon/>)
            }
           </Button>
          <Button onClick={() => setMapClosed(true)}>
            <CloseOutlinedIcon/>
          </Button>  
        </div>
        <div id="mapa"/>
        {
          polygonData.length >= 2 && (
            <span>{polygonData.length === 3 && (<CachedImage className={classes.selectedImage} fullsizeLink={polygonData[2]}/>)}{t(polygonData[1])}</span>
          )
        }
        <Button className={`${polygonData.length >= 2 && 'send'}`} disabled={polygonData.length >= 2 ? false : true} onClick={() => polygonData.length >= 2 && handleGuessClick()}>
          {
            polygonData.length >= 2 ? t('Hádat') : gameData.Type === "Regions" ? t('Vyberte kraj na mapě') : t('Vyberte okres na mapě')
          }
        </Button>
      </div>
      {
        mapClosed && (
          <Button className={classes.openGuessBox} onClick={() => setMapClosed(false)}>
            <TravelExploreOutlinedIcon/>
          </Button>
        )
      }
      <div className={`${classes.panorama} ${"panorama"}`}/>
      {
        parseInt(userSettings.CompassType) === 2 && compassRotate!= null && (
          <NewCompass rotate={compassRotate}/>  
        )
      }
      <div className={classes.infobox}>
        <div>
          <span>{t('Série')}</span>
          <p>{gameData.Guessed.length}</p>
        </div>
        {
          parseInt(gameData.TimeLimit) !== 0 && (
            <div>
              <span>{t('Zbývající čas')}</span>
              <p>{gameTimeFormat(timeLimitTotal,t)}</p>
            </div>
          )
        }
      </div>
      <div className={classes.controls}>
        {
          parseInt(userSettings.CompassType) === 1 && compassRotate!= null && (
            <OldCompass rotate={compassRotate}/>
          )
        }
        <div className={classes.controlsRow}>
          <div className={classes.controlsColumn}>
            <div className={classes.controlsZoom}>
            {
              (zoom === zoomLimit[0] || parseInt(gameData.CanZoom) === 0) ? (
                <Button disabled={true}>
                  <AddOutlinedIcon/>
                </Button>
              ) : (
                <Tooltip title={t('Přiblížit')} placement="top" classes={{ tooltip: classes.tooltip }} arrow>
                    <Button onClick={handleZoomIn}>
                      <AddOutlinedIcon/>
                    </Button>
                </Tooltip>              
              )
            }
            {
              (zoom === zoomLimit[1] || parseInt(gameData.CanZoom) === 0) ? (
                <Button disabled={true}>
                  <RemoveOutlinedIcon/>
                </Button>
              ) : (
                <Tooltip title={t('Oddálit')} placement="bottom" classes={{ tooltip: classes.tooltip }} arrow>
                    <Button onClick={handleZoomOut}>
                      <RemoveOutlinedIcon/>
                    </Button>
                </Tooltip>             
              )
            }
            </div>
            <Tooltip title={t('Nastavení')} placement="top" classes={{ tooltip: classes.tooltip }} arrow>
              <Button onClick={() => setIsSettingsOpen(true)}>
                <SettingsOutlinedIcon/>
              </Button>
            </Tooltip>
          </div>
          <div className={classes.controlsColumn}>
            {
              movesRef.current.length <= 1 ? (
                <Button ref={handleLastMoveButton} disabled={true}>
                  <KeyboardReturnOutlinedIcon/>
                </Button>
              ) : (
                <Tooltip title={t('Krok zpět (Z)')} placement="right" classes={{ tooltip: classes.tooltip }} arrow>
                  <Button onClick={handleLastMove} ref={handleLastMoveButton}>
                    <KeyboardReturnOutlinedIcon/>
                  </Button>
                </Tooltip>
              )
            }
            <Tooltip title={checkpoint === null ? t('Vytvořit záchytný bod (C)') : t('Zpět na záchytný bod (C)')} placement="right" classes={{ tooltip: classes.tooltip }} arrow>
              <Button onClick={handleCheckpoint} ref={handleCheckpointButton}>
                {checkpoint === null ? (<AddLocationOutlinedIcon/>) : (<LocationOnOutlinedIcon/>)}
              </Button>
            </Tooltip>
            {
              movesRef.current.length <= 1 ? (
                <Button disabled={true} ref={handleReturnStartButton}>
                  <FlagOutlinedIcon/>
                </Button>
              ) : (
                <Tooltip title={t('Zpět na start (R)')} placement="right" classes={{ tooltip: classes.tooltip }} arrow>
                  <Button onClick={handleReturnStart} ref={handleReturnStartButton}>
                    <FlagOutlinedIcon/>
                  </Button>
                </Tooltip>
              )
            }
          </div>
        </div>
      </div>
      <Modal
        open={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ style: { backgroundColor: 'rgba(0,0,0,0.8)' } }}
        className={classes.modal}
        container={isFullscreen && document.querySelector(".fullscreen")}
        disableEscapeKeyDown={true}
      >
        <div className={classes.settings}>
          <h1>{t('Nastavení')}</h1>
          <div className={classes.settingsVolumes}>
            <div>
              <span>{t('Hlasitost efektů')}</span>
              <div>
                <GraphicEqOutlinedIcon/>
                <Slider
                  value={parseFloat(effectVolume) * 100}
                  min={0}
                  max={100}
                  step={5}
                  marks={volumeMarks}
                  onChange={handleChangeEffectVolume}
                  onChangeCommitted={handleUpdateEffectVolume}
                />
              </div>
            </div>
            <div>
              <span>{t('Hlasitost hudby')}</span>
              <div>
                <MusicNoteOutlinedIcon/>
                <Slider
                  value={parseFloat(musicVolume) * 100}
                  min={0}
                  max={100}
                  step={5}
                  marks={volumeMarks}
                  onChange={handleChangeMusicVolume}
                  onChangeCommitted={handleUpdateMusicVolume}
                />
              </div>
            </div>
          </div>
          <div className={classes.settingsToggles}>
            <div>
              {
                parseInt(userSettings.Sound) === 1 ? (<VolumeUpOutlinedIcon/>) : (<VolumeOffOutlinedIcon/>)
              }
              <span>
              {
                parseInt(userSettings.Sound) === 1 ? t('Zvuky povoleny') : t('Zvuky zakázany')
              }
              </span>
              <Switch checked={parseInt(userSettings.Sound) === 1 ? true : false} onChange={handleUpdateSound} />
            </div>
            <div>
              {
                parseInt(userSettings.CompassType) === 1 ? (<ExploreOutlinedIcon/>) : (<ExploreOffOutlinedIcon/>)
              }
              <span>
              {
                parseInt(userSettings.CompassType) === 1 ? t('Starý kompas') : t('Nový kompas')
              }
              </span>
              <Switch checked={parseInt(userSettings.CompassType) === 1 ? true : false} onChange={handleUpdateCompassType}/>
            </div>
            <div>
              {
                isFullscreen ? (<FullscreenOutlinedIcon/>) : (<FullscreenExitOutlinedIcon/>)
              }
              <span>
              {
                isFullscreen ? t('Celá obrazovky') : t('V okně')
              }           
              &nbsp;(F11)
              </span>
              <Switch checked={isFullscreen} onChange={handleUpdateFullscreen} />
            </div>
          </div>
          <div className={classes.settingsFooter}>
            <NormalButton buttonType="play" startIcon={<PlayArrowOutlinedIcon/>} onClick={() => setIsSettingsOpen(false)}>
              {t('Pokračovat ve hře')}
            </NormalButton>
            <NormalButton buttonType="default" startIcon={<ExitToAppOutlinedIcon/>} onClick={handleQuitGame}>
              {t('Ukončit hru')}
            </NormalButton>
          </div>
        </div>
      </Modal>
    </FullScreen>
  );
};
export default PlayStreaks;