import React, {useEffect, useState, useContext} from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import config from '../../../constants/config';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StorageService from "../../../services/storage.service";
import * as AuthService from "../../../services/auth.service";
import * as Toast from '../../../components/Notifications/Toast';
import useThemes from '../../../constants/themes';
import { Context, ContextDefaultData } from "../../../contexts";
import {Link, useHistory, useLocation} from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
		position: 'relative',
		top: 'auto',
		left: 'auto',
		bottom: 'auto',
  	zIndex: '1003',
		width: '16.25rem',
		marginTop: '0',
		height: '100%',
  	overflowY: 'auto',
  	overflowX: 'hidden',
  	transition: 'width 0.4s, background-color 0.4s',
  	whiteSpace: 'nowrap',
  	display: 'flex',
	  flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
    	position: 'fixed',
    	top: '0',
    	left: '0',
    	bottom: '0',
    	width: '0',
   	  height: 'calc(100% - 3.125rem)',
  	  marginTop: '3.125rem',                          
    },
    '&.isToggled': {
		  width: '0',
		  overflowY: 'visible',
      [theme.breakpoints.down('sm')]: {
      	width: '100%',                         
      },    
    },
  },
  menu: {
	  maxHeight: 'initial',
 		margin: '1rem 0',
    '&:first-of-type': {
		  margin: '4.375rem 0 1rem 0', 
      [theme.breakpoints.down('sm')]: {
        margin: '0.625rem 0 1rem 0',                         
      },    
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0.625rem 0 1rem 0',                         
    },    
  },
  item: {
    position: 'relative',
	  padding: '0.5rem 1rem 0.5rem 2rem',
    transition: 'background-color 0.4s, color 0.4s',
    '& > a': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: 'clamp(1rem,1vw,1.15rem)',
      color: 'inherit',
      cursor: 'pointer',
      '& > svg': {
        fontSize: '1.25rem',
        marginRight: '0.5rem',      
      },    
    },
    '&.dropdown': {
      display: 'flex',
    	flexDirection: 'column',
    	padding: '0',
      '& > a': {
      	display: 'flex',
      	flexDirection: 'row',
      	alignItems: 'center',
      	textTransform: 'uppercase',
      	fontWeight: '600',
      	cursor: 'pointer',
      	padding: '0.5rem 1rem 0.5rem 2rem',
      	transition: 'background-color 0.4s, color 0.4s',
        '& > svg': {
        	marginLeft: 'auto',
        	transition: 'transform 0.25s',        
        },      
      },
      '& > ul': {
        width: '100%',
        maxHeight: '0',
        transition: 'max-height 0.25s ease-out',
        overflow: 'hidden',
        '& > li': {
	        padding: '0.5rem 1rem 0.5rem 2rem',
          '& > a': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: 'inherit',
            cursor: 'pointer',          
          },        
        },      
      },
      '&.active': {
        backgroundColor: 'transparent !important',
        '& > a': {
          '& > svg': {
          	'-webkit-transform': 'rotate(90deg)',
          	'-moz-transform': 'rotate(90deg)',
          	'-ms-transform': 'rotate(90deg)',
          	'-o-transform': 'rotate(90deg)',
          	transform: 'rotate(90deg)',          
          },
        },
        '& > ul': {
          maxHeight: '100vh',
	        transition: 'max-height 0.25s ease-in',        
        },
        '&.overflow': {
          '& > ul': {
            overflow: 'auto',
          },
        },    
      },     
    }, 
  },
  socials: {
  	display: 'flex',
  	flexDirection: 'row',
  	justifyContent: 'center',
  	alignItems: 'center',
  	width: '100%',
    minWidth: '16.25rem',
  	marginTop: 'auto',
  	paddingBottom: '1rem',
    '& > a': {
      display: 'flex',
      flex: '0 0 auto',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '2rem',
      width: '2rem',
      borderRadius: '0.25rem',
      fontSize: '1.25rem',
      '& > span': {
      	visibility: 'hidden',
      	whiteSpace: 'nowrap',
      	fontSize: '0',      
      },    
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100vw',                
    },     
  },
  copyright: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minWidth: '16.25rem',
    paddingBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100vw',                
    },  
  }
}));

const MainMenu = ({ menuItems }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { contextData, setContextData } = useContext(Context);
  const userStatus = contextData.userStatus;
  const userData = contextData.userData;
  const userTheme = contextData.userData?.Settings?.Theme || 'default'; 
  const history = useHistory();
  const location = useLocation();
  const navigate = (to) => history.push(to);
  const theme = useTheme();
  const themes = useThemes.themes[userTheme] ? useThemes.themes[userTheme]() : useThemes.themes["default"]();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isToggled = contextData.isToggledMenu;

  const handleDropdown = (e) => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  
  const handleLogout = () => {
    const authToken = contextData.authToken;
    AuthService.logout(authToken).then((result) => {
        if(result) {
          if(result.data) {
            StorageService.removeItem(config.TOKEN_KEY);
            StorageService.removeItem(config.USER_KEY);
            setContextData({...ContextDefaultData, configuration: contextData.configuration});  
            setIsDropdownOpen(false);
            Toast.createNotification("info",t("Byl jste odhlášen"));
          }
        }    
    }).catch((error) => {
        StorageService.removeItem(config.TOKEN_KEY);
        StorageService.removeItem(config.USER_KEY);
        setContextData({...ContextDefaultData, configuration: contextData.configuration});  
        setIsDropdownOpen(false);
        Toast.createNotification("info",t("Byl jste odhlášen"));
      });
  };
  
  const handleNavigate = (to) => {
    if(isToggled && isMobile) {
      setContextData({...contextData, isToggledMenu: !isToggled});   
    }
    navigate(to);
  };
    
  return (
		<nav className={`${classes.root} ${themes.mainMenu} ${isToggled && 'isToggled'}`}>
			<ul className={classes.menu}>
        {
          menuItems["mainMenu"]?.map((menuItem, key) => (
    				<li className={`${classes.item} ${themes.mainMenuItem}`} key={`k_${key}`}>
              <a onClick={() => handleNavigate(menuItem.to)}>{menuItem.icon} {t(menuItem.title)}</a>
            </li>
          ))
        }
			</ul>
      {
        userStatus ? (
    			<ul className={classes.menu}>
            {
              menuItems["userMenu"]?.map((menuItem, key) => (
        				<li className={`${classes.item} ${themes.mainMenuItem} ${'dropdown'} ${isDropdownOpen ? 'active' : ''}`} key={`k_${key}`}>
                  <a onClick={handleDropdown}>
                    {t(menuItem.title)}
                    <ChevronRightIcon/>
                  </a>
                  <ul>
                    {
                      menuItem.submenus.map((subMenuItem, subKey) => (
                        <li key={`k_${subKey}`}>
                          <a onClick={() => handleNavigate(subMenuItem.to)}>{t(subMenuItem.title)}</a>
                        </li>
                      ))
                    }
                    <li>
                      <a onClick={handleLogout}>{t('Odhlásit se')}</a>
                    </li>
                  </ul>
                </li>
              ))
            }
    			</ul>        
        ) : (
          <ul className={classes.menu}/>
        )
      }
			<ul className={classes.menu}>
        {
          menuItems["secondMainMenu"]?.map((menuItem, key) => (
    				<li className={`${classes.item} ${themes.mainMenuItem}`} key={`k_${key}`}>
              <a onClick={() => handleNavigate(menuItem.to)}>{t(menuItem.title)}</a>
            </li>
          ))
        }
			</ul>
			<div className={`${classes.socials} ${themes.mainMenuSocials}`}>
        <Link to={{  pathname: "https://www.facebook.com/lokalokator" }} target="_blank" tabIndex="-1" title="Facebook" rel="nofollow">
  				<FacebookOutlinedIcon/>
  				<span>Facebook</span>
  			</Link>
        <Link to={{  pathname: "https://twitter.com/lokalokator" }} target="_blank" tabIndex="-1" title="Twitter" rel="nofollow">
  				<TwitterIcon/>
  				<span>Twitter</span>
  			</Link>
        <Link to={{  pathname: "https://instagram.com/lokalokator.cz" }} target="_blank" tabIndex="-1" title="Instagram" rel="nofollow">
  				<InstagramIcon/>
  				<span>Instagram</span>
  			</Link>
        <Link to={{  pathname: "mailto:info@lokalokator.cz" }} target="_blank" tabIndex="-1" title="Email" rel="nofollow">
  				<MailOutlinedIcon/>
  				<span>Email</span>
  			</Link>
			</div>
			<div className={`${classes.copyright} ${themes.mainMenuCopyright}`}>
				&copy; {new Date().getFullYear()} Lokalokator.cz
			</div>
		</nav>
  );
};            
export default MainMenu;
