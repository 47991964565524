import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBar = ({
  open,
  autoHideDuration,
  horizontal = 'center',
  vertical = 'bottom',
  className,
  onClose,
  content,
  type = 'default',
  action 
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
    onClose && onClose();
  };
  const handleAction = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small"/>
      </IconButton>
    </>
  );
  
  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  
  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{
        horizontal,
        vertical
      }}
      autoHideDuration={6000}
      onClose={handleClose}
      action={action ? action : handleAction}
    >
      {
        type === 'default' ? content : (
          <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
            {content}
          </Alert>
        )
      }
    </Snackbar>
  )
};

export default SnackBar;
