import React, { useEffect, useState, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../constants/config';
import DocumentTitle from "react-document-title";
import Button from "@material-ui/core/Button";
import * as PlayService from "../../../services/content/play.service";
import ErrorCompass from '../../../components/Widgets/ErrorCompass';

import LobbyExplorer from '../../../components/Lobbies/LobbyExplorer';
import PlayExplorer from '../../../components/Play/PlayExplorer';
import ResultsExplorer from '../../../components/Results/ResultsExplorer';
import FinishedExplorer from '../../../components/Finished/FinishedExplorer';

import LobbyStreaks from '../../../components/Lobbies/LobbyStreaks';
import PlayStreaks from '../../../components/Play/PlayStreaks';
import ResultsStreaks from '../../../components/Results/ResultsStreaks';
import FinishedStreaks from '../../../components/Finished/FinishedStreaks';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Context, GameContext } from "../../../contexts";

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    maxHeight: 'calc(100vh - 4.375rem)',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'calc(100vh - 3.125rem)',
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      flex: '1',
      overflow: 'auto',
      '& > div.fullscreen': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        flex: '1',
        overflow: 'hidden',
      },
    },
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    height: 'calc(100vh - 8.375rem)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 7.125rem)',
    },
    '& svg': {
      color: "#8CC63F",
    }
  },
  gameNotFound: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center !important',
    flex: '1',
    width: '100%',
    height: '100%',
    '& > h1': {
      fontSize: '2rem',
      fontWeight: '600',
    },
    '& > div': {
      [theme.breakpoints.down('sm')]: {
        zoom: '0.5',
      },
    },
  },
}));

const Play = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const { contextData, setContextData } = useContext(Context);
  const { gameContextData, setGameContextData } = useContext(GameContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [gameData, setGameData] = useState(null);
  const [lastPhase, setLastPhase] = useState([""]);
  
  const gid = gameContextData.gid;
  const userData = contextData.userData;
  const userSettings = userData.Settings;
  
  useEffect(() => {
    if(gid === null) {
      setIsLoaded(true);
    } else {
      getGameData();
    }
  }, []);
  
  const getGameData = () => {
    PlayService.getGameData(gid,contextData.authToken).then((result) => {
      setGameData(result.data);
      setGameContextData({...gameContextData, ...result.data});
      setIsLoaded(true);
    });  
  };
  
  const updateGameData = () => {
    PlayService.getGameData(gid,contextData.authToken).then((result) => {
      setGameData(result.data);
      setGameContextData({...gameContextData, ...result.data});
    });  
  };
  
  useEffect(() => {
    if(gameContextData.Phase) {
      if(lastPhase.join() !== gameContextData.Phase.join()) {
        setLastPhase(gameContextData.Phase);
        updateGameData();
      }
    }  
  }, [gameContextData.Phase]);

  return isLoaded ? (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Hra')}`}> 
      <section className={classes.section}>
      {
        gid === null && (
          <div className={classes.gameNotFound}>
            <ErrorCompass/>
            <h1>{t('Hru se nepodařilo najít')}</h1>
          </div>
        )
      }
      {
        gid != null && gameData.GameMode === "explorer" && (
          <div>
          {
            gameData.Phase[0] === "Lobby" && (
              <LobbyExplorer/>
            )
          }
          {
            gameData.Phase[0] === "Round" && (
              <PlayExplorer/>
            )
          }
          {
            gameData.Phase[0] === "Result" && (
              <ResultsExplorer/>
            )
          }
          {
            gameData.Phase[0] === "Finished" && (
              <FinishedExplorer/>
            )
          }
          </div>
        )
      }
      {
        gid != null && gameData.GameMode === "streaks" && (
          <div>
          {
            gameData.Phase[0] === "Lobby" && (
              <LobbyStreaks/>
            )
          }
          {
            gameData.Phase[0] === "Round" && (
              <PlayStreaks/>
            )
          }
          {
            gameData.Phase[0] === "Result" && (
              <ResultsStreaks/>
            )
          }
          {
            gameData.Phase[0] === "Finished" && (
              <FinishedStreaks/>
            )
          }
          </div>
        )
      }
      </section>
    </DocumentTitle>
  ) : (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Načítání hry')}`}>
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    </DocumentTitle>
  );
};



export default Play;
