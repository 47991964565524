export const languages = [
  {
    lan: "English",
    acronym: "EN"
  },
  {
    lan: "Czech",
    acronym: "CS"
  },
  /*
  {
    lan: "French",
    acronym: "FR"
  },
  {
    lan: "Slovak",
    acronym: "SK"
  },
  {
    lan: "German",
    acronym: "DE"
  },
  {
    lan: "Spanish",
    acronym: "ES"
  },
  {
    lan: "Russian",
    acronym: "RU"
  },
  */
];
export const langs = ["EN","CS"];
