import HttpService from './http.service';

export function updateUser(accessToken) {
  return HttpService.get('user/data', {}, {}, accessToken);
}

export function updateSettings(data, accessToken) {
  return HttpService.post('user/settings', data, {}, {}, accessToken);
}

export function removeSeznamToken(token, accessToken) {
  let payload = {
    token: token,
  }
  return HttpService.post('seznam/remove', payload, {}, {}, accessToken);
}

