import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../../constants/config';
import DocumentTitle from "react-document-title";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import NormalButton from "../../../../components/Buttons/NormalButton";
import Snackbar from "../../../../components/Notifications/Snackbar";
import Modal from '@mui/material/Modal';
import SimpleDetailModal from "../../../../components/Modals/SimpleDetailModal";
import * as ModeCreatorService from "../../../../services/content/modeCreator.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Context, CreatorContext } from "../../../../contexts";
import MapsCreator from "../../../../components/Items/Maps/Creator";

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  banner: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '30rem',
    flex: '0 0 30rem',
    backgroundColor: 'rgb(157,55,55)',
    backgroundImage: 'linear-gradient(0deg, rgba(157,55,55,1) 0%, rgba(5,149,245,1) 100%)',
    [theme.breakpoints.down('sm')]: {
      height: '10rem',
      flex: '0 0 10rem',
    },
    '& > svg': {
      color: '#E5B513',
      fontSize: '6rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '3rem',
      },
    }, 
  },
  title: {
    color: '#FFFFFF',
    fontSize: '4rem',
    marginTop: '1rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  subTitle: {
    color: '#FFFFFF',
    fontSize: '2rem',
    marginTop: '0.25rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    flex: '1',
    '& svg': {
      color: "#8CC63F",
    }
  },
  maps: {
  	width: '100%',
  	display: 'flex',
  	flexDirection: 'row',
    '& > p': {
      display: 'block',
      width: '100%',
      textAling: 'center',
      paddingTop: '1rem',
    },
    '& > div': {
    	width: '100%',
    	display: 'flex',
    	flexDirection: 'column',    
    },  
  },
  notlogged: {
    width: "auto",
    maxWidth: '100%',
    overflow: "auto",
    padding: "1.25rem",
    margin: "1.25rem",
    flex: "1",
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
      fontSize: '1.25rem',
    },
  },
  body: {
    padding: "1.25rem 0",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1.25rem",
    gap: '1rem',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      padding: '1rem 2rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      color: '#8CC63F',
      borderColor: '#8CC63F',
      transition: 'all 0.5s',
      '&:hover': {
        color: '#709E32',
        borderColor: '#709E32',
      },
      '& > span': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '8rem',
        height: '8rem',
        borderRadius: '100%',
        borderWidth: '0.25rem',
        borderStyle: 'solid',
        borderColor: 'inherit',
        backgroundColor: '#EEEEEE',
        '& > svg': {
          fontSize: '3rem',
          color: 'inherit',
        },
      },
      '& > h3': {
        color: 'inherit',
        fontWeight: '600',
        fontSize: '1.25rem',
        marginTop: '1rem',
      },
      '& > p': {
        marginTop: '1rem',
        fontSize: '0.8rem',
        color: '#000000',
        lineHeight: '1rem',
      },
    },
  },
}));

const ModeCreator = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const navigate = (to) => history.push(to);
  const { contextData, setContextData } = useContext(Context);
  const { creatorContextData, setCreatorContextData } = useContext(CreatorContext);
  const authToken = contextData.authToken;
  const userStatus = contextData.userStatus;
  const [maps, setMaps] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpenCreator, setIsOpenCreator] = useState(false);
  
  const create = (type) => {
    if(userStatus) {
      handleCloseCreator();
      ModeCreatorService.create(type, authToken).then((result) => {
        if(result) {
          if(result.data) {
            setCreatorContextData({...creatorContextData, mid: result.data.MID});
            navigate('/mode/creator/edit');
          }
        }
      }).catch((error) => {
        
      });
    } else {
      setContextData({...contextData, showNotLoggedModal: true});
    }
  };
  
  useEffect(() => {
    if(userStatus) {
      ModeCreatorService.getMaps(authToken).then((result) => {
        setMaps(result.data);
        setIsLoaded(true);
      });
    }  
  }, []);
  
  const handleCreate = () => {
    setIsOpenCreator(true);
  };
  
  const handleCloseCreator = () => {
    setIsOpenCreator(false);
  };
  
  const handleLogin = () => {
    navigate('/login');
  };
  
  const handleRegister = () => {
    navigate('/register');
  }; 
  
   const creatorContent = (
    <div className={classes.row}>
      <div onClick={() => create('Locations')}>
        <span>
          <LocationOnOutlinedIcon/>  
        </span>
        <h3>{t('Ručně vybrané lokace')}</h3>
        <p>{t('Ručně vyberte každé místo. Získáte tak plnou kontrolu nad místy vybranými na mapě, ale také to znamená, že budete muset vybrat spoustu míst, aby byla Vaše mapa byla hratelná i po několika hrách.')}</p>
      </div>
      <div>
        <span>
          <PolylineOutlinedIcon/>  
        </span>
        <h3>{t('Polygonální oblasti')}</h3>
        <p>{t('Lokace vyberete nakreslením jednoho nebo více polygonů na mapu. Doporučujeme, aby jste vybrali větší oblasti a nebo více oblastí, aby jste zajistili lepší hratelnost Vaší mapy i po několika hrách.')}</p>
      </div>
    </div>
  ); 
  
  return (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Tvorba map')}`}> 
      <section className={classes.section}>
        <div className={classes.banner}>
      		<MapOutlinedIcon/>
          <h1 className={classes.title}>{t('Tvorba map')}</h1>
          <h2 className={classes.subTitle}>{t('Vytvořte vlastní mapu do režimu Průzkumník')}</h2>
      	</div>
        {
          userStatus ? isLoaded ? (
          	<div className={classes.maps}>
              {
                Object.keys(maps).length === 0 ? (
                  <p>{t('Nemáte žádnou vytvořenou mapu')}</p>
                ) : (
                  <div>
                    <MapsCreator items={Object.values(maps)} onCreate={handleCreate}/>
                  </div>           
                )
              }
            </div>
          ) : (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>          
          ) : (
            <div className={classes.notlogged}>
              <div className={classes.header}>
                <div className={classes.wrapper}>
                  <p>{t('Musíte být přihlášen')}</p>
                </div>
              </div>
              <div className={classes.body}>
                  Pro tento obsah musíte být přihlášen
              </div>
              <div className={classes.footer}>
                <NormalButton buttonType="play" onClick={handleLogin}>
                  {t('Přihlásit se')}
                </NormalButton>
                <NormalButton buttonType="default" onClick={handleRegister}>
                  {t('Zaregistrovat se')}
                </NormalButton>
              </div>
            </div>
          )         
        }
        <Modal
          open={isOpenCreator}
          onClose={handleCloseCreator}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modal}
        >
          <div>
            <SimpleDetailModal
              onCloseModal={handleCloseCreator}
              title={t('Typ tvorby mapy')}
              content={creatorContent}
              closeButton={false}
            />
          </div>
        </Modal>
      </section>
    </DocumentTitle>
  );
};



export default ModeCreator;