import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../constants/config';
import DocumentTitle from "react-document-title";
import * as PlayService from "../../../services/content/play.service";
import * as ModeExplorerService from "../../../services/content/modeExplorer.service";          
import * as xpFormat from "../../../utils/xpFormat";
import distanceFormat from '../../../utils/distanceFormat';
import pointsFormat from '../../../utils/pointsFormat';
import FinishImage from '../../../components/Images/FinishImage';
import PointsBar from '../../../components/Bars/PointsBar';
import XpBar from '../../../components/Bars/XpBar';
import NormalButton from "../../../components/Buttons/NormalButton";
import { Context, GameContext } from "../../../contexts";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import Snackbar from "../../../components/Notifications/Snackbar";
import Avatar from '../../../components/Images/Avatar';

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    height: 'calc(100vh - 8.375rem)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 7.125rem)',
    },
    '& svg': {
      color: "#8CC63F",
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    minHeight: 'calc(100vh - 4.375rem)',
    flex: '1 0 auto',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 3.125rem)',                               
    },
  },
  mapWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    flex: '1 1 auto',
    [theme.breakpoints.down('sm')]: {
  		minHeight: '400px',                          
    }, 
    '& > #mapa': {
      width: '100%',
      minHeight: '40%',
      flex: '1 1 100%',
      '& .card': {
        zIndex: '3',
        '& > .card-header': {
          '& > strong': {
            fontWeight: 'bold',
          },
        },
        '& > .card-body': {
          '& > p': {
            fontSize: '0.8rem',
            marginTop: '0-25rem',
          },
        },
        '& > .card-footer': {
          '& > p': {
            fontSize: '0.8rem',
            marginTop: '0-25rem',
          },
          '& > a': {
            fontSize: '0.8rem',
            marginTop: '0-25rem',
            color: '#8CC63F',          
          },
        },
      },
      '& .cluster': {
        '& > div': {
          color: '#FFFFFF !important',
          backgroundColor: 'transparent',
          backgroundImage: 'url("https://api.lokalokator.cz/images/marker/cluster-red.png")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          border: '0',
          fontSize: '0',
          transform: 'scale(0.5)',
          '& > img': {
            display: 'none',
          },        
        },
      },
      '& a.marker.type-paid': {
        zIndex: '1',
        cursor: 'help',
      },
      '& a.marker.type-poi': {
        zIndex: '1',
        cursor: 'help',
      },
    },    
  },
  data: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    minHeight: 'fit-content',
    flex: '0 1 fit-content',
    backgroundColor: 'rgb(193,193,193)',
    backgroundImage: 'linear-gradient(180deg, rgba(69,93,92,1) 0%, rgba(2,13,66,1) 100%)',
    padding: '2rem 0',
    '& > h1': {
      color: '#E5B513',
      fontWeight: '700',
      fontSize: '2rem',
    },
    '& > h2': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '1rem',
      color: '#FFFFFF',
      fontWeight: '600',
      marginTop: '1rem',
      '& > span': {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,0.5)',
        padding: '0.25rem',
        margin: '0 0.25rem',
        borderRadius: '0.25rem',
      },
    },
    '& > h3': {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '3rem',
      color: 'rgba(255,255,255,0.75)',
      fontWeight: '500',
      '&::after': {
        content: `''`,
      	width: '100%',
      	height: '0.125rem',
        marginTop: '0.25rem',
      	backgroundColor: '#DDDDDD',        
      },
      '& + div': {
        marginTop: '1rem',
        color: '#FFFFFF',
      },
    },
    '& > div:first-of-type': {
      marginTop: '1rem',
      color: '#FFFFFF',
    },
    '& > div:last-of-type': {
      display: 'flex',
      gap: '2rem',
      marginTop: '3rem',
      borderTopWidth: '0.125rem',
      borderTopStyle: 'solid',
      borderTopColor: '#DDDDDD',
      padding: '2rem 4rem',
    },
  },
}));

const FinishedExplorer = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const navigate = (to) => history.push(to);
  const { contextData, setContextData } = useContext(Context);
  const { gameContextData, setGameContextData } = useContext(GameContext);
  let gameData = gameContextData;
  const userData = contextData.userData;
  const authToken = contextData.authToken;
  const gid = gameData.gid; 
  const [isLoaded, setIsLoaded] = useState(false);
  const [isUnknownError,setIsUnknownError] = useState(false);  
  const [isWrongType,setIsWrongType] = useState(false);
  const [isTooMuchOngoingGames,setIsTooMuchOngoingGames] = useState(false);
  const userXP = userData.XP;
  
  useEffect(() => {
    if(gameData.LastGuess === undefined) {
      ModeExplorerService.getGameResults(gid, authToken).then((result) => {
        setGameContextData({...gameContextData, ...result.data});
        gameData = {...gameData, ...result.data};
        setIsLoaded(true);
        window.Loader.load(null, {poi:true}, initMap);
      }); 
    } else {
      setIsLoaded(true);
      window.Loader.load(null, {poi:true}, initMap);
    }
  }, []);
  
  let SMap;
  let JAK;
  let mapa;
  
  const initMap = (data) => {
    SMap = window.SMap;
    JAK = window.JAK; 
    let center = SMap.Coords.fromWGS84(15.3386383,49.7437572);
		mapa = new SMap(JAK.gel("mapa"), center, 7);
		mapa.addDefaultLayer(SMap.DEF_BASE).enable();
    mapa.setZoomRange(7, 19);
    mapa.addDefaultLayer(SMap.DEF_BASE).enable();
    let zajmy = new SMap.Layer.Marker(undefined, {
    	poiTooltip: true
    });
    mapa.addLayer(zajmy).enable();
    let dataProvider = mapa.createDefaultDataProvider();
    dataProvider.setOwner(mapa);
    dataProvider.addLayer(zajmy);
    dataProvider.setMapSet(SMap.MAPSET_BASE);
    dataProvider.enable();
    let move = new SMap.Control.Mouse(1);
    let scroll = new SMap.Control.Mouse(2);
    let zoom = new SMap.Control.Zoom('', {showZoomMenu: false});
    let sync = new SMap.Control.Sync({bottomSpace:0});
    mapa.addControl(move);
    mapa.addControl(scroll);
    mapa.addControl(zoom);
    mapa.addControl(sync);
    let compute_locations = [];
    let click = false;
    let removeLink = function() {
    	document.querySelector("a.print").removeAttribute("href");
    }
    let signals = mapa.getSignals();
    signals.addListener(window, "map-redraw", removeLink);
    removeLink();
    let layer_marker = new SMap.Layer.Marker();
		mapa.addLayer(layer_marker);
		layer_marker.enable();
    let layer_lines = new SMap.Layer.Geometry();
    mapa.addLayer(layer_lines);
    layer_lines.enable();
    let lmarker_location = gameData.LastGuess.Location.split(",");
  	let lmarker_coords = SMap.Coords.fromWGS84(parseFloat(lmarker_location[0]),parseFloat(lmarker_location[1]));
    let lmarker_mark = JAK.mel("div");
    let lmarker_image = JAK.mel("img", {src: 'https://api.lokalokator.cz/images/marker/drop-blank.png'});
    lmarker_mark.appendChild(lmarker_image);
  	let lmarker_description = JAK.mel("div", {}, {position: 'absolute', left: '-6px', top: '10px', textAlign: 'center', width: '22px', color: 'white', fontWeight: 'bold', zIndex: '2'});
  	lmarker_description.innerHTML = '<div id="location">...</div>';
		lmarker_mark.appendChild(lmarker_description);
  	let lmarker_marker = new SMap.Marker(lmarker_coords, 'location', {url: lmarker_mark});
  	let lmarker_card = new SMap.Card();
  	lmarker_card.getHeader().innerHTML = "";
  	lmarker_marker.decorate(SMap.Marker.Feature.Card, lmarker_card);
  	layer_marker.addMarker(lmarker_marker);
  	let lmarker_answer = function(geocoder) {
     	let results = geocoder.getResults();
     	lmarker_card.getHeader().innerHTML = '<strong>Správná lokace</strong>';
     	lmarker_card.getBody().innerHTML = '<p>' + results.items[0].name + ', ' + results.items[2].name + '</p>';
  	  lmarker_card.getFooter().innerHTML = '<a href="https://mapy.cz/?x=' + lmarker_location[0] + '&y=' + lmarker_location[1] + '&z=16&source=coor&id=' + lmarker_location[0] + '%2C' + lmarker_location[1] + '" target="_Blank">Zobrazit na mapě</a>';
  	}
  	new SMap.Geocoder.Reverse(lmarker_coords, lmarker_answer);
    ReactDOM.render(<FinishImage size="1.5rem"/>, document.getElementById("location"));
    compute_locations.push(lmarker_marker._coords);
    if(gameData.LastGuess.GuessLocation !== "-1") {
      let gmarker_location = gameData.LastGuess.GuessLocation.split(",");
    	let gmarker_coords = SMap.Coords.fromWGS84(parseFloat(gmarker_location[0]),parseFloat(gmarker_location[1]));
      let gmarker_mark = JAK.mel("div");
      let gmarker_image = JAK.mel("img", {src: 'https://api.lokalokator.cz/images/marker/drop-blank.png'});
      gmarker_mark.appendChild(gmarker_image);
    	let gmarker_description = JAK.mel("div", {}, {position: 'absolute', left: '-6px', top: '10px', textAlign: 'center', width: '22px', color: 'white', fontWeight: 'bold', zIndex: '2'});
    	gmarker_description.innerHTML = '<div id="guess">...</div>';
  		gmarker_mark.appendChild(gmarker_description);
    	let gmarker_marker = new SMap.Marker(gmarker_coords, 'guess', {url: gmarker_mark});
    	let gmarker_card = new SMap.Card();
    	gmarker_card.getHeader().innerHTML = "";
    	gmarker_marker.decorate(SMap.Marker.Feature.Card, gmarker_card);
    	layer_marker.addMarker(gmarker_marker);
    	let gmarker_answer = function(geocoder) {
       	let results = geocoder.getResults();
       	gmarker_card.getHeader().innerHTML = '<strong>Tvůj odhad</strong>';
       	gmarker_card.getBody().innerHTML = '<p>' + results.items[0].name + ', ' + results.items[2].name + '</p>';
    	  gmarker_card.getFooter().innerHTML = '<a href="https://mapy.cz/?x=' + gmarker_location[0] + '&y=' + gmarker_location[1] + '&z=16&source=coor&id=' + gmarker_location[0] + '%2C' + gmarker_location[1] + '" target="_Blank">Zobrazit na mapě</a>';
    	}
    	new SMap.Geocoder.Reverse(gmarker_coords, gmarker_answer);
      ReactDOM.render(<Avatar userData={userData} size="1.75rem"/>, document.getElementById("guess"));
      compute_locations.push(gmarker_marker._coords);
  		let lineOptions = {
  			color: "#000000",
  			width: 3
  		}; 
      let linePoints = [
        SMap.Coords.fromWGS84(parseFloat(lmarker_location[0]),parseFloat(lmarker_location[1])),
        	SMap.Coords.fromWGS84(parseFloat(gmarker_location[0]),parseFloat(gmarker_location[1]))
    	];
      let lineLine = new SMap.Geometry(SMap.GEOMETRY_POLYLINE, 'line', linePoints, lineOptions);
  		layer_lines.addGeometry(lineLine);
    } else {
      compute_locations.push(lmarker_marker._coords);
    } 
    let compute_all = mapa.computeCenterZoom(compute_locations);
    let compute_coords = compute_all[0];
    let compute_zoom = compute_all[1];
    mapa.setCenterZoom(compute_coords,compute_zoom);  
  };
  
  const handlePlayAgain = () => {
    ModeExplorerService.newGame(gameData.MID, authToken).then((result) => {
      if(result) {
        if(result.data) {
          history.replace('/mode/explorer');
          setTimeout(() => {
            setGameContextData({gid: result.data.GID});
            history.replace('/play');
          });
        }
      }
    }).catch((error) => {
      if (error.response) {
        if(error.response.data) {
          switch(error.response.data) {
            case "WrongType": 
              setIsWrongType(true);
              break;
            case "TooMuchOngoingGames": 
              setIsTooMuchOngoingGames(true);
              break;
            default:
              setIsUnknownError(true); 
          }
        }
      }
    });  
  };
  
  const handleViewResults = () => {
    navigate('/results/' + gid);
  };

  return isLoaded ? (
    <div className={classes.main}>
      <div className={classes.mapWrapper}>
        <div id="mapa"></div>
      </div>
      <div className={classes.data}>
        <h1>{pointsFormat(gameData.LastGuess.Points,t)}</h1>
        <PointsBar points={gameData.LastGuess.Points} userData={userData}/>
        {
          distanceFormat(gameData.LastGuess.Distance,gameData.LastGuess.Points,t) === "Přesná pozice" && (
            <h2>{t('Tvůj odhad byl přesný, trefil jsi správnou pozici!')}</h2>
          )
        }
        {
          distanceFormat(gameData.LastGuess.Distance,gameData.LastGuess.Points,t) === "Žádná pozice" && (
            <h2>{t('V tomto kole jsi vybral žádnou pozici')}</h2>
          )
        }
        {
          distanceFormat(gameData.LastGuess.Distance,gameData.LastGuess.Points,t) !== "Přesná pozice" && distanceFormat(gameData.LastGuess.Distance,gameData.LastGuess.Points,t) !== "Žádná pozice" && (
            <h2>{t('Tvůj odhad byl')} <span>{distanceFormat(gameData.LastGuess.Distance,gameData.LastGuess.Points,t)}</span> {t('daleko od správné pozice')}</h2>
          )
        }
        <h3>{t('Získané zkušenosti')}</h3>
        <XpBar currentLevel={xpFormat.levelFromXP(parseInt(userData.XP))} currentLevelXP={xpFormat.levelXP(parseInt(xpFormat.levelFromXP(parseInt(userData.XP))) - 1)} currentXP={parseInt(userData.XP)} nextXP={xpFormat.neededXP(parseInt(userData.XP))} gainXP={gameData.LastGuess.XP}/>
        <div>
          <NormalButton buttonType="play" startIcon={<ReplayOutlinedIcon/>} oneClick={true} onClick={handlePlayAgain}>
            {t('Hrát znovu')}
          </NormalButton>
          <NormalButton buttonType="default" startIcon={<FormatListNumberedOutlinedIcon/>} oneClick={true} onClick={handleViewResults}>
            {t('Zobrazit výsledky')}
          </NormalButton>
        </div>
      </div>
      <Snackbar
        open={isUnknownError}
        autoHideDuration={3000}
        onClose={() => setIsUnknownError(false)}
        type="error"
        content={t("Vyskytla se neznámá chyba")}
      />
      <Snackbar
        open={isWrongType}
        autoHideDuration={3000}
        onClose={() => setIsWrongType(false)}
        type="warning"
        content={t("Neplatný typ hry")}
      />
      <Snackbar
        open={isTooMuchOngoingGames}
        autoHideDuration={3000}
        onClose={() => setIsTooMuchOngoingGames(false)}
        type="warning"
        content={t("Máte moc rozehraných her. Dohrajte nějakou nebo ji smažte.")}
      />
    </div>
  ) : (<div/>)
};



export default FinishedExplorer;