import moment from 'moment';
import 'moment/min/locales';
import StorageService from "../services/storage.service";

const dateFormat = (date,type = "default") => {
  moment.updateLocale('en', { longDateFormat: { LDM: 'M/D' } });
  moment.updateLocale('cs', { longDateFormat: { LDM: 'D.M.' } });
  moment.updateLocale('en', { longDateFormat: { LDMM: 'MMMM D' } });
  moment.updateLocale('cs', { longDateFormat: { LDMM: 'D. MMMM' } });
  const language = StorageService.getItem('lokalokator.language').language.toLowerCase(); 
  let formats = [];
  formats["default"] = `${moment(date).locale(language).format("L")}`;
  formats["full"] = `${moment(date).locale(language).format("LL")}, ${moment(date).locale(language).format("LT")}`; 
  formats["daymonth"] = `${moment(date).locale(language).format(moment.localeData(language).longDateFormat('LDM'))}`;
  formats["daymonthname"] = `${moment(date).locale(language).format(moment.localeData(language).longDateFormat('LDMM'))}`;
  formats["time"] = `${moment(date).locale(language).format("LT")}`;
  formats["timeseconds"] = `${moment(date).locale(language).format("LTS")}`;
  let formatedDate = formats[type];
  formatedDate = formatedDate.replace(",,",",");
  return formatedDate;
}

export default dateFormat;