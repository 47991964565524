import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import config from '../../../../../constants/config';
import DocumentTitle from "react-document-title";
import GoogleAds from "react-google-ads";
import NormalButton from "../../../../../components/Buttons/NormalButton";
import * as ProfileService from "../../../../../services/content/profile.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Context } from "../../../../../contexts";
import dateFormat from "../../../../../utils/dateFormat";
import modeFormat from "../../../../../utils/modeFormat";
import phaseFormat from "../../../../../utils/phaseFormat";
import streaksTypeFormat from "../../../../../utils/streaksTypeFormat";
import Maps from "../../../../../components/Items/Maps";

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    height: 'calc(100vh - 8.375rem)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 7.125rem)',
    },
    '& svg': {
      color: "#8CC63F",
    }
  },
  banner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "clamp(2.5rem,5vw,5rem) 0",
    backgroundColor: "#EEEEEE",
    width: "100%",
    "& > h1": {
      color: "#8CC63F",
      fontSize: "clamp(2rem,2.5vw,3rem)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      "&:only-child": {
        fontSize: "clamp(3rem,3.5vw,4rem)",
      },
    },
  },
  maps: {
  	width: '100%',
  	display: 'flex',
  	flexDirection: 'row',
    '& > p': {
      display: 'block',
      width: '100%',
      textAling: 'center',
      paddingTop: '1rem',
    },
    '& > div': {
    	width: '100%',
    	display: 'flex',
    	flexDirection: 'column',    
    },  
  },
}));

const ProfileOngoing = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const navigate = (to) => history.push(to);
  const { contextData, setContextData } = useContext(Context);
  const uid = contextData?.userData?.UID;
  const authToken = contextData.authToken;    
  const [isLoaded, setIsLoaded] = useState(false);
  const [profileData, setProfileData] = useState({});

  useEffect(() => { 
    if(uid) {
      ProfileService.getProfileData(uid, authToken).then((result) => {
        setProfileData(result.data);
        setIsLoaded(true);
      });    
    }
  }, [uid]);
  
  
  return (isLoaded) ? (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Oblíbené mapy')}`}> 
      <section className={classes.section}>
      	<div className={classes.banner}>
      		<h1>{t('Oblíbené mapy')}</h1>
      	</div>
      	<div className={classes.maps}>
          {
            Object.keys(profileData.LikedMaps).length === 0 ? (
              <p>{t('Nemáte žádnou oblíbenou mapu')}</p>
            ) : (
              <div>
                <Maps items={Object.values(profileData.LikedMaps)} size="small"/>
              </div>           
            )
          }
        </div>
      </section>
    </DocumentTitle>
  ) : (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Načítání oblíbených map')}`}>
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    </DocumentTitle>
  );
};



export default ProfileOngoing;