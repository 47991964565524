import React, {useEffect, useState} from 'react'
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
    position: 'relative',
    width: '8rem',
    height: '8rem',
    minHeight: '8rem',
    border: '1.5rem solid rgba(255,255,255,0.75)',
    borderRadius: '100%',
    backgroundColor: '#DDDDDD',
    margin: '5rem 0',
    zIndex: '2',
    '&::before': {
      content: `''`,
      position: 'absolute',
      top: '-2.5rem',
      left: '-2.5rem',
      right: '-2.5rem',
      bottom: '-2.5rem',
      backgroundColor: '#FFD700',
      borderWidth: '0.25rem',
      borderStyle: 'solid',
      borderColor: '#996515',
      borderRadius: '100%',
      zIndex: '0',    
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      width: '8rem',
      height: '8rem',
      top: '-1.5rem',
      left: '-1.5rem',
      right: '0',
      bottom: '0',
      borderWidth: '1.5rem',
      borderStyle: 'solid',
      borderColor: 'rgba(255,255,255,0.75)',
      backgroundColor: '#DDDDDD',
      borderRadius: '100%',
      zIndex: '1',    
    },
    '& > span': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%) rotate(0deg)',
      width: '4rem',
      height: '4rem',
      zIndex: '3',
      transition: 'transform 0.5s',
      '&::before': {
        content: `''`,
        position: 'absolute',
        top: '-3rem',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#FF0000',
        width: '3rem',
        height: '5rem',
        clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',    
      },
      '&::after': {
        content: `''`,
        position: 'absolute',
        bottom: '-3rem',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#FFFFFF',
        width: '3rem',
        height: '5rem',
        clipPath: 'polygon(0 0, 50% 100%, 100% 0)' 
      },  
    },   
  },
}));

const ErrorCompass = ({isAnimated = true}) => {
  const classes = useStyles();
  const [rotate, setRotate] = useState(0);
  let interval;
  
  useEffect(() => {
    if(isAnimated === true) {
      let tmpRotate = rotate;
      interval = setInterval(function() {
        if(tmpRotate === 0) {
          tmpRotate = 80;
          setRotate(80);
        }
        else if(tmpRotate === 80) {
          tmpRotate = -180;
          setRotate(-180);
        }
        else if(tmpRotate === -180) {
          tmpRotate = 0;
          setRotate(0);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, []);
  
  return (
		<div className={classes.root}>
			<span style={{transform: 'translate(-50%,-50%) rotate(' + rotate.toString() + 'deg)'}}/>
		</div>
  );
};      
export default ErrorCompass;
