import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { Context } from "../contexts";      

const PublicRoute = ({
  component: C, isAuthPage, props: cProps, path, ...rest
}) => {
  const { contextData } = useContext(Context);
  return (
    <Route
      {...rest}
      render={(props) => (((isAuthPage && !contextData.userStatus) || (!isAuthPage)) ? (
        <C {...props} {...cProps} match={rest.computedMatch} />
      ) : (
        <Redirect to="/" />
      ))}
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  isAuthPage: PropTypes.bool,
  path: PropTypes.string,
  props: PropTypes.object,
};

PublicRoute.defaultProps = {
  isAuthPage: false,
  path: '',
};


export default PublicRoute;
