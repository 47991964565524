import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@mui/material/Avatar'; 
import { ReactFitty } from "react-fitty";

const useStyles = makeStyles((theme) => ({
  root: {
  	display: 'inline-flex',
  },
  placeholder: {
    width: '100%',
   	height: '100%',
  	display: 'flex',
  	flexDirection: 'row',
  	justifyContent: 'center',
  	alignItems: 'center',
    overflow: 'hidden',
    textTransform: 'uppercase',
    fontWeight: '600',
    '& > div': {
    	display: 'flex',
    	flexDirection: 'row',
    	justifyContent: 'center',
    	alignItems: 'center',      
    },
  },
  name: {
    padding: '10px',
  },
}));

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const isLightOrDark = (color) => {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155;
}

const stringAvatar = (name, classes) => {
  const initials = name.split(" ");
  let newName = name[0] + name[1];
  if(initials.length > 1) newName = ((initials.shift()?.[0] || '') + (initials.pop()?.[0] || '')).toUpperCase(); 
  
  return (
    <div className={classes.placeholder} style={{
      backgroundColor: stringToColor(name),
      color: isLightOrDark(stringToColor(name)) ? '#000000' : '#FFFFFF',
    }}>
      <ReactFitty className={classes.name}>
        {newName}
      </ReactFitty>
    </div>
  );
};

const UserAvatar = ({ userData, size = '2rem', className }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className}`}>
      {
        userData.Avatar === "default" ? (
          <Avatar
            sx={{
              bgColor: userData.Premium === "0" ? '#FFFFFF' : '#FFD700',
              width: size,
              height: size,
              borderWidth: '0.2rem',
              borderStyle: 'double',
              borderColor: userData.Premium === "0" ? '#000000' : '#996515',
            }}
          >
            {stringAvatar(userData.Nickname, classes)}
          </Avatar>
        ) : (
          <Avatar
            alt={userData.Nickname}
            src={userData.Avatar}
            sx={{
              bgColor: userData.Premium === "0" ? '#FFFFFF' : '#FFD700',
              width: size,
              height: size,
              borderWidth: '0.2rem',
              borderStyle: 'double',
              borderColor: userData.Premium === "0" ? '#000000' : '#996515',
            }}
          />
        )
      }
    </div>
  )
};

export default UserAvatar;
