import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';

export const mainMenu = [
  {
    title: 'Režim Průzkumník',
    to: '/mode/explorer',
    icon: <TravelExploreOutlinedIcon/>,
  },
  {
    title: 'Režim Výzva',
    to: '/mode/challenge',
    icon: <RouteOutlinedIcon/>,
  },
  {
    title: 'Režim Série',
    to: '/mode/streaks',
    icon: <BoltOutlinedIcon/>,
  },
  {
    title: 'Režim Bitva',
    to: '/mode/battleroyal',
    icon: <EmojiPeopleOutlinedIcon/>,
  },
  {
    title: 'Režim Miniher',
    to: '/mode/minigames',
    icon: <SportsEsportsOutlinedIcon/>,
  },
  {
    title: 'Tvorba map',
    to: '/mode/creator',
    icon: <MapOutlinedIcon/>,
  },
];
export const userMenu = [
  {
    title: 'Váš účet',
    submenus: [
      {
        title: 'Historie her',
        to: '/profile/history',
      },
      {
        title: 'Rozehrané hry',
        to: '/profile/ongoing',
      },
      {
        title: 'Přátele',
        to: '/profile/friends',
      },
      {
        title: 'Odznaky',
        to: '/profile/badges',
      },
      {
        title: 'Oblíbené mapy',
        to: '/profile/liked/maps',
      },
      {
        title: 'Nastavení',
        to: '/settings',
      },
    ],
  }
];
export const secondMainMenu = [
  {
    title: 'Školství',
    to: '/education',
  },
  {
    title: 'Podpora',
    to: '/support',
  },
  {
    title: 'O nás',
    to: '/about',
  },
];