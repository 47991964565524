import React, { useState, useEffect, useContext } from 'react';
import config from '../../constants/config';
import Modal from "@material-ui/core/Modal";
import StorageService from "../../services/storage.service";
import classnames from 'classnames';
import { InView } from 'react-intersection-observer';
import { setImages } from "../../store/actions/images.action";
import { makeStyles } from "@material-ui/core";
import CachedImage from '../Images/CachedImage';
import { ImageContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    outline: 'none',
    width: '100%',
    height: '100%',
  },
  clickable: {
    cursor: 'pointer',
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageModal: {
    borderRadius: '10px',
    background: 'white',
    overflow: 'hidden',
    margin: '20px auto',
    width: 'fit-content',
    maxWidth: 'calc(100% - 30px)',
    maxHeight: 'calc(100% - 30px)',
    outline: 'none',
    '& > .image-container': {
      maxWidth: 'unset',
      '& > img': {
        outline: 'none',
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
  },
}));

const ImageRenderer = ({ thumbLink = null, fullsizeLink = null, isResponsive = true, isPreloaded = false, className, isClickable = false, onClick }) => {

  const [isFailed, setIsFailed] = useState(false);
  const [hasThumb,setHasThumb] = useState(false);
  const [hasFullsize,setHasFullsize] = useState(false);
  const [thumbImage,setThumbImage] = useState("");
  const [fullsizeImage,setFullsizeImage] = useState("");
  const [isThumbLoaded,setIsThumbLoaded] = useState(false);
  const [isFullsizeLoaded,setIsFullsizeLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const [imageID, setImageID] = useState(0);
  const classes = useStyles();
  const { imageContextData, setImageContextData } = useContext(ImageContext);
  const cachedImages = imageContextData.images;
  
  useEffect(() => {
    if(isInView) { 
      startFetchImage();
    }
    return () => {
      setIsInView(true);
    };
  }, [isInView]);

  const startFetchImage = () => {
    let hasThumbTemp = false;
    let hasFullsizeTemp = false;
    if(thumbLink != null) {
      hasThumbTemp = true;
      setHasThumb(true);
    }  
    if(fullsizeLink != null) {
      hasFullsizeTemp = true;
      setHasFullsize(true);
    }
    prepareImage(hasThumbTemp,hasFullsizeTemp); 
  };
  
  const prepareImage = (hasThumbTemp,hasFullsizeTemp) => {
    if(hasThumbTemp && hasFullsizeTemp) {
      fetchImage(thumbLink, "first");
    } else {
      if(hasThumbTemp) {
        fetchImage(thumbLink, "thumb"); 
      } else if(hasFullsizeTemp) {
        fetchImage(fullsizeLink, "fullsize");
      } else {
        setIsFailed(true);
      }
    }
  };
  
  const fetchImage = (src, type) => {
    if(src != null) {
      if (cachedImages.find(img => img.url === src)) {
        if(type === "thumb") {
          setThumbImage(cachedImages.find(img => img.url === src).image);
          setIsThumbLoaded(true);
        } else if(type === "fullsize") {
          setFullsizeImage(cachedImages.find(img => img.url === src).image);
          setIsFullsizeLoaded(true);
        } else if(type === "first") {
          setThumbImage(cachedImages.find(img => img.url === src).image);
          setIsThumbLoaded(true);
          fetchImage(fullsizeLink, "second");
        } else if(type === "second") {
          setFullsizeImage(cachedImages.find(img => img.url === src).image);
          setIsFullsizeLoaded(true);
        }    
      } else {
        const headers = new Headers();
        fetch(src, {headers}).then(async (response) => {
          const binaryData = await response.arrayBuffer();
          const bytes = new Uint8Array(binaryData);
          const len = bytes.byteLength;
          let string = '';
          for (let i = 0; i < len; i++) {
            string += String.fromCharCode(bytes[i]);
          }
          const base64 = btoa(string);
          const data = `data:image/png;base64,${base64}`;
          const imageObj = { image: data, url: src };
          let newImages = imageContextData.images;
          newImages.push(imageObj);
          setImageContextData({...imageContextData, images: newImages});
          if(type === "thumb") {
            setThumbImage(data);
            setIsThumbLoaded(true);
          } else if(type === "fullsize") {
            setFullsizeImage(data);
            setIsFullsizeLoaded(true);
          } else if(type === "first") {
            setThumbImage(data);
            setIsThumbLoaded(true);
            fetchImage(fullsizeLink, "second");  
          } else if(type === "second") {
            setFullsizeImage(data);
            setIsFullsizeLoaded(true);  
          }
        });
      }
    }
  };
  
  const loadView = (inView) => {
    if(!isInView) {
      setIsInView(inView);
    }
  };
  
  const handleClick = () => {
    if (isClickable) {
      setIsOpen(true);
    } else {
      return false;
    }
  };
 
  return isInView ? (
    <div className={`${classes.root} ${isClickable && classes.clickable} ${className ? className : ''}`}>
      {
        (!isResponsive || isPreloaded) && hasThumb && hasFullsize && isThumbLoaded && !isFullsizeLoaded && (
          <img
            src={thumbImage}
            className={`${classes.root} ${isClickable && classes.clickable} ${className ? className : ''}`}
            onClick={() => onClick ? onClick() : handleClick()}
          />       
        )
      }
      {
        (!isResponsive || isPreloaded) && hasThumb && hasFullsize && isThumbLoaded && isFullsizeLoaded && (
          <img
            src={fullsizeImage}
            className={`${classes.root} ${isClickable && classes.clickable} ${className ? className : ''}`}
            onClick={() => onClick ? onClick() : handleClick()}
          />       
        )
      }
      {
       (!isResponsive || isPreloaded) && hasThumb && !hasFullsize && isThumbLoaded && (
          <img
            src={thumbImage}
            className={`${classes.root} ${isClickable && classes.clickable} ${className ? className : ''}`}
            onClick={() => onClick ? onClick() : handleClick()}
          />       
        )
      }
      {
        (!isResponsive || isPreloaded) && !hasThumb && hasFullsize && isFullsizeLoaded && (
          <img
            src={fullsizeImage}
            className={`${classes.root} ${isClickable && classes.clickable} ${className ? className : ''}`}
            onClick={() => onClick ? onClick() : handleClick()}
          />       
        )
      }
      {
        isResponsive && !isPreloaded && hasThumb && hasFullsize && isThumbLoaded && !isFullsizeLoaded && (
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${thumbImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              position: 'absolute',
              top: '0px',
              left: '0px',
              right: '0px',
              bottom: '0px',
            }}
            onClick={() => onClick ? onClick() : handleClick()}
          />     
        )
      }
      {
        isResponsive && !isPreloaded && hasThumb && hasFullsize && isThumbLoaded && isFullsizeLoaded && (
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${fullsizeImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              position: 'absolute',
              top: '0px',
              left: '0px',
              right: '0px',
              bottom: '0px',
            }}
            onClick={() => onClick ? onClick() : handleClick()}
          />       
        )
      }
      {
        isResponsive && !isPreloaded && hasThumb && !hasFullsize && isThumbLoaded && (
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${thumbImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              position: 'absolute',
              top: '0px',
              left: '0px',
              right: '0px',
              bottom: '0px',
            }}
            onClick={() => onClick ? onClick() : handleClick()}
          />     
        )
      }
      {
        isResponsive && !isPreloaded && !hasThumb && hasFullsize && isFullsizeLoaded && (
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${fullsizeImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              position: 'absolute',
              top: '0px',
              left: '0px',
              right: '0px',
              bottom: '0px',
            }}
            onClick={() => onClick ? onClick() : handleClick()}
          />       
        )
      }
      {
        isClickable && (
          <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
          >
            <div className={classes.imageModal}>
              <CachedImage className={classes.childPhoto} thumbLink={thumbLink} fullsizeLink={fullsizeLink} isResponsive={false}/>
            </div>
          </Modal>
        )
      }
    </div>  
  ) : (
    <InView onChange={(inView) => loadView(inView)} className={`${classes.root} ${className ? className : ''}`}/>
  );
};


export default ImageRenderer;


