import React, {useEffect} from 'react'
import {makeStyles} from "@material-ui/core";
import config from '../../../constants/config';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
    zIndex: '1205',
    backgroundColor: '#111111',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div.loading': {
      position: 'absolute',
      top: '75%',
      transform: 'translateY(-75%)',
    },
    '& > svg': {
      maxWidth: '60%',
    }, 
    '& > p': {
      position: 'absolute',
      bottom: '43px',
      left: '0',
      right: '0',
      textAlign: 'center',
      width: 'calc(100% - 3rem)',
      padding: '0 1.5rem',
      color: '#FFFFFF',
    },
  },
}));

const Rotatescreen = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  useEffect(() => {
    document.querySelector('meta[name="theme-color"]').setAttribute("content", "#111111");
  }, []);
  
  return (
    <div className={classes.root}>
      <SVG src={config.APP_LOGO}/>
      <p>{t('Lokalokátor podporuje orientaci pouze na šířku, otočte prosím své zařízení.')}</p>
    </div>
  );
};      
export default Rotatescreen;                      