import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core";
import { Link } from 'react-router-dom';
import crypto from 'crypto';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../constants/config';
import DocumentTitle from "react-document-title";
import * as AuthService from "../../../services/auth.service";
import LoginInput from "../../../components/Forms/LoginInput";
import Checkbox from "../../../components/Forms/Checkbox";
import NormalButton from "../../../components/Buttons/NormalButton";
import Snackbar from "../../../components/Notifications/Snackbar";
import PasswordStrengthBar from 'react-password-strength-bar';

const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: '1rem',
  },
  banner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "clamp(2.5rem,5vw,5rem) 0",
    backgroundColor: "#EEEEEE",
    width: "100%",
    "& > h1": {
      color: "#8cc63f",
      fontSize: "clamp(2rem,2.5vw,3rem)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      "&:only-child": {
        fontSize: "clamp(3rem,3.5vw,4rem)",
      },
    },
    "& > h2": {
      color: "#000000",
      fontSize: "clamp(1rem,2vw,1.5rem)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "0 0 1rem 0",
    width: "50%",
    [theme.breakpoints.down('sm')]: {
      width: "90%",
    },  
  },
  passwordWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",  
  },
  passwordBar: {
    width: '90%',
  },
  buttons: {
  	width: '100%',
  	display: 'flex',
  	flexDirection: 'row',
  	flexWrap: 'wrap',
  	justifyContent: 'flex-end',
    gap: '1rem',
    marginTop: '1rem',
  },
}));

const Register = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();

  const [isNotFilledAll,setIsNotFilledAll] = useState(false);
  const [isNotFilledAllSnack,setIsNotFilledAllSnack] = useState(false);  
  const [isUnknownError,setIsUnknownError] = useState(false);  
  const [isEmailExist,setIsEmailExist] = useState(false);
  const [isEmailInvalid,setIsEmailInvalid] = useState(false);
  const [isPasswordInvalid,setIsPasswordInvalid] = useState(false);
  const [isPasswordDidNotMatch,setIsPasswordDidNotMatch] = useState(false);
  const [isNotCheckedTerms,setIsNotCheckedTerms] = useState(false);
  const [isNotCheckedPrivacy,setIsNotCheckedPrivacy] = useState(false);
  const [isSubmit,setIsSubmit] = useState(false);
  const [isRegistered,setIsRegistered] = useState(false);
  
  const [formState, setFormState] = useState({
    name: null,
    nickname: null,
    email: null,
    password: null,
    passwordConfirm: null,
    terms: null,
    privacy: null,                              
  });
  
  const onInputChange = (name, value) => {
    setIsSubmit(false);
    setIsNotFilledAll(false);
    setIsNotFilledAllSnack(false);
    setFormState({
      ...formState,
      [name]: value,
    });
  };
  
  const handleSubmit = (e) => {
    setIsSubmit(false);
    setIsSubmit(true);
    e.preventDefault();
    if(formState.name && formState.nickname && formState.email && formState.password && formState.passwordConfirm && formState.terms && formState.privacy) {
      const encryptPassword = crypto.createHash('sha1').update(formState.password).digest('hex');
      const encryptPasswordConfirm = crypto.createHash('sha1').update(formState.passwordConfirm).digest('hex');
      AuthService.signUp(formState.name, formState.nickname, formState.email, encryptPassword, encryptPasswordConfirm).then((result) => {
        if (result.data) {
          setIsRegistered(true);
        }
      }).catch((error) => {
        if (error.response) {
          switch(error.response.status) {
            case 406:
              if(error.response.data) {
                switch(error.response.data) {
                  case "EmailInvalid": 
                    setIsEmailInvalid(false);
                    break;
                  case "PasswordInvalid": 
                    setIsPasswordInvalid(true);
                    break;
                  case "PasswordDidNotMatch": 
                    setIsPasswordDidNotMatch(true);
                    break;
                  default:
                    setIsUnknownError(true);
                    break; 
                }
              }
              break;
            case 409:
              setIsEmailExist(true);
              break;
            default:
              setIsUnknownError(true);
              break;
          }
        }
        setIsSubmit(false);
      });
    } else {
      setIsNotFilledAll(true);
      setIsNotFilledAllSnack(true);
      setIsSubmit(false);
    }
  };

  return (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Registrace')}`}>
      <section className={classes.section}> 
      	<div className={classes.banner}>
      		<h1>{t('Registrace')}</h1>
      		<h2>{t('je zdarma!')}</h2>
      	</div>
      	<form className={classes.form} onSubmit={handleSubmit}>
          <LoginInput
            placeholder={t('Zadejte Vaše jméno a příjmení')}
            type="text"
            label={t('Jméno a příjmení') + '*'}
            name="name"
            onChange={onInputChange}
            tabIndex="1"
            disabled={isSubmit}
          />
          <LoginInput
            placeholder={t('Zadejte Vaši přezdívku')}
            type="text"
            label={t('Přezdívka') + '*'}
            name="nickname"
            onChange={onInputChange}
            disabled={isSubmit}
            tabIndex="2"
          />
          <LoginInput
            placeholder={t('Zadejte Vaši emailovou adresu')}
            type="text"
            label={t('Email') + '*'}
            name="email"
            onChange={onInputChange}
            disabled={isSubmit}
            tabIndex="3"
          />
          <div className={classes.passwordWrapper}>
            <LoginInput
              placeholder={t('Zadejte Vaše heslo')}
              type="password"
              label={t('Heslo') + '*'}
              name="password"
              onChange={onInputChange}
              disabled={isSubmit}
              tabIndex="4"
            />
            <PasswordStrengthBar
              className={classes.passwordBar}
              password={formState.password || ''}
              scoreWords={[t('Velmi slabé'), t('Slabé'), t('Dobré'), t('Silné'), t('Velmi silné')]}
              shortScoreWord={t("Příliš krátké")}
              minLength={8}
            />
          </div>
          <LoginInput
            placeholder={t('Zadejte Vaše heslo znovu')}
            type="passwordConfirm"
            label={t('Potvrzení hesla') + '*'}
            name="passwordConfirm"
            onChange={onInputChange}
            disabled={isSubmit}
            tabIndex="6"
          />
          <Checkbox onChange={onInputChange} name="terms" label={<span>{t('Souhlasím s')} <Link to="/policies/terms" tabIndex="-1">{t('podmínkami použití')}</Link>.</span>} tabIndex="8" disabled={isSubmit}/>
          <Checkbox onChange={onInputChange} name="privacy" label={<span>{t('Souhlasím se')} <Link to="/policies/privacy" tabIndex="-1">{t('zpracováním osobních údajů')}</Link>.</span>} tabIndex="9" disabled={isSubmit}/>
      		<div className={classes.buttons}>
      			<NormalButton buttonType="lokalokator" type="submit" tabIndex="10" disabled={isSubmit} startIcon={<SVG src="/resources/images/logo_icon.svg" alt="Lokalokátor.cz" width="0" height="0"/>}>
      				{t('Vytvořit účet')}
      			</NormalButton>
      		</div>
      	</form>
        <Snackbar
          open={isNotFilledAllSnack}
          autoHideDuration={3000}
          onClose={() => setIsNotFilledAllSnack(false)}
          type="warning"
          content={(!formState.name || !formState.nickname || !formState.email || !formState.password || !formState.passwordConfirm) ? t("Musíte vyplnit všechna pole") : !formState.terms ? t('Musíte souhlasit s podmínkami použití') : t('Musíte souhlasit se zpracováním osobních údajů')}
        />
        <Snackbar
          open={isUnknownError}
          autoHideDuration={3000}
          onClose={() => setIsUnknownError(false)}
          type="error"
          content={t("Vyskytla se neznámá chyba")}
        />
        <Snackbar
          open={isEmailInvalid}
          autoHideDuration={3000}
          onClose={() => setIsEmailInvalid(false)}
          type="error"
          content={t("Zadaný email je neplatný")}
        />
        <Snackbar
          open={isPasswordInvalid}
          autoHideDuration={3000}
          onClose={() => setIsPasswordInvalid(false)}
          type="error"
          content={t("Zadané heslo je neplatné")}
        />
        <Snackbar
          open={isEmailExist}
          autoHideDuration={3000}
          onClose={() => setIsEmailExist(false)}
          type="error"
          content={t("Tento email již existuje")}
        />
        <Snackbar
          open={isRegistered}
          autoHideDuration={3000}
          onClose={() => setIsRegistered(false)}
          type="success"
          content={t("Registrace dokončena, nyní se můžete přihlásit")}
        />
      </section>
    </DocumentTitle>
  );
};

export default Register;
