import React, {useEffect, useState, useContext} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import config from '../../../constants/config';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StorageService from "../../../services/storage.service";
import {useHistory, useLocation} from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '../../../components/Images/Avatar'; 
import useThemes from '../../../constants/themes';
import { Context } from "../../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
  	position: 'fixed',
  	top: '0',
  	left: '0',
  	right: '0',
    height: '4.375rem',
  	width: '100%',
  	display: 'flex',
  	flexDirection: 'row',
  	justifyContent: 'flex-end',
  	alignItems: 'center',
  	zIndex: '1002',
	  transition: 'background-color 0.4s',
    [theme.breakpoints.down('sm')]: {
      height: '3.125rem',                           
    },
  },
  nav: {
	  height: '100%',  
  },
  menu: {
  	display: 'flex',
  	flexDirection: 'row',
  	justifyContent: 'center',
  	alignItems: 'center',
  	height: '100%',  
  },
  item: {
  	display: 'flex',
  	flexDirection: 'row',
  	justifyContent: 'center',
  	alignItems: 'center',
  	height: '100%',
  	backgroundColor: 'transparent',
  	transition: 'all 0.4s',
    '& > a': {
      display: 'flex',
      flexDirection: 'row',
    	justifyContent: 'center',
    	alignItems: 'center',
    	maxWidth: '14rem',
    	width: '100%',
    	height: '100%',
      maxHeight: '100%',
    	padding: '0 1rem',
      textTransform: 'uppercase',
      fontWeight: '600',
    	cursor: 'pointer',
    	transition: 'all 0.4s',
      '& > svg': {
        fontSize: '1rem',
      },
    }, 
  },
  nickname: {
    marginLeft: '1rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      display: 'none',                           
    }, 
  },
  toggle: {
   	width: '4.375rem',
   	height: '100%',
  	display: 'flex',
  	flexDirection: 'column',
  	justifyContent: 'center',
  	alignItems: 'center',
  	fontSize: '1.5rem',
  	backgroundColor: 'transparent',
  	transition: 'background-color 0.4s',
  	cursor: 'pointer',
    '& > svg': {
      width: '2rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
    	width: '3.125rem',
      '& > svg': {
        width: '1.725rem',
        height: '1.725rem',
      },                           
    }, 
  },
}));

const Header = ({}) => {
  const classes = useStyles(); 
  const { contextData, setContextData } = useContext(Context);
  const userStatus = contextData.userStatus;
  const userData = contextData.userData;
  const userTheme = contextData.userData?.Settings?.Theme || 'default'; 
  const themes = useThemes.themes[userTheme] ? useThemes.themes[userTheme]() : useThemes.themes["default"]();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const navigate = (to) => history.push(to);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isToggled = contextData.isToggledMenu; 

  const handleNavigate = (to) => {
    if(isToggled && isMobile) {
      handleToggle();  
    }
    navigate(to);
  };
  
  const handleToggle = () => {
    setContextData({...contextData, isToggledMenu: !isToggled});  
  };
    
  return (
    <header className={`${classes.root} ${themes.header}`}>
			<nav className={classes.nav}>
				<ul className={classes.menu}>
          {
            userStatus && (
              <li className={`${classes.item} ${themes.headerItem}`}>
                <a onClick={() => handleNavigate("/profile")}>
                  <Avatar userData={userData}/>
                  <span className={classes.nickname}>{userData.Nickname}</span>
                </a>
              </li>
            )
          }
          {
            !userStatus && ( 
              <li className={`${classes.item} ${themes.headerItem}`}>
                <a onClick={() => handleNavigate("/register")}>
                  {
                    isMobile ? (
                      <PersonAddAltIcon/>
                    ) : (
                      <span>{t('Zaregistrovat se')}</span>
                    )
                  }
                </a>
              </li>
            )
          }
          {
            !userStatus && (
              <li className={`${classes.item} ${themes.headerItem}`}>
                <a onClick={() => handleNavigate("/login")}>
                {
                    isMobile ? (
                      <LoginIcon/>
                    ) : (
                      <span>{t('Přihlásit se')}</span>
                    )
                }
                </a>
              </li>            
            )
          }
					</ul>
			</nav>
			<a onClick={handleToggle} className={`${classes.toggle} ${themes.headerToggle} ${isToggled && 'isToggled'}`}>
        <MenuIcon/>
      </a>
		</header>
  );
};

export default Header;
