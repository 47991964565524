import React, { useState } from "react";
import config from '../constants/config';
import StorageService from "../services/storage.service";
 
export const ContextDefaultData = {
  authToken: null,
  configuration: null,
  isToggledMenu: false,
  showNotLoggedModal: false,
  userData: null,
  userStatus: false,
  updateUser: null,
}; 

const ContextInitData = {
  authToken: StorageService.getItem(config.TOKEN_KEY) || null,
  configuration: null,
  isToggledMenu: false,
  showNotLoggedModal: false,
  userData: StorageService.getItem(config.USER_KEY) || null,
  userStatus: (StorageService.getItem(config.USER_KEY) && StorageService.getItem(config.TOKEN_KEY)) ? true : false,
  updateUser: null,
};
 
export const Context = React.createContext(ContextInitData);
export const ContextProvider = ({ children }) => {
  const [contextData, setContextData] = useState(ContextInitData);
  return (
    <Context.Provider value={{ contextData, setContextData }}>
      {children}
    </Context.Provider>
  );
};

export const ImageContextDefaultData = {
  images: [],
}; 

const ImageContextInitData = {
  images: [],
};
 
export const ImageContext = React.createContext(ImageContextInitData);
export const ImageContextProvider = ({ children }) => {
  const [imageContextData, setImageContextData] = useState(ImageContextInitData);
  return (
    <ImageContext.Provider value={{ imageContextData, setImageContextData }}>
      {children}
    </ImageContext.Provider>
  );
};


export const GameContextDefaultData = {
  gid: null,
}; 

const GameContextInitData = {
  gid: null,
};
 
export const GameContext = React.createContext(GameContextInitData);
export const GameContextProvider = ({ children }) => {
  const [gameContextData, setGameContextData] = useState(GameContextInitData);
  return (
    <GameContext.Provider value={{ gameContextData, setGameContextData }}>
      {children}
    </GameContext.Provider>
  );
};

export const CreatorContextDefaultData = {
  mid: null,
}; 

const CreatorContextInitData = {
  mid: null,
};
 
export const CreatorContext = React.createContext(CreatorContextInitData);
export const CreatorContextProvider = ({ children }) => {
  const [creatorContextData, setCreatorContextData] = useState(CreatorContextInitData);
  return (
    <CreatorContext.Provider value={{ creatorContextData, setCreatorContextData }}>
      {children}
    </CreatorContext.Provider>
  );
};