import React from 'react'
import {makeStyles} from "@material-ui/core";
import config from '../../../constants/config';
import SVG, { Props as SVGProps } from 'react-inlinesvg';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      maxWidth: '60%',
    }, 
  },
}));

const Splashscreen = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <SVG src={config.APP_LOGO}/>
    </div>
  );
};      
export default Splashscreen;
