import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import imageReducer from './image.reducer';

const exportedReducers = (history) => combineReducers({
  router: connectRouter(history),
  imageReducer
});

export default exportedReducers;
