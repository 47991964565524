import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import StorageService from './services/storage.service';
import { langs } from "./constants/languages";

import Backend from 'i18next-http-backend';

let lang = "CS";
let language = StorageService.getItem("lokalokator.language");
let navLang = (((navigator?.language)?.split("-"))[0])?.toUpperCase();
if(language?.language) {
  lang = language?.language;
} else {
  if(langs.indexOf(navLang) !== -1) {
    lang = navLang;
  } else {
    lang = "EN";
  }
  StorageService.setItem("lokalokator.language", {language: lang}); 
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: lang,
    backend: {
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
    },
    fallbackLng: 'EN',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      useSuspense: true
    }
  });

export default i18n;
