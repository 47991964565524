import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../../../constants/config';
import DocumentTitle from "react-document-title";
import GoogleAds from "react-google-ads";
import CountUp from 'react-countup';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined'; 
import NormalButton from "../../../../../components/Buttons/NormalButton";
import * as ModeExplorerService from "../../../../../services/content/modeExplorer.service";
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import CachedImage from '../../../../../components/Images/CachedImage';
import HighScoresTable from '../../../../../components/Tables/Score';
import Maps from '../../../../../components/Items/Maps'; 
import { Context, GameContext } from "../../../../../contexts";
import pointsFormat from "../../../../../utils/pointsFormat";
import timeFormat from "../../../../../utils/timeFormat";
import { useParams }  from "react-router-dom";
import Snackbar from "../../../../../components/Notifications/Snackbar";

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    height: 'calc(100vh - 8.375rem)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 7.125rem)',
    },
    '& svg': {
      color: "#8CC63F",
    }
  },
  header: { 
    backgroundColor: '#A3B28E',
    position: 'relative',
    width: '100%',
    height: '400px',
    '& > .coat': {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      width: 'auto',
      height: 'auto',
      zIndex: '2',
      '& > img': {
        width: '72px',
        height: 'auto',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-o-user-select': 'none',
        userSelect: 'none',
        '-webkit-user-drag': 'none',
        '-khtml-user-drag': 'none',
        '-moz-user-drag': 'none',
        '-o-user-drag': 'none',
        userDrag: 'none',
        pointerEvents: 'none',
      },
    },
  },
  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    zIndex: '1',
    '& > h1': {
      marginTop: 'auto',
      color: '#FFFFFF',
      fontSize: '48px',
      [theme.breakpoints.down('sm')]: {
      	fontSize: '20px',                         
      },
    },
    '& > p': {
      maxWidth: '80%',
      color: '#FFFFFF',
      fontSize: '18px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
      	fontSize: '14px',                         
      },
    },
    '& > div': {
      marginTop: 'auto',
      marginBottom: '1rem',
      display: 'flex',
      gap: '1rem',
      '& > button:first-of-type': {
        width: '200px',
      },
      '& > button:last-of-type': {
        minWidth: 'unset',
        width: '2.531rem',
        height: '2.531rem',
        '& .MuiButton-startIcon': {
          margin: '0',
        },
      }, 
    },  
  },
  infoBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr))',
    gridColumnGap: '1rem',
    gridRowGap: '2rem',
    justifyItems: 'center',
    width: '100%',
    backgroundImage: 'linear-gradient(180deg, rgba(69,93,92,1) 0%, rgba(2,13,66,1) 100%)',
    padding: '2rem 0 4rem 0',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      padding: '2rem 0 6rem 0',                      
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundImage: 'linear-gradient(to bottom, rgba(252,250,249,0) 90%,rgba(252,250,249,1) 100%)',
      zIndex: '2',
    },
    '& > li': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: '0.85rem',
      width: '300px',
      height: '75px',
      whiteSpace: 'nowrap',
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
      color: '#8CC63F',
      borderRadius: '10px',
      fontWeight: 'bold',
      '& > svg': {
        marginLeft: '1rem',
        marginRight: '1rem',
        width: '2.25rem',
        height: '2.25rem',
        color: '#FFFFFF',
      },
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        fontWeight: '300',
        '& > span': {
          fontWeight: '600',
          fontSize: '1.05rem',
          color: '#FFFFFF',
          textTransform: 'uppercase',
        },       
      }           
    },        
  },
  tooltipAutoWidth: {
    width: "auto"
  },
  scoreIndicator: {
    display: 'flex',
    gap: '0.063rem',
    alignItems: 'flex-end',
    marginLeft: '1rem',
    marginRight: '1.5rem',
    color: '#FFFFFF',
    '& > span': {
      display: 'block',
      backgroundColor: 'currentColor',
    },
    '& > span:nth-child(1)': {
      width: '0.313rem',
      height: '0.5rem',
    },
    '& > span:nth-child(2)': {
      width: '0.313rem',
      height: '0.875rem',
    }, 
    '& > span:nth-child(3)': {
      width: '0.313rem',
      height: '1.25rem',
    }, 
    '& > span:nth-child(4)': {
      width: '0.313rem',
      height: '1.625rem',
    }, 
    '& > span:nth-child(5)': {
      width: '0.313rem',
      height: '2rem',
    },     
    '&.score_1': {
      '& > span.color': {
        color: '#57D600',
      },
    },
    '&.score_2': {
      '& > span.color': {
        color: '#8CC63F',
      },
    },
    '&.score_3': {
      '& > span.color': {
        color: '#E99235',
      },
    },
    '&.score_4': {
      '& > span.color': {
        color: '#D11B26',
      },
    },
    '&.score_5': {
      '& > span.color': {
        color: '#FF0000',
      },
    },
  },
  personal: {
  	display: 'flex',
  	flexDirection: 'column',
  	alignItems: 'center',
  	padding: '5rem 0',
  	width: '80%',
  	margin: '0 auto',
    '& > h3': {
    	color: '#8cc63f',
    	fontSize: 'clamp(1rem, 1vw, 1.25rem)',
    	marginBottom: '3rem',    
    },  
  },
  progressBar: {
  	position: 'relative',
  	display: 'flex',
  	flexDirection: 'row',
  	justifyContent: 'space-between',
  	width: '100%',  
    '& > .line': {
    	position: 'absolute',
    	top: '50%',
    	left: '50%',
    	backgroundColor: '#999999',
    	width: '100%',
    	height: '0.5rem',
    	borderRadius: '10px',
    	transform: 'translate(-50%, -50%)',
    	overflow: 'hidden',
      '& > span': {
      	display: 'block',
      	backgroundColor: '#999999',
      	height: '100%',    
      },  
    },
    '& > .point': {
  	position: 'relative',
  	color: '#999999',
      '& > span': {
      	display: 'block',
      	zIndex: '1',
      	position: 'relative',
      	backgroundColor: '#999999',
      	width: '1rem',
      	height: '1rem',
      	borderRadius: '100%',
      	transition: '0.3s ease',    
      },
      '& > label': {
      	position: 'absolute',
      	top: '100%',
      	left: '50%',
      	margin: '20px 0 0 0',
      	fontSize: 'clamp(0.75rem,1vw,1rem)',
      	transform: 'translate(-50%, 0)',
      	whiteSpace: 'nowrap',
      	color: '#999999',    
      },  
    },
    '&.bronze': {
      '& > .line': {
        '& > span': {
          backgroundColor: '#BCAAA4',        
        },
      },
      '& > .point': {
        '& > span': {
          backgroundColor: '#795548',        
        },
        '&:nth-child(2)': {
          '& > span': {
        	 backgroundColor: '#FFFFFF',
        	 boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 0.375rem #795548',
          },
          '& > label': {
            color: '#000000',
          },
        },
        '&:nth-child(3)': {
          '& > span': {
          	backgroundColor: '#FFFFFF',
          	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 0.625rem #795548',
          },
          '& > label': {
            color: '#000000',
          },
        },
      },
    },
  '&.silver': {
      '& > .line': {
        '& > span': {
          backgroundColor: '#BDBDBD',        
        },
      },
      '& > .point': {
        '& > span': {
          backgroundColor: '#616161',        
        },
        '&:nth-child(2)': {
          '& > span': {
        	 backgroundColor: '#FFFFFF',
        	 boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 0.375rem #616161',
          },
          '& > label': {
            color: '#000000',
          },
        },
        '&:nth-child(3)': {
          '& > span': {
          	backgroundColor: '#FFFFFF',
          	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 0.375rem #616161',
          },
          '& > label': {
            color: '#000000',
          },
        },
        '&:nth-child(4)': {
          '& > span': {
          	backgroundColor: '#FFFFFF',
          	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 0.625rem #616161',
          },
          '& > label': {
            color: '#000000',
          },
        },
      },
    },
    '&.gold': {
      '& > .line': {
        '& > span': {
          backgroundColor: '#FFC107',        
        },
      },
      '& > .point': {
        '& > span': {
          backgroundColor: '#FFA726',        
        },
        '&:nth-child(2)': {
          '& > span': {
        	 backgroundColor: '#FFFFFF',
        	 boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 0.375rem #FFA726',
          },
          '& > label': {
            color: '#000000',
          },
        },
        '&:nth-child(3)': {
          '& > span': {
          	backgroundColor: '#FFFFFF',
          	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 0.375rem #FFA726',
          },
          '& > label': {
            color: '#000000',
          },
        },
        '&:nth-child(4)': {
          '& > span': {
          	backgroundColor: '#FFFFFF',
          	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 0.375rem #FFA726',
          },
          '& > label': {
            color: '#000000',
          },
        },
        '&:nth-child(5)': {
          '& > span': {
          	backgroundColor: '#FFFFFF',
          	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 0.625rem #FFA726',
          },
          '& > label': {
            color: '#000000',
          },
        },
      },
    },
    '&.full': {
      '& > .line': {
        '& > span': {
          backgroundColor: '#FFC107',        
        },
      },
      '& > .point': {
        '& > span': {
          backgroundColor: '#FFA726',        
        },
        '&:nth-child(2)': {
          '& > span': {
        	 backgroundColor: '#FFFFFF',
        	 boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 0.375rem #FFA726',
          },
          '& > label': {
            color: '#000000',
          },
        },
        '&:nth-child(3)': {
          '& > span': {
          	backgroundColor: '#FFFFFF',
          	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 0.375rem #FFA726',
          },
          '& > label': {
            color: '#000000',
          },
        },
        '&:nth-child(4)': {
          '& > span': {
          	backgroundColor: '#FFFFFF',
          	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 0.375rem #FFA726',
          },
          '& > label': {
            color: '#000000',
          },
        },
        '&:nth-child(5)': {
          '& > span': {
          	backgroundColor: '#FFFFFF',
          	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 0.375rem #FFA726',
          },
          '& > label': {
            color: '#000000',
          },
        },
        '&:nth-child(6)': {
          '& > span': {
          	backgroundColor: '#FFFFFF',
          	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 0.625rem #FFA726',
          },
          '& > label': {
            color: '#000000',
          },
        },
      },
    },
  },
  highscore: {
  	display: 'flex',
  	flexDirection: 'column',
  	alignItems: 'center',
  	padding: '5rem 0',
  	width: '80%',
  	margin: '0 auto',
    '& > h2': {
    	color: '#8cc63f',
    	fontSize: 'clamp(1rem, 2vw, 2rem)',
    	marginBottom: '3rem',
    },
    '& > a': {
      textDecoration: 'none',
      '& > button': {
        marginTop: '3rem',
      },
    },  
  },
  maps: {
  	width: '100%',
  	display: 'flex',
  	flexDirection: 'column',
    alignItems: 'center',
    '& > h2': {
    	color: '#8cc63f',
    	fontSize: 'clamp(1rem, 2vw, 2rem)',
    	marginBottom: '3rem',
      maxWidth: '80%',
    },
    '& > p': {
      display: 'block',
      width: '100%',
      textAling: 'center',
      paddingTop: '1rem',
    },
    '& > div': {
    	width: '100%',
    	display: 'flex',
    	flexDirection: 'column',    
    },  
  },
}));

const ModeExplorerMap = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const navigate = (to) => history.push(to); 
  const mid = useParams().id;
  const { contextData, setContextData } = useContext(Context);
  const { gameContextData, setGameContextData } = useContext(GameContext);
  const [mapData, setMapData] = useState(null);
  const [mapHighScores, setMapHighScores] = useState(null);
  const [mapsAlsoLiked, setMapsAlsoLiked] = useState(null);
  const [mapsSameCategory, setMapsSameCategory] = useState(null);
  const authToken = contextData.authToken;
  const userStatus = contextData.userStatus;  
  const [isMapDataLoaded, setIsMapDataLoaded] = useState(false);
  const [isMapHighScoresLoaded, setIsMapHighScoresLoaded] = useState(false);
  const [isMapsAlsoLikedLoaded, setIsMapsAlsoLikedLoaded] = useState(false);
  const [isMapsSameCategoryLoaded, setIsMapsSameCategoryLoaded] = useState(false);
  const [isUnknownError,setIsUnknownError] = useState(false);  
  const [isWrongType,setIsWrongType] = useState(false);
  const [isTooMuchOngoingGames,setIsTooMuchOngoingGames] = useState(false);
  
  useEffect(() => {
    setIsMapDataLoaded(false);
    setIsMapHighScoresLoaded(false);
    setIsMapsAlsoLikedLoaded(false);
    setIsMapsSameCategoryLoaded(false);
    loadMapData();
  }, [mid]);  
  
  const loadMapData = () => {
    ModeExplorerService.getMapData(mid,contextData.authToken).then((result) => {
      setMapData(result.data);
      loadMapSameCategory(result.data.Category);
      setIsMapDataLoaded(true);
    });
    ModeExplorerService.getMapHighScores(mid).then((result) => {
      setMapHighScores(result.data);
      setIsMapHighScoresLoaded(true);
    });
    ModeExplorerService.getMapAlsoLiked(mid, 1, 5, contextData.authToken).then((result) => {
      setMapsAlsoLiked(result.data);
      setIsMapsAlsoLikedLoaded(true);    
    });   
  };
  
  const loadMapSameCategory = (category) => {
    ModeExplorerService.getMapSameCategory(category, 1, 5, mid, contextData.authToken).then((result) => {
      setMapsSameCategory(result.data);
      setIsMapsSameCategoryLoaded(true);    
    });    
  };
  
  const handlePlay = () => {
    if(userStatus) {
      ModeExplorerService.newGame(mid, authToken).then((result) => {
        if(result) {
          if(result.data) {
            setGameContextData({...gameContextData, gid: result.data.GID});
            navigate('/play');
          }
        }
      }).catch((error) => {
        if (error.response) {
          if(error.response.data) {
            switch(error.response.data) {
              case "WrongType": 
                setIsWrongType(true);
                break;
              case "TooMuchOngoingGames": 
                setIsTooMuchOngoingGames(true);
                break;
              default:
                setIsUnknownError(true); 
            }
          }
        }
      });     
    } else {
      setContextData({...contextData, showNotLoggedModal: true});  
    }
  };
  
  const handleLike = () => {
    ModeExplorerService.toggleLike(mid, authToken).then((result) => {
      let tempMapData = mapData;
      tempMapData.Personal.Liked = result.data;
      setMapData(tempMapData);
    });  
  };
  
  return (isMapDataLoaded && isMapHighScoresLoaded && isMapsAlsoLikedLoaded && isMapsSameCategoryLoaded) ? (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${mapData.Name}`}> 
      <section className={classes.section}>
        <div className={classes.header}>
          <CachedImage thumbLink={mapData.thumbLink} fullsizeLink={mapData.fullsizeLink}/>
          {
            mapData.Coat != null && (
              <CachedImage className={"coat"} fullsizeLink={mapData.Coat} isResponsive={false}/>
            )
          }
          <div className={classes.overlay}>
            <h1>{mapData.Name}</h1>
            <p>{mapData.Description}</p>
            <div>
              <NormalButton buttonType="play" oneClick={true} startIcon={<SportsEsportsOutlinedIcon/>} onClick={handlePlay}>
                {'Hrát'}
              </NormalButton>
              {
                userStatus && (
                  <NormalButton buttonType="primary" startIcon={parseInt(mapData.Personal.Liked) === 1 ? (<ThumbUpIcon/>) : (<ThumbUpOutlinedIcon/>)} onClick={handleLike}/>
                )
              }
            </div>
          </div>
        </div>
        <ul className={classes.infoBox}>
          <li>
            <TimerOutlinedIcon/>
            <div>
              <span>{t('Čas')}</span>
              {timeFormat(mapData.TimeLimit, false, t)}
            </div>
          </li>
          <li>
            <ReplayOutlinedIcon/>
            <div>
              <span>{t('Počet kol')}</span>
              {mapData.Rounds}
            </div>
          </li>
          <li>
            <LocationOnOutlinedIcon/>
            <div>
              <span>{t('Počet lokací')}</span>
              {mapData.Locations}
            </div>
          </li>
          <li>
            <span className={`${classes.scoreIndicator} ${"score_"}${mapData.Score.Indicator.length}`}>
              {
                [...Array(mapData.Score.Indicator.length)].map((i, key) => (
                  <span key={`${"k_"}${key}`} className={"color"}></span>
                ))
              }
              {
                [...Array(5 - mapData.Score.Indicator.length)].map((i, key) => (
                  <span key={`${"k_"}${key}`}></span>
                ))
              }
            </span>
            <div>
              <span>
                {t('Obtížnost')}
               </span>
               {t("Průměrné skóre")}: {pointsFormat(mapData.Score.Data, t)}
            </div>
          </li>
          <li>
            <GroupsOutlinedIcon/>
            <div>
              <span>{t('Počet hraní')}</span>
              {mapData.TotalPlay}
            </div>
          </li>
          <li>
            <ThumbUpOutlinedIcon/>
            <div>
              <span>{t('Počet palců')}</span>
              {mapData.Likes}
            </div>
          </li>
        </ul>
        {
          userStatus && mapData.Personal.TopScore !== 0 && (
            <div className={classes.personal}>
              <h3>{t('Vaše nejvyšší skóre - ')} {pointsFormat(mapData.Personal.TopScore, t)}</h3>
              <div className={`${classes.progressBar} ${mapData.Personal.TopScore !== 25000 && mapData.Personal.Medal != null && mapData.Personal.Medal.toLowerCase()} ${mapData.Personal.TopScore === 25000 && 'full'}`}>
      	    		<div className={"line"}>
        				  <span style={{width: ((mapData.Personal.TopScore * 100) / 25000) + '%'}}/>
      			    </div>
          			<div className={"point"}>
              		<span/>
              		<label>0 {t('bodů')}</label>
            		</div>
          			<div className={"point"}>
              		<span/>
              		<label>{t('Bronzová')}</label>
            		</div>
          			<div className={"point"}>
              		<span/>
              		<label>{t('Stříbrná')}</label>
            		</div>
              	<div className={"point"}>
              		<span/>
              		<label>{t('Zlatá')}</label>
            			</div>
        			  <div className={"point"}>
              		<span/>
              		<label>25000 {t('bodů')}</label>
            		</div>
          		</div>
            </div>
          )
        }
        {
          Object.values(mapHighScores).length > 0 && (
            <div className={classes.highscore}>
              <h2>{t('Nejvyšší skóre')}</h2>
              <HighScoresTable data={Object.values(mapHighScores)} limit="5"/>
              <Link to={{pathname: '/mode/explorer/' + mid + '/scores'}}>
                <NormalButton buttonType="primary" startIcon={<EmojiEventsOutlinedIcon/>}>
                  {'Všechna skóre'}
                </NormalButton>
              </Link>
            </div>
          )
        }
        {
          Object.keys(mapsAlsoLiked).length > 0 && (
            <div className={classes.maps}>
              <h2>{t('Mapy, které se líbí lidem, kterým se líbí i tato mapa')}</h2>
              <div>
                <Maps items={Object.values(mapsAlsoLiked)} size="small"/>
              </div>
            </div>
          )
        }
        {
          Object.keys(mapsSameCategory).length > 0 && (
            <div className={classes.maps}>
              <h2>{t('Mapy ze stejné kategorie')}</h2>
              <div>
                <Maps items={Object.values(mapsSameCategory)} size="small"/>
              </div>
            </div>
          )
        }
        <Snackbar
          open={isUnknownError}
          autoHideDuration={3000}
          onClose={() => setIsUnknownError(false)}
          type="error"
          content={t("Vyskytla se neznámá chyba")}
        />
        <Snackbar
          open={isWrongType}
          autoHideDuration={3000}
          onClose={() => setIsWrongType(false)}
          type="warning"
          content={t("Neplatný typ hry")}
        />
        <Snackbar
          open={isTooMuchOngoingGames}
          autoHideDuration={3000}
          onClose={() => setIsTooMuchOngoingGames(false)}
          type="warning"
          content={t("Máte moc rozehraných her. Dohrajte nějakou nebo ji smažte.")}
        />
      </section>
    </DocumentTitle>
  ) : (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Načítání mapy')}`}>
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    </DocumentTitle>
  );
};



export default ModeExplorerMap;