import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";

const themesNames = [];
themesNames["default"] = "Základní";
themesNames["lokalokator"] = "Lokalokátor";
themesNames["gray"] = "Šedé";
themesNames["darkGreen"] = "Tmavě zelené";
themesNames["blue"] = "Modré";
themesNames["pink"] = "Růžové";
themesNames["mapy-cz"] = "Mapy.cz";
themesNames["lgbt"] = "Komunita LGBT";
themesNames["twigsee"] = "Twigsee";

const themes = [];

themes["default"] = makeStyles((theme) => ({
  root: {
  	backgroundColor: '#FCFAF9',  
  },
  logo: {
    backgroundColor: 'transparent',
  },
  header: {
  	backgroundColor: '#222222',
  },
  headerItem: {
    '& > a': {
      color: '#FFFFFF',    
    },
    '&:hover': {
      '& > a': {
        backgroundColor: '#444444',
    	  color: 'rgba(255, 255, 255, 0.7)',        
      }
    }
  },
  headerToggle: {
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      '&:not(.isToggled)': {
        backgroundColor: '#444444',
      },                         
    },
    [theme.breakpoints.down('sm')]: {
      '&.isToggled': {
        backgroundColor: '#444444',
      },                          
    }, 
  },
  footer: {
  	backgroundColor: '#222222',
  },
  mainMenu: {
    backgroundColor: '#111111',
  },
  mainMenuItem: {
    color: '#AAB5B7',
    '&:not(.dropdown):hover': {
      backgroundColor: '#333333',
      color: '#FFFFFF',    
    },
    '&.dropdown': {
      '& > ul': {
        '& > li': {
	        color: '#AAB5B7',
          '&:hover': {
            backgroundColor: '#333333',
            color: '#FFFFFF',          
          },        
        },
      },
      '&:hover': {
        '& > a': {
          backgroundColor: '#333333',
          color: '#FFFFFF',
        }    
      },
      '&.active': {
        color: '#FFFFFF',
        '& > a': {
          backgroundColor: '#333333',
          color: '#FFFFFF',
        }
      },
    },   
  },
  mainMenuSocials: {
    '& > a': {
      color: '#FFFFFF',
    },
  },
  mainMenuCopyright: {
    color: '#FFFFFF',
  },
  previewRoot: {
  	backgroundColor: '#FCFAF9',  
  },
  previewLogo: {
    backgroundColor: 'transparent',    
  },
  previewHeader: {
    backgroundColor: '#222222',  
  },
  previewHeaderItemActive: {
    backgroundColor: '#444444',
    color: 'rgba(255, 255, 255, 0.7)',    
  },
  previewHeaderToggle: {
    color: '#FFFFFF',
    backgroundColor: '#444444',                          
  },
  previewMenu: {
    backgroundColor: '#111111',
    color: '#AAB5B7',  
  },
  previewMenuHover: {
    backgroundColor: '#444444',
    color: 'rgba(255, 255, 255, 0.7)',        
  },
}));

themes["lokalokator"] = makeStyles((theme) => ({
  root: {
  	backgroundColor: '#FCFAF9',  
  },
  logo: {
    backgroundColor: 'transparent',
  },
  header: {
  	backgroundColor: '#8CC63F',
  },
  headerItem: {
    '& > a': {
      color: '#FFFFFF',    
    },
    '&:hover': {
      '& > a': {
        backgroundColor: '#AFD779',
    	  color: 'rgba(255, 255, 255, 0.7)',        
      }
    }
  },
  headerToggle: {
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      '&:not(.isToggled)': {
        backgroundColor: '#AFD779',
      },                         
    },
    [theme.breakpoints.down('sm')]: {
      '&.isToggled': {
        backgroundColor: '#AFD779',
      },                          
    },  
  },
  footer: {
  	backgroundColor: '#8CC63F',
  },
  mainMenu: {
    backgroundColor: '#222222',
  },
  mainMenuItem: {
    color: '#AAB5B7',
    '&:not(.dropdown):hover': {
      backgroundColor: '#AFD779',
      color: '#FFFFFF',    
    },
    '&.dropdown': {
      '& > ul': {
        '& > li': {
	        color: '#AAB5B7',
          '&:hover': {
            backgroundColor: '#AFD779',
            color: '#FFFFFF',          
          },        
        },
      },
      '&:hover': {
        '& > a': {
          backgroundColor: '#AFD779',
          color: '#FFFFFF',
        }    
      },
      '&.active': {
        color: '#FFFFFF',
        '& > a': {
          backgroundColor: '#AFD779',
          color: '#FFFFFF',
        }
      }, 
    },  
  },
  mainMenuSocials: {
    '& > a': {
      color: '#FFFFFF',
    },
  },
  mainMenuCopyright: {
    color: '#FFFFFF',
  },
  previewRoot: {
  	backgroundColor: '#FCFAF9',  
  },
  previewLogo: {
    backgroundColor: 'transparent',    
  },
  previewHeader: {
    backgroundColor: '#8CC63F',  
  },
  previewHeaderItemActive: {
    backgroundColor: '#444444',
    color: 'rgba(255, 255, 255, 0.7)',    
  },
  previewHeaderToggle: {
    color: '#FFFFFF',
    backgroundColor: '#AFD779',                          
  },
  previewMenu: {
    backgroundColor: '#222222',
    color: '#AAB5B7',  
  },
  previewMenuHover: {
    backgroundColor: '#AFD779',
    color: 'rgba(255, 255, 255, 0.7)',        
  },
}));

themes["gray"] = makeStyles((theme) => ({
  root: {
  	backgroundColor: '#FCFAF9',  
  },
  logo: {
    backgroundColor: 'transparent',
  },
  header: {
  	backgroundColor: '#282C2F',
  },
  headerItem: {
    '& > a': {
      color: '#FFFFFF',    
    },
    '&:hover': {
      '& > a': {
        backgroundColor: '#323639',
    	  color: 'rgba(255, 255, 255, 0.7)',        
      }
    }
  },
  headerToggle: {
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      '&:not(.isToggled)': {
        backgroundColor: '#323639',
      },                         
    },
    [theme.breakpoints.down('sm')]: {
      '&.isToggled': {
        backgroundColor: '#323639',
      },                          
    }, 
  },
  footer: {
  	backgroundColor: '#282C2F',
  },
  mainMenu: {
    backgroundColor: '#202124',
  },
  mainMenuItem: {
    color: '#AAB5B7',
    '&:not(.dropdown):hover': {
      backgroundColor: '#323639',
      color: '#FFFFFF',    
    },
    '&.dropdown': {
      '& > ul': {
        '& > li': {
	        color: '#AAB5B7',
          '&:hover': {
            backgroundColor: '#323639',
            color: '#FFFFFF',          
          },        
        },
      },
      '&:hover': {
        '& > a': {
          backgroundColor: '#323639',
          color: '#FFFFFF',
        }    
      },
      '&.active': {
        color: '#FFFFFF',
        '& > a': {
          backgroundColor: '#323639',
          color: '#FFFFFF',
        }
      },
    },   
  },
  mainMenuSocials: {
    '& > a': {
      color: '#FFFFFF',
    },
  },
  mainMenuCopyright: {
    color: '#FFFFFF',
  },
  previewRoot: {
  	backgroundColor: '#FCFAF9',  
  },
  previewLogo: {
    backgroundColor: 'transparent',    
  },
  previewHeader: {
    backgroundColor: '#282C2F',  
  },
  previewHeaderItemActive: {
    backgroundColor: '#323639',
    color: 'rgba(255, 255, 255, 0.7)',    
  },
  previewHeaderToggle: {
    color: '#FFFFFF',
    backgroundColor: '#323639',                          
  },
  previewMenu: {
    backgroundColor: '#202124',
    color: '#AAB5B7',  
  },
  previewMenuHover: {
    backgroundColor: '#323639',
    color: 'rgba(255, 255, 255, 0.7)',        
  },
}));

themes["darkGreen"] = makeStyles((theme) => ({
  root: {
  	backgroundColor: '#FCFAF9',  
  },
  logo: {
    backgroundColor: 'transparent',
  },
  header: {
  	backgroundColor: '#4E6361',
  },
  headerItem: {
    '& > a': {
      color: '#FFFFFF',    
    },
    '&:hover': {
      '& > a': {
        backgroundColor: '#64807D',
    	  color: 'rgba(255, 255, 255, 0.7)',        
      }
    }
  },
  headerToggle: {
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      '&:not(.isToggled)': {
        backgroundColor: '#64807D',
      },                         
    },
    [theme.breakpoints.down('sm')]: {
      '&.isToggled': {
        backgroundColor: '#64807D',
      },                          
    }, 
  },
  footer: {
  	backgroundColor: '#4E6361',
  },
  mainMenu: {
    backgroundColor: '#242E30',
  },
  mainMenuItem: {
    color: '#AAB5B7',
    '&:not(.dropdown):hover': {
      backgroundColor: '#3A4A4D',
      color: '#FFFFFF',    
    },
    '&.dropdown': {
      '& > ul': {
        '& > li': {
	        color: '#AAB5B7',
          '&:hover': {
            backgroundColor: '#3A4A4D',
            color: '#FFFFFF',          
          },        
        },
      },
      '&:hover': {
        '& > a': {
          backgroundColor: '#3A4A4D',
          color: '#FFFFFF',
        }    
      },
      '&.active': {
        color: '#FFFFFF',
        '& > a': {
          backgroundColor: '#3A4A4D',
          color: '#FFFFFF',
        }
      },
    },   
  },
  mainMenuSocials: {
    '& > a': {
      color: '#FFFFFF',
    },
  },
  mainMenuCopyright: {
    color: '#FFFFFF',
  },
  previewRoot: {
  	backgroundColor: '#FCFAF9',  
  },
  previewLogo: {
    backgroundColor: 'transparent',    
  },
  previewHeader: {
    backgroundColor: '#4E6361',  
  },
  previewHeaderItemActive: {
    backgroundColor: '#64807D',
    color: 'rgba(255, 255, 255, 0.7)',    
  },
  previewHeaderToggle: {
    color: '#FFFFFF',
    backgroundColor: '#64807D',                          
  },
  previewMenu: {
    backgroundColor: '#242E30',
    color: '#AAB5B7',  
  },
  previewMenuHover: {
    backgroundColor: '#64807D',
    color: 'rgba(255, 255, 255, 0.7)',        
  },
}));

themes["blue"] = makeStyles((theme) => ({
  root: {
  	backgroundColor: '#FCFAF9',  
  },
  logo: {
    backgroundColor: 'transparent',
  },
  header: {
  	backgroundColor: '#0073D0',
  },
  headerItem: {
    '& > a': {
      color: '#FFFFFF',    
    },
    '&:hover': {
      '& > a': {
        backgroundColor: '#0089FA',
    	  color: 'rgba(255, 255, 255, 0.7)',        
      }
    }
  },
  headerToggle: {
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      '&:not(.isToggled)': {
        backgroundColor: '#0089FA',
      },                         
    },
    [theme.breakpoints.down('sm')]: {
      '&.isToggled': {
        backgroundColor: '#0089FA',
      },                          
    }, 
  },
  footer: {
  	backgroundColor: '#0073D0',
  },
  mainMenu: {
    backgroundColor: '#1D1F20',
  },
  mainMenuItem: {
    color: '#AAB5B7',
    '&:not(.dropdown):hover': {
      backgroundColor: '#0089FA',
      color: '#FFFFFF',    
    },
    '&.dropdown': {
      '& > ul': {
        '& > li': {
	        color: '#AAB5B7',
          '&:hover': {
            backgroundColor: '#0089FA',
            color: '#FFFFFF',          
          },        
        },
      },
      '&:hover': {
        '& > a': {
          backgroundColor: '#0089FA',
          color: '#FFFFFF',
        }    
      },
      '&.active': {
        color: '#FFFFFF',
        '& > a': {
          backgroundColor: '#0089FA',
          color: '#FFFFFF',
        }
      },
    },   
  },
  mainMenuSocials: {
    '& > a': {
      color: '#FFFFFF',
    },
  },
  mainMenuCopyright: {
    color: '#FFFFFF',
  },
  previewRoot: {
  	backgroundColor: '#FCFAF9',  
  },
  previewLogo: {
    backgroundColor: 'transparent',    
  },
  previewHeader: {
    backgroundColor: '#0073D0',  
  },
  previewHeaderItemActive: {
    backgroundColor: '#0089FA',
    color: 'rgba(255, 255, 255, 0.7)',    
  },
  previewHeaderToggle: {
    color: '#FFFFFF',
    backgroundColor: '#0089FA',                          
  },
  previewMenu: {
    backgroundColor: '#1D1F20',
    color: '#AAB5B7',  
  },
  previewMenuHover: {
    backgroundColor: '#0089FA',
    color: 'rgba(255, 255, 255, 0.7)',        
  },
}));

themes["pink"] = makeStyles((theme) => ({
  root: {
  	backgroundColor: '#FCFAF9',  
  },
  logo: {
    backgroundColor: 'transparent',
  },
  header: {
  	backgroundColor: '#E94C88',
  },
  headerItem: {
    '& > a': {
      color: '#FFFFFF',    
    },
    '&:hover': {
      '& > a': {
        backgroundColor: '#F294B8',
    	  color: 'rgba(255, 255, 255, 0.7)',        
      }
    }
  },
  headerToggle: {
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      '&:not(.isToggled)': {
        backgroundColor: '#F294B8',
      },                         
    },
    [theme.breakpoints.down('sm')]: {
      '&.isToggled': {
        backgroundColor: '#F294B8',
      },                          
    }, 
  },
  footer: {
  	backgroundColor: '#E94C88',
  },
  mainMenu: {
    backgroundColor: '#333333',
  },
  mainMenuItem: {
    color: '#AAB5B7',
    '&:not(.dropdown):hover': {
      backgroundColor: '#F294B8',
      color: '#FFFFFF',    
    },
    '&.dropdown': {
      '& > ul': {
        '& > li': {
	        color: '#AAB5B7',
          '&:hover': {
            backgroundColor: '#F294B8',
            color: '#FFFFFF',          
          },        
        },
      },
      '&:hover': {
        '& > a': {
          backgroundColor: '#F294B8',
          color: '#FFFFFF',
        }    
      },
      '&.active': {
        color: '#FFFFFF',
        '& > a': {
          backgroundColor: '#F294B8',
          color: '#FFFFFF',
        }
      },
    },   
  },
  mainMenuSocials: {
    '& > a': {
      color: '#FFFFFF',
    },
  },
  mainMenuCopyright: {
    color: '#FFFFFF',
  },
  previewRoot: {
  	backgroundColor: '#FCFAF9',  
  },
  previewLogo: {
    backgroundColor: 'transparent',    
  },
  previewHeader: {
    backgroundColor: '#E94C88',  
  },
  previewHeaderItemActive: {
    backgroundColor: '#F294B8',
    color: 'rgba(255, 255, 255, 0.7)',    
  },
  previewHeaderToggle: {
    color: '#FFFFFF',
    backgroundColor: '#444444',                          
  },
  previewMenu: {
    backgroundColor: '#333333',
    color: '#AAB5B7',  
  },
  previewMenuHover: {
    backgroundColor: '#F294B8',
    color: 'rgba(255, 255, 255, 0.7)',        
  },
}));

themes["mapy-cz"] = makeStyles((theme) => ({
  root: {
  	backgroundColor: '#FCFAF9',  
  },
  logo: {
    backgroundColor: '#29AC07',
  },
  header: {
  	backgroundColor: '#29AC07',
  },
  headerItem: {
    '& > a': {
      color: '#FFFFFF',    
    },
    '&:hover': {
      '& > a': {
        backgroundColor: '#69C551',
    	  color: 'rgba(255, 255, 255, 0.7)',        
      }
    }
  },
  headerToggle: {
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      '&:not(.isToggled)': {
        backgroundColor: '#69C551',
      },                         
    },
    [theme.breakpoints.down('sm')]: {
      '&.isToggled': {
        backgroundColor: '#69C551',
      },                          
    }, 
  },
  footer: {
  	backgroundColor: '#29AC07',
  },
  mainMenu: {
    backgroundColor: '#F8F8F8',
    '&::before': {
    	content: `''`,
    	position: 'absolute',
    	top: '0',
    	bottom: '0',
    	right: '-16px',
    	width: '16px',
    	'-webkit-box-shadow': '0 4px 16px 0 rgb(0 0 0 / 20%)',
      boxShadow: '0 4px 16px 0 rgb(0 0 0 / 20%)',    
    },
  },
  mainMenuItem: {
    color: '#000000',
    '&:not(.dropdown):hover': {
      backgroundColor: '#F0F0F0',
      color: '#000000',    
    },
    '&.dropdown': {
      '& > ul': {
        '& > li': {
	        color: '#000000',
          '&:hover': {
            backgroundColor: '#F0F0F0',
            color: '#000000',          
          },        
        },
      },
      '&:hover': {
        '& > a': {
          backgroundColor: '#F0F0F0',
          color: '#000000',
        }    
      },
      '&.active': {
        color: '#FFFFFF',
        '& > a': {
          backgroundColor: '#F0F0F0',
          color: '#000000',
        }
      },
    },   
  },
  mainMenuSocials: {
    '& > a': {
      color: '#000000',
    },
  },
  mainMenuCopyright: {
    color: '#000000',
  },
  previewRoot: {
  	backgroundColor: '#FCFAF9',  
  },
  previewLogo: {
    backgroundColor: '#29AC07',    
  },
  previewHeader: {
    backgroundColor: '#29AC07',  
  },
  previewHeaderItemActive: {
    backgroundColor: '#69C551',
    color: 'rgba(255, 255, 255, 0.7)',    
  },
  previewHeaderToggle: {
    color: '#FFFFFF',
    backgroundColor: '#69C551',                          
  },
  previewMenu: {
    backgroundColor: '#F8F8F8',
    color: '#000000',
    '&::before': {
    	content: `''`,
    	position: 'absolute',
    	top: '0',
    	bottom: '0',
    	right: '-16px',
    	width: '16px',
    	'-webkit-box-shadow': '0 4px 16px 0 rgb(0 0 0 / 20%)',
      boxShadow: '0 4px 16px 0 rgb(0 0 0 / 20%)',    
    },  
  },
  previewMenuHover: {
    backgroundColor: '#69C551',
    color: 'rgba(255, 255, 255, 0.7)',        
  },
}));

themes["lgbt"] = makeStyles((theme) => ({
  root: {
  	backgroundColor: '#FCFAF9',  
  },
  logo: {
    backgroundColor: 'transparent',
  },
  header: {
    backgroundColor: '#222222',
  	backgroundImage: 'linear-gradient(90deg, #FE0000 16.66%, #FD8C00 16.66%, 33.32%, #FFE500 33.32%, 49.98%, #119F0B 49.98%, 66.64%, #0644B3 66.64%, 83.3%, #C22EDC 83.3%)',
  },
  headerItem: {
    '& > a': {
      color: '#FFFFFF',    
    },
    '&:hover': {
      '& > a': {
        backgroundColor: '#444444',
    	  color: 'rgba(255, 255, 255, 0.7)',        
      }
    }
  },
  headerToggle: {
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      '&:not(.isToggled)': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },                         
    },
    [theme.breakpoints.down('sm')]: {
      '&.isToggled': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },                          
    }, 
  },
  footer: {
  	backgroundImage: 'linear-gradient(90deg, #FE0000 16.66%, #FD8C00 16.66%, 33.32%, #FFE500 33.32%, 49.98%, #119F0B 49.98%, 66.64%, #0644B3 66.64%, 83.3%, #C22EDC 83.3%)',
  },
  mainMenu: {
    backgroundColor: '#202124',
  },
  mainMenuItem: {
    color: '#AAB5B7',
    '&:not(.dropdown):hover': {
      backgroundColor: '#333333',
      color: '#FFFFFF',    
    },
    '&.dropdown': {
      '& > ul': {
        '& > li': {
	        color: '#AAB5B7',
          '&:hover': {
            backgroundColor: '#333333',
            color: '#FFFFFF',          
          },        
        },
      },
      '&:hover': {
        '& > a': {
          backgroundColor: '#333333',
          color: '#FFFFFF',
        }    
      },
      '&.active': {
        color: '#FFFFFF',
        '& > a': {
          backgroundColor: '#333333',
          color: '#FFFFFF',
        }
      },
    },   
  },
  mainMenuSocials: {
    '& > a': {
      color: '#FFFFFF',
    },
  },
  mainMenuCopyright: {
    color: '#FFFFFF',
  },
  previewRoot: {
  	backgroundColor: '#FCFAF9',  
  },
  previewLogo: {
    backgroundColor: 'transparent',    
  },
  previewHeader: {
    backgroundColor: '#222222',
    backgroundImage: 'linear-gradient(90deg, #FE0000 16.66%, #FD8C00 16.66%, 33.32%, #FFE500 33.32%, 49.98%, #119F0B 49.98%, 66.64%, #0644B3 66.64%, 83.3%, #C22EDC 83.3%)',  
  },
  previewHeaderItemActive: {
    backgroundColor: '#444444',
    color: 'rgba(255, 255, 255, 0.7)',    
  },
  previewHeaderToggle: {
    color: '#FFFFFF',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',                          
  },
  previewMenu: {
    backgroundColor: '#202124',
    color: '#AAB5B7',  
  },
  previewMenuHover: {
    backgroundColor: '#333333',
    color: 'rgba(255, 255, 255, 0.7)',        
  },
}));

themes["twigsee"] = makeStyles((theme) => ({
  root: {
  	backgroundImage: 'linear-gradient(to bottom,#DDE1F2,#ECEDF7)',  
  },
  logo: {
    backgroundColor: '#FFFFFF',
  },
  header: {
  	backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.08)',
  },
  headerItem: {
    '& > a': {
      color: '#3A3E53',    
    },
    '&:hover': {
      '& > a': {
        backgroundColor: '#EFF0F7',
    	  color: '#0085FF',        
      }
    }
  },
  headerToggle: {
    color: '#3A3E53',
    [theme.breakpoints.up('md')]: {
      '&:not(.isToggled)': {
        backgroundColor: '#EFF0F7',
      },                         
    },
    [theme.breakpoints.down('sm')]: {
      '&.isToggled': {
        backgroundColor: '#EFF0F7',
      },                          
    }, 
  },
  footer: {
  	backgroundColor: '#222222',
  },
  mainMenu: {
    backgroundColor: 'transparent',
  },
  mainMenuItem: {
    color: '#01509B',
    padding: '4px 8px !important',
    '& > a': {
      padding: '8px 20px',
      borderRadius: '24px',
    },  
    '&:not(.dropdown):hover': {
      '& > a': {
        backgroundColor: '#FFFFFF',
        color: '#0085FF',
        boxShadow: '0px 3px 20px rgb(0 0 0 / 8%)',
       },    
    },
    '&.dropdown': {
      '& > ul': {
        '& > li': {
	        color: '#01509B',
          padding: '4px 8px !important',
          '& > a': {
            padding: '8px 20px',
            borderRadius: '24px',
          },
          '&:hover': {
            '& > a': {
              backgroundColor: '#FFFFFF',
              color: '#0085FF',
              boxShadow: '0px 3px 20px rgb(0 0 0 / 8%)',
             },          
          },        
        },
      },
      '&:hover': {
        '& > a': {
          backgroundColor: '#FFFFFF',
          color: '#0085FF',
        }    
      },
      '&.active': {
        color: '#FFFFFF',
        '& > a': {
          backgroundColor: '#FFFFFF',
          color: '#0085FF',
        }
      },
    },   
  },
  mainMenuSocials: {
    '& > a': {
      color: '#0085FF',
    },
  },
  mainMenuCopyright: {
    color: '#0085FF',
  },
  previewRoot: {
  	backgroundImage: 'linear-gradient(to bottom,#DDE1F2,#ECEDF7)',    
  },
  previewLogo: {
    backgroundColor: '#FFFFFF',    
  },
  previewHeader: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.08)',  
  },
  previewHeaderItemActive: {
    backgroundColor: '#444444',
    color: 'rgba(255, 255, 255, 0.7)',    
  },
  previewHeaderToggle: {
    color: '#3A3E53',
    backgroundColor: '#EFF0F7',                          
  },
  previewMenu: {
    backgroundColor: '#EFF0F7',
    color: '#01509B',  
  },
  previewMenuHover: {
    backgroundColor: '#FFFFFF',
    color: '#0085FF',
    boxShadow: '0px 3px 20px rgb(0 0 0 / 8%)',
    borderRadius: '24px',     
  },
}));

export default {
  themesNames,
  themes
};       