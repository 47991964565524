import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { Context } from "../contexts";

const PrivateRoute = ({
  component: C, props: cProps, path, ...rest
}) => {
  const { contextData } = useContext(Context);
  if(!contextData.userStatus) {
    return (
      <Redirect to="/login" />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <C {...props} {...cProps} match={rest.computedMatch} />
      )}
    />
  );
};

PrivateRoute.propTypes = {
  layout: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  path: PropTypes.string,
  props: PropTypes.object.isRequired,
};

PrivateRoute.defaultProps = {
  path: '',
};


export default PrivateRoute;
