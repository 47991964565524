import React from 'react'
import {makeStyles} from "@material-ui/core";
import config from '../../../constants/config';
import CircularProgress from '@mui/material/CircularProgress';
import NormalButton from "../../../components/Buttons/NormalButton";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
    zIndex: '1205',
    backgroundColor: '#111111',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div.loading': {
      position: 'absolute',
      top: '75%',
      transform: 'translateY(-75%)',
    },
    '& > svg': {
      maxWidth: '60%',
    }, 
    '& > p': {
      position: 'absolute',
      bottom: '43px',
      left: '50%',
      transform: 'translateX(-50%)',
      whiteSpace: 'nowrap',
      color: '#FFFFFF',
    },
    '& > div.failed': {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      bottom: '43px',
      left: '50%',
      transform: 'translateX(-50%)',
      '& > span': {
        textAlign: 'center',
        color: '#EE576C',
        fontWeight: 'bold',
      },
      '& > button': {
        marginTop: '16px',
      },
    }, 
  },
  spinner: {
    '& svg': {
      color: "#FFFFFF",
    },
  }
}));

const Splashscreen = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const loadFailed = false;
  
  const reloadApplication = () => {
    window.location.reload();
  };
  
  return (
    <div className={classes.root}>
      <SVG src={config.APP_LOGO}/>
        <div className="loading">  
          <CircularProgress className={classes.spinner} />
        </div>
        {
          !loadFailed && (
            <p>{t('Verze')} {config.APP_VERSION}</p>
          )
        }
        {
          loadFailed && (
            <div className="failed">
              <span>{t('Nepodařilo se načíst')}!</span>
                <NormalButton buttonType={"primary"} onClick={reloadApplication}>
                  {t("Zkusit znovu")}
                </NormalButton>
              </div>
            )
        }
    </div>
  );
};      
export default Splashscreen;
