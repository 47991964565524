import React from 'react';
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: ({ disabled }) => {
        if(disabled) return "#E8E8E8";
        else return "#000000"; 
    },
    borderWidth: '0.125rem',
    borderStyle: 'solid',
    borderRadius: '0.5rem',
    borderColor: ({ disabled }) => {
        if(disabled) return "#E8E8E8";
        else return '#000000';   
    },   
    padding: '6px 6px',
    minWidth: '40.5px',   
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.25s',
    textTransform: 'none',
    textDecoration: 'none',
    cursor: ({ disabled }) => {
        if(disabled) return "default";
        else return 'pointer';   
    },
    '& span': {
      fontSize: "14px",
      whiteSpace: "nowrap",
      transition: 'all 0.25s',
      textTransform: 'none',
      textDecoration: 'none',
      color: ({ disabled }) => {
        if(disabled) return "#949494";
        else return "#FFFFFF";
      },
    },
    '&:hover': {
      backgroundColor: '#111111',
      '& span': {
        color: '#FFFFFF',      
      },
    },
  }
}));

const PaginationButton = ({ className, onClick, children, disabled, tabIndex }) => {
  const classes = useStyles({ disabled });
  return (
    <Button
      type="button"
      className={`${classes.root} ${className}`}
      onClick={onClick}
      disabled={disabled}
      tabIndex={tabIndex}
    >
      {children}
    </Button>
  )
};

export default PaginationButton;
