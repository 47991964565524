import React, {useEffect, useState} from 'react'
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
  	width: '4rem',
  	height: '4rem',
  	borderWidth: '1.5rem',
    borderStyle: 'solid',
    borderColor: 'rgba(255,255,255,0.75)',
    borderRadius: '100%',
    position: 'relative',
    '& > span': {
    	position: 'absolute',
    	top: '50%',
    	left: '50%',
    	width: '4rem',
    	height: '4rem',
      '&::before': {
      	content: `''`,
      	position: 'absolute',
      	top: '-0.5rem',
      	left: '50%',
      	transform: 'translateX(-50%)',
      	backgroundColor: '#FF0000',
      	width: '1.5rem',
      	height: '2.5rem',
      	clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',      
      },
      '&::after': {
      	content: `''`,
      	position: 'absolute',
      	bottom: '-0.5rem',
      	left: '50%',
      	transform: 'translateX(-50%)',
      	backgroundColor: '#FFFFFF',
      	width: '1.5rem',
      	height: '2.5rem',
      	clipPath: 'polygon(0 0, 50% 100%, 100% 0)',      
      },      
    },
  },
}));

const OldCompass = ({rotate = 0}) => {
  const classes = useStyles();  
  return (
    <div className={classes.root}>
    	<span style={{transform: 'translate(-50%,-50%) rotate(' + rotate + 'deg)'}}/>
    </div>
  );
};      
export default OldCompass;
