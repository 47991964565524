
  export default function imageReducer(state = [], action) {
    switch (action.type) {
      case "cachedImages":
          state = [ ...state, action.imgObj ]
        return [
          ...state
        ];
  
      default:
        return state;
    }
  }