import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core";
import { Link } from 'react-router-dom';
import crypto from 'crypto';
import { bindActionCreators } from 'redux';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../constants/config';
import Title, { flushTitle } from 'reactjs-title';
import * as AuthService from "../../../services/auth.service";

const useStyles = makeStyles((theme) => ({
}));

const ResetPassword = ({}) => {

  return (
    <div>Test</div>
  );
};

export default ResetPassword;
