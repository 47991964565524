import HttpService from '../http.service';

export const newGame = (mid, accessToken) => {
  return HttpService.post('mode/explorer/newgame', {mid: mid}, {}, {}, accessToken);
}

export const getMapData = (mid,accessToken) => {
  return HttpService.get('get/modeExplorerMap?mid=' + mid, {}, {}, accessToken);
}

export const toggleLike = (mid, accessToken) => {
  return HttpService.post('mode/explorer/togglelike', {mid: mid}, {}, {}, accessToken);
}

export const getMapHighScores = (mid) => {
  return HttpService.get('get/modeExplorerMapHighScores?mid=' + mid, {}, {});
}

export const getMapSameCategory = (category, official, limit, except, accessToken) => {
  return HttpService.get('get/modeExplorerMapsSameCategory?category=' + category + '&official=' + official + '&limit=' + limit + '&except=' + except, {}, {}, accessToken);
}

export const getMapAlsoLiked = (mid, official, limit, accessToken) => {
  return HttpService.get('get/modeExplorerMapsAlsoLiked?mid=' + mid + '&official=' + official + '&limit=' + limit, {}, {}, accessToken);
}

export const getMapScores = (mid) => {
  return HttpService.get('get/modeExplorerMapScores?mid=' + mid, {}, {});
}

export const getCategories = () => {
  return HttpService.get('get/modeExplorerCategories', {}, {});
}

export const getCategory = (shortcode) => {
  return HttpService.get('get/modeExplorerCategory?shortcode=' + shortcode, {}, {});
}

export const getGameResults = (gid, accessToken) => {
  return HttpService.get('get/modeExplorerGameResults?gid=' + gid, {}, {}, accessToken);
}
