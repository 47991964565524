import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SportsScoreOutlinedIcon from '@mui/icons-material/SportsScoreOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
  	display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: '#000000',
    borderRadius: '100%',
    color: '#FFFFFF',
    '& > svg': {
      transform: 'scale(0.85)',
    },
  },
  number: {
  	display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: '-17.5%',
    right: '-17.5%',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    borderRadius: '100%',
    fontSize: '0.65rem',
    lineHeight: '0.65rem',
    width: '60%',
    height: '60%',
  }
}));

const UserAvatar = ({ number = 0, size = '2rem' }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={{width: size, height: size}}>
      <SportsScoreOutlinedIcon/>
      {
        number !== 0 && (
          <span className={classes.number}>
            {number.toString()}
          </span>
        )
      }
    </div>
  )
};

export default UserAvatar;
