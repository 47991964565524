import React, { useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core";
import {useTranslation} from "react-i18next";
import { Link, useHistory } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import NormalButton from "../../../Buttons/NormalButton";
import CachedImage from '../../../Images/CachedImage';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import EditRoadOutlinedIcon from '@mui/icons-material/EditRoadOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import timeFormat from "../../../../utils/timeFormat";
import YesOrNo from "../../../../utils/YesOrNo";
import { Context, GameContext, CreatorContext } from "../../../../contexts";
import * as ModeExplorerService from "../../../../services/content/modeExplorer.service";
import * as ModeCreatorService from "../../../../services/content/modeCreator.service";
import Snackbar from "../../../../components/Notifications/Snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
  	position: 'relative',
  	margin: '0 auto',
  	padding: '2rem 0',
  	justifyContent: 'center',
  	display: 'grid',
  	gridTemplateColumns: 'repeat( auto-fill, minmax(29rem, 0.5fr) )',
  	gridGap: '3rem',
  	flexWrap: 'wrap',
  	width: 'calc(100% - 4rem)',
  	maxWidth: '100%',
    '& > li:not(:first-of-type)': {
    	display: 'flex',
    	flexDirection: 'column',
    	justifyContent: 'flex-start',
    	justifySelf: 'center',
    	alignItems: 'center',
    	minWidth: '18rem',
    	width: '100%',
    	maxWidth: 'calc(100vw - 4rem)',
    	minHeight: '50vh',
    	borderRadius: '1rem',
    	overflow: 'hidden',
    	'-webkit-box-shadow': '0px 0px 32px -8px rgba(0,0,0,0.75)',
    	'-moz-box-shadow': '0px 0px 32px -8px rgba(0,0,0,0.75)',
    	boxShadow: '0px 0px 32px -8px rgba(0,0,0,0.75)',
    	position: 'relative',
    	zIndex: '3',
      backgroundImage: 'linear-gradient(to bottom, #ececec, #e3e3e4, #dcd9db, #d4d0d2, #cdc7c8)',
      '& > div.preview': {
      	display: 'flex',
      	justifyContent: 'center',
      	alignItems: 'center',
      	width: '100%',
      	height: '10rem',
      	minHeight: '10rem',
      	flex: '0 0 10rem',
      	backgroundColor: '#8CC63F',
      	backgroundSize: '100% auto',
      	backgroundPosition: 'center center',
      	position: 'relative',
      	overflow: 'hidden',
        '&::after': {
          content: `''`,
        	position: 'absolute',
        	top: '0',
        	left: '0',
        	right: '0',
        	bottom: '0',
        	zIndex: '2',
        	backgroundColor: 'rgba(0,0,0,0.2)',          
        },      
      }, 
      '& > div:not(.preview)': {
      	display: 'flex',
      	flexDirection: 'column',
      	alignItems: 'center',
      	width: 'calc(100% - 2rem)',
      	padding: '1rem 1rem 0 1rem',
      	height: 'auto',
      	flex: '1 1 auto',
        '& > h4': {
        	position: 'relative',
        	zIndex: '2',
        	fontSize: '2rem',
          fontWeight: '600',
        	whiteSpace: 'nowrap',
        	overflow: 'hidden',
          textOverflow: 'ellipsis',
        	maxWidth: '100%',
          paddingBottom: '0.175rem',
          '& > a': {
            textDecoration: 'none',
          	color: '#8CC63F',
          	borderBottomWidth: '0.175rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'transparent',
          	transition: 'all 0.5s',
            '&:hover': {
              borderBottomColor: '#8CC63F',
            },          
          },        
        },
        '& > h5': {
        	position: 'relative',
        	zIndex: '2',
        	fontSize: '1rem',
          fontWeight: '500',
        	whiteSpace: 'nowrap',
        	overflow: 'hidden',
          textOverflow: 'ellipsis',
        	maxWidth: '100%',
          paddingBottom: '0.125rem',
          '& > a': {
            textDecoration: 'none',
          	color: '#000000',
          	borderBottomWidth: '0.125rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'transparent',
          	transition: 'all 0.5s',
            '&:hover': {
              borderBottomColor: '#000000',
            },          
          },        
        },
        '& > span': {
          backgroundColor: '#CCCCCC',
          color: '#000000',
          padding: '0.25rem 0.5rem',
          fontSize: '0.8rem',
          borderRadius: '0.25rem',
          marginTop: '1rem',
          '& > a': {
            textDecoration: 'none',
          	color: '#000000',
            fontWeight: '600',
          	borderBottomWidth: '0.125rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'transparent',
          	transition: 'all 0.5s',
            '&:hover': {
              borderBottomColor: '#000000',
            },          
          }, 
        },
        '& > div': {
          display: 'flex',
          marginTop: '1rem',
          marginBottom: '1.5rem',
          gap: '1rem',
        },      
      },
      '& > ul': {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
        gridColumnGap: '1rem',
        gridRowGap: '1rem',
        justifyItems: 'center',
        width: '100%',
        backgroundImage: 'linear-gradient(180deg, rgba(140,198,63,1) 0%, rgba(98,138,44,1) 100%)',
        padding: '1rem 0',
        [theme.breakpoints.down('sm')]: {
          gridTemplateColumns: 'repeat(auto-fill, minmax(130px, 1fr))',
          gridColumnGap: '0.75rem',
          gridRowGap: '0.75rem',                         
        },
        '& > li': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          fontSize: '0.75rem',
          width: '130px',
          height: '50px',
          whiteSpace: 'nowrap',
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          color: '#EDEDED',
          borderRadius: '10px',
          fontWeight: 'bold',
          '& > svg': {
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
            width: '1.25rem',
            height: '1.25rem',
            color: '#FFFFFF',
          },
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
            fontWeight: '300',
            '& > span': {
              fontWeight: '600',
              fontSize: '0.75rem',
              color: '#FFFFFF',
              textTransform: 'uppercase',
            },       
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: '0.6rem',
            width: '120px',
            height: '40px',
            '& > div': {
              '& > span': {
                fontSize: '0.65rem !important',
              },       
            },                     
          },           
        },         
      },   
    },
  },
  tooltipAutoWidth: {
    width: "auto"
  },
  create: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#8CC63F',
    backgroundImage: 'linear-gradient(180deg, rgba(140,198,63,1) 0%, rgba(98,138,44,1) 100%)',
    color: '#FFFFFF',
  	borderRadius: '1rem',
  	minWidth: '18rem',
  	width: '100%',
  	maxWidth: 'calc(100vw - 4rem)',
  	minHeight: '50vh',
  	'-webkit-box-shadow': '0px 0px 32px -8px rgba(0,0,0,0.75)',
  	'-moz-box-shadow': '0px 0px 32px -8px rgba(0,0,0,0.75)',
  	boxShadow: '0px 0px 32px -8px rgba(0,0,0,0.75)',
    cursor: 'pointer',
    transition: 'all 0.5s',
    '& > svg': {
      fontSize: '6rem',
    },
    '& > span': {
      marginTop: '0.5rem',
      fontSize: '2rem',
    },
  },
}));

const MapsCreator = ({ className, items, moreButton, onCreate }) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const navigate = (to) => history.push(to);
  const { contextData, setContextData } = useContext(Context);
  const { gameContextData, setGameContextData } = useContext(GameContext);
  const { creatorContextData, setCreatorContextData } = useContext(CreatorContext);
  const authToken = contextData.authToken;
  const userStatus = contextData.userStatus;
  const [isUnknownError,setIsUnknownError] = useState(false);  
  const [isWrongType,setIsWrongType] = useState(false);
  const [isTooMuchOngoingGames,setIsTooMuchOngoingGames] = useState(false);
  
  const handlePlay = (mid) => {
    if(userStatus) {
      ModeExplorerService.newGame(mid, authToken).then((result) => {
        if(result) {
          if(result.data) {
            setGameContextData({...gameContextData, gid: result.data.GID});
            navigate('/play');
          }
        }
      }).catch((error) => {
        if (error.response) {
          if(error.response.data) {
            switch(error.response.data) {
              case "WrongType": 
                setIsWrongType(true);
                break;
              case "TooMuchOngoingGames": 
                setIsTooMuchOngoingGames(true);
                break;
              default:
                setIsUnknownError(true); 
            }
          }
        }
      });    
    } else {
      setContextData({...contextData, showNotLoggedModal: true});  
    }
  };
  
  const handleEdit = (mid) => {
    if(userStatus) {
      setCreatorContextData({...creatorContextData, mid: mid});
      navigate('/mode/creator/edit');   
    } else {
      setContextData({...contextData, showNotLoggedModal: true});
    }
  };
  
  return (
    <ul className={`${classes.root} ${className}`}>
      <li className={classes.create} onClick={onCreate}> 
        <AddCircleOutlineOutlinedIcon/>
        <span>{t('Vytvořit novou mapu')}</span>
      </li>
      {
        items.map((map,key) => (
          <li key={`${"k_"}${key}`}>
            <CachedImage className={"preview"} thumbLink={map.thumbLink} fullsizeLink={map.fullsizeLink} alt={map.Name}/>   
              {
                map.Medal != null && (
                  <CachedImage className={"medal"} fullsizeLink={`${"https://api.lokalokator.cz/images/medals/medal_"}${map.Medal.toLowerCase()}${"_b.png"}`} alt={t('Medaile')}/>  
                )
              }
              <div>
                <h4>
                  <Link to={`${"/mode/explorer/"}${map.MID}${"/map"}`}>{map.Name}</Link>
                </h4>
                <h5>
                  <Link to={`${"/mode/explorer/"}${map.Category.Shortcode.toLowerCase()}${"/category"}`}>{t(map.Category.Name)}</Link>
                </h5>
                <span>
                  {t('Vytvořil')} <Link to={`${"/profile/"}${map.Author.UID}`}>{map.Author.Nickname}</Link>
                </span>
                <div>
                  <NormalButton buttonType="default" type="button" oneClick={true} tabIndex="-1" startIcon={<EditRoadOutlinedIcon/>} onClick={() => handleEdit(map.MID)}>
        				    {t('Upravit')}
        			    </NormalButton>
                  <NormalButton buttonType="play" type="button" oneClick={true} tabIndex="-1" startIcon={<SportsEsportsOutlinedIcon/>} onClick={() => handlePlay(map.MID)}>
          				  {t('Hrát')}
          			  </NormalButton>
                </div>
              </div>
              <ul>
                <li>
                  <TimerOutlinedIcon/>
                  <div>
                    <span>{t('Čas')}</span>
                    {timeFormat(map.TimeLimit, false, t)}
                  </div>
                </li>
                <li>
                  <ReplayOutlinedIcon/>
                  <div>
                    <span>{t('Počet kol')}</span>
                    {map.Rounds}
                  </div>
                </li>
                <li>
                  <LocationOnOutlinedIcon/>
                  <div>
                    <span>{t('Počet lokací')}</span>
                    {map.Locations}
                  </div>
                </li>
                <li>
                  <GroupsOutlinedIcon/>
                  <div>
                    <span>{t('Počet hraní')}</span>
                    {map.TotalPlay}
                  </div>
                </li>
                <li>
                  <ThumbUpOutlinedIcon/>
                  <div>
                    <span>{t('Počet palců')}</span>
                    {map.Likes}
                  </div>
                </li>
                <li>
                  <ThumbUpOutlinedIcon/>
                  <div>
                    <span>{t('Publikováno')}</span>
                    {YesOrNo(map.Published, t)}
                  </div>
                </li>
              </ul>
            </li>  
          ))
        }
        <Snackbar
          open={isUnknownError}
          autoHideDuration={3000}
          onClose={() => setIsUnknownError(false)}
          type="error"
          content={t("Vyskytla se neznámá chyba")}
        />
        <Snackbar
          open={isWrongType}
          autoHideDuration={3000}
          onClose={() => setIsWrongType(false)}
          type="warning"
          content={t("Neplatný typ hry")}
        />
        <Snackbar
          open={isTooMuchOngoingGames}
          autoHideDuration={3000}
          onClose={() => setIsTooMuchOngoingGames(false)}
          type="warning"
          content={t("Máte moc rozehraných her. Dohrajte nějakou nebo ji smažte.")}
        />
    </ul>
  )
};

export default MapsCreator;
