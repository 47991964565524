import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import config from '../../../../constants/config';
import DocumentTitle from "react-document-title";
import GoogleAds from "react-google-ads";
import NormalButton from "../../../../components/Buttons/NormalButton";
import * as ProfileService from "../../../../services/content/profile.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Context, GameContext } from "../../../../contexts";
import dateFormat from "../../../../utils/dateFormat";
import modeFormat from "../../../../utils/modeFormat";
import phaseFormat from "../../../../utils/phaseFormat";
import streaksTypeFormat from "../../../../utils/streaksTypeFormat";
import Snackbar from "../../../../components/Notifications/Snackbar";

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    height: 'calc(100vh - 8.375rem)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 7.125rem)',
    },
    '& svg': {
      color: "#8CC63F",
    }
  },
  banner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "clamp(2.5rem,5vw,5rem) 0",
    backgroundColor: "#EEEEEE",
    width: "100%",
    "& > h1": {
      color: "#8CC63F",
      fontSize: "clamp(2rem,2.5vw,3rem)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      "&:only-child": {
        fontSize: "clamp(3rem,3.5vw,4rem)",
      },
    },
  },
  list: {
    padding: '2rem 1rem',
    width: 'calc(80% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 2rem)',
    },
    '& > button': {
      marginLeft: 'auto',
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    backgroundColor: '#EEEEEE',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: '0.5rem 0.5rem 0 0',
    '& > h2': {
      display: 'flex',
      fontWeight: 'bold',
      '& > span': {
        color: '#8CC63F',
        marginRight: '0.5rem',      
      },
      '& > a': {
        color: '#000000',
        textDecoration: 'none',
        borderBottomWidth: '0.125rem',
        borderBottomStyle: 'solid',
        borderBottomColor: 'transparent',
        transition: 'all 0.5s',
        '&:hover': {
          borderBottomColor: '#000000',
        },
      },
    },
    '&:hover': {
      '& > div': {
        opacity: '1',
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > h2': { 
        flexDirection: 'column',
      },
      '& > div': { 
        opacity: '1',
        width: '100%',
        '& > button': {
          transform: 'scale(0.75)',        
        },
      }
    },  
  },
  buttons: {
    display: 'flex',
    marginLeft: 'auto',
    gap: '0.5rem',
    opacity: '0',
    transition: 'opacity 0.5s',
  },
  bottom: {
    padding: '0.5rem 1rem',
    display: 'flex',
    fontSize: '0.8rem',
    '& > span': {
      margin: '0 0.5rem',
    },  
  },
}));

const ProfileOngoing = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const navigate = (to) => history.push(to);
  const { contextData, setContextData } = useContext(Context);
  const { gameContextData, setGameContextData } = useContext(GameContext);
  const uid = contextData?.userData?.UID;
  const authToken = contextData.authToken;    
  const [isLoaded, setIsLoaded] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [games, setGames] = useState([]);
  const [isUnknownError,setIsUnknownError] = useState(false);
  const [isGameNotExistOrNoAccess,setIsGameNotExistOrNoAccess] = useState(false);
  const [isDeleted,setIsDeleted] = useState(false);  
  
  useEffect(() => { 
    if(uid) {
      ProfileService.getProfileData(uid, authToken).then((result) => {
        setProfileData(result.data);
        setGames(Object.keys(result.data.OngoingGames));
        setIsLoaded(true);
      });    
    }
  }, [uid]);
  
  const handleDelete = (gid) => {
    let tempgid = gid;
    if(gid === "all") {
      gid = games.join(",");
    }
    setIsDeleted(false);
    ProfileService.removeOngoingGame(gid, authToken).then((result) => {
      if(tempgid === "all") {
        let deleted = result.data.Deleted;
        deleted.map((deleteGid) => {
          document.getElementById(deleteGid).remove();  
        });
        let newGames = [];
        games.map((game) => {
          if(deleted.indexOf(game) === -1) {
            newGames.push(game);
          }
        });
        setGames(newGames);
      } else {
        document.getElementById(gid).remove();
        let newGames = [];
        games.map((game) => {
          if(game !== gid) {
            newGames.push(game);
          }
        });
        setGames(newGames);
      }
      setIsDeleted(true);
    }).catch((error) => {
      if (error.response) {
        if(error.response.data) {
          switch(error.response.data) {
            case "GameNotExistOrNoAccess": 
              setIsGameNotExistOrNoAccess(true);
              break;
            default:
              setIsUnknownError(true); 
          }
        }
      }
    });  
  };
  
  const handlePlay = (gid) => {
    setGameContextData({...gameContextData, gid: gid});
    navigate('/play');  
  };
  
  return (isLoaded) ? (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Rozehrané hry')}`}> 
      <section className={classes.section}>
      	<div className={classes.banner}>
      		<h1>{t('Rozehrané hry')}</h1>
      	</div>
        <div className={classes.list}>
          {
            games.length > 1 && (
              <NormalButton buttonType="delete" onClick={() => handleDelete("all")}>
                {t('Smazat vše')}
              </NormalButton>
            )
          }
          {
            Object.values(profileData.OngoingGames).map((game, key) => (
              <div id={game.GID} className={classes.item} key={`key_${key}`}>
                <div className={classes.top}>
                  <h2>
                    <span>{modeFormat(game.Mode, t)}</span>
                    {game.Mode === "explorer" && (<Link to={{pathname: '/mode/explorer/' + game.MID + '/map'}}>{game.Name}</Link>) }
                    {game.Mode === "streaks" && (<Link to={{pathname: '/mode/streaks/' + game.Type.toLowerCase()}}>{streaksTypeFormat(game.Type, t)}</Link>)}
                  </h2>
                  <div className={classes.buttons}>
                    <NormalButton buttonType="delete" onClick={() => handleDelete(game.GID)}>
                      {t('Smazat')}
                    </NormalButton>
                    <NormalButton buttonType="play" onClick={() => handlePlay(game.GID)}>
                      {t('Pokračovat')}
                    </NormalButton>
                  </div>
                </div>
                <div className={classes.bottom}>
                  {dateFormat(game.Date, "full")}
                  <span>&bull;</span>
                  {phaseFormat(game.Phase, game.Rounds, t)}
                </div>  
              </div>
            ))
          }
          {
            games.length === 0 && (
              <p>{t('Nemáte žádné rozehrané hry')}</p>
            )
          }
        </div>
        <Snackbar
          open={isUnknownError}
          autoHideDuration={3000}
          onClose={() => setIsUnknownError(false)}
          type="error"
          content={t("Vyskytla se neznámá chyba")}
        />
        <Snackbar
          open={isGameNotExistOrNoAccess}
          autoHideDuration={3000}
          onClose={() => setIsGameNotExistOrNoAccess(false)}
          type="error"
          content={t("Tato hra neexistuje nebo k ní nemáte oprávnění")}
        />
        <Snackbar
          open={isDeleted}
          autoHideDuration={3000}
          onClose={() => setIsDeleted(false)}
          type="success"
          content={t("Hra byla smazána")}
        />
      </section>
    </DocumentTitle>
  ) : (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Načítání rozehraných her')}`}>
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    </DocumentTitle>
  );
};



export default ProfileOngoing;