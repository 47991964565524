const resultTimeFormat = (seconds, t) => {
  seconds = parseFloat(seconds);
  let formatedResultTime;
  if(seconds <= 0) formatedResultTime = t('Žádná aktivita');
  else {
    formatedResultTime = [];
  	const minutes = Math.floor(seconds / 60);
  	let totalMinutes = "";
  	if(minutes === 1) totalMinutes = minutes + ' ' + t('minuta');
  	else if(minutes === 2 || minutes === 3 || minutes === 4) totalMinutes = minutes + ' ' + t('minuty');
  	else totalMinutes = minutes + ' ' + t('minut');
  	seconds = seconds - (minutes * 60);
  	let totalSeconds = "";
  	if(seconds === 1) totalSeconds = seconds + ' ' + t('sekunda');
  	else if(seconds === 2 || seconds === 3 || seconds === 4) totalSeconds = seconds + ' ' + t('sekundy');
  	else totalSeconds = seconds + ' ' + t('sekund');
  	if(minutes !== 0) formatedResultTime.push(totalMinutes);
  	if(seconds !== 0) formatedResultTime.push(totalSeconds);
    formatedResultTime = formatedResultTime.join(" ")
  }
  return formatedResultTime;
}

export default resultTimeFormat;