import HttpService from '../http.service';

export const newGame = (type, accessToken) => {
  return HttpService.post('mode/streaks/newgame', {type: type}, {}, {}, accessToken);
}

export const changeMode = (gid, mode, accessToken) => {
  return HttpService.post('mode/streaks/changemode', {gid: gid, mode: mode}, {}, {}, accessToken);
}

export const getBBoxes = (mode, accessToken) => {
  return HttpService.get('get/modeStreaks' + mode + 'BBoxes', {}, {}, accessToken);
}

export const getPolygon = (mode, id, accessToken) => {
  return HttpService.get('get/modeStreaks' + mode + 'Polygon?id=' + id, {}, {}, accessToken);
}

export const getHighStreaks = (type, accessToken) => {
  return HttpService.get('get/modeStreaksHigh?type=' + type, {}, {}, accessToken);
}

export const getGameResults = (gid, accessToken) => {
  return HttpService.get('get/modeStreaksGameResults?gid=' + gid, {}, {}, accessToken);
}

export const getDatas = (mode) => {
  return HttpService.get('get/modeStreaks' + mode + 'Datas', {}, {});
}