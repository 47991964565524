import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../constants/config';
import DocumentTitle from "react-document-title";
import GoogleAds from "react-google-ads";
import CountUp from 'react-countup';
import Tooltip from '@mui/material/Tooltip';
import NormalButton from "../../../components/Buttons/NormalButton";
import * as ProfileService from "../../../services/content/profile.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '../../../components/Images/Avatar';
import CachedImage from '../../../components/Images/CachedImage';
import XpBar from '../../../components/Bars/XpBar';
import { Context } from "../../../contexts";
import pointsFormat from "../../../utils/pointsFormat";
import timeFormat from "../../../utils/timeFormat";
import * as xpFormat from "../../../utils/xpFormat";
import { useParams }  from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundImage: 'linear-gradient(to bottom, #ececec, #e3e3e4, #dcd9db, #d4d0d2, #cdc7c8)',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    height: 'calc(100vh - 8.375rem)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 7.125rem)',
    },
    '& svg': {
      color: "#8CC63F",
    }
  },
  maps: {
  	width: '100%',
  	display: 'flex',
  	flexDirection: 'column',
    alignItems: 'center',
    '& > h2': {
    	color: '#8cc63f',
    	fontSize: 'clamp(1rem, 2vw, 2rem)',
    	marginBottom: '3rem',
      maxWidth: '80%',
    },
    '& > p': {
      display: 'block',
      width: '100%',
      textAling: 'center',
      paddingTop: '1rem',
    },
    '& > div': {
    	width: '100%',
    	display: 'flex',
    	flexDirection: 'column',    
    },  
  },
}));

const Profile = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { contextData, setContextData } = useContext(Context);
  const userID = contextData?.userData?.UID || false;
  const authToken = contextData.authToken;  
  const userStatus = contextData.userStatus;  
  let uid = useParams().id || userID;
  const [isLoaded, setIsLoaded] = useState(false);
  const [profileData, setProfileData] = useState({});
  
  useEffect(() => { 
    if(uid) {
      ProfileService.getProfileData(uid, authToken).then((result) => {
        console.log(result.data);
        setProfileData(result.data);
        setIsLoaded(true);
      });    
    }
  }, [uid]);
  
  
  return (isLoaded) ? (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${profileData.User.Nickname}`}> 
      <section className={classes.section}>
        <Avatar userData={profileData.User} size="6rem"/>
        <h1>{profileData.User.Nickname}</h1>
        <h2>Titul</h2>
        <p>Pro text</p>
        <XpBar currentLevel={xpFormat.levelFromXP(parseInt(profileData.User.XP))} currentLevelXP={xpFormat.levelXP(parseInt(xpFormat.levelFromXP(parseInt(profileData.User.XP))) - 1)} currentXP={parseInt(profileData.User.XP)} nextXP={xpFormat.neededXP(parseInt(profileData.User.XP))}/>
      </section>
    </DocumentTitle>
  ) : (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Načítání profilu')}`}>
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    </DocumentTitle>
  );
};



export default Profile;