import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import config from '../../../constants/config';
import DocumentTitle from "react-document-title";
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100vw - 48px)',
    overflow: 'auto',
    padding: '24px',
    flex: '0 0 auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      textAlign: "left",
      height: "100%",
      width: "calc(90% - 10rem)",
      fontSize: "0.9rem",
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.7rem',
        width: "calc(90% - 5rem)",
      },
      '& > p': {
        '& > a': {
          color: '#8CC63F',  
        },
        '& + h4': {
          marginTop: "0.5rem",        
        },
      },
      '& > strong': {
        width: "100%",
        textAlign: "center",
        marginTop: "1rem",
        fontWeight: "bold",
        paddingBottom: "2rem",
        '& > a': {
          color: '#8CC63F',  
        },
        '& > span': {
          color: '#8CC63F',
        },       
      },
      '& > h1': {
        fontSize: "3rem",
        marginTop: "1rem",
        marginLeft: "auto",
        marginRight: "auto",
        fontWeight: "300",
        [theme.breakpoints.down('sm')]: {
          fontSize: '2rem',
        },
      },
      '& > h2': {
        fontSize: "1.5rem",
        fontWeight: "400",
        marginTop: "1rem",
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.85rem',
        },
        '& ~ p': {
          paddingLeft: '4rem',
          [theme.breakpoints.down('sm')]: {
            paddingLeft: '2.5rem',
          },
        },
        '& ~ ul': {
          paddingLeft: '4rem',
          '& > li': {
            '& > a': {
              color: '#8CC63F',
            },
          },
          [theme.breakpoints.down('sm')]: {
            paddingLeft: '2.5rem',
          },
        },
      },
      '& > h3': {
        fontSize: "1.25rem",
        paddingLeft: "2rem",
        marginTop: "1rem",
        fontWeight: "500",
        '& ~ p': {
          paddingLeft: '3rem',
          [theme.breakpoints.down('sm')]: {
            paddingLeft: '2rem',
          },
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.75rem',
          paddingLeft: '1.5rem',
        },
        '& ~ div': {
          overflowX: "auto",
          marginLeft: "clamp(2rem,2.5vw,3rem)",
          marginTop: "0.5rem",
          flex: "0 0 auto",
          maxWidth: "calc(100% - clamp(2rem,2.5vw,3rem))",
          padding: "0 0 0.5rem 0",
          '& > table': {
            border: "0.15rem solid #000000",
            borderCollapse: "collapse",
            borderSpacing: "0",
            '& > tr': {
              '& > td': {
                borderLeftWidth: '0.15rem',
                borderLeftStyle: 'solid',
                borderLeftColor: '#000000',
                '& > p': {
                	whiteSpace: "nowrap",
                	padding: "0.5rem",                  
                },
              },
              '&:first-of-type': {
                '& > td': {
                  backgroundColor: '#DDDDDD',
                  '& > p': {
                    fontWeight: '600',
                  },
                },
              },
              '& + tr': {
                borderTopWidth: '0.15rem',
                borderTopStyle: 'solid',
                borderTopColor: '#000000',
              },
            },
          },        
        },      
      },
      '& > h4': {
        fontSize: "1rem",
        paddingLeft: "3rem",
        fontWeight: "600",
        '& ~ p': {
          paddingLeft: '4rem',
          [theme.breakpoints.down('sm')]: {
            paddingLeft: '3rem',
          },
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.85rem',
          paddingLeft: '2rem',
        },      
      },
    },
  },                                                        
}));

const PoliciesPrivacy = ({}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Ochrana osobních údajů')}`}>                            
      <section className={classes.root}>
      <div>
        <h1>Ochrana osobních údajů</h1>
          <strong>Poslední aktualizace: <span>31/05/2022</span></strong>
        	<h2>1 - Správce osobních údajů</h2>
        		<h3>1.1</h3>
        		<p>
            	Správce osobních údajů Jakub Kučera, IČO: 11915251, sídlo: Zahradní 808, 386 01 Strakonice, (dále jen „správce“),
            	prohlašuje, že veškeré osobní údaje zpracovávané správcem jsou přísně
            	důvěrné. Správce s nimi nakládá v souladu s vnitrostátními právními
            	předpisy i předpisy Evropské unie platnými v oblasti ochrany osobních údajů.
        		</p>
        		<h3>1.2</h3>
        		<p>
           		Správce shromažďuje, uchovává a využívá Vaše osobní údaje ve smyslu zákona
            	č. 110/2019 Sb. o zpracování osobních údajů (dále jen zákon o zpracování
            	osobních údajů), resp. nařízení Evropského parlamentu a Rady (EU) 2016/679
            	ze dne 27. dubna 2016, o ochraně fyzických osob v souvislosti se zpracováním osobních údajů
        			a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (dále jen „nařízení GDPR“).
        			Jednotlivé účely, pro které správce osobní údaje zpracovává, jsou dále vymezeny.
        		</p>
        		<h3>1.3</h3>
        		<p>
            	Správce tyto osobní údaje shromažďuje i prostřednictvím svých internetových
            	stránek na adrese <Link to={{pathname: "https://www.lokalokator.cz"}} target="_Blank">lokalokator.cz</Link> (dále jen „internetové stránky“).
        		</p>
        		<h3>1.4</h3>
        		<p>
        			Tyto zásady vydává správce proto, abyste byli dostatečně informováni o tom,
            	jaké Vaše osobní údaje správce zpracovává, za jakým účelem, po jakou dobu,
            	kdo k Vašim osobním údajům bude mít přístup a jaká máte práva. Tyto zásady
            	se vztahují na všechny osobní údaje shromážděné správcem, ať už byly
            	shromážděny za účelem plnění smluvního vztahu, právní povinnosti,
            	oprávněného zájmu, nebo uděleného souhlasu.
        		</p>
        	<h2>2 - Zpracovávané údaje</h2>
        		<h3>2.1</h3>
        		<p>
            	Správce je oprávněn zpracovávat následující osobní údaje dle účelu, dle
            	kategorií osobních údajů, dle kategorií subjektu údajů, dle kategorií
            	příjemců a dle doby uchování následovně.
        		</p>
        		<div>
          		<table border="1" cellspacing="0" cellpadding="0">
            		<tr>
            			<td width="96" valign="top">
                			<p>Číslo</p>
            			</td>
            			<td width="115" valign="top">
                			<p>Účel zpracování</p>
            			</td>
            			<td width="111" valign="top">
                			<p>Kategorie osobních ůdajů</p>
            			</td>
            			<td width="100" valign="top">
					           <p>Kategorie subjektu údajů</p>
            			</td>
            			<td width="100" valign="top">
					           <p>Kategorie příjemců</p>
            			</td>
            			<td width="82" valign="top">
					           <p>Doba uchování</p>
            			</td>
           			</tr>
           			<tr>
            			<td width="96" valign="top">
    					      <p>1</p>
            			</td>
            			<td width="115" valign="top">
    					      <p>Jméno a Příjmení</p>                      
                	</td>
            			<td width="111" valign="top">
					          <p>Identifikační údaje</p>
            			</td>
            			<td width="100" valign="top">
					          <p>Uživatelé</p>
            			</td>
            			<td width="100" valign="top">
					          <p>Správce</p>
            			</td>
            			<td width="82" valign="top">
					          <p>1 rok od poslední aktivity</p>
            			</td>
          			</tr>
          			<tr>
            			<td width="96" valign="top">
					          <p>2</p>
            			</td>
            			<td width="115" valign="top">
					          <p>Email</p>
            			</td>
            			<td width="111" valign="top">
					          <p>Identifikační údaje</p>
            			</td>
            			<td width="100" valign="top">
					          <p>Uživatelé</p>
            			</td>
            			<td width="100" valign="top">
					          <p>Správce</p>
            			</td>
            			<td width="82" valign="top">
					          <p>1 rok od poslední aktivity</p>
            			</td>
    			      </tr>
     	       		<tr>
            			<td width="96" valign="top">
					          <p>3</p>
            			</td>
            			<td width="115" valign="top">
					          <p>Identifikační číslo</p>
            			</td>
            			<td width="111" valign="top">
					          <p>Identifikační údaje</p>
            			</td>
            			<td width="100" valign="top">
					          <p>Uživatelé</p>
            			</td>
            			<td width="100" valign="top">
					          <p>Správce</p>
            			</td>
            			<td width="82" valign="top">
					          <p>1 rok od poslední aktivity</p>
            			</td>
    			      </tr>
          		</table>
        		</div>
        		<h3>2.2</h3>
        		<p>
            	Jednotlivými účely zpracování se míní následující:
        		</p>
        			<h4>2.2.1</h4>
        			<p>
        				plnění smluvního vztahu znamená: vztah mezi Vámi a správcem vzniklý na
        				základě objednávky, registrace, na základě uzavřené smlouvy, na základě
        				přihlášky a účasti v soutěži a podobně
        			</p>
        			<h4>2.2.2</h4>
        			<p>
        				zasílání obchodních sdělení a nabídka produktů a služeb znamená: zasílání
        				obchodních nabídek, a to prostřednictvím elektronické pošty (e-mailem),
        				prostřednictvím krátkých textových zpráv nebo prostřednictvím telefonního hovoru
        			</p>
        			<h4>2.2.3</h4>
        			<p>
        				účetní a daňové účely znamená: evidence účetnictví ve smyslu účetní a daňové legislativy
        			</p>
        			<h4>2.2.4</h4>
        			<p>
        				personální, mzdová agenda znamená: uzavírání pracovních smluv, zpracování
        				mezd, odvody sociálního a zdravotního pojištění podle zákona č. 262/2006
        				Sb., zákoník práce, č. 582/1991 Sb. o organizaci a provádění sociálního
        				zabezpečení, č. 48/1997 Sb., o veřejném zdravotním pojištění a o změně a
        				doplnění některých souvisejících zákonů
        			</p>
        			<h4>2.2.5</h4>
        			<p>
        				statistické účely znamená: anonymizované zjišťování návštěvnosti
        				internetových stránek, dále monitoring počtu zhlédnutí stránek, času
        				stráveného na internetových stránkách, typ zařízení, ze kterého přicházíte
        				na internetové stránky. Data sbíráme proto, abychom mohli zkvalitňovat
        				poskytované služby a nabízet klientům relevantní obsah
        			</p>
        			<h4>2.2.6</h4>
        			<p>
        				zobrazování reklam znamená: zobrazování reklam na internetových stránkách
        				na základě statisticky zjištěných preferencí zákazníka
        			</p>
        			<h4>2.2.7</h4>
        			<p>
        				oprávněný zájem znamená: účinná obhajoba v případě sporu; doba zpracování
        				osobních údajů v takovém případě činí 4 roky od uplynutí záruční doby na
        				zboží a prodlužuje se o dobu, po kterou je veden spor. Chceme stále
        				zkvalitňovat své služby a případně poskytovat služby nové a lepší; chceme
        				se bránit maření takové činnosti; proto činnosti, které přispívají k
        				naplnění tohoto cíle, jsou naším oprávněným zájmem. Oprávněným zájmem je
        				také zpracování pro účely zamezení podvodům (např. posuzování rizikovosti
        				uzavření smlouvy), přímý marketing (např. nabízení relevantních služeb
        				stávajícím zákazníkům), předávání osobních údajů v rámci skupiny podniků
        				pro vnitřní administrativní účely, oznámení trestných činů a předání
        				osobních údajů příslušnému orgánu, zajištění bezpečnosti sítě a informací.
        				Tento výčet je pouze příkladný.
        			</p>
        			<h4>2.2.8</h4>
        			<p>
        				plnění dalších zákonných povinností znamená: poskytování informací orgánům
        				činným v trestním řízení, poskytování informací jiným orgánům veřejné moci
        				a podobně.
        			</p>
        		<h3>2.3</h3>
        		<p>
        			Vaše osobní údaje zpracováváme po dobu nezbytně nutnou k zajištění všech
        			práv a povinností plynoucí ze vzájemného právního jednání, to minimálně po
        			dobu vyřízení objednávky, provedení obchodu, zařízení služby apod., dále po
        			dobu, kterou je správce povinen osobní údaje uchovávat podle obecně
        			závazných právních předpisů nebo po dobu, na kterou jste správci případně
        			udělili souhlas. Jinak doba zpracování vyplývá z účelu, pro které jsou
        			osobní údaje zpracovány, nebo je dána právními předpisy.
        		</p>
        		<h3>2.4</h3>
        		<p>
        			Osobní údaje jsou správcem zpracovány manuálně i automatizovaně. Správce je
        			oprávněn zpracovávat některé informace automatizovaně například pro
        			vytvoření statistických informací o návštěvnosti svých internetových stránek.
        		</p>
        	<h2>3 - Osobní údaje zpracovávané na základě souhlasu</h2>
        		<h3>3.1</h3>
        		<p>
        			Pokud jsme od Vás získali souhlas ke zpracování osobních údajů, stalo se
        			tak pro některý z následujících účelů:
        		</p>
        			<h4>3.1.1</h4>
        			<p>
        				Registrace
        			</p>
        			<h4>3.1.2</h4>
        			<p>
        				Přihlášení
        			</p>
        			<h4>3.1.3</h4>
        			<p>
        				Správa účtu
        			</p>
        	<h2>4 - Práva subjektu údajů</h2>
        		<h3>4.1</h3>
        		<p>
        			Jako subjekt údajů máte uvedená práva, která pro Vás z právních předpisů
        			vyplývají a která můžete kdykoliv uplatnit. Jedná se o:
        		</p>
        			<h4>4.1.1</h4>
        			<p>
        				právo na přístup k osobním údajům, podle kterého máte právo získat od
        				správce informace o tom, jestli správce zpracovává Vaše osobní údaje. Tuto
        				informaci je správce povinen Vám bez zbytečného odkladu předat. Obsah
        				informace je dán ustanovením článku 15 nařízení GDPR. Správce má právo za
        				poskytnutí informace požadovat přiměřenou úhradu nepřevyšující náklady
        				nezbytné na poskytnutí informace
        			</p>
        			<h4>4.1.2</h4>
        			<p>
        				právo na opravu nebo výmaz osobních údajů, popřípadě omezení zpracování,
        				podle kterého máte právo na to, nechat opravit osobní údaje, které jsou
        				nepřesné nebo nesprávné. Pokud již Vaše osobní údaje nejsou potřebné pro
        				účely, ke kterým byly shromážděny, nebo jsou zpracovávány protiprávně, máte
        				právo požadovat jejich výmaz. Pokud nechcete žádat výmaz osobních údajů,
        				ale pouze dočasně omezit jejich zpracování, můžete žádat omezení zpracování
        			</p>
        			<h4>4.1.3</h4>
        			<p>
        				právo požadovat vysvětlení v případě, že máte podezření, že zpracování
        				osobních údajů správcem je v rozporu s právními předpisy
        			</p>
        			<h4>4.1.4</h4>
        			<p>
        				právo obrátit se na Úřad pro ochranu osobních údajů v případě pochybností o
        				dodržování povinností souvisejících se zpracováním osobních údajů
        			</p>
        			<h4>4.1.5</h4>
        			<p>
        				právo na přenositelnost údajů, tj. právo získat osobní údaje, které se Vás
        				týkají, které jste poskytl správci, ve strukturovaném, běžně používaném a
        				strojově čitelném formátu, blíže viz. čl. 20 GDPR
        			</p>
        			<h4>4.1.6</h4>
        			<p>
        				právo vznést námitku proti zpracování osobních údajů, které jsou
        				zpracovávány pro účely splnění úkolu prováděného ve veřejném zájmu nebo při
        				výkonu veřejné moci nebo pro účely ochrany oprávněných zájmů správce.
        				Správce zpracování ukončí bez zbytečného odkladu, pokud neprokáže, že
        				existuje oprávněný zájem/důvod pro zpracování, který převažuje nad Vaším
        				zájmem, právy nebo svobodami
        			</p>
        			<h4>4.1.7</h4>
        			<p>
        				právo souhlas se zpracováním osobních údajů kdykoliv odvolat, pokud jste
        				udělili správci souhlas se zpracováním osobních údajů.
        			</p>
        	<h2>5 - Cookies</h2>
        		<h3>5.1</h3>
        		<p>
        			Soubory cookies jsou krátké textové soubory, které internetová stránka
        			odešle do Vašeho prohlížeče. Umožňují internetové stránce zaznamenat
        			informace o Vaší návštěvě, například zvolený jazyk a podobně, následující
        			návštěva stránek tak pro Vás může být snazší a příjemnější. Soubory cookies
        			jsou důležité, neboť bez nich by procházení sítě Internet bylo mnohem
        			složitější. Soubory cookies umožňují lepší využití naší internetové stránky
        			a přizpůsobení jejího obsahu Vašim potřebám; používá je téměř každá
        			internetová stránka na světě. Soubory cookies jsou užitečné, protože
        			zvyšují uživatelskou přívětivost opakovaně navštívené internetové stránky.
        		</p>
        		<h3>5.2</h3>
        		<p>
        			Na internetových stránkách správce může využívat tyto druhy cookies:
        		</p>
        			<h4>5.2.1</h4>
        			<p>
        				Relační (tedy dočasné) soubory cookies nám umožňují propojovat Vaše
        				jednotlivé aktivity po dobu prohlížení těchto internetových stránek. V
        				okamžiku otevření okna Vašeho prohlížeče se tyto soubory aktivují a
        				deaktivují se po zavření okna Vašeho prohlížeče. Relační cookies jsou
        				dočasné a po zavření prohlížeče se všechny tyto soubory vymažou.
        			</p>
        			<h4>5.2.2</h4>
        			<p>
        				Trvalé soubory cookies nám pomáhají Váš počítač identifikovat, jestliže
        				opětovně navštívíte naši internetovou stránku. Další výhodou trvalých
        				cookies je to, že nám umožňují přizpůsobit naše internetové stránky Vašim potřebám.
        			</p>
        		<h3>5.3</h3>
        		<p>
        			V souladu s ustanovením § 89 odst. 3 zák. č. 127/2005 Sb., o elektronických
        			komunikacích, v účinném znění, si Vás tímto dovolujeme informovat, že naše
        			internetové stránky využívají pro svoji činnost soubory cookies, tedy že
        			Vaše soubory cookies, včetně trvalých, zpracováváme.
        		</p>
        		<h3>5.4</h3>
        		<p>
        			Internetové prohlížeče obvykle obsahují správu souborů cookies. V rámci
        			nastavení Vašeho prohlížeče tak pravděpodobně můžete jednotlivé soubory
        			cookies ručně mazat, blokovat či zcela zakázat jejich používání. Pro více
        			informací použijte nápovědu svého internetového prohlížeče. Pokud použití
        			souborů cookies nepovolíte, většina funkcí a stránek nemusí fungovat, jak
        			by měly nebo vůbec.
        		</p>
        		<h3>5.5</h3>
        		<p>
        			K personalizaci obsahu a reklam, poskytování funkcí sociálních médií a
        			analýze naší návštěvnosti využíváme soubory cookies. Informace o tom, jak
        			náš web používáte, sdílíme se svými partnery působícími v oblasti
        			sociálních médií, inzerce a analýz. Používáním internetových stránek
        			vyjadřujete souhlas propojením následujících služeb:
        		</p>
        			<h4>5.5.1</h4>
        			<p>
        				Seznam.cz
        			</p>
        			<h4>5.5.2</h4>
        			<p>
        				Mapy.cz
        			</p>
        			<h4>5.5.3</h4>
        			<p>
        				Google.com
        			</p>
        		<h3>5.6</h3>
        		<p>
        			Kvůli zobrazení cílené reklamy v rámci reklamních a sociálních sítí na
        			jiných internetových stránkách předáváme těmto reklamním a sociálním sítím
        			údaje o vašem chování na webu; nepředáváme jim však vaše identifikační údaje.
        		</p>
        	<h2>6 - Předávání do třetích zemí</h2>
        		<h3>6.1</h3>
        		<p>
        			Vaše osobní údaje nebudou předávány do třetích zemí.
        		</p>
        	<h2>7 - Informace a otázky</h2>
        		<h3>7.1</h3>
        		<p>
        			Další informace o právech a povinnostech při ochraně osobních údajů může
        			subjekt údajů získat na webových stránkách <Link to={{pathname: "https://www.lokalokator.cz"}} target="_Blank">lokalokator.cz</Link> nebo
        			prostřednictvím e-mailu na <Link to={{pathname:"mailto:info@lokalokator.cz"}} target="_top">info@lokalokator.cz</Link>
        		</p>
        	<h2>8 - Kontaktní informace</h2>
            <h3>8.1</h3>
        	<p>
        		V ohledu ochrany Vašich osobních údajů nás můžete kdykoliv kontaktovat na:
        	</p>
        	<ul>
        		<li>Email: <Link to={{pathname:"mailto:info@lokalokator.cz"}} target="_top">info@lokalokator.cz</Link></li>
        		<li>Datová schránka: <a>dxuktt2</a></li>
        	</ul>
        	<strong>Toto prohlášení je veřejně přístupné na internetových stránkách <Link to={{pathname: "https://www.lokalokator.cz"}} target="_Blank">lokalokator.cz</Link>.</strong>
        </div>
      </section>
    </DocumentTitle>
  );
};

export default PoliciesPrivacy;
