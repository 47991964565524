const timeFormat = (seconds, limit = false, t) => {
  let formatedTime = [];
  seconds = parseFloat(seconds);
	if(seconds === 0 && limit) formatedTime = t('Žádný časový limit');
	else if(seconds === 0 && !limit) formatedTime = t('0 sekund');
	else if(seconds === 1) formatedTime = seconds + ' ' + t('sekunda');
	else if(seconds === 2 || seconds === 3 || seconds === 4) formatedTime = seconds + ' ' + t('sekundy');
	else formatedTime = seconds + ' ' + t('sekund');
  return formatedTime;
}

export default timeFormat;