import React, { useEffect, useState } from 'react';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../constants/config';
import DocumentTitle from "react-document-title";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams }  from "react-router-dom";
import PublicResultsExplorer from '../../../components/Results/PublicResultsExplorer';
import PublicResultsStreaks from '../../../components/Results/PublicResultsStreaks';
import * as ResultsService from "../../../services/content/results.service";

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    height: 'calc(100vh - 8.375rem)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 7.125rem)',
    },
    '& svg': {
      color: "#8CC63F",
    }
  },
  ads: {
    display: 'none',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '15%',
    flex: '0 1 auto',
    transition: 'width 0.5s',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

const Results = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles(); 
  
  const [isLoaded, setIsLoaded] = useState(false);
  const [gameMode, setGameMode] = useState(null);
  const gid = useParams().id;  
  
  useEffect(() => {
    ResultsService.getGameMode(gid).then((result) => {
      if(result) {
        if(result.data) {
          setGameMode(result.data);
          setIsLoaded(true);  
        }
      }
    });  
  }, []);
  
  return isLoaded ? (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Výsledky')}`}> 
      <section className={classes.section}>
        <div className={classes.ads}>
        </div>
        {
          gameMode === "explorer" && (
            <PublicResultsExplorer/>
          )
        }
        {
          gameMode === "streaks" && (
            <PublicResultsStreaks/>
          )
        }        
      </section>
    </DocumentTitle>
  ) : (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Načítání výsledků')}`}>
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    </DocumentTitle>
  )
};



export default Results;
