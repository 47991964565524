import React, { useState, useEffect } from "react";
import { toast } from 'material-react-toastify';

export function createNotification(type = 'default', text = '', position = 'bottom-center') { 
  switch (type) {
    case 'default':
      toast.default(text, {position: 'top-right', autoClose: 3000, hideProgressBar: false, closeOnClick: false, pauseOnHover: true, draggable: false});
      break;
    case 'dark':
      toast.dark(text, {position: position, autoClose: 3000, hideProgressBar: false, closeOnClick: false, pauseOnHover: true, draggable: false});
      break;
    case 'info':
      toast.info(text, {position: position, autoClose: 3000, hideProgressBar: false, closeOnClick: false, pauseOnHover: true, draggable: false});
      break;
    case 'success':
      toast.success(text, {position: position, autoClose: 3000, hideProgressBar: false, closeOnClick: false, pauseOnHover: true, draggable: false});
      break;
    case 'warning':
      toast.warning(text, {position: position, autoClose: 3000, hideProgressBar: false, closeOnClick: false, pauseOnHover: true, draggable: false});
      break;
    case 'error':
      toast.error(text, {position: position, autoClose: 3000, hideProgressBar: false, closeOnClick: false, pauseOnHover: true, draggable: false});
      break;
  }
};