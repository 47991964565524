import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactFitty } from "react-fitty";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '1.25rem 0',
    boxSizing: 'border-box',
  },
  level: {
    textAlign: 'center',
    display: 'inline-block',
    fontSize: '1rem',
    lineHeight: '1rem',
    padding: '0rem 0.5rem',
    fontWeight: 'bold',
    verticalAlign: 'middle',
    letterSpacing: '0.063rem',
  },
  container: {
    display: 'inline-block',
    width: '60%',
    borderWidth: '0.063rem',
    borderStyle: 'solid',
    borderColor: '#E5B513',
    backgroundColor: 'rgba(0,0,0,0.1)',
    height: '1.875rem',
    verticalAlign: 'middle',
    padding: '0.125rem',
    boxSizing: 'border-box',
    position: 'relative',
    borderRadius: '0.25rem',
  },
  bar: {
    position: 'absolute',
    left: '0.125rem',
    height: 'calc(100% - 0.25rem)',
    top: '0.125rem',
    maxWidth: 'calc(100% - 0.25rem)',
    backgroundColor: '#E5B513',
    borderRadius: '0.25rem',
  },
  gain: {
    position: 'absolute',
    left: '0.125rem',
    height: 'calc(100% - 0.25rem)',
    top: '0.125rem',
    maxWidth: 'calc(100% - 0.25rem)',
    backgroundColor: 'rgba(229,181,19,0.3)',
    borderRadius: '0.25rem',
  },
  pointLine: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    fontSize: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',  
  },
  gainLine: {
    position: 'absolute',
    top: 'calc(100% + 0.5rem)',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',  
  },
  points: {
    display: 'flex',
    fontSize: '1rem',
    lineHeight: '1rem',
    letterSpacing: '0.063rem',
    fontWeight: '600',
    color: '#FFFFFF',
    textShadow: '0.063rem 0.063rem 0.063rem #000000', 
  },
  gained: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    height: '2rem',
    borderRadius: '100%',
    letterSpacing: '0.063rem',
    fontWeight: '600',
    color: '#FFFFFF',
    textShadow: '0.063rem 0.063rem 0.063rem #000000',
    backgroundColor: '#E5B513',
    '& > div': {
    	display: 'flex',
    	flexDirection: 'row',
    	justifyContent: 'center',
    	alignItems: 'center',      
    }, 
  },
  gainedNumber: {
    textAlign: 'center',
    padding: '0.063rem',
  },
}));

const XpBar = ({ currentLevel = 0, currentLevelXP, currentXP = 0, nextXP = 0, gainXP = null }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const gainPercent = currentXP < 100 ? ((currentXP + parseInt(gainXP))) : ((currentXP - currentLevelXP + parseInt(gainXP)) / (nextXP  - currentLevelXP)) * 100;
  const barPercent = currentXP < 100 ? (currentXP / nextXP) * 100 : ((currentXP - currentLevelXP) / (nextXP  - currentLevelXP)) * 100;
  return (
    <div className={classes.root}>
      <div className={classes.level}>{t('Úroveň')} {currentLevel}</div>
      <div className={classes.container}>
        {
          gainXP != null && (
            <div className={classes.gain} style={{width: gainPercent + '%'}}/>
          )
        }
        <div className={classes.bar} style={{width: barPercent + '%'}}/>
        <div className={classes.pointLine}>
          <div className={classes.points}>
            {currentXP} / {nextXP}
          </div>
        </div>
        {
          gainXP != null && (
            <div className={classes.gainLine}>
              <div className={classes.gained}>
                <ReactFitty className={classes.gainedNumber} minSize={5}>+{gainXP}</ReactFitty>
              </div>
            </div>
          )
        }
      </div>
      <div className={classes.level}>{t('Úroveň')} {currentLevel + 1}</div>
    </div>    
  );
};

export default XpBar;
