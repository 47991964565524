import React, {useState} from 'react';
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: ({ buttonType }) => {
        if(buttonType === "lokalokator") return "#FFFFFF";
        else if(buttonType === "seznam") return "#FFFFFF";
        else if(buttonType === "disabled") return "#E8E8E8";
        else if(buttonType === "save") return "#8CC63F";
        else if(buttonType === "play") return "#8CC63F";
        else if(buttonType === "delete") return "#C61422";
        else if(buttonType === "nochange") return "#3A536B";
        else if(buttonType === "default") return "#EEEEEE";
        else return '#000000'; 
    },
    borderWidth: '0.125rem',
    borderStyle: 'solid',
    borderRadius: '0.5rem',
    borderColor: ({ buttonType }) => {
        if(buttonType === "lokalokator") return "#8CC63F";
        else if(buttonType === "seznam") return "#CC0000";
        else if(buttonType === "disabled") return "#E8E8E8";
        else return 'transparent';   
    },
    padding: ({ buttonType }) => {
        if(buttonType === "lokalokator") return "0.5rem 1rem";
        else if(buttonType === "seznam") return "0.5rem 1rem";
        else return '6px 16px';   
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.25s',
    textTransform: 'none',
    textDecoration: 'none',
    cursor: ({ buttonType }) => {
        if(buttonType === "lokalokator") return "pointer";
        else if(buttonType === "seznam") return "pointer";
        else if(buttonType === "disabled") return "default";
        else if(buttonType === "save") return "pointer";
        else if(buttonType === "play") return "pointer";
        else if(buttonType === "delete") return "pointer";
        else if(buttonType === "nochange") return "default";
        else return 'pointer';   
    },
    pointerEvents: ({ buttonType }) => {
        if(buttonType === "nochange") return "none";
        else return 'unset';   
    },
    '& span': {
      fontSize: "14px",
      whiteSpace: "nowrap",
      transition: 'all 0.25s',
      textTransform: 'none',
      textDecoration: 'none',
      color: ({ buttonType }) => {
        if(buttonType === "lokalokator") return "#8CC63F";
        else if(buttonType === "seznam") return "#CC0000";
        else if(buttonType === "disabled") return "#949494";
        else if(buttonType === "default") return "#111111";
        else return "#FFFFFF";
      },
    },
    '& svg': {
      width: ({ buttonType }) => {
        if(buttonType === "lokalokator") return "auto";
        else if(buttonType === "seznam") return "auto";
        else return '1em';
      },
      height: ({ buttonType }) => {
        if(buttonType === "lokalokator") return "2rem";
        else if(buttonType === "seznam") return "2rem";
        else return '1em';
      },
      transition: 'all 0.5s',
      filter: ({ buttonType }) => {
        if(buttonType === "lokalokator") return "contrast(100%) brightness(100%)";
        else if(buttonType === "seznam") return "contrast(100%) brightness(100%)";
        else if(buttonType === "disabled") return "grayscale(100%)";
        else return 'none';
      }, 
    },
    '&:hover': {
      backgroundColor: ({ buttonType }) => {
        if(buttonType === "lokalokator") return "#8CC63F";
        else if(buttonType === "seznam") return "#CC0000";
        else if(buttonType === "save") return "#7EB238";
        else if(buttonType === "play") return "#7EB238";
        else if(buttonType === "delete") return "#B2121E";
        else if(buttonType === "nochange") return "#3A536B";
        else if(buttonType === "default") return "#D6D6D6";
        else return '#111111';
      },
      boxShadow: ({ buttonType }) => {
        if(buttonType === "chip") return "0px 5px 7px 0px rgba(158, 158, 158, 0.5)";
        else return 'none';
      },
      '& span': {
        color: ({ buttonType }) => {
          if(buttonType === "lokalokator") return "#FFFFFF";
          else if(buttonType === "seznam") return "#FFFFFF";
          else if(buttonType === "save") return "#FFFFFF";
          else if(buttonType === "play") return "#FFFFFF";
          else if(buttonType === "default") return "#000000";
          else return '#FFFFFF';
        },      
      },
      '& svg': {
        filter: ({ buttonType }) => {
          if(buttonType === "lokalokator") return "contrast(0%) brightness(200%)";
          else if(buttonType === "seznam") return "contrast(0%) brightness(200%)";
          else return 'none';
        },      
      },
    },
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: 'unset !important',
      height: 'unset !important',    
    },
    '& svg': {
      color: "#8CC63F",
      width: 'unset',
      height: 'unset',
    }
  },
}));

const NormalButton = ({ type, buttonType, className, startIcon, oneClick, onClick, children, disabled, tabIndex }) => {
  if(buttonType == "disabled") disabled = true;
  if(disabled) buttonType = "disabled";
  const classes = useStyles({ buttonType });
  const [isClicked, setIsClicked] = useState(false);
  
  const handleClick = (e) => {
    if(oneClick) {
      setIsClicked(true);
    }
    if(onClick && !disabled) {
      onClick(e);
    }
  }; 
  
  return (
    <Button
      type={type}
      startIcon={isClicked ? (<div className={classes.spinner}><CircularProgress /></div>) : startIcon}
      className={`${classes.root} ${className}`}
      onClick={handleClick}
      disabled={disabled || isClicked}
      tabIndex={tabIndex}
    >
      {children}
    </Button>
  )
};

export default NormalButton;
