const modeFormat = (mode, t) => {
  let formatedMode = "";
  if(mode === "explorer") formatedMode = t('Režim Průzkumník');
  else if(mode === "challenge") formatedMode = t('Režim Výzva');
	else if(mode === "streaks") formatedMode = t('Režim Série');
  else if(mode === "battleroyal") formatedMode = t('Režim Bitva');
	else if(mode === "minigames") formatedMode = t('Režim Minihry');
	else formatedMode = t('Neznámý režim');
  return formatedMode;
}

export default modeFormat;