import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../constants/config';
import DocumentTitle from "react-document-title";
import * as PlayService from "../../../services/content/play.service";
import * as ModeStreaksService from "../../../services/content/modeStreaks.service";          
import * as xpFormat from "../../../utils/xpFormat";
import FinishImage from '../../../components/Images/FinishImage';
import XpBar from '../../../components/Bars/XpBar';
import NormalButton from "../../../components/Buttons/NormalButton";
import { Context, GameContext } from "../../../contexts";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Snackbar from "../../../components/Notifications/Snackbar";
import CachedImage from '../../../components/Images/CachedImage';

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    height: 'calc(100vh - 8.375rem)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 7.125rem)',
    },
    '& svg': {
      color: "#8CC63F",
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    minHeight: 'calc(100vh - 4.375rem)',
    flex: '1 0 auto',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 3.125rem)',                               
    },
  },
  mapWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    flex: '1 1 auto',
    [theme.breakpoints.down('sm')]: {
  		minHeight: '400px',                          
    }, 
    '& > #mapa': {
      width: '100%',
      minHeight: '40%',
      flex: '1 1 100%',
      '& .card': {
        zIndex: '3',
        '& > .card-header': {
          '& > strong': {
            fontWeight: 'bold',
          },
        },
        '& > .card-body': {
          '& > p': {
            fontSize: '0.8rem',
            marginTop: '0-25rem',
          },
        },
        '& > .card-footer': {
          '& > p': {
            fontSize: '0.8rem',
            marginTop: '0-25rem',
          },
          '& > a': {
            fontSize: '0.8rem',
            marginTop: '0-25rem',
            color: '#8CC63F',          
          },
        },
      },
      '& .cluster': {
        '& > div': {
          color: '#FFFFFF !important',
          backgroundColor: 'transparent',
          backgroundImage: 'url("https://api.lokalokator.cz/images/marker/cluster-red.png")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          border: '0',
          fontSize: '0',
          transform: 'scale(0.5)',
          '& > img': {
            display: 'none',
          },        
        },
      },
      '& a.marker.type-paid': {
        zIndex: '1',
        cursor: 'help',
      },
      '& a.marker.type-poi': {
        zIndex: '1',
        cursor: 'help',
      },
    },    
  },
  data: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    minHeight: 'fit-content',
    flex: '0 1 fit-content',
    backgroundColor: 'rgb(0,0,119)',
    backgroundImage: 'linear-gradient(0deg, rgba(0,0,119,1) 0%, rgba(194,191,255,1) 100%)',
    padding: '2rem 0',
    '& > h1': {
      color: '#E5B513',
      fontWeight: '700',
      fontSize: '2rem',
    },
    '& > h2': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '1rem',
      color: '#FFFFFF',
      fontWeight: '600',
      marginTop: '1rem',
      '& > span': {
        display: 'flex',
        alignItems: 'center',
      },
      '&::after': {
        content: `''`,
      	width: '50%',
      	height: '0.125rem',
        marginTop: '0.25rem',
      	backgroundColor: '#DDDDDD',        
      },
    },
    '& > h3': {
      marginTop: '1rem',
      color: 'rgba(255,255,255,0.75)',
      fontWeight: '500',
    },
    '& > h4': {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '3rem',
      color: 'rgba(255,255,255,0.75)',
      fontWeight: '500',
      '&::after': {
        content: `''`,
      	width: '100%',
      	height: '0.125rem',
        marginTop: '0.25rem',
      	backgroundColor: '#DDDDDD',        
      },
    },
    '& > div:first-of-type': {
      marginTop: '1rem',
      color: '#FFFFFF',
    },
    '& > div:last-of-type': {
      display: 'flex',
      gap: '2rem',
      marginTop: '3rem',
      borderTopWidth: '0.125rem',
      borderTopStyle: 'solid',
      borderTopColor: '#DDDDDD',
      padding: '2rem 4rem',
    },
  },
  correctImage: {
    display: 'inline-block',
    width: '2rem',
    height: '1.313rem',
    marginLeft: '0.25rem',
  },
}));

const ResultsStreaks = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const navigate = (to) => history.push(to);
  const { contextData, setContextData } = useContext(Context);
  const { gameContextData, setGameContextData } = useContext(GameContext);
  let gameData = gameContextData;
  const userData = contextData.userData;
  const authToken = contextData.authToken;
  const gid = gameData.gid; 
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const userXP = userData.XP;
  
  useEffect(() => {
    ModeStreaksService.getGameResults(gid, authToken).then((result) => {
      setGameContextData({...gameContextData, ...result.data});
      gameData = {...gameData, ...result.data};
      setIsLoaded(true);
      window.Loader.load(null, {poi:true}, initMap);
    });
  }, []);
  
  let SMap;
  let JAK;
  let mapa;
  
  const initMap = (data) => {
    SMap = window.SMap;
    JAK = window.JAK; 
    let stred = SMap.Coords.fromWGS84(15.3386383,49.7437572);
		mapa = new SMap(JAK.gel("mapa"), stred, 7);
		mapa.addDefaultLayer(SMap.DEF_BASE).enable();
    mapa.setZoomRange(7, 19);
    mapa.addDefaultLayer(SMap.DEF_BASE).enable();
    let zajmy = new SMap.Layer.Marker(undefined, {
    	poiTooltip: true
    });
    mapa.addLayer(zajmy).enable();
    let dataProvider = mapa.createDefaultDataProvider();
    dataProvider.setOwner(mapa);
    dataProvider.addLayer(zajmy);
    dataProvider.setMapSet(SMap.MAPSET_BASE);
    dataProvider.enable();
    let move = new SMap.Control.Mouse(1);
    let scroll = new SMap.Control.Mouse(2);
    let zoom = new SMap.Control.Zoom('', {showZoomMenu: false});
    let sync = new SMap.Control.Sync({bottomSpace:0});
    mapa.addControl(move);
    mapa.addControl(scroll);
    mapa.addControl(zoom);
    mapa.addControl(sync);
    let click = false;
    let removeLink = function() {
    	if(document.querySelector("a.print")) document.querySelector("a.print").removeAttribute("href");
    }
    let signals = mapa.getSignals();
    signals.addListener(window, "map-redraw", removeLink);
    removeLink();
    let layer_geometry = new SMap.Layer.Geometry();
		mapa.addLayer(layer_geometry);
		layer_geometry.enable();
    const polygonData = JSON.parse(gameData.WasCorrect.Polygon);
	  let points = [];
    polygonData.map((value) => {
      points.push(SMap.Coords.fromWGS84(value[0], value[1]));
    });
  	let options = {
      color: "#547625",
      outlineColor: "#547625",
  	};
  	let polygon = new SMap.Geometry(SMap.GEOMETRY_POLYGON, null, points, options);
  	layer_geometry.addGeometry(polygon);
    let layer_marker = new SMap.Layer.Marker();
		mapa.addLayer(layer_marker);
		layer_marker.enable();
    let marker_location = gameData.WasCorrect.Location.split(",");
  	let marker_coords = SMap.Coords.fromWGS84(parseFloat(marker_location[0]),parseFloat(marker_location[1]));
    let marker_mark = JAK.mel("div");
    let marker_image = JAK.mel("img", {src: 'https://api.lokalokator.cz/images/marker/drop-blank.png'});
    marker_mark.appendChild(marker_image);
  	let marker_description = JAK.mel("div", {}, {position: 'absolute', left: '-6px', top: '10px', textAlign: 'center', width: '22px', color: 'white', fontWeight: 'bold', zIndex: '2'});
  	marker_description.innerHTML = '<div id="location">...</div>';
		marker_mark.appendChild(marker_description);
  	let marker_marker = new SMap.Marker(marker_coords, 'location', {url: marker_mark});
  	let marker_card = new SMap.Card();
  	marker_card.getHeader().innerHTML = "";
  	marker_marker.decorate(SMap.Marker.Feature.Card, marker_card);
  	layer_marker.addMarker(marker_marker);
  	let marker_answer = function(geocoder) {
     	let results = geocoder.getResults();
     	marker_card.getHeader().innerHTML = '<strong>Zobrazená lokace</strong>';
     	marker_card.getBody().innerHTML = '<p>' + results.items[0].name + ', ' + results.items[2].name + '</p>';
  	  marker_card.getFooter().innerHTML = '<a href="https://mapy.cz/?x=' + marker_location[0] + '&y=' + marker_location[1] + '&z=16&source=coor&id=' + marker_location[0] + '%2C' + marker_location[1] + '" target="_Blank">Zobrazit na mapě</a>';
  	}
  	new SMap.Geocoder.Reverse(marker_coords, marker_answer);
    ReactDOM.render(<FinishImage size="1.5rem"/>, document.getElementById("location"));
  };
  
  const updateGameData = (payload) => {
    PlayService.editGameData("streaks",payload,contextData.authToken).then((result) => {}).catch((error) => {
      setIsFailed(true);
    });
  };
  
  const handlePlay = () => {
    const dateround = moment().format("YYYY-MM-DD HH:mm:ss");
    setGameContextData({...gameContextData, Phase: ["Round|" + (parseInt(gameData.Phase[1]) + 1)], DateRound: dateround});
    const payload = {
      "gid": gid,
      "phase": "Round|" + (parseInt(gameData.Phase[1]) + 1),
      "dateround": dateround
    };
    updateGameData(payload);   
  };

  return isLoaded ? (
    <div className={classes.main}>
      <div className={classes.mapWrapper}>
        <div id="mapa"></div>
      </div>
      <div className={classes.data}>
        <h1>{t('Výborně, jen tak dál!')}</h1>
        <h2><span>{t('Správná odpověď byla skutečně')}&nbsp;{t(gameData.WasCorrect.Name)}{gameData.WasCorrect.Image && (<CachedImage className={classes.correctImage} fullsizeLink={gameData.WasCorrect.Image}/>)}</span></h2>
        <h3>
        {
          gameData.Type === "Regions" && (gameData.Guessed.length === 1 ? t('Tvá série zatím pokračuje uhádnutím 1 kraje') : t('Tvá série zatím pokračuje uhádnutím') + ' ' + gameData.Guessed.length.toString() + ' ' + t('krajů'))
        }
        {
          gameData.Type === "Districts" && (gameData.Guessed.length === 1 ? t('Tvá série zatím pokračuje uhádnutím 1 okresu') : t('Tvá série zatím pokračuje uhádnutím') + ' ' + gameData.Guessed.length.toString() + ' ' + t('okresů'))
        }
        </h3>
        <h4>{t('Získané zkušenosti')}</h4>
        <XpBar currentLevel={xpFormat.levelFromXP(parseInt(userXP))} currentLevelXP={xpFormat.levelXP(parseInt(xpFormat.levelFromXP(parseInt(userXP))) - 1)} currentXP={parseInt(userXP)} nextXP={xpFormat.neededXP(parseInt(userXP))} gainXP={"20"}/>
        <div>
          <NormalButton buttonType="play" startIcon={<ArrowForwardOutlinedIcon/>} oneClick={true} onClick={handlePlay}>
            {t('Další kolo')}
          </NormalButton>
        </div>
      </div>
      <Snackbar
        open={isFailed}
        autoHideDuration={3000}
        onClose={() => setIsFailed(false)}
        type="error"
        content={t("Něco se nepovedlo")}
      />
    </div>
  ) : (<div/>)
};



export default ResultsStreaks;