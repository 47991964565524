import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../constants/config';
import DocumentTitle from "react-document-title";
import GoogleAds from "react-google-ads";
import CountUp from 'react-countup';
import { InView } from 'react-intersection-observer';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined'; 
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import AltRouteOutlinedIcon from '@mui/icons-material/AltRouteOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import NormalButton from "../../../components/Buttons/NormalButton";
import * as HomepageService from "../../../services/content/homepage.service";
import * as SearchService from "../../../services/content/search.service";
import * as ModeExplorerService from "../../../services/content/modeExplorer.service";
import streaksTypeFormat from "../../../utils/streaksTypeFormat";
import CircularProgress from '@material-ui/core/CircularProgress';
import Maps from '../../../components/Items/Maps';
import Avatar from '../../../components/Images/Avatar';
import { Context, GameContext } from "../../../contexts";
import Snackbar from "../../../components/Notifications/Snackbar";

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  searchBanner: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '30rem',
    flex: '0 0 30rem',
    backgroundColor: 'rgb(193,193,193)',
    backgroundImage: 'linear-gradient(180deg, rgba(193,193,193,1) 0%, rgba(95,96,95,1) 100%)',
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 10rem',
      height: "30rem",
    },
    '&::before': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundImage: 'url("https://api.lokalokator.cz/images/banners/banner_homepage.png")',
      backgroundSize: '100% auto',
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
      zIndex: '1',
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundImage: 'linear-gradient(to bottom, rgba(252,250,249,0) 90%,rgba(252,250,249,1) 100%)',
      zIndex: '2',
    },
    '& > h1': {
      visibilty: 'hidden',
      fontSize: '0',
      opacity: '0',   
    },
    '& > svg': {
      position: 'relative',
      width: '45rem',
      height: 'auto',
      '-webkit-user-select': 'none',
      '-khtml-user-select': 'none',
      '-moz-user-select': 'none',
      '-o-user-select': 'none',
      userSelect: 'none',
      '-webkit-user-drag': 'none',
      '-khtml-user-drag': 'none',
      '-moz-user-drag': 'none',
      '-o-user-drag': 'none',
      userDrag: 'none',
      pointerEvents: 'none',
      zIndex: '3',
      [theme.breakpoints.down('sm')]: {
        width: '15rem',
      },    
    }, 
  },
  searchWrapper: {
    position: 'relative',
    width: '80%',
    height: 'auto',
    marginTop: '1rem',
    zIndex: '3',
    '& > svg': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '0.5rem',
      color: '#FFFFFF',
      cursor: 'help',
    }  
  },
  searchInput: {
    position: 'relative',
    width: 'calc(100% - 1.5rem)',
    padding: '1.125rem 0.75rem',
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: '#FFFFFF',
    border: '0',
    boxShadow: 'none',
    '-webkit-appearance': 'none',
    outline: 'none',
    lineHeight: 'normal',
    fontSize: '1.25rem',
    borderRadius: '0.25rem',
    '&::placeholder': {
      color: '#EEEEEE',
      opacity: '1',    
    },
    '&:-ms-input-placeholder': {
      color: '#EEEEEE',
    },
    '&::-ms-input-placeholder': {
      color: '#EEEEEE',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem 0.75rem',
      fontSize: '1rem',
    },    
  },
  results: {
    position: 'absolute',
    top: '100%',
    left: '0',
    right: '0',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '20rem',
    overflow: 'hidden',
    boxShadow: '0rem 0rem 2rem -0.5rem rgba(0,0,0,0.75)',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: '#E5DCCE',
    borderRadius: '0 0 0.25rem 0.25rem',
    zIndex: '4',
    [theme.breakpoints.down('sm')]: {
      maxHeight: "10rem",
    },
    '&:empty': {
      maxHeight: '0rem',
      border: '0',  
    },
    '&:not(:empty)': {
      '& + input': {
        borderRadius: '0.25rem 0.25rem 0 0',
      },
    },
    '& > ul': {
      position: 'relative',
      backgroundColor: '#FFFFFF',
      color: '#000000',
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '100%',
      overflowY: 'auto',
      '& > li': {
        display: 'flex',
        flexDirection: 'row',
        '& + li': {
          borderTopWidth: '0.1rem',
          borderTopStyle: 'solid',
          borderTopColor: '#E5DCCE',
        },
        '&.searching': {
          justifyContent: 'center',
          fontWeight: 'bold',        
        },
        '&.empty': {
          justifyContent: 'center',
          fontWeight: 'bold',        
        },
        '&.viewmore': {
          justifyContent: 'center',
          fontWeight: 'bold',
          textDecoration: 'underline',
          cursor: 'pointer',
          '& > a': {
            color: '#000000',
            transition: 'color 0.5s',
            '&:hover': {
              color: '#8CC63F',            
            },          
          },       
        },
        '&.map': {
          '& > span': {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            padding: '0.5rem 0',
            '& > svg': {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              fontSize: '2rem',
              width: '3rem',
              color: '#8CC63F',           
            }, 
            '& > span': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              color: '#000000',
              fontSize: '0.85rem',
              '& > a': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: '#8CC63F',
                cursor: 'pointer',
                width: '100%',
                fontSize: '1rem',         
              },
              '& > strong': {
                color: '#000000',
                fontSize: '0.8rem',              
              },
            },
          },
        },
        '&.category': {
          '& > span': {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            padding: '0.5rem 0',
            '& > svg': {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              fontSize: '2rem',
              width: '3rem',
              color: '#8CC63F',           
            }, 
            '& > span': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              color: '#000000',
              fontSize: '0.85rem',
              '& > a': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: '#8CC63F',
                cursor: 'pointer',
                width: '100%',
                fontSize: '1rem',         
              },
              '& > strong': {
                color: '#000000',
                fontSize: '0.8rem',              
              },
            },
          },
        },
        '&.user': {
          '& > span': {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            padding: '0.5rem 0',
            '& > div': {
              margin: '0 0.5rem',
            },
            '& > span': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              color: '#000000',
              fontSize: '0.85rem',
              '& > a': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: '#8CC63F',
                cursor: 'pointer',
                width: '100%',
                fontSize: '1rem',         
              },
              '& > strong': {
                color: '#000000',
                fontSize: '0.8rem',              
              },         
            },
          },        
        },
        '&.mode': {
          '& > span': {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            padding: '0.5rem 0',
            '& > svg': {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              fontSize: '2rem',
              width: '3rem',
              color: '#8CC63F',           
            }, 
            '& > span': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              color: '#000000',
              fontSize: '0.85rem',
              '& > a': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: '#8CC63F',
                cursor: 'pointer',
                width: '100%',
                fontSize: '1rem',         
              },
              '& > strong': {
                color: '#000000',
                fontSize: '0.8rem',              
              },
            },
          },
        },
        '&.streaktype': {
          '& > span': {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            padding: '0.5rem 0',
            '& > svg': {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              fontSize: '2rem',
              width: '3rem',
              color: '#8CC63F',           
            }, 
            '& > span': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              color: '#000000',
              fontSize: '0.85rem',
              '& > a': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: '#8CC63F',
                cursor: 'pointer',
                width: '100%',
                fontSize: '1rem',         
              },
              '& > strong': {
                color: '#000000',
                fontSize: '0.8rem',              
              },
            },
          },
        },       
      },    
    },
  },
  subTitle: {
    color: '#8CC63F',
    fontSize: '2rem',
    marginTop: '1rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  randomMap: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '2rem auto',
    padding: '1rem 0',
    backgroundColor: 'rgb(193,193,193)',
    backgroundImage: 'linear-gradient(180deg, rgba(69,93,92,1) 0%, rgba(2,13,66,1) 100%)',
    width: 'calc(100% - 4rem)',
    minHeight: '40vh',
    borderRadius: '1rem',
    '& > svg': {
      textAlign: 'center',
      marginLeft: 'auto',
      width: '8rem',
      height: '8rem',
      flex: '1 1 auto',
      color: '#FFD700',
      transform: 'scale(1)',
      transition: 'transform 0.5s',
      [theme.breakpoints.down('sm')]: {
        width: '4rem',
        height: "4rem",
      },    
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flex: '1 1 auto',
      padding: '0 6rem',
      [theme.breakpoints.down('sm')]: {
        padding: '0 0.65rem',
      },
      '& > span:first-of-type': {
        fontSize: '1.5rem',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
          fontSize: "0.65rem",
        },      
      },
      '& > span:last-of-type': {
        marginTop: '0.5rem',
        fontSize: '2rem',
        textTransform: 'uppercase',
        color: '#FFD700',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
          fontSize: "0.75rem",
        },       
      },
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '2rem',
        [theme.breakpoints.down('sm')]: {
          marginTop: "1rem",
        }, 
        '& > span': {
          fontSize: '1.5rem',
          textTransform: 'uppercase',
          color: '#FFFFFF',
          whiteSpace: 'nowrap',
          marginLeft: '2rem',
          [theme.breakpoints.down('sm')]: {
            fontSize: "0.75rem",
            marginLeft: '1rem',
          }, 
          '& > span': {
            color: '#FFD700',        
          },        
        },      
      },    
    },
    '&:hover': {
      '& > svg': {
        transform: 'scale(1.2)',      
      },
    },  
  },
  maps: {
  	width: '100%',
  	display: 'flex',
  	flexDirection: 'row',
    '& > p': {
      display: 'block',
      width: '100%',
      textAling: 'center',
      paddingTop: '1rem',
    },
    '& > div': {
    	width: '100%',
    	display: 'flex',
    	flexDirection: 'column',    
    },  
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    '& svg': {
      color: "#8CC63F",
    }
  },
}));

const Homepage = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const navigate = (to) => history.push(to);
    
  const [totalLocations, setTotalLocations] = useState(0);
  const [mostPlayedMaps, setMostPlayedMaps] = useState({});
  const [newestMaps, setNewestMaps] = useState({}); 
  const [isMostPlayedMapsLoading, setIsMostPlayedMapsLoading] = useState(true);
  const [isNewestMapsLoading, setIsNewestMapsLoading] = useState(true);
  const [isCounterInView, setIsCounterInView] = useState(false);
  const { contextData, setContextData } = useContext(Context);
  const { gameContextData, setGameContextData } = useContext(GameContext);
  const authToken = contextData.authToken;
  const userStatus = contextData.userStatus;
  const [isUnknownError,setIsUnknownError] = useState(false);  
  const [isWrongType,setIsWrongType] = useState(false);
  const [isTooMuchOngoingGames,setIsTooMuchOngoingGames] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  
  useEffect(() => {
    HomepageService.getHomepageData(7,contextData.authToken).then((result) => {
      if(result) {
        if(result.data) {
          setTotalLocations(result.data.location_count);
          setMostPlayedMaps(result.data.most_played_maps);
          setIsMostPlayedMapsLoading(false);
          setNewestMaps(result.data.newest_maps);
          setIsNewestMapsLoading(false);
        }
      }
    });
  }, []);
  
  const handleRandomPlay = () => {
    if(userStatus) {
      ModeExplorerService.newGame("Random", authToken).then((result) => {
        if(result) {
          if(result.data) {
            setGameContextData({...gameContextData, gid: result.data.GID});
            navigate('/play');
          }
        }
      }).catch((error) => {
        if (error.response) {
          if(error.response.data) {
            switch(error.response.data) {
              case "WrongType": 
                setIsWrongType(true);
                break;
              case "TooMuchOngoingGames": 
                setIsTooMuchOngoingGames(true);
                break;
              default:
                setIsUnknownError(true); 
            }
          }
        }
      });  
    } else {
      setContextData({...contextData, showNotLoggedModal: true});  
    }
  }; 
  
  const handleSearch = (e) => {
    const search = e.target.value;
    setSearchValue(search);
    if(search.length >= 3) {
      setIsSearching(true);
      SearchService.getSearchData(search,"all","25",false).then((result) => {
        if(result) {
          if(result.data) {
            setSearchResults(result.data);
            setIsSearching(false);
          }
        }
      });    
    }
  };
  
  return (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Prozkoumejte Česko')}`}> 
      <section className={classes.section}>
        <div className={classes.searchBanner}>
      		<h1>{t('Lokalokátor')}</h1>
      		<SVG src="https://api.lokalokator.cz/images/logo_subtitle.svg" alt={t('Lokalokátor')} width="0" height="0"/>
      		<div className={classes.searchWrapper}>
      			<div className={classes.results}>
            {
              searchValue.length >= 3 && (
                <ul>
                {
                  searchValue.length >= 3 && isSearching ? (
                    <li className="searching">{t('Probíhá hledání')}...</li>
                  ) : searchResults.length === 0 ? (
                    <li className="empty">{t('Nic nebylo nalezeno')}</li>
                  ) : searchResults.map((result, key) => (
                    <li className={result.Type.toLowerCase()} key={`${"k_"}${key}`}>
                      {
                        result.Type === "Map" && (
                          <span>
                            <LocationOnOutlinedIcon/>
                            <span>
                              <Link to={{pathname: '/mode/explorer/' + result.MID + '/map'}}>{t(result.Name)}</Link>
                              <strong>{t(result.Category.Name)}</strong>
                            </span> 
                          </span>
                        )  
                      }
                      {
                        result.Type === "Category" && (
                          <span>
                            <LocationCityOutlinedIcon/>
                            <span>
                              <Link to={{pathname: '/mode/explorer/' + result.Shortcode.toLowerCase() + '/category'}}>{t(result.Name)}</Link>
                              <strong>{t('Kategorie map')}</strong>
                            </span> 
                          </span>
                        )  
                      }
                      {
                        result.Type === "User" && (
                          <span>
                            <Avatar userData={result}/>
                            <span>
                              <Link to={{pathname: '/profile/' + result.UID}}>{result.Nickname}</Link>
                              <strong>{t('Uživatel')}</strong>
                            </span> 
                          </span>
                        )  
                      }
                      {
                        result.Type === "Mode" && (
                          <span>
                            <AltRouteOutlinedIcon/>
                            <span>
                              <Link to={{pathname: '/mode/' + result.Shortcode}}>{t('Režim')} {t(result.Name)}</Link>
                              <strong>{t('Režim')}</strong>
                            </span> 
                          </span>
                        )  
                      }
                      {
                        result.Type === "StreakType" && (
                          <span>
                            <BoltOutlinedIcon/>
                            <span>
                              <Link to={{pathname: '/mode/streaks/' + result.Shortcode.toLowerCase()}}>{streaksTypeFormat(result.Shortcode,t)}</Link>
                              <strong>{t('Typ režimu Série')}</strong>
                            </span> 
                          </span>
                        )  
                      }
                    </li>  
                  )) 
                }
                </ul>
              )
            }
            </div>
      			<input className={classes.searchInput} type="text" placeholder={t('Hledejte mapy nebo hráče')} onKeyUp={handleSearch}/>
      			<Tooltip title={t('Pro více výsledků stiskněte klávesu Enter')}>
              <InfoOutlinedIcon/>
            </Tooltip>
      		</div>
      	</div>
      	<h2 className={classes.subTitle}>{t('Nejhranější mapy')}</h2>
      	<div className={classes.maps}>
          {
            isMostPlayedMapsLoading ? (
              <div className={classes.spinner}>
                <CircularProgress />
              </div>
            ) : Object.keys(mostPlayedMaps).length === 0 ? (
              <p>{t('Není tu žádná nehranější mapa')}</p>
            ) : (
              <div>
                <Maps items={Object.values(mostPlayedMaps).slice(0,3)} size="large"/>
                {
                  Object.keys(mostPlayedMaps).length > 3 && (
                    <Maps items={Object.values(mostPlayedMaps).slice(3,7)} size="small"/>   
                  ) 
                }
              </div>           
            )
          }
        </div>
      	<div className={classes.randomMap}>
      		<SVG src="/resources/images/icons/dices.svg" alt={t("Náhodná mapa")} width="0" height="0"/>
      		<div>
      			<span>{t('Zahrajte si mapu vytvořenou z')}</span>
      			<span>{t('náhodných míst po celé České republice!')}</span>
      			<div>
              <NormalButton buttonType="default" oneClick={true} type="button" tabIndex="-1" startIcon={<SportsEsportsOutlinedIcon/>} onClick={handleRandomPlay}>
        				{t('Hrát')}
        			</NormalButton>
              <InView as="span" onChange={(inView) => !isCounterInView && setIsCounterInView(inView)}>
                {
                  isCounterInView ? (
                    <CountUp end={totalLocations} />
                  ) : (
                    0
                  )
                }
                &nbsp;{t('lokací')}
              </InView>
      			</div>
      		</div>
      	</div>
        <GoogleAds
          client="ca-pub-6188128495565166"
          slot="9798032968"
          style={{ display: 'inline-flex', width: '100%', height: '5vh', justifyContent: 'center', }}
        />
        <h2 className={classes.subTitle}>{t('Nejnovější mapy')}</h2>
	      <div className={classes.maps}>
          {
            isNewestMapsLoading ? (
              <div className={classes.spinner}>
                <CircularProgress />
              </div>
            ) : Object.keys(newestMaps).length === 0 ? (
              <p>{t('Není tu žádná nejnovější mapa')}</p>
            ) : (
              <div>
                <Maps items={Object.values(newestMaps).slice(0,3)} size="large"/>
                {
                  Object.keys(newestMaps).length > 3 && (
                    <Maps items={Object.values(newestMaps).slice(3,7)} size="small"/>   
                  ) 
                }
              </div>           
            )
          }
        </div>
        <Snackbar
          open={isUnknownError}
          autoHideDuration={3000}
          onClose={() => setIsUnknownError(false)}
          type="error"
          content={t("Vyskytla se neznámá chyba")}
        />
        <Snackbar
          open={isWrongType}
          autoHideDuration={3000}
          onClose={() => setIsWrongType(false)}
          type="warning"
          content={t("Neplatný typ hry")}
        />
        <Snackbar
          open={isTooMuchOngoingGames}
          autoHideDuration={3000}
          onClose={() => setIsTooMuchOngoingGames(false)}
          type="warning"
          content={t("Máte moc rozehraných her. Dohrajte nějakou nebo ji smažte.")}
        />
      </section>
    </DocumentTitle>
  );
};



export default Homepage;