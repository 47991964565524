import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingTop: '1rem',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none',
    lineHeight: '0.8rem',
    '& > label': {
    	marginLeft: '0.5rem',
    	fontSize: '0.8rem',
    	width: '90%',
      cursor: ({ disabled }) => {
        if (!disabled) return "pointer";
        else return 'default';
      },
      '& a': {
        color: '#8cc63f',
        textDecoration: 'none',
      },
    },
    '& > input': {
      position: 'absolute',
      opacity: '0',
      cursor: ({ disabled }) => {
        if (!disabled) return "pointer";
        else return 'default';
      },
      height: '0',
      width: '0',
      '&:focus': {
        '& + svg > path': {
          filter: 'drop-shadow(0 0 0.1rem rgba(140, 198, 63, 1))',
        },
      },
    },
  },
  'checkmark': {
    color: ({ disabled }) => {
      if (!disabled) return "#8CC63F";
      else return '#949494';
    },
    cursor: ({ disabled }) => {
      if (!disabled) return "pointer";
      else return 'default';
    },
  },
}));

const Checkbox = ({label, checked = false, name, value, onChange, disabled, tabIndex}) => {
  const [checkboxChecked,setCheckboxChecked] = useState(checked);
  const handleChange = (e) => {
    if(!disabled) {
      let value = !checkboxChecked;
      setCheckboxChecked(value);
      onChange(name, value);
    } 
  };
  const classes = useStyles({disabled});

  return (
    <div className={classes.checkbox} onClick={handleChange}>
      <input
        type="checkbox"
        checked={checkboxChecked}
        name={name}
        disabled={disabled}
        tabIndex={tabIndex}
        value={value}
        onClick={handleChange}
        readOnly={true}
      />
      {
        checkboxChecked ? (
          <CheckBoxIcon className={classes.checkmark}/>
        ) : (
          <CheckBoxOutlineBlankIcon className={classes.checkmark}/>
        )  
      }
      <label>
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.object,
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
};

export default Checkbox;
