const phaseFormat = (phase, rounds, t) => {
  let formatedPhase = "";
  if(phase === "Lobby") formatedPhase = t('Lobby');
	else if(phase === "Invite") formatedPhase = t('Lobby - Pozvání hráčů');
	else if(phase === "Finished") formatedPhase = t('Dohráno');
	else {
		let phases = phase.split("|");
		if(phases[0] === "Round") formatedPhase = t('Kolo');
		else if(phases[0] === "Result") formatedPhase = t('Výsledek kola');
    if(phases[1] != null) {
		  formatedPhase = formatedPhase + ' ' + phases[1];
      if(rounds != null) {
        formatedPhase = formatedPhase + ' / ' + rounds;  
      }
    }
	}
  return formatedPhase;
}

export default phaseFormat;