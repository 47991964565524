import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../../../constants/config';
import DocumentTitle from "react-document-title";
import GoogleAds from "react-google-ads";
import CountUp from 'react-countup';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import NormalButton from "../../../../../components/Buttons/NormalButton";
import * as ModeExplorerService from "../../../../../services/content/modeExplorer.service";
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import CachedImage from '../../../../../components/Images/CachedImage';
import HighScoresTable from '../../../../../components/Tables/Score';
import Maps from '../../../../../components/Items/Maps'; 
import { Context } from "../../../../../contexts";
import pointsFormat from "../../../../../utils/pointsFormat";
import timeFormat from "../../../../../utils/timeFormat";
import { useParams }  from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    height: 'calc(100vh - 8.375rem)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 7.125rem)',
    },
    '& svg': {
      color: "#8CC63F",
    }
  },
  header: { 
    backgroundColor: '#A3B28E',
    position: 'relative',
    width: '100%',
    height: '400px',
    '& > .coat': {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      width: 'auto',
      height: 'auto',
      zIndex: '2',
      '& > img': {
        width: '72px',
        height: 'auto',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-o-user-select': 'none',
        userSelect: 'none',
        '-webkit-user-drag': 'none',
        '-khtml-user-drag': 'none',
        '-moz-user-drag': 'none',
        '-o-user-drag': 'none',
        userDrag: 'none',
        pointerEvents: 'none',
      },
    },
  },
  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    zIndex: '1',
    '& > h1': {
      marginTop: 'auto',
      color: '#FFFFFF',
      fontSize: '48px',
      [theme.breakpoints.down('sm')]: {
      	fontSize: '20px',                         
      },
    },
    '& > p': {
      maxWidth: '80%',
      color: '#FFFFFF',
      fontSize: '18px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
      	fontSize: '14px',                         
      },
    },
    '& > button': {
      marginTop: 'auto',
      marginBottom: '1rem',
      width: '200px',
    },  
  },
  infoBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr))',
    gridColumnGap: '1rem',
    gridRowGap: '2rem',
    justifyItems: 'center',
    width: '100%',
    backgroundImage: 'linear-gradient(180deg, rgba(69,93,92,1) 0%, rgba(2,13,66,1) 100%)',
    padding: '2rem 0 4rem 0',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      padding: '2rem 0 6rem 0',                      
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundImage: 'linear-gradient(to bottom, rgba(252,250,249,0) 90%,rgba(252,250,249,1) 100%)',
      zIndex: '2',
    },
    '& > li': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: '0.75rem',
      width: '300px',
      height: '75px',
      whiteSpace: 'nowrap',
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
      color: '#8CC63F',
      borderRadius: '10px',
      fontWeight: 'bold',
      '& > svg': {
        marginLeft: '1rem',
        marginRight: '1rem',
        width: '2.25rem',
        height: '2.25rem',
        color: '#FFFFFF',
      },
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        '& > span': {
          fontSize: '1.25rem',
          color: '#FFFFFF',
          textTransform: 'uppercase',
        },       
      }           
    },        
  },
  scoreIndicator: {
    display: 'flex',
    gap: '0.063rem',
    alignItems: 'flex-end',
    marginLeft: '1rem',
    marginRight: '1.5rem',
    color: '#FFFFFF',
    '& > span': {
      display: 'block',
      backgroundColor: 'currentColor',
    },
    '& > span:nth-child(1)': {
      width: '0.313rem',
      height: '0.5rem',
    },
    '& > span:nth-child(2)': {
      width: '0.313rem',
      height: '0.875rem',
    }, 
    '& > span:nth-child(3)': {
      width: '0.313rem',
      height: '1.25rem',
    }, 
    '& > span:nth-child(4)': {
      width: '0.313rem',
      height: '1.625rem',
    }, 
    '& > span:nth-child(5)': {
      width: '0.313rem',
      height: '2rem',
    },     
    '&.score_1': {
      '& > span.color': {
        color: '#57D600',
      },
    },
    '&.score_2': {
      '& > span.color': {
        color: '#8CC63F',
      },
    },
    '&.score_3': {
      '& > span.color': {
        color: '#E99235',
      },
    },
    '&.score_4': {
      '& > span.color': {
        color: '#D11B26',
      },
    },
    '&.score_5': {
      '& > span.color': {
        color: '#FF0000',
      },
    },
  },
  score: {
  	display: 'flex',
  	flexDirection: 'column',
  	alignItems: 'center',
  	padding: '5rem 0',
  	width: '80%',
  	margin: '0 auto',
    '& > h2': {
    	color: '#8cc63f',
    	fontSize: 'clamp(1rem, 2vw, 2rem)',
    	marginBottom: '3rem',
    },
    '& > a': {
      textDecoration: 'none',
      '& > button': {
        marginTop: '3rem',
      },
    },  
  },
}));

const ModeExplorerScores = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory(); 
  const mid = useParams().id;
  const navigate = (to) => history.push(to);
  const { contextData, setContextData } = useContext(Context);
  const [mapData, setMapData] = useState(null);
  const [mapScores, setMapScores] = useState(null);
  const userStatus = contextData.userStatus;  
  const [isMapDataLoaded, setIsMapDataLoaded] = useState(false);
  const [isMapScoresLoaded, setIsMapScoresLoaded] = useState(false);
  
  
  useEffect(() => {
    ModeExplorerService.getMapData(mid,contextData.authToken).then((result) => {
      setMapData(result.data);
      setIsMapDataLoaded(true);
    });
    ModeExplorerService.getMapScores(mid).then((result) => {
      setMapScores(result.data);
      setIsMapScoresLoaded(true);
    });
  }, []);

  
  return (isMapDataLoaded && isMapScoresLoaded) ? (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Skóre')} ${'-'} ${mapData.Name}`}> 
      <section className={classes.section}>
        <div className={classes.header}>
          <CachedImage thumbLink={mapData.thumbLink} fullsizeLink={mapData.fullsizeLink}/>
          {
            mapData.Coat != null && (
              <CachedImage className={"coat"} fullsizeLink={mapData.Coat} isResponsive={false}/>
            )
          }
          <div className={classes.overlay}>
            <h1>{mapData.Name}</h1>
            <p>{mapData.Description}</p>
            <NormalButton buttonType="primary" onClick={() => navigate("/mode/explorer/" + mid + "/map/")} startIcon={<ArrowBackOutlinedIcon/>}>
              {'Zpět'}
            </NormalButton>
          </div>
        </div>
        <ul className={classes.infoBox}>
          <li>
            <TimerOutlinedIcon/>
            <div>
              <span>{t('Čas')}</span>
              {timeFormat(mapData.TimeLimit, false, t)}
            </div>
          </li>
          <li>
            <ReplayOutlinedIcon/>
            <div>
              <span>{t('Počet kol')}</span>
              {mapData.Rounds}
            </div>
          </li>
          <li>
            <LocationOnOutlinedIcon/>
            <div>
              <span>{t('Počet lokací')}</span>
              {mapData.Locations}
            </div>
          </li>
          <li>
            <span className={`${classes.scoreIndicator} ${"score_"}${mapData.Score.Indicator.length}`}>
              {
                [...Array(mapData.Score.Indicator.length)].map((i, key) => (
                  <span key={`${"k_"}${key}`} className={"color"}></span>
                ))
              }
              {
                [...Array(5 - mapData.Score.Indicator.length)].map((i, key) => (
                  <span key={`${"k_"}${key}`}></span>
                ))
              }
            </span>
            <div>
              <span>
                {t('Obtížnost')}
               </span>
               {t("Průměrné skóre")}: {pointsFormat(mapData.Score.Data, t)}
            </div>
          </li>
          <li>
            <GroupsOutlinedIcon/>
            <div>
              <span>{t('Počet hraní')}</span>
              {mapData.TotalPlay}
            </div>
          </li>
          <li>
            <ThumbUpOutlinedIcon/>
            <div>
              <span>{t('Počet palců')}</span>
              {mapData.Likes}
            </div>
          </li>
        </ul>
        <div className={classes.score}>
          <h2>{t('Skóre')}</h2>
          <HighScoresTable data={Object.values(mapScores)} fillEmpty={true}/>
        </div>
      </section>
    </DocumentTitle>
  ) : (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Načítání skóre')}`}>
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    </DocumentTitle>
  );
};



export default ModeExplorerScores;