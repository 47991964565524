const xpStart = 100;

const levelFromXP = (xp) => {
  xp = parseInt(xp);
  let level = 0;
  let levelSet = false;
  let i = 0;
  if(xp === 0) {
    levelSet = true;
  }
  while (!levelSet) {
    if(xp - (parseInt(xpStart * (i + 1)) + parseInt(10 * i)) >= 0) {
      i++;
    } else {
      levelSet = true;
      level = i;      
    }
  }

  return level;
}

const neededXP = (xp) => {
  xp = parseInt(xp);
  let needed = 100;
  let amountSet = false;
  let i = 0;
  if(xp === 0) {
    amountSet = true;
  }
  while (!amountSet) {
    if(xp - (parseInt(xpStart * (i + 1)) + parseInt(10 * i)) >= 0) {
      i++;
    } else {
      amountSet = true;
      needed = (parseInt(xpStart * (i + 1)) + parseInt(10 * i));      
    }
  }
  return needed;
}

const levelXP = (level) => {
  level = parseInt(level);
  let xp = 100;
  if(level > 0) {
    xp = (parseInt(xpStart * (level + 1)) + parseInt(10 * level));
  }
  return xp;
}


export {
  levelFromXP,
  neededXP,
  levelXP
};