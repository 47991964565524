import React, {useContext} from 'react';
import {makeStyles, useTheme} from "@material-ui/core";
import config from '../../../constants/config';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { Context } from "../../../contexts";

const useStyles = makeStyles(() => ({
  root: {
  	position: 'relative',
  	display: 'flex',
  	flexDirection: 'column',
  	backgroundColor: '#1A1A1A',
  	color: '#FFFFFF',
  	width: 'calc(100% - 2rem)',
  	padding: '2rem 1rem',
  },
  row: {
	  display: 'flex',
	  flexDirection: 'row',
	  flexWrap: 'wrap',  
  },
  cell: {
    display: 'flex',
    flexDirection: 'column',
  	flex: '1 0 calc(50% - 2rem)',
  	padding: '1rem',
    '& > p': {
      color: '#FFFFFF',
      fontSize: 'clamp(0.75rem,1vw,1rem)',    
    },  
  },
  block: {
    display: 'flex',
    flexDirection: 'column',  
  },
  title: {
  	width: '100%',
  	fontSize: 'clamp(1rem,1.5vw,1.75rem)',
  	paddingBottom: '0.5rem',
  	marginBottom: '0.5rem',
  	borderBottom: '0.1rem solid #2A2A2A',  
  },
  text: {
    padding: '1rem',  
  },
  providers: {
    padding: '1rem',
  },
  links: {
  	display: 'flex',
  	flexDirection: 'row',
  	flexWrap: 'wrap',
  	justifyContent: 'flex-start',
  	alignItems: 'center',
  	width: '100%',
    '& > a': {
    	color: '#FFFFFF',
    	fontSize: 'clamp(0.75rem,1vw,1rem)',
    	padding: '0.25rem 0.25rem',
    	whiteSpace: 'nowrap',
      textDecoration: 'none',
      cursor: 'pointer',    
    },  
  },
  socials: {
  	display: 'flex',
  	flexDirection: 'row',
  	justifyContent: 'flex-start',
  	alignItems: 'center',
  	width: '100%',
    '& > a': {
    	display: 'flex',
    	flex: '0 0 auto',
    	flexDirection: 'column',
    	justifyContent: 'center',
    	alignItems: 'center',
    	height: '2rem',
    	width: '2rem',
    	borderRadius: '0.25rem',
    	color: '#FFFFFF',
    	fontSize: '1.25rem',
      '& > span': {
      	visibility: 'hidden',
      	whiteSpace: 'nowrap',
      	fontSize: '0',      
      },    
    },  
  },
  images: {
	  display: 'flex',
	  flexDirection: 'row',
	  flexWrap: 'wrap',
    gap: '0.5rem',
    '& > a': {
    	width: 'auto',
    	height: '3rem',
    	margin: '0.5rem 0',
      '& > img': {
      	width: 'auto',
      	height: '100%',
      	filter: 'contrast(0%) brightness(200%)',      
      },      
    },  
  },
}));

const Footer = ({}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const navigate = (to) => history.push(to);
  const theme = useTheme();
  const { contextData, setContextData } = useContext(Context);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isToggled = contextData.isToggledMenu; 
  
  const handleNavigate = (to) => {
    if(isToggled && isMobile) {
      setContextData({...contextData, isToggledMenu: !isToggled});
    }
    navigate(to);
  };
    
  return (
  	<footer className={classes.root}>
  		<div className={classes.row}>
  			<div className={classes.cell}>
  				<h3 className={classes.title}>Rychlé odkazy</h3>
  				<div className={classes.links}>
  					<a onClick={() => handleNavigate("/policies/privacy")} tabIndex="-1" title="Ochrana osobních údajů">
  						Ochrana osobních údajů
  					</a>
  					<a onClick={() => handleNavigate("/policies/terms")} tabIndex="-1" title="Podmínky použití">
  						Podmínky použití
  					</a>
            <Link to={{  pathname: "https://www.shean.eu" }} target="_blank" tabIndex="-1" title="Shean." rel="nofollow">
  						Shean.
  					</Link>
            <Link to={{  pathname: "https://www.mapy.cz" }} target="_blank" tabIndex="-1" title="Mapy.cz" rel="nofollow">
  						Mapy.cz
  					</Link>
  				</div>
  			</div>
  			<div className={classes.cell}>
  				<h3 className={classes.title}>Sociální sítě</h3>
  				<div className={classes.socials}>
            <Link to={{  pathname: "https://www.facebook.com/lokalokator" }} target="_blank" tabIndex="-1" title="Facebook" rel="nofollow">
  						<FacebookOutlinedIcon/>
  						<span>Facebook</span>
  					</Link>
            <Link to={{  pathname: "https://twitter.com/lokalokator" }} target="_blank" tabIndex="-1" title="Twitter" rel="nofollow">
  						<TwitterIcon/>
  						<span>Twitter</span>
  					</Link>
            <Link to={{  pathname: "https://instagram.com/lokalokator.cz" }} target="_blank" tabIndex="-1" title="Instagram" rel="nofollow">
  						<InstagramIcon/>
  						<span>Instagram</span>
  					</Link>
            <Link to={{  pathname: "mailto:info@lokalokator.cz" }} target="_blank" tabIndex="-1" title="Email" rel="nofollow">
  						<MailOutlinedIcon/>
  						<span>Email</span>
  					</Link>
  				</div>
  			</div>
  		</div>
  		<div className={`${classes.block} ${classes.text}`}>
  			<h3 className={classes.title}>Prohlášení</h3>
  			<p>Tato webová stránka není součástí Mapy.cz ani Seznam.cz. Navíc tato stránka není Seznamem žádným způsobem podporována. Mapy.cz je služba společnosti Seznam.cz, a.s.</p>
  		</div>
  		<div className={`${classes.block} ${classes.providers}`}>
  			<h3 className={classes.title}>Poskytovatelé dat</h3>
  			<div className={classes.images}>
          <Link to={{  pathname: "https://www.mapy.cz" }} target="_blank" tabIndex="-1" title="Mapy.cz" rel="nofollow">
  					<img src="/resources/images/dataproviders/mapy-cz.png" alt="Mapy.cz"/>
  				</Link>
          <Link to={{  pathname: "https://www.openstreetmap.org" }} target="_blank" tabIndex="-1" title="Openstreetmap.org" rel="nofollow">
  						<img src="/resources/images/dataproviders/openstreetmap-org.png" alt="Openstreetmap.org"/>
  				</Link>
          <Link to={{  pathname: "https://www.wikipedia.org" }} target="_blank" tabIndex="-1" title="Wikipedia.org" rel="nofollow">
  					<img src="/resources/images/dataproviders/wikipedia-org.png" alt="Wikipedia.org"/>
  				</Link>
  			</div>
  		</div>
  	</footer>
  );
};      
export default Footer;
