import React, { useEffect, useState, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles, useMediaQuery, withStyles } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import config from '../../../../../constants/config';
import DocumentTitle from "react-document-title";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import FmdGoodTwoToneIcon from '@mui/icons-material/FmdGoodTwoTone';
import VrpanoOutlinedIcon from '@mui/icons-material/VrpanoOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import NormalButton from "../../../../../components/Buttons/NormalButton";
import Modal from '@mui/material/Modal';
import Button from "@material-ui/core/Button";
import * as ModeCreatorService from "../../../../../services/content/modeCreator.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Context, CreatorContext } from "../../../../../contexts";
import CachedImage from '../../../../../components/Images/CachedImage';
import hotkeys from 'hotkeys-js';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  banner: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '30rem',
    flex: '0 0 30rem',
    backgroundColor: 'rgb(157,55,55)',
    backgroundImage: 'linear-gradient(0deg, rgba(157,55,55,1) 0%, rgba(5,149,245,1) 100%)',
    [theme.breakpoints.down('sm')]: {
      height: '10rem',
      flex: '0 0 10rem',
    },
    '& > svg': {
      color: '#E5B513',
      fontSize: '6rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '3rem',
      },
    }, 
  },
  title: {
    color: '#FFFFFF',
    fontSize: '4rem',
    marginTop: '1rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    flex: '1',
    '& svg': {
      color: "#8CC63F",
    }
  },
  notlogged: {
    width: "auto",
    maxWidth: '100%',
    overflow: "auto",
    padding: "1.25rem",
    margin: "1.25rem",
    flex: "1",
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
      fontSize: '1.25rem',
    },
  },
  body: {
    padding: "1.25rem 0",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1.25rem",
    gap: '1rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 8rem)',
    padding: '4rem',
  },
  row: {
    display: 'flex',
    width: '100%',
    gap: '2rem',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      '&:nth-child(1)': {
        width: 'calc((100% - 16rem) / 2)',
      },
      '&:nth-child(2)': {
        width: 'calc((100% - 16rem) / 2)',
      },
      '&:nth-child(3)': {
        width: '16rem',
      },
      '& > label': {
        display: 'flex',
        width: '100%',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: '600',
        marginBottom: '0.25rem',
        '& > a': {
          textDecoration: 'none',
          color: '#8CC63F',
          marginLeft: 'auto',
          fontWeight: '400',
        },
      },
      '& > span': {
        display: 'flex',
        width: '100%',
        color: '#000000',
        fontSize: '0.8rem',
        marginBottom: '0.25rem',
        fontWeight: '600',
        '& > a': {
          textDecoration: 'none',
          color: '#8CC63F',
          marginLeft: 'auto',
          fontWeight: '400',
        },
      },
      '& > input': {
        width: '90%',
        padding: '0.75rem 1.125rem',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        borderRadius: '0.25rem',
        borderWidth: '0.125rem',
        borderStyle: 'solid',
        borderColor: '#E1E1E1',
        boxShadow: 'none',
        '-webkit-appearance': 'none',
        outline: 'none',
        lineHeight: 'normal',
        marginTop: '0.5rem',
        transition: 'all 0.5s',
        '&:focus': {
          borderColor: '#8CC63F',
        },      
      },
      '& > select': {
        width: '90%',
        padding: '0.75rem 1.125rem',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        borderRadius: '0.25rem',
        borderWidth: '0.125rem',
        borderStyle: 'solid',
        borderColor: '#E1E1E1',
        boxShadow: 'none',
        '-webkit-appearance': 'none',
        outline: 'none',
        lineHeight: 'normal',
        marginTop: '0.5rem',
        transition: 'all 0.5s',
        '&:focus': {
          borderColor: '#8CC63F',
        },      
      },
      '& > div': {
        width: '16rem',
        paddingTop: '9rem',
        borderRadius: '0.25rem',
        '& > div': {
          backgroundSize: 'contain !important',
          backgroundPosition: 'unset !important',
          backgroundRepeat: 'no-repeat !important',
        },
      },
    },
  },
  textarea: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '1rem',
    marginBottom: '3rem', 
    '& > label': {
      display: 'flex',
      width: '100%',
      color: '#000000',
      fontSize: '0.8rem',
      fontWeight: '600',
      marginBottom: '0.25rem',
      '& > a': {
        textDecoration: 'none',
        color: '#8CC63F',
        marginLeft: 'auto',
        fontWeight: '400',
      },
    },
    '& > textarea': {
      width: 'calc(100% - 2.5rem)',
      padding: '0.75rem 1.125rem',
      backgroundColor: '#FFFFFF',
      color: '#000000',
      borderRadius: '0.25rem',
      borderWidth: '0.125rem',
      borderStyle: 'solid',
      borderColor: '#E1E1E1',
      boxShadow: 'none',
      '-webkit-appearance': 'none',
      outline: 'none',
      lineHeight: 'normal',
      marginTop: '0.5rem',
      transition: 'all 0.5s',
      minHeight: '10rem',
      resize: 'none',
      overflow: 'auto',
      '&:focus': {
        borderColor: '#8CC63F',
      },      
    },
  },
  mapWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  	minHeight: '90vh',
    position: 'relative',
    '&.available': {
      cursor: 'crosshair',
    },
    '&.unavailable': {
      cursor: 'not-allowed',
    },
    '&.panorama-available': {
      '& canvas': {
        opacity: '1',
      }
    },
    '&.panorama-unavailable': {
      '& canvas': {
        opacity: '0',
      }
    },
    '& > #mapa': {
      width: '100%',
      minHeight: '40%',
      flex: '1 1 100%',
      '& .card': {
        zIndex: '3',
        '& > .card-header': {
          '& > strong': {
            fontWeight: 'bold',
          },
        },
        '& > .card-body': {
          '& > p': {
            fontSize: '0.8rem',
            marginTop: '0-25rem',
          },
        },
        '& > .card-footer': {
          '& > p': {
            fontSize: '0.8rem',
            marginTop: '0-25rem',
          },
          '& > a': {
            fontSize: '0.8rem',
            marginTop: '0-25rem',
            color: '#8CC63F',          
          },
        },
      },
      '& .cluster': {
        '& > div': {
          color: '#FFFFFF !important',
          backgroundColor: 'transparent',
          backgroundImage: 'url("https://api.lokalokator.cz/images/marker/cluster-red.png")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          border: '0',
          fontSize: '0',
          transform: 'scale(0.5)',
          '& > img': {
            display: 'none',
          },        
        },
      },
      '& a.marker.type-paid': {
        zIndex: '1',
        cursor: 'help',
      },
      '& a.marker.type-poi': {
        zIndex: '1',
        cursor: 'help',
      },
    },    
  },
  controls: {
    position: 'absolute',
    top: '0.5rem',
    left: '0.5rem',
    display: 'flex',
    gap: '1rem',
    zIndex: '2',
    '& > input': {
      width: '90%',
      padding: '0.5rem 0.75rem',
      backgroundColor: '#FFFFFF',
      color: '#000000',
      borderRadius: '0.25rem',
      borderWidth: '0.125rem',
      borderStyle: 'solid',
      borderColor: '#E1E1E1',
      boxShadow: 'none',
      '-webkit-appearance': 'none',
      outline: 'none',
      lineHeight: 'normal',
      transition: 'all 0.5s',
      '&:focus': {
        borderColor: '#8CC63F',
      },      
    },
    '& > button': {
      minWidth: '2.188rem',
      width: '2.188rem',
      maxWidth: '2.188rem',
      minHeight: '2.188rem',
      height: '2.188rem',
      maxHeight: '2.188rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      color: '#000000',
      borderRadius: '0.25rem',
      borderWidth: '0.125rem',
      borderStyle: 'solid',
      borderColor: '#E1E1E1',
      boxShadow: 'none',
      '-webkit-appearance': 'none',
      outline: 'none',
      lineHeight: 'normal',
      transition: 'all 0.5s',
      cursor: 'pointer',
      '&.active': {
        borderColor: '#8CC63F',
      },      
    },
    '& ul.smap-suggest': {
      display: 'flex',
      flexDirection: 'column',
    	left: '0.5rem',
    	top: '3rem',
    	width: '12.313rem',
    	backgroundColor: '#FFFFFF',
      borderRadius: '0.25rem',
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: '#CCCCCC',
    	maxHeight: 'calc(100vh - 7rem)',
      minHeight: '50vh',
    	overflow: 'auto',
      '& > li': {
        minHeight: 'fit-content !important',
      },
    }  
  },
}));

const ModeCreatorEdit = ({}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const navigate = (to) => history.push(to);
  const { contextData, setContextData } = useContext(Context);
  const { creatorContextData, setCreatorContextData } = useContext(CreatorContext);
  const authToken = contextData.authToken;
  const userStatus = contextData.userStatus;
  const mid = creatorContextData.mid;
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [mapData, setMapData] = useState({});
  const [categories, setCategories] = useState({});
  const [mapThumbLink, setMapThumbLink] = useState(null);
  const [mapAddLocations, _setMapAddLocations] = useState([]);
  const mapAddLocationsRef = useRef(mapAddLocations);
  const setMapAddLocations = data => {
    mapAddLocationsRef.current = data;
    _setMapAddLocations(data);
  };
  const [mapUpdateLocations, _setMapUpdateLocations] = useState([]);
  const mapUpdateLocationsRef = useRef(mapUpdateLocations);
  const setMapUpdateLocations = data => {
    mapUpdateLocationsRef.current = data;
    _setMapUpdateLocations(data);
  };
  const [mapRemoveLocations, _setMapRemoveLocations] = useState([]);
  const mapRemoveLocationsRef = useRef(mapRemoveLocations);
  const setMapRemoveLocations = data => {
    mapRemoveLocationsRef.current = data;
    _setMapRemoveLocations(data);
  };
  const [markerClicked, _setMarkerClicked] = useState(false);
  const markerClickedRef = useRef(markerClicked);
  const setMarkerClicked = data => {
    markerClickedRef.current = data;
    _setMarkerClicked(data);
  };
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isPanoramaVisible, setIsPanoramaVisible] = useState(false);
  const [isPOIVisible, setIsPOIVisible] = useState(false);
  const [poiLayer, setPOILayer] = useState(null);
  const [isPanoramaAvailable, _setIsPanoramaAvailable] = useState(false);
  const isPanoramaAvailableRef = useRef(isPanoramaAvailable);
  const setIsPanoramaAvailable = data => {
    isPanoramaAvailableRef.current = data;
    _setIsPanoramaAvailable(data);
  };
  const handleFullscreen = useFullScreenHandle();
  
  const mapName = useRef(null);
  const mapDescription = useRef(null);
  
  useEffect(() => {
    if(mid === undefined || !mid || mid === {} || mid === []) {
      navigate('/mode/creator');
    } else {
      if(userStatus) {
        if(mid) {
          ModeCreatorService.getMap(mid,authToken).then((result) => {
            setMapData(result.data);
            console.log(result.data);
            setMapThumbLink(result.data.thumbLink);
          });
          ModeCreatorService.getCategories().then((result) => {
            setCategories(result.data);
          });  
        }
      }
    }  
  }, []);
  
  useEffect(() => {
    if(Object.keys(mapData).length > 0 && Object.keys(categories).length > 0) {
      setIsLoaded(true);
      window.Loader.load(null, {suggest: true, pano: true, poi:true}, loadData);
    }
  }, [mapData, categories]);
  
  const handleLogin = () => {
    navigate('/login');
  };
  
  const handleRegister = () => {
    navigate('/register');
  }; 
  
  const handleUpdateImage = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setMapThumbLink('https://api.lokalokator.cz/images/third/mapycz.png');
    ModeCreatorService.updateImage(mid, authToken).then((result) => {
			setTimeout(function() {
        setMapThumbLink(mapData.thumbLink);
      }, 1500);
    }).catch((error) => {
      
    });
  };
  
  const handleImportWiki = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let value = mapName.current.value;
    ModeCreatorService.getWikipedia(value).then((result) => {
			let articles = JSON.parse(result.data).query.pages;
			let articleKey = Object.keys(articles)[0];
			let article = articles[articleKey];
			let extract = article.extract;
			extract = extract.replace(/(\r\n|\n|\r)/gm, "");
      mapDescription.current.value = extract;
    }).catch((error) => {
      
    });    
  };
  
  const handleUpdateFullscreen = () => {
    if(isFullscreen) {
      setIsFullscreen(false);
      handleFullscreen.exit();
    } else {
      setIsFullscreen(true);
      handleFullscreen.enter();   
    }                              
  };
  
  let SMap;
  let JAK;
  let mapa;
  let layer_locations;

  const loadData = () => {
    initMap(mapData);
  };
  
  const initMap = (data) => {
    SMap = window.SMap;
    JAK = window.JAK; 
    let centerData = data.Center.split("|");
    let coords = centerData[0].split(",");
    let center = SMap.Coords.fromWGS84(coords[0],coords[1]);
		mapa = new SMap(JAK.gel("mapa"), center, centerData[1]);
		mapa.addDefaultLayer(SMap.DEF_BASE).enable();
    mapa.setZoomRange(8, 19);
    mapa.addDefaultLayer(SMap.DEF_BASE).enable();
    let pano = mapa.addDefaultLayer(SMap.DEF_PANO).enable();
    let poi = new SMap.Layer.Marker(undefined, {
    	poiTooltip: true
    });
    mapa.addLayer(poi);
    let dataProvider = mapa.createDefaultDataProvider();
    dataProvider.setOwner(mapa);
    dataProvider.addLayer(poi);
    dataProvider.setMapSet(SMap.MAPSET_BASE);
    setPOILayer(dataProvider);
    let move = new SMap.Control.Mouse(1);
    let scroll = new SMap.Control.Mouse(2);
    let zoom = new SMap.Control.Zoom('', {showZoomMenu: false});
    let sync = new SMap.Control.Sync({bottomSpace:0});
    mapa.addControl(move);
    mapa.addControl(scroll);
    mapa.addControl(zoom);
    mapa.addControl(sync);
    let click = false;
    let removeLink = function() {
    	if(document.querySelector("a.print")) document.querySelector("a.print").removeAttribute("href");
    }
    let signals = mapa.getSignals();
    signals.addListener(window, "map-redraw", removeLink);
    signals.addListener(window, "marker-click", handleMarkerClick);
    signals.addListener(window, "map-click", handleMapClick);    
    layer_locations = new SMap.Layer.Marker();
    mapa.addLayer(layer_locations);
    layer_locations.enable();
    mapa.getSignals().addListener(this, "pano-available", function(e) {
    	setIsPanoramaAvailable(true);
    });
    mapa.getSignals().addListener(this, "pano-unavailable", function(e) {
    	setIsPanoramaAvailable(false);
    });
    let inputEl = document.querySelector("input#suggest");
    let suggest = new SMap.Suggest(inputEl, {
    	provider: new SMap.SuggestProvider({
      		updateParams: params => {
          		let c = mapa.getCenter().toWGS84();
          		params.lon = c[0].toFixed(5);
          		params.lat = c[1].toFixed(5);
        			params.locality = "cz";
        			params.category = "region_cz,district_cz,address_cz,country_cz,quarter_cz,street_cz,ward_cz,traffic_cz,poi_cz";
        			params.limit = 100;
        			params.count = 100;
              params.zoom = mapa.getZoom();
              params.bounds = "48.5370786,12.0921668|51.0746358,18.8927040";
              params.lang = "cs";
              params.infinity = false;
        		}
      	})
    });
    suggest._urlParams.limit = 100;
    suggest.addListener("suggest", suggestData => {
    	let coords = SMap.Coords.fromWGS84(suggestData.data.longitude,suggestData.data.latitude);
    	handleNewPoint(coords,true);
    	inputEl.value = '';
    });
    setTimeout(function() {
      suggest.open();
    }, 10000);
    loadMapData(data);
  };

  
  const loadMapData =(data) => {
    let llocations = [];
    let lcoords = [];
    let lmarks = [];
    let limages = [];
    let ldescriptions = [];
    let lmarkers = [];
    let lanswers = [];
    let lcards = [];
    data.Locations.map((location, key) => {
      llocations[key] = location.split(",");
  		lcoords[key] = SMap.Coords.fromWGS84(parseFloat(llocations[key][0]),parseFloat(llocations[key][1]));
    	lmarks[key] = JAK.mel("div", {}, {cursor: 'pointer'});
    	limages[key] = JAK.mel("img", {src: 'https://api.lokalokator.cz/images/marker/drop-red.png'});
    	lmarks[key].appendChild(limages[key]);
    	lmarkers[key] = new SMap.Marker(lcoords[key], 'location' + key, {url: lmarks[key]});
    	layer_locations.addMarker(lmarkers[key]);
    });
  };
  
  const handleMapClick = (signal) => {
  	if(isPanoramaAvailableRef) {
      let event = signal.data.event;
      let coords = SMap.Coords.fromEvent(event, mapa);
      new SMap.Geocoder.Reverse(coords, handleAddPoint);
  	}
  };
  const handleAddPoint = (geocoder) => {
  	let results = geocoder.getResults();
  	if(results.items[results.items.length - 1].name == "Česko") {
  		handleNewPoint(results.coords,true);
  	}
  };
  
  const handleMarkerClick = (e) => {
    let marker = e.target;
  	if(marker._poi) {
  		setMarkerClicked(true);
  		setTimeout(function() {
  			setMarkerClicked(false);
  		}, 100);
  		return false;
  	} else {
  		let coords = marker.getCoords();
  		let id = marker.getId();
  		if(id !== "center") {
  			handleOpenDialog(coords, id);
  			setMarkerClicked(true);
  			return false;
  		}
  	}
  };
  
  const handleOpenDialog = (coords, id) => {
    console.log(coords, id);
    setIsDialogOpen(true);
  };
  
  const handleNewPoint = (coords, openDialog) => {
    if(markerClickedRef.current) {
      setMarkerClicked(false);
    } else {
      let coords_string = coords.x + "," + coords.y;
      let key = mapAddLocationsRef.current.length;
      console.log(key);
    	let mark = JAK.mel("div", {}, {cursor: 'pointer'});
    	let image = JAK.mel("img", {src: 'https://api.lokalokator.cz/images/marker/drop-yellow.png'});
    	mark.appendChild(image);
    	let marker = new SMap.Marker(coords, 'newlocation' + key, {url: mark});
    	layer_locations.addMarker(marker);
      setMapAddLocations([...mapAddLocationsRef.current, coords]);
    } 
  };
  
  const handlePanoramaVisible = () => {
    setIsPanoramaVisible(!isPanoramaVisible);
  };
  
  const handlePOIVisible = () => {
    if(isPOIVisible) {
      poiLayer.disable();
      setIsPOIVisible(false);
    } else {
      poiLayer.enable();
      setIsPOIVisible(true);
    }
  };

   useEffect(() => {
    hotkeys('F11', function (event, handler) {
      switch (handler.key) {
        case 'F11': {
            event.stopPropagation();
            event.preventDefault();
            handleUpdateFullscreen();
          }
          break;
        default: {};
      }
    });
    return () => hotkeys.unbind('Z,C,R,F11');
  }, []); 
  
  return isLoaded ? (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Úprava mapy')} ${t(mapData.Name)}`}> 
      <section className={classes.section}>
        <div className={classes.banner}>
      		<MapOutlinedIcon/>
          <h1 className={classes.title}>{t('Úprava mapy')} {t(mapData.Name)}</h1>
      	</div>
        {
          userStatus ? (
          	<div className={classes.container}>
              <div className={classes.row}>
                <div>
                  <label htmlFor="name">{t('Název mapy')}</label>
                  <input type="text" id="name" defaultValue={t(mapData.Name)} placeholder={t('Název mapy')} ref={mapName}/>
                </div>
                <div>
                  <label htmlFor="category">{t('Kategorie')}</label>
                  <select id="category" defaultValue={mapData.Category}>
                  {
                    Object.values(categories).map((category, key) => (
                      <option key={`${"k_"}${key}`} value={category.Shortcode}>{t(category.Name)}</option>
                    ))
                  }
                  </select>
                </div>
                <div>
                  <span>
                    {t('Obrázek')}
                    <Link to={{pathName: '#'}} onClick={handleUpdateImage}>{t('Aktualizovat')}</Link>
                  </span>
                  <CachedImage thumbLink={mapThumbLink}/>
                </div>
              </div>
              <div className={classes.textarea}>
                  <label htmlFor="description">
                    {t('Popis mapy')}
                    <Link to={{pathName: '#'}} onClick={handleImportWiki}>{t('Importovat z Wikipedie')}</Link>
                  </label>
                  <textarea id="description" defaultValue={t(mapData.Description)} placeholder={t('Popis mapy')} ref={mapDescription}/>
                </div>
                <FullScreen handle={handleFullscreen}>
                  <div className={`${classes.mapWrapper} ${isPanoramaAvailable ? 'available' : 'unavailable'} ${isPanoramaVisible ? 'panorama-available' : 'panorama-unavailable'}`}>
                    <div className={classes.controls}>
                      <input id="suggest" placeholder={t('Hledat lokaci')}/>
                      <button></button>
                      <button></button>
                      <button className={`${isPanoramaVisible && 'active'}`} onClick={handlePanoramaVisible}>
                        <VrpanoOutlinedIcon/>
                      </button>
                      <button className={`${isPOIVisible && 'active'}`} onClick={handlePOIVisible}>
                        <PinDropOutlinedIcon/>
                      </button>
                      <button className={`${isFullscreen && 'active'}`} onClick={handleUpdateFullscreen}>
                        {
                          isFullscreen ? (<FullscreenOutlinedIcon/>) : (<FullscreenExitOutlinedIcon/>)
                        }         
                      </button>
                    </div>
                    <div className={classes.dialog}>
                    
                    </div>
                    <div id="mapa"></div>
                  </div>
                </FullScreen>
            </div>
          ) : (
            <div className={classes.notlogged}>
              <div className={classes.header}>
                <div className={classes.wrapper}>
                  <p>{t('Musíte být přihlášen')}</p>
                </div>
              </div>
              <div className={classes.body}>
                  Pro tento obsah musíte být přihlášen
              </div>
              <div className={classes.footer}>
                <NormalButton buttonType="play" onClick={handleLogin}>
                  {t('Přihlásit se')}
                </NormalButton>
                <NormalButton buttonType="default" onClick={handleRegister}>
                  {t('Zaregistrovat se')}
                </NormalButton>
              </div>
            </div>
          )         
        }
      </section>
    </DocumentTitle>
  ) : (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Načítání mapy')}`}> 
      <section className={classes.section}>
        <div className={classes.banner}>
      		<MapOutlinedIcon/>
          <h1 className={classes.title}>{t('Úprava mapy')}</h1>
      	</div>
        {
          userStatus ? (
            <div className={classes.spinner}>
              <CircularProgress />
            </div>
          ) : (
            <div className={classes.notlogged}>
              <div className={classes.header}>
                <div className={classes.wrapper}>
                  <p>{t('Musíte být přihlášen')}</p>
                </div>
              </div>
              <div className={classes.body}>
                  Pro tento obsah musíte být přihlášen
              </div>
              <div className={classes.footer}>
                <NormalButton buttonType="play" onClick={handleLogin}>
                  {t('Přihlásit se')}
                </NormalButton>
                <NormalButton buttonType="default" onClick={handleRegister}>
                  {t('Zaregistrovat se')}
                </NormalButton>
              </div>
            </div>
          )         
        }
      </section>
    </DocumentTitle>  
  );
};

export default ModeCreatorEdit;