import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactFitty } from "react-fitty";
import {useTranslation} from "react-i18next";
import Avatar from '../../../components/Images/Avatar';
import pointsFormat from '../../../utils/pointsFormat';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '1.25rem 0',
    boxSizing: 'border-box',
  },
  level: {
    textAlign: 'center',
    display: 'inline-block',
    fontSize: '1rem',
    lineHeight: '1rem',
    padding: '0rem 0.5rem',
    fontWeight: 'bold',
    verticalAlign: 'middle',
    letterSpacing: '0.063rem',
  },
  container: {
    display: 'inline-block',
    width: '60%',
    borderWidth: '0.063rem',
    borderStyle: 'solid',
    borderColor: '#E5B513',
    backgroundColor: 'rgba(0,0,0,0.1)',
    height: '1.875rem',
    verticalAlign: 'middle',
    padding: '0.125rem',
    boxSizing: 'border-box',
    position: 'relative',
    borderRadius: '0.25rem',
  },
  bar: {
    position: 'absolute',
    left: '0.125rem',
    height: 'calc(100% - 0.25rem)',
    top: '0.125rem',
    maxWidth: 'calc(100% - 0.25rem)',
    backgroundColor: '#E5B513',
    borderRadius: '0.25rem',
  },
}));

const PointsBar = ({ points = 0, userData }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const barPercent = (parseInt(points) / 5000) * 100;
  return (
    <div className={classes.root}>
      <div className={classes.level}>
        <Avatar userData={userData} size="1.75rem"/>
      </div>
      <div className={classes.container}>
        <div className={classes.bar} style={{width: barPercent + '%'}}/>
      </div>
      <div className={classes.level}>{pointsFormat(5000, t)}</div>
    </div>    
  );
};

export default PointsBar;
