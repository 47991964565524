import React, {useEffect, useState, useContext} from 'react';
import config from './constants/config';
import { Context, ContextDefaultData } from "./contexts";
import { Route, Switch, withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';
import {useHistory, useLocation} from 'react-router-dom';
import { io } from "socket.io-client";
import rgbHex from 'rgb-hex';

import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";

import NotFound from "./containers/NotFound";

import PoliciesPrivacy from "./containers/Policies/Privacy";
import PoliciesTerms from "./containers/Policies/Terms";

import Register from "./containers/Auth/Register";
import Login from "./containers/Auth/Login";
import ResetPasswordRequest from "./containers/Auth/ResetPassword/Request";
import ResetPassword from "./containers/Auth/ResetPassword";

import Homepage from "./containers/Main/Homepage";

import Settings from "./containers/Main/Settings";
import Play from "./containers/Main/Play";
import Results from "./containers/Main/Results";

import Profile from "./containers/Main/Profile";
import ProfileHistory from "./containers/Main/Profile/History";
import ProfileOngoing from "./containers/Main/Profile/Ongoing";
import ProfileLikedMaps from "./containers/Main/Profile/Liked/Maps";

import ModeExplorer from "./containers/Main/Mode/Explorer";
import ModeExplorerCategory from "./containers/Main/Mode/Explorer/Category";
import ModeExplorerMap from "./containers/Main/Mode/Explorer/Map";
import ModeExplorerScores from "./containers/Main/Mode/Explorer/Scores";

import ModeStreaks from "./containers/Main/Mode/Streaks";

import ModeMinigames from "./containers/Main/Mode/Minigames";

import ModeCreator from "./containers/Main/Mode/Creator";
import ModeCreatorEdit from "./containers/Main/Mode/Creator/Edit";

import Loadingscreen from "./components/Widgets/Loadingscreen";
import MainLayout from './layouts/MainLayout';

import StorageService from "./services/storage.service";
import * as AuthService from "./services/auth.service";
import * as MainService from "./services/main.service";
import * as UserService from "./services/user.service";
import useLocalStorageObject from './utils/useLocalStorageObject';
import './App.css';

const App = (props) => {

  const history = useHistory();
  const location = useLocation();
  
  const [isLoaded, setIsLoaded] = useState(false);
  const [userToken, setUserToken] = useLocalStorageObject(config.TOKEN_KEY, null);
  const [userData, setUserData] = useLocalStorageObject(config.USER_KEY, null);
  const [lastUserToken, setLastUserToken] = useState(null);
  const [lastUserData, setLastUserData] = useState(null);
  const [lastPath, setLastPath] = useState(null);
  const [configurationLoaded, setConfigurationLoaded] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const { contextData, setContextData } = useContext(Context);

  const ENDPOINT = "https://lokalokator.cz:8000";  
  const [response, setResponse] = useState(""); 
  
  const handleConfigurationInit = () => {
    MainService.getConfiguration(config.APP_VERSION).then((result) => {
      if(result) {
        if(result.data) {
          setContextData({...contextData, configuration: result.data});
          setConfigurationLoaded(true);
        }
      }
    }).catch((error) => {
      alert("Konfiguraci se nepodařilo načíst"); 
    });  
  };
  
  useEffect(() => {
    const socket = io.connect(ENDPOINT, {secure: true});
    socket.on("FromAPI", data => {
      setResponse(data);
    });
  }, []);
  
  const handleUserInit = () => {
    if(contextData.authToken != null && contextData.userData != null) {
      if(contextData.authToken !== lastUserToken || JSON.stringify(contextData.userData) !== JSON.stringify(lastUserData)) {
        handleUpdateUser(contextData.authToken);
      }
    } else if(userToken && userData) {
      if(userToken !== lastUserToken || userData !== lastUserData) {
        handleUpdateUser(userToken);
      }  
    } else {
      setUserLoaded(true);  
    }  
  }
  
  const handleForceLogout = (token) => {
    AuthService.logout(token).then((result) => {
      StorageService.removeItem(config.TOKEN_KEY);
      StorageService.removeItem(config.USER_KEY);
      setContextData({...ContextDefaultData, configuration: contextData.configuration});    
    }).catch((error) => {
      StorageService.removeItem(config.TOKEN_KEY);
      StorageService.removeItem(config.USER_KEY);
      setContextData({...ContextDefaultData, configuration: contextData.configuration});  
    });   
  };
  
  const handleUpdateUser = (token) => {
    UserService.updateUser(token).then((result) => {
      if(result) {
        if(result.data) {
          if(result.data.user) {
            setLastUserToken(token);
            setLastUserData(result.data.user);
            setUserData(result.data.user);
            setContextData({...contextData, authToken: token, userData: result.data.user, userStatus: true});
            if(!userLoaded) setUserLoaded(true);
          }
        }
      }
    }).catch((error) => {
      handleForceLogout(token);   
    });
    setUserLoaded(true);  
  }; 
  
  useEffect(() => {
    handleConfigurationInit();
    StorageService.setItem("lokalokator.images",JSON.stringify({count: 0, loaded : 0}));
    StorageService.setItem("lokalokator.cachedImages",JSON.stringify({}));   
  }, []);
  
  useEffect(() => {
    if(configurationLoaded) {  
      handleUserInit();
    }
  }, [configurationLoaded]);   
  
  useEffect(() => {  
    if(contextData.authToken != null) {
      if(contextData.updateUser != null) {
        handleUpdateUser(contextData.authToken);
      }
    }
  }, [contextData.updateUser]);
  
  useEffect(() => {
    if(isLoaded) {
      setTimeout(function() {
        if(document.querySelector('header')) {
          const headerBackgroundColor = '#' + rgbHex(window.getComputedStyle(document.querySelector('header'), null).getPropertyValue('background-color'));
          document.querySelector('meta[name="theme-color"]').setAttribute("content", headerBackgroundColor);
        }    
      }, 500);
    }
  }, [contextData.userData?.Settings?.Theme]);
  
  useEffect(() => {
    if(isLoaded) {
      if(contextData.authToken != null) {
        handleUpdateUser(contextData.authToken);
      }
    }  
  }, [location]);
  
  useEffect(() => {
    if(configurationLoaded && userLoaded) {
      setTimeout(function() { 
        setIsLoaded(true);
        if(document.querySelector('header')) {
          const headerBackgroundColor = '#' + rgbHex(window.getComputedStyle(document.querySelector('header'), null).getPropertyValue('background-color'));
          document.querySelector('meta[name="theme-color"]').setAttribute("content", headerBackgroundColor);
        }  
      }, 1000);    
    }
  }, [configurationLoaded,userLoaded]);
  
  return isLoaded ? (
    <MainLayout>
      <Switch>
        <PublicRoute
          exact
          path="/"
          component={Homepage}
          props={props}
        />
        <PublicRoute
          exact
          path="/register"
          isAuthPage
          component={Register}
        />
        <PublicRoute
          exact
          path="/login"
          component={Login}
          isAuthPage
          props={props}
        />
        <PublicRoute
          exact
          path="/reset-password-request"
          isAuthPage
          component={ResetPasswordRequest}
          props={props}
        />
        <PublicRoute
          exact
          path="/reset-password/:id"
          isAuthPage
          component={ResetPassword}
          props={props}
        />           
        <Route
          path="/"
        >
          <Switch>
            <PrivateRoute
              exact
              path="/settings"
              component={Settings}
              props={props}
            />
            <PrivateRoute
              exact
              path="/play"
              component={Play}
              props={props}
            />
           <PublicRoute
              exact
              path="/results/:id"
              component={Results}
              props={props}
            />
           <PublicRoute
              exact
              path="/profile"
              component={Profile}
              props={props}
            />
            <PrivateRoute
              exact
              path="/profile/history"
              component={ProfileHistory}
              props={props}
            />
            <PrivateRoute
              exact
              path="/profile/ongoing"
              component={ProfileOngoing}
              props={props}
            />
            <PrivateRoute
              exact
              path="/profile/friends"
              component={NotFound}
              props={props}
            />
            <PrivateRoute
              exact
              path="/profile/badges"
              component={NotFound}
              props={props}
            />
            <Route
              path="/profile/liked"
            >
              <Switch>
                <PrivateRoute
                  exact
                  path="/profile/liked/maps"
                  component={ProfileLikedMaps}
                  props={props}
                />
                <Route component={NotFound}/>
              </Switch>
            </Route>
            <PublicRoute
              exact
              path="/profile/:id"
              component={Profile}
              props={props}
            />
            <Route
              path="/mode/"
            >
              <Switch>
                <Route
                  path="/mode/explorer/"
                >
                  <Switch>
                    <PublicRoute
                      exact
                      path="/mode/explorer"
                      component={ModeExplorer}
                      props={props}
                    />
                    <PublicRoute
                      exact
                      path="/mode/explorer/:id/category"
                      component={ModeExplorerCategory}
                      props={props}
                    />
                    <PublicRoute
                      exact
                      path="/mode/explorer/:id/map"
                      component={ModeExplorerMap}
                      props={props}
                    />
                   <PublicRoute
                      exact
                      path="/mode/explorer/:id/scores"
                      component={ModeExplorerScores}
                      props={props}
                    />
                    <Route component={NotFound}/>                  
                  </Switch>
                </Route>
                <PublicRoute
                  exact
                  path="/mode/streaks"
                  component={ModeStreaks}
                  props={props}
                />
                <PublicRoute
                  exact
                  path="/mode/streaks/:id"
                  component={ModeStreaks}
                  props={props}
                />
                <PublicRoute
                  exact
                  path="/mode/minigames"
                  component={ModeMinigames}
                  props={props}
                />
                <PublicRoute
                  exact
                  path="/mode/creator"
                  component={ModeCreator}
                  props={props}
                />
                <PublicRoute
                  exact
                  path="/mode/creator/edit"
                  component={ModeCreatorEdit}
                  props={props}
                />
                <Route component={NotFound}/>
              </Switch>
            </Route>
            <Route
              path="/policies/"
            >
              <Switch>
                <PublicRoute
                  exact
                  path="/policies/privacy"
                  component={PoliciesPrivacy}
                  props={props}
                />
                <PublicRoute
                  exact
                  path="/policies/terms"
                  component={PoliciesTerms}
                  props={props}
                />
              </Switch>
            </Route>
            <Route component={NotFound}/>
          </Switch>   
        </Route>
        <Route component={NotFound}>
          <Route component={NotFound}/>
        </Route>
      </Switch>
    </MainLayout>
  ) : (
    <div>
      <Loadingscreen/>
    </div>
  )
};

export default withRouter(App);