import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import config from '../../constants/config';
import DocumentTitle from "react-document-title";
import ErrorCompass from '../../components/Widgets/ErrorCompass';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100vw - 48px)',
    overflow: 'auto',
    padding: '24px',
    flex: '0 0 auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      fontSize: '48px',
      textAlign: 'center',
      color: '#8CC63F',
      [theme.breakpoints.down('sm')]: {
        fontSize: '36px',
      },
    },
    '& > p': {
      marginTop: '16px',
      textAlign: 'center',
      '& > a': {
        color: '#8CC63F',
      },
    },
  },                                                        
}));

const NotFound = ({}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <DocumentTitle title={`${config.APP_NAME} ${config.APP_DELIMITER} ${t('Nic nebylo nalezeno')}`}>                            
      <section className={classes.root}>
        <ErrorCompass/>                                                                                        
        <span>{t('Nic nebylo nalezeno')}</span>
        <p>{t('Zatím tu nic není')}</p>
      </section>
    </DocumentTitle>
  );
};

export default NotFound;
